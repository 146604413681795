import useUpdateIngredientGroupName from "../../../hooks/ingredientGroup/useUpdateIngredientGroupName";
import IngredientGroupForm from "../../Forms/IngredientGroup/IngredientGroupForm";

import {useState} from "react";
import ButtonLink from "../../Buttons/ButtonLink";

const UpdateIngredientGroup = ({item, reload}) => {

    const [updateOpenModal, setUpdateOpenModal] = useState(false);
    const {update} = useUpdateIngredientGroupName();

    const handleOk = (values) => {
        let obj = {
            id: item.id,
            ...values
        }

        update(obj).then(() => {
            reload();
            setUpdateOpenModal(false);
        })
    }

    return <>
        <ButtonLink text={'Edycja'} onClick={() => setUpdateOpenModal(true)}/>
        <IngredientGroupForm
            name={item.name}
            open={updateOpenModal}
            onCancel={() => setUpdateOpenModal(false)}
            handleOK={handleOk}
            title={'Edytuj nazwe'}
        />
    </>
}

export default UpdateIngredientGroup;
import {Modal, Select, Typography} from "antd";
import {useState} from "react";
import useMargeProducts from "../../../hooks/products/useMargeProducts";

const {Option} = Select;
const AssignProductsByNameModal = ({openModal, setOpenModal, selectedProducts}) => {

    const [selectedProduct, setSelectedProduct] = useState(null);
    const {margeProducts} = useMargeProducts();


    const handleOk = () => {
        let tmp = selectedProducts.map((it) => it.id);
        margeProducts(
            selectedProduct,
            tmp
        ).then(() => {
            setOpenModal(false);
        });
    };

    const handleCancel = () => {
        setOpenModal(false);
    };

    return <Modal title='Łączenie produktów' open={openModal} onOk={handleOk}
                  onCancel={handleCancel} key='modal'>
        Zaznaczone produkty do połączenia: <br/>
        {
            selectedProducts.map((item, index) => <div key={index}><Typography.Text strong
                                                                                    key={item.id}>{index + 1}. {item.name}</Typography.Text><br/>
            </div>)
        }
        Wybierz docelowy produkt: <br/>
        <Select style={{width: "100%"}} onChange={(value, option) => setSelectedProduct(option.key)}>
            {
                selectedProducts.map((item, index) => <Option key={item.id}
                                                              value={item.id}>{index + 1 + '. ' + item.name}</Option>)
            }
        </Select>
    </Modal>
}

export default AssignProductsByNameModal;

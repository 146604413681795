import useGetAllOrders from "../../hooks/orders/useGetAllOrders";
import FormIndicator from "../../components/Forms/FormIndicator";
import {useContext, useEffect} from "react";
import OrderFiltersContext from "../../context/OrderFiltersContext";
import PaginationList from "../../components/PaginationList";
import {CHANGE_PAGE_AND_LIMIT} from "../../reducers/OrderFiltersReducers";
import FilterCollapse from "../../components/Collaps/FilterCollapse";
import TableOrder from "../../components/Tables/TableOrder";
import useGetAllDeliveries from "../../hooks/delivery/useGetAllDeliveries";
import useGetAllProductOwners from "../../hooks/productOwners/useGetAllProductOwners";
import CustomButton from "../../components/Forms/Buttons/CustomButton";
import useGenerateOrdersToFile from "../../hooks/orders/useGenerateOrdersToFile";
import FiltersForm from "../../components/Forms/Orders/FiltersForm";
import {isMobile} from 'react-device-detect';
import styled from "styled-components";
import OrderCardsMobile from "../../components/Cards/OrderCardsMobile";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Order = () => {
    const {data, isLoading, reload} = useGetAllOrders();
    const {data: deliveries, isLoading: isLoadingDeliveries} = useGetAllDeliveries();
    const {data: productOwners, isLoading: isLoadingProductOwners} = useGetAllProductOwners();
    const {state} = useContext(OrderFiltersContext);
    const {getFilesOrders, isLoadingFile} = useGenerateOrdersToFile();

    useEffect(() => {
        reload(state);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.page, state.limit]);

    const handleGetFile = () => {
        getFilesOrders(state);
    }

    return (<Wrapper>
            {!isLoadingDeliveries && !isLoadingProductOwners ? <FilterCollapse
                children={<FiltersForm reload={reload} deliveries={deliveries}
                                       productOwners={productOwners}/>}/> : <FormIndicator numberOfElements={1}/>}
            {isLoading ? (<FormIndicator numberOfElements={2}/>) : (<>
                    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                        {isMobile ? '' : <CustomButton isLoading={isLoadingFile} title={'Pobierz do pliku'}
                                                       onClick={() => handleGetFile()}/>}
                        <PaginationList pagination={data.pagination} FiltersContext={OrderFiltersContext}
                                        type={CHANGE_PAGE_AND_LIMIT}/>
                    </div>
                    {isMobile ? <OrderCardsMobile data={data.results}/> : <TableOrder data={data ? data.results : []}/>}
                    <PaginationList pagination={data.pagination} FiltersContext={OrderFiltersContext}
                                    type={CHANGE_PAGE_AND_LIMIT}/>
                </>)}
        </Wrapper>);
}

export default Order;
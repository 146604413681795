import React, {useRef, useState} from "react";
import {Button, Input, Space, Table} from "antd";
import BlogIsPublishedSelect from "../../Selects/BlogIsPublishedSelect";
import {SearchOutlined, SelectOutlined} from "@ant-design/icons";
import {Link} from "react-router-dom";
import useUpdateIsPublishedBlogPost from "../../../hooks/blogPost/useUpdateIsPublishedBlogPost";
import Highlighter from "react-highlight-words";

const TableBlogPosts = ({data, reload}) => {

    const {update} = useUpdateIsPublishedBlogPost();

    const handleChange = (value, row) => {
        update({isPublished: value, id: row.id}).then(reload)
    }

    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div
                style={{
                    padding: 8,
                }}
            >
                <Input
                    ref={searchInput}
                    placeholder={`Szukaj po tytule`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: 'block',
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Szukaj
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Wyczyść
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? '#1890ff' : undefined,
                }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{
                        backgroundColor: '#ffc069',
                        padding: 0,
                    }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };


    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };

    const columns = [
        {
          key: 'icon',
          render: row => <a rel="noreferrer" target={'_blank'} href={'https://www.appet.pl/' + row.slug}><SelectOutlined/></a>
        },
        {
            title: 'Nazwa',
            dataIndex: 'title',
            key: 'title',
            ...getColumnSearchProps('title'),
        },
        {
            title: 'Status',
            dataIndex: '',
            key: 'isPublished',
            width: '10%',
            render: (row) => <BlogIsPublishedSelect value={row.isPublished}
                                                    handleChange={(value) => handleChange(value, row)}/>
        },
        {
            title: 'Utworzono',
            dataIndex: 'createdAt',
            key: 'createdAt',
        },
        {
            title: 'Opublikowany',
            dataIndex: 'publishedAt',
            key: 'publishedAt',
            render: (row) => row ? row : 'Nie opublikowany'
        },
        {
            title: 'Kategorie',
            dataIndex: '',
            key: 'categories',
            render: (row) => row.blogCategories.map((it) => it.name + ', ')
        },
        {
            title: '',
            dataIndex: '',
            key: 'x',
            render: row => <Space key={row.id} size='middle'><Link
                key={row.id + '1'}
                to={`/blog-post/${row.id}`}
            >
                Szczegóły
            </Link>
            </Space>
        },

    ];

    return <>
        <Table pagination={false} columns={columns} dataSource={data.map((it) => {
            return {
                ...it,
                key: it.id
            }
        })}/>
    </>
}

export default TableBlogPosts;
// /admin/discount/export/{discountId}
import { useState } from "react";
import axiosInstance from "../../../lib/axios";
import useAlert from "../../useAlert";

const useExportListOfCodes = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const {errorMessage} = useAlert();


  const getListOfCodesForFile = async (id: string): Promise<any> => {
    const res = await axiosInstance.get(`/admin/discount-code/export/${id}`);
    try {
      if (res.status === 200) {
        const type = res.headers["content-type"];
        const blob = new Blob([res.data], {
          type: type,
        });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = "codes list";
        link.click();
        setIsLoading(false);
      }
    } catch (err) {

      errorMessage("Błąd zapisu, spróbuj ponownie");
      setIsLoading(false);
    }
  };
  return { isLoading, getListOfCodesForFile };
};

export default useExportListOfCodes;

import {Select} from "antd";
import React, {useContext} from "react";

const ProductOwnerSelect = ({productOwners = [], type, context}) => {

    const {dispatch, state} = useContext(context);

    return <Select
        name="productOwnerId"
        onChange={(e) => dispatch({type: type, payload: e})}
        defaultValue={state.productOwnerId}
        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
        showSearch={true}
    >
        <Select.Option value="">Wszystkie</Select.Option>
        {
            productOwners.map((it) => <Select.Option value={it.id} key={it.id}>{it.name}</Select.Option>)
        }
    </Select>
}

export default ProductOwnerSelect;

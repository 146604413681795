import {useState} from "react";
import {createdAtFrom, createdAtTo} from "../../reducers/ProductsFiltersReducers";
import axiosInstance from "../../lib/axios";

const useGetOrderSettlementSummary = () => {

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [data, setData] = useState<{} | null>(null);

    const getOrdersSettlementSummary = async (state?: any): Promise<any> => {

        let modifiedState = {
            ...state,
            createdAtFrom: state[createdAtFrom] ? state[createdAtFrom] : null,
            createdAtTo: state[createdAtTo] ? state[createdAtTo] : null
        }
        try {
            const res = await axiosInstance.get(`/admin/b2b/settlement/order/count/summary`, {params: modifiedState});

            if (res.status === 200) {
                setData(res.data ? res.data : []);
                setIsLoading(false);
            }
        } catch (err) {
            setIsLoading(false);
        }
    };

    return {isLoading, data, getOrdersSettlementSummary};

}

export default useGetOrderSettlementSummary;
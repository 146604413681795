import React from "react";
import { Field } from "formik";
import styled from "styled-components";

const Wrapper = styled.div`
  .field-item {
    width: 100%;
    box-sizing: border-box;
    height: 40px;
    border: 1px solid #a0a0a0;
    border-radius: 5px;
    padding: 5px;
    padding-left: 15px;

    :focus {
      outline-color: #a9a9a9 !important;
    }
  }
`;

const LabelWrapper = styled.div`
  margin-bottom: 10px;
`;

const Label = styled.label`
  font-size: 14px;
  color: #344357;
`;

const CustomField = ({
  name,
  placeholder,
  labelText = "",
  type = "text",
  disabled = false,
}) => {
  return (
    <Wrapper>
      <LabelWrapper>
        <Label htmlFor={name}>{labelText}</Label>
      </LabelWrapper>

      <Field
        name={name}
        placeholder={placeholder}
        type={type}
        className="field-item"
        disabled={disabled}
      />
    </Wrapper>
  );
};

export default CustomField;

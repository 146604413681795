import useAlert from "../useAlert";
import axiosInstance from "../../lib/axios";

const useUpdateDiscountActivity = () => {

    const {successMessage, errorMessage} = useAlert();

    const update = async (id: string, isActive: boolean): Promise<any> => {
        try {
            const obj = {
                isActive: isActive,
            };

            await axiosInstance.put("/admin/discount/activity/" + id, obj);
            successMessage('Zapisano.')

        } catch (err) {

            errorMessage('Błąd zapisu, spróbuj ponownie');
            throw err;
        }
    };

    return {update};
}

export default useUpdateDiscountActivity;
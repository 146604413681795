import React, {useContext} from "react";
import {DatePicker} from "antd";
import moment from "moment";

const DatePickerTo = ({type, context}) => {

    const {dispatch, state} = useContext(context);

    return <DatePicker style={{width: '100%'}} placeholder={'Data'}
                       defaultValue={state.createdAtTo ? moment(state.createdAtTo) : null}
                       onBlur={(e) => {
                           dispatch({type: type, payload: e.target.value + ' 23:59:00'})
                       }
                       }/>

}

export default DatePickerTo;
import styled from "styled-components";
import useGetAllVatRates from "../../hooks/vatRates/useGetAllVatRates";
import Title from "../../components/Title";
import FormIndicator from "../../components/Forms/FormIndicator";
import TableVatRate from "../../components/Tables/TableVatRate";

const Wrapper = styled.div`
  position: relative;
`;

const VatRate = () => {

    const {data, isLoading} = useGetAllVatRates();

    return <>
    <Wrapper>
        <Title title={"Podatki"} />
        {isLoading ? <FormIndicator numberOfElements={3}/> : <TableVatRate data={data}/> }
    </Wrapper></>
}

export default VatRate;
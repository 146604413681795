import {Select} from "antd";
import React, {useContext} from "react";
import ProductsFiltersContext from "../../../../context/ProductsFiltersContext";
import {UPDATE_IS_ACTIVE} from "../../../../reducers/ProductsFiltersReducers";

const ActivitySelect = () => {

    const {dispatch, state} = useContext(ProductsFiltersContext);

    return <Select mode="single" placeholder="Wybierz aktywność" allowClear={true} defaultValue={state.isActive}
                   onChange={(e) => {

                       dispatch({type: UPDATE_IS_ACTIVE, payload: e})
                   }}>
        <Select.Option key={0}>
            Wszystkie
        </Select.Option>
        <Select.Option key={1} value={1}>
            Tak
        </Select.Option>
        <Select.Option key={2} value={0}>
            Nie
        </Select.Option>
    </Select>
}

export default ActivitySelect;
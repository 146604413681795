import styled from "styled-components";
import {Button, Timeline} from "antd";
import usePlaceOrderInExternalService from "../../hooks/orders/usePlaceOrderInExternalService";

const OrderDetailRow = styled.div`
  display: flex;
  flex-direction: row;
  border-top: 1px solid #f0f0f0;
`;

const OrderDetailRowLeft = styled.div`
  width: 25%;
  background-color: #fafafa;
  display: flex;
  align-items: center;
  padding-left: 25px;
  border-right: 1px solid #f0f0f0;
`;

const OrderDetailRowRight = styled.div`
  width: 75%;
  display: flex;
  align-items: center;
  padding-left: 25px;
  min-height: 56px;
  height: 100%;
`;

const OrderDetailIntegrationInfo = ({data, orderId, reload}) => {

    const {placeOrder } = usePlaceOrderInExternalService();

    const handlePlaceOrder = () => {
        placeOrder(orderId).then(() => reload());

    }

    return <>
        <OrderDetailRow>
            <OrderDetailRowLeft>Złóż ponownie zamówienie</OrderDetailRowLeft>
            <OrderDetailRowRight>
                {data === null ? <Button type='primary' onClick={() => handlePlaceOrder()}>Złóż ponownie zamówienie</Button> : 'Brak możliwości złożenia ponownego zamówienia' }
            </OrderDetailRowRight>
        </OrderDetailRow>

        {
            data ? <><OrderDetailRow>
                <OrderDetailRowLeft>Status zewnętrzny zamówienia</OrderDetailRowLeft>
                <OrderDetailRowRight>{data.statuses.length ? data.statuses[data.statuses.length-1].status : 'Brak'} </OrderDetailRowRight>
            </OrderDetailRow>


                <OrderDetailRow>
                    <OrderDetailRowLeft>Historia zamówienia</OrderDetailRowLeft>
                    <OrderDetailRowRight>
                        {data.statuses.length ? <Timeline style={{paddingTop: '20px'}}>
                            {
                                data.statuses.map((status, index) => {
                                    return <Timeline.Item key={index}>{status.status} - {status.createdAt}</Timeline.Item>
                                })
                            }
                        </Timeline> : 'Brak historii'}
                    </OrderDetailRowRight>
                </OrderDetailRow>

                <OrderDetailRow>
                    <OrderDetailRowLeft>Numer zamówienia w zewnętrznej integracji</OrderDetailRowLeft>
                    <OrderDetailRowRight>{data.externalId}</OrderDetailRowRight>
                </OrderDetailRow></> : null
        }
    </>;
}

export default OrderDetailIntegrationInfo;
import React, {useState} from "react";
import ButtonLink from "../../Buttons/ButtonLink";
import CategorySlugAlt from "../../Forms/Category/CategorySlugAlt";
import useUpdateCategorySlugAlt from "../../../hooks/category/useUpdateCategorySlugAlt";

const UpdateCategorySlugAlt = ({reload, item}) => {

    const [updateOpenModal, setUpdateOpenModal] = useState(false);
    const {update} = useUpdateCategorySlugAlt();

    const handleSave = (obj) => {
        update(item.id, obj)
            .then(() => {
                setUpdateOpenModal(false);
                reload();
            })
            .catch((err) => {
                console.log(err);
            });
    };

    return (
        <>
            <ButtonLink
                text={'Zmień slug alternatywny'}
                onClick={() => setUpdateOpenModal(true)}
            />
            <CategorySlugAlt  open={updateOpenModal} onCancel={() => setUpdateOpenModal(false)}
                       handleOK={handleSave} item={item}/>
        </>
    );
}

export default UpdateCategorySlugAlt;
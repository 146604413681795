import {useState} from "react";
import useAlert from "../useAlert";
import axiosInstance from "../../lib/axios";

type UpdateUserProps = {
    email: string;
    role: string;
    name: string,
    lastName: string;
    number: string;
};


const useUpdateUserInfo = () => {

    const [isLoading, setIsLoading] = useState(false);
    const { errorMessage, successMessage} = useAlert();

    const update = async (id: string, obj: UpdateUserProps): Promise<any> => {
        try {
            setIsLoading(true);
            await axiosInstance.put("/admin/users/all_info/" +id , obj);
            successMessage('Dane zmienione')

        } catch (err) {
            setIsLoading(false);
            errorMessage("Błąd zapisu, spróbuj ponownie");
            throw err;
        }
    };

    return {isLoading, update};

}

export default useUpdateUserInfo;
import {useState} from "react";
import axiosInstance from "../../lib/axios";
import useAlert from "../useAlert";

type CreateProductProps = {
    name: string;
    ids: [];
    grammageId: string;
    vatRate: number;
};

const useCreateProductBasedOnProductVariant = () => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const {successMessage, errorMessage, infoMessage} = useAlert();

    const create = async (obj: CreateProductProps): Promise<any> => {

        infoMessage('Za chwile zostaniesz przeniesiony do nowo utworzonego produktu');
        try {
            setIsLoading(true);
            const res = await axiosInstance.post("/admin/product/based_on_variant/", obj);
            setIsLoading(false);
            successMessage("Produkt został utworzony");
            return res.data;
        } catch (err) {
            setIsLoading(false);
            errorMessage("Błąd zapisu, spróbuj ponownie");
            throw err;
        }
    };

    return {create, isLoading};
};

export default useCreateProductBasedOnProductVariant;
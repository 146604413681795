import {Form, Modal, Select} from "antd";

const {Option} = Select;
const AssignBreedToTagForm = ({tags, handleOK, onCancel, open, item = null}) => {

    const [form] = Form.useForm();

    return (
        <Modal width={'50%'} title={'Przypisz tag do rasy'}
               open={open}
               onOk={() => {
                   form
                       .validateFields()
                       .then((values) => {
                           form.resetFields();
                           handleOK(values);
                       })
                       .catch((info) => {
                           console.log("Validate Failed:", info);
                       });
               }}
               onCancel={onCancel}
               okText={"Zapisz"}
               cancelText={"Anuluj"}>
            <Form
                layout="vertical"
                form={form}
                name="form_in_modal"
                initialValues={{
                    other: item.tags.other ? item.tags.other.map((it) => it.id) : [],
                    puppy: item.tags.puppy ? item.tags.puppy.map((it) => it.id) : [],
                    mature: item.tags.mature ? item.tags.mature.map((it) => it.id) : [],
                    old: item.tags.old ? item.tags.old.map((it) => it.id) : []
                }}
            >
                <Form.Item
                    name="other"
                    label="Przypisz tag dla całej rasy, niezależnie od wieku"
                >
                    <Select mode="multiple" placeholder="Wybierz tagi dla rasy">
                        {
                            tags.map((it) => <Option key={it.id + Math.random()} value={it.id}>{it.name}</Option>)
                        }
                    </Select>
                </Form.Item>
                <Form.Item
                    name="puppy"
                    label="Przypisz tag dla rasy w wieku szczeniak"
                >

                    <Select mode="multiple" placeholder="Wybierz tagi dla rasy">
                        {
                            tags.map((it) => <Option key={it.id + Math.random()} value={it.id}>{it.name}</Option>)
                        }
                    </Select>

                </Form.Item>
                <Form.Item
                    name="mature"
                    label="Przypisz tag dla rasy w wieku dorosłym"
                >

                    <Select mode="multiple" placeholder="Wybierz tagi dla rasy">
                        {
                            tags.map((it) => <Option key={it.id + Math.random()} value={it.id}>{it.name}</Option>)
                        }
                    </Select>

                </Form.Item>
                <Form.Item
                    name="old"
                    label="Przypisz tag dla rasy w wieku starszym"
                >
                    <Select mode="multiple" placeholder="Wybierz tagi dla rasy">
                        {
                            tags.map((it) => <Option key={it.id + Math.random()} value={it.id}>{it.name}</Option>)
                        }
                    </Select>
                </Form.Item>
            </Form>
        </Modal>
    );
}

export default AssignBreedToTagForm;
import {useState} from "react";
import useAlert from "../useAlert";
import axiosInstance from "../../lib/axios";

interface UpdateProductStatusProps {
    status: string,
    productIds: Array<string>
}

const useUpdateProductStatus = () => {


    const [isLoading, setIsLoading] = useState(false);
    const {successMessage, errorMessage} = useAlert();

    const update = async(obj: UpdateProductStatusProps) : Promise<any> => {
        try {
            setIsLoading(true);
            await axiosInstance.put(
                `/admin/product/status`,
                obj
            );

            setIsLoading(false);
            successMessage('Status produktu został zmieniony');

        } catch (err) {
            errorMessage('Błąd podczas zmiany statusu produktu');
            setIsLoading(false);
            throw err;
        }
    };

    return {update, isLoading}
}

export default useUpdateProductStatus;
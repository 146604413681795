import Brands from "../pages/brands/Brands";
import Payment from "../pages/payments/Payment";
import UpdateProduct from "../pages/products/UpdateProduct";
import ManageVariants from "../pages/products/ManageVariants";
import Delivery from "../pages/delivery/Delivery";
import ProductOwners from "../pages/productOwners/ProductOwners";
import Category from "../pages/category/Category";
import Discounts from "../pages/discount/Discounts";
import DiscountCodes from "../pages/discount/DiscountCodes";
import UpdateDiscount from "../pages/discount/UpdateDiscount";
import NewDiscount from "../pages/discount/NewDiscount";
import Tags from "../pages/tags/Tags";
import Orders from "../pages/orders/Orders";
import OrderDetails from "../pages/orders/OrderDetails";
import Users from "../pages/users/Users";
import ProductVariantDebug from "../pages/products/ProductVariantDebug";
import MainSettings from "../pages/settings/MainSettings";
import UserInfo from "../pages/users/UserInfo";
import ProductVariants from "../pages/productVariants/ProductVariants";
import ProductVariantDetails from "../pages/productVariants/ProductVariantDetails";

//icons
import {
    FaThList,
    FaHome,
    FaClipboardList,
    FaAlignCenter,
    FaCreditCard,
    FaBus,
    FaStream,
    FaStoreAlt,
    FaUserAlt,
    FaDesktop,
    FaColumns,
    FaMoneyBill,
    FaTag,
    FaRegSun,
    FaBone,
    FaChartBar,
    FaShoppingBag, FaLaptop, FaDog,
} from "react-icons/fa";
import VatRate from "../pages/vatRates/VatRate";
import ProductsTest from "../pages/products/ProductsTest";
import Flavour from "../pages/flavours/Flavour";
import ProductOwnerDetails from "../pages/productOwners/ProductOwnerDetails";
import Ingredient from "../pages/ingredients/Ingredient";
import IngredientGroup from "../pages/ingredientsGroup/IngredientGroup";
import CategoryDetails from "../pages/category/CategoryDetails";
import ProductCustomList from "../pages/productCustomList/ProductCustomList";
import UpdateProductCustomList from "../pages/productCustomList/UpdateProductCustomList";
import AlgorytmPage from "../pages/products/AlgorytmPage";
import ReviewProduct from "../pages/review/ReviewProduct";
import ProductCustomListProducts from "../pages/productCustomList/ProductCustomListProducts";
import BrandGroups from "../pages/brandsGroup/BrandGroups";
import StaticPages from "../pages/staticPages/StaticPages";
import UpdateStaticPage from "../pages/staticPages/UpdateStaticPage";
import BlogPost from "../pages/blogPost/BlogPost";
import EditBlogPost from "../pages/blogPost/EditBlogPost";
import Breeds from "../pages/breed/Breeds";
import Administrators from "../pages/administrators/Administrators";
import CategoryList from "../pages/category/CategoryList";
import AbandonedCarts from "../pages/cart/AbandonedCarts";
import AbandonendCartDetail from "../pages/cart/AbandonendCartDetail";
import MarketingBox from "../pages/marketingBox/MarketingBox";
import UpdateMarketingBox from "../pages/marketingBox/UpdateMarketingBox";
import OrderSettlement from "../pages/orderSettlement/OrderSettlement";


//jesli jest submenu, ustawiamy path na null

const Katalog = [
    {
        path: "/",
        name: "Produkty",
        icon: <FaHome/>,
        forEditor: true,
        children: [
            {
                path: "/products",
                name: "Produkty",
                component: <ProductsTest/>,
                forEditor: true,
                icon: <FaThList/>,
            },
            {
                path: "/product-variants",
                name: "Warianty Produktów",
                component: <ProductVariants/>,
                forEditor: true,
                icon: <FaColumns/>,
            },
            {
                path: "/ingredients-groups",
                name: "Grupy składników",
                component: <IngredientGroup/>,
                icon: <FaBone/>,
                forEditor: true,
                children: null,
            },
            {
                path: "/categories",
                name: "Kategorie",
                component: <Category/>,
                icon: <FaStream/>,
                forEditor: true,
                children: null,
            },
            {
                path: "/brands",
                name: "Marki",
                component: <Brands/>,
                icon: <FaAlignCenter/>,
                isActive: 1,
                children: null,
            },
            {
                path: "/brands-groups",
                name: "Grupy Marek",
                component: <BrandGroups/>,
                icon: <FaAlignCenter/>,
                isActive: 1,
                children: null,
            },
            {
                path: "/flavours",
                name: "Smaki",
                component: <Flavour />,
                icon: <FaBone/>,
                forEditor: true,
                children: null,
            },
            {
                path: "/ingredients",
                name: "Składniki",
                component: <Ingredient />,
                icon: <FaBone/>,
                forEditor: true,
                children: null,
            },
            {
                path: "/tags",
                name: "Tagi",
                component: <Tags/>,
                icon: <FaTag/>,
                forEditor: true,
                children: null,
            },
        ],
    },
];

const ConfigureRoutes = [
    {
        path: "/",
        name: "Konfiguracje",
        icon: <FaRegSun/>,
        children: [
            {
                path: "/administrators",
                name: "Administratorzy",
                component: <Administrators/>,
                icon: <FaUserAlt/>,
                isActive: 1,
                children: null,
            },
            {
                path: "/categories-list",
                name: "Kategorie - lista",
                component: <CategoryList/>,
                isActive: 1,
                children: null,
            },
            {
                path: "/delivery",
                name: "Dostawy",
                component: <Delivery/>,
                icon: <FaBus/>,
                isActive: 1,
                children: null,
            },
            {
                path: "/product_owners",
                name: "Sklepy",
                component: <ProductOwners/>,
                icon: <FaStoreAlt/>,
                isActive: 1,
                children: null,
            },

            {
                path: "/payments",
                name: "Płatności",
                component: <Payment/>,
                icon: <FaCreditCard/>,
                isActive: 1,
                children: null,
            },
            {
                path: "/vat-rate",
                name: "Podatki",
                component: <VatRate/>,
                icon: <FaMoneyBill/>,
                isActive: 1,
                children: null,
            },
            {
                path: "/static-pages",
                name: "Strony statyczne",
                component: <StaticPages/>,
                isActive: 1,
                children: null,
            },
            {
                path: "/breeds",
                name: "Rasy Zwierząt",
                component: <Breeds/>,
                icon: <FaDog/>,
                isActive: 1,
                children: null,
            },
        ],
    }
];

const MarketingRoutes = [
    {
        path: "/",
        name: "Marketing",
        icon: <FaChartBar/>,
        children: [
            {
                path: "/marketing-box",
                name: "Boxy Marketingowe",
                component: <MarketingBox/>,
                isActive: 1,
                children: null,
            },
            {
                path: "/discounts",
                name: "Promocje",
                component: <Discounts/>,
                isActive: 1,
                children: null,
            },
            {
                path: "/custom-product-list",
                name: "Listy Produtkowe",
                component: <ProductCustomList />,
                isActive: 1,
                children: null,
            },
            {
                path: "/review",
                name: "Opinie",
                component: <ReviewProduct />,
                isActive: 1,
                children: null,
            }
        ]
    }
];


const SalesRoutes = [
    {
        path: "/",
        name: "Sprzedaż",
        icon: <FaShoppingBag/>,
        children: [
            {
                path: "/orders",
                name: "Zamówienia",
                component: <Orders/>,
                icon: <FaClipboardList/>,
                isActive: 1,
                children: null
            },
            {
                path: "/abandoned-carts",
                name: "Porzucone koszyki",
                component: <AbandonedCarts/>,
                icon: <FaClipboardList/>,
                isActive: 1,
                children: null
            },
            {
                path: "/orders-settlements",
                name: "Rozliczenia",
                component: <OrderSettlement/>,
                icon: <FaClipboardList/>,
                isActive: 1,
                children: null
            },
        ]
    }
];


const ClientRoutes = [
    {
        path: "/users",
        name: "Klienci",
        component: <Users/>,
        icon: <FaUserAlt/>,
        isActive: 1,
        children: null,
    },
];


const BlogRoutes = [
    {
        path: "/",
        name: "Blog",
        icon: <FaLaptop/>,
        children: [
            {
                path: "/blog-post",
                name: "Posty",
                component: <BlogPost/>,
                isActive: 1,
                children: null
            },
            // {
            //     path: "/blog-category",
            //     name: "Kategorie",
            //     component: <BlogCategories/>,
            //     isActive: 1,
            //     children: null
            // },
        ]
    },
];

const SettingRoutes = [
    {
        path: "/settings",
        name: "Ustawienia",
        component: <MainSettings/>,
        icon: <FaDesktop/>,
        isActive: 1,
    },
];

// tworzymy tablice dla LeftMenu.jsx
const Routing = [
    ...Katalog,
    ...ClientRoutes,
    ...SalesRoutes,
    ...MarketingRoutes,
    ...ConfigureRoutes,
    ...BlogRoutes,
    ...SettingRoutes,
];

/**!!! JESLI CHCEMY DODAĆ ROUTING, ALE NIE CHCEMY DODAWAC GO DO LEFT MENU, DODAJEMY OBIEKT DO TABLICY PONIEJ  */
const routing = [
    {
        path: "/product/update/:id",
        component: <UpdateProduct/>,
    },
    {
        path: "/product/variants/:id",
        component: <ManageVariants/>,
    },
    {
        path: "/discount/codes/:id",
        component: <DiscountCodes/>
    },
    {
        path: "/discount/edit/:id",
        component: <UpdateDiscount/>
    },
    {
        path: "/discount/new",
        component: <NewDiscount/>
    },
    {
        path: '/order/items/:id',
        component: <OrderDetails/>
    },
    {
        path: '/product/variant/debug/:id',
        component: <ProductVariantDebug/>
    },
    {
        path: '/users/info/:id',
        component: <UserInfo/>
    },
    {
        path: '/show/variant/:id',
        component: <ProductVariantDetails/>
    },
    {
        path: '/product-owner/:id',
        component: <ProductOwnerDetails/>
    },
    {
        path: '/category/:id',
        component: <CategoryDetails />
    },
    {
        path: '/',
        component: <Orders />
    },
    {
        path: '/custom-product-list/update/:id',
        component: <UpdateProductCustomList />
    },
    {
        path: '/product/algorytm-logs/:id',
        component: <AlgorytmPage />,
    },
    {
        path: '/custom-product-list/products/:sid',
        component: <ProductCustomListProducts />
    },
    {
        path: '/static-pages/:id',
        component: <UpdateStaticPage />
    },
    {
        path: '/blog-post/:id',
        component: <EditBlogPost />
    },
    {
        path: '/cart-details/:id',
        component: <AbandonendCartDetail/>
    },
    {
        path: '/marketing-box/:groupName',
        component: <UpdateMarketingBox/>
    }
];

// tworzymy tablice dla routera w App.js
export const getRouting = () => {

    Routing.map((it) => {
        routing.push({path: it.path, component: it.component, editor: it.forEditor});
        if (it.children) {
            it.children.map((child) => {
                routing.push({path: child.path, component: child.component});

                return child;
            });
        }

        return it;
    });

    return routing;
};

export default Routing;

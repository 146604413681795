import {Form, Input, Modal, Select, Switch, Typography} from "antd";
import {themes} from "../../../layout/styles/ColorStyles";
import {CheckOutlined, CloseOutlined} from "@ant-design/icons";
import {useEffect, useState} from "react";

const {Text} = Typography;
const {Option} = Select;

const UserFormAnt = ({handleOK, onCancel, open, title, user = null,}) => {

    const [form] = Form.useForm();
    const [isActive, setIsActive] = useState(user ? user.active : true);

    useEffect(() => {
        setIsActive(user ? user.active : true)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Modal
            title={title}
            open={open}
            onOk={() => {
                form
                    .validateFields()
                    .then((values) => {
                        form.resetFields();
                        handleOK({
                            ...values,
                            isActive: isActive
                        });
                    })
                    .catch((info) => {
                        console.log("Validate Failed:", info);
                    });
            }}
            onCancel={onCancel}
            okText={!user ? "Dodaj nowego użytkownika" : "Zapisz"}
            cancelText={"Anuluj"}
        >
            <Form
                layout="vertical"
                form={form}
                name="form_in_modal"
                initialValues={{
                    email: user ? user.email : '',
                    name: user ? user.name : '',
                    lastName: user ? user.lastName : '',
                    password: user ? user.password : '',
                    number: user ? user.number : '',
                    role: user ? user.role : 'ROLE_USER',
                    active: user ? user.active : true,
                }}
            >
                <Form.Item
                    label="Aktywność użytkownika"
                    name="isActive"
                    valuePropName="checked"
                    required={true}
                >
                    <Switch
                        defaultChecked={isActive}
                        style={{
                            backgroundColor: isActive
                                ? themes.activeMenuItemColor
                                : themes.switchInActive,
                        }}
                        checkedChildren={<CheckOutlined/>}
                        unCheckedChildren={<CloseOutlined/>}
                        onChange={(value) => setIsActive(value)}
                    />
                </Form.Item>
                <Text type="secondary">
                    Ta opcja decyduje czy użytkownik będzie mógł się zalogować do panelu
                    sprzedawcy Appet.pl.
                </Text>
                {!user  ? <Form.Item
                    label="Email"
                    name="email"
                    rules={[
                        {required: user ?? true, message: "Wpisz email, pole jest wymagane!"},
                    ]}
                >
                    <Input type='email' placeholder="Wpisz email"/>
                </Form.Item> : null}
                <Form.Item
                    label="Imię"
                    name="name"
                    rules={[
                        {required: true, message: "Wpisz imię, pole jest wymagane!"},
                    ]}
                >
                    <Input placeholder="Wpisz imię"/>
                </Form.Item>
                <Form.Item
                    label="Nazwisko"
                    name="lastName"
                >
                    <Input placeholder="Wpisz nazwisko"/>
                </Form.Item>
                <Form.Item label="Numer telefonu" name="number">
                    <Input placeholder="Wpisz numer telefonu"/>
                </Form.Item>
                {!user ? (
                    <>
                        <Form.Item
                            name="password"
                            label="Password"
                            rules={[
                                {
                                    required: user ?? true,
                                    message: 'Wprowadź hasło!',
                                },
                            ]}
                            hasFeedback
                        >
                            <Input.Password/>
                        </Form.Item>

                        <Form.Item
                            name="confirm"
                            label="Potwierdź hasło"
                            dependencies={['password']}
                            hasFeedback
                            rules={[
                                {
                                    required: user ?? true,
                                    message: 'Potwierdz hasło!',
                                },
                                ({getFieldValue}) => ({
                                    validator(_, value) {
                                        if (!value || getFieldValue('password') === value) {
                                            return Promise.resolve();
                                        }

                                        return Promise.reject(new Error('Dwa hasła nie są takie same!'));
                                    },
                                }),
                            ]}
                        >
                            <Input.Password/>
                        </Form.Item>
                    </>
                ) : null}
                <Form.Item
                    name="role"
                    label="Rola"
                    rules={[
                        {
                            required: true,
                            message: 'Wybierz rodzaj uprawnień użytkownika',
                        },
                    ]}
                >
                    <Select placeholder="Wybierz role">
                        <Option value="ROLE_ADMIN">Administrator</Option>
                        <Option value="ROLE_ADMIN_EDITOR">Edytor</Option>
                        <Option value="ROLE_USER">Użytkowinik</Option>
                    </Select>
                </Form.Item>
            </Form>
        </Modal>
    )
}

export default UserFormAnt;
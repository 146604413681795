import {Image, Table, Typography} from "antd";
import {Link} from "react-router-dom";

const TableAbandonedCartItems = ({data}) => {

    const columns = [
        {
            title: 'Produkt',
            key: 'product',
            dataIndex: '',
            render: row => <div style={{display: "flex", gap: "5px"}}>
                <Image preview={true}
                       src={row.product.photo ? row.product.photo.fullPath : 'graphic-placeholder.png'}
                       style={{width: '110px', height: '110px', objectFit: 'contain'}}/>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-around",
                    }}
                >

                    <div style={{fontWeight: "600"}}> {row.productVariant.name}</div>
                    <div style={{fontWeight: "600"}}> {row.productVariant.ean}</div>
                    <Link target="_blank" rel="noopener noreferrer" to={'/product/update/' + row.product.id}>Produkt w
                        adminie</Link>
                    <Link target="_blank" rel="noopener noreferrer" to={'/show/variant/' + row.productVariant.id}>Wariant
                        w adminie</Link>
                </div>
            </div>
        },
        {
            title: 'Wariant',
            key: 'variant',
            dataIndex: 'productVariant',
            render: row => row.productGroup.variantValue.formattedValueWithUnit
        },
        {
            title: 'Cena produktu',
            key: 'pricePerItem',
            dataIndex: 'pricePerItem',
            render: row => row + 'zł'
        },
        {
            title: 'Ilość',
            key: 'qty',
            dataIndex: 'qty',
            render: row => row + ' x'
        },
        {
            title: 'Cena',
            key: 'price',
            width: '10%',
            render: row => <div style={{fontWeight: '700', textDecoration: row.discount ? 'line-through': 'none'}}>{row.discount ? row.discountTotalPriceGross  : row.totalPrice + ' zł'}</div>
        }
    ];


    return data.map((it, index) => {
        return <>
            <Typography.Title key={index} level={3}
                              style={{margin: '10px'}}>{it.items[0].productVariant.productOwner}</Typography.Title>
            <Table footer={() => <div
                style={{display: 'flex', justifyContent: 'flex-end', marginRight: '30px', fontWeight: '700'}}>
                Suma: {it.totalItemPriceGross + ' PLN'}

            </div>} pagination={false} key={index} columns={columns} dataSource={it.items.map((item) => {
                return {
                    ...item,
                    key: item.id
                }
            })}/>
        </>
    })
}

export default TableAbandonedCartItems;
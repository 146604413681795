import { useState } from "react";
import axiosInstance from "../../lib/axios";

interface CreateBrandProps {
  name: string;
}

const useCreateBrand = () => {
  const [isLoading, setisLoading] = useState<boolean>(false);

  const create = async (obj: CreateBrandProps): Promise<any> => {
    try {
      setisLoading(true);
      const { data } = await axiosInstance.post("/admin/brand/", obj);

      if (data.id) {
        setisLoading(false);
      }
    } catch (err) {
      setisLoading(false);
      throw err;
    }
  };

  return { create, isLoading };
};

export default useCreateBrand;

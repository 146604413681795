import ProductOwnerAddress from "../../../Forms/ProductOwner/ProductOwnerAddress";
import useUpdateProductOwnerHeadquarterAddress
    from "../../../../hooks/productOwners/useUpdateProductOwnerHeadquarterAddress";


const UpdateProductOwnerHeadquarterAddress = ({openModal, handleClose, item, reload}) => {

    const {update} = useUpdateProductOwnerHeadquarterAddress();
    const handleOk = (values) => {
        update({...values, id: item.id}).then(() => reload());
        handleClose();
    };

    return (
        <ProductOwnerAddress
            title={'Edytuj adres siedziby'}
            handleOk={handleOk}
            onCancel={handleClose}
            open={openModal}
            headquarter={item.headquarterView}
        />
    );
}
export default UpdateProductOwnerHeadquarterAddress;


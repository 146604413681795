import useCreateBlogPost from "../../../hooks/blogPost/useCreateBlogPost";
import React from "react";
import BlogPostForm from "../../Forms/Blog/BlogPostForm";

const CreateBlogPostModal = ({ reload, setOpenModal, open, blogCategories }) => {
    const { create } = useCreateBlogPost();

    const handleSave = (obj) => {

        create(obj)
            .then(() => {
                setOpenModal(false);
                reload();
            });
    };

    return (
        <BlogPostForm open={open} onCancel={() => setOpenModal(false)}
                   handleOK={handleSave} blogCategories={blogCategories}/>
    );
}

export default CreateBlogPostModal;
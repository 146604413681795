import {useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import useGetProductFullInformation from "../../hooks/products/useGetProductFullInformation";
import Title from "../../components/Title";
import FormIndicator from "../../components/Forms/FormIndicator";
import Paper from "../../layout/Paper";
import ProductVariantSizeMenu from "../../components/Debug/ProductVariantSizeMenu";
import {Collapse} from "antd";
import useGetProductVariantDebugInfo from "../../hooks/debug/useGetProductVariantDebugInfo";

const {Panel} = Collapse;

const ProductVariantDebug = () => {

    const [selectedMenuIdx, setSelectedMenuIdx] = useState(null);
    const params = useParams();
    const {data, getProductById, isLoading} = useGetProductFullInformation();
    const {isLoadingDebugs, debugs, getProductVariantDebugByPvId} = useGetProductVariantDebugInfo();

    useEffect(() => {
        getProductById(params.id);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getDebugInfo = (e) => {
        setSelectedMenuIdx(e.code);
        getProductVariantDebugByPvId(e.id);
    }

    return <>
        <Title title="Informacja na temat wariantu produktu z hurtowni"/>
        {isLoading ?
            <FormIndicator numberOfElements={1}/>
            :
            (<Paper>
                    <ProductVariantSizeMenu
                        selected={selectedMenuIdx}
                        getDebugInfo={getDebugInfo}
                        sizeMenu={data.variants}
                    />
            </Paper>)
        }{
        isLoadingDebugs ? <FormIndicator numberOfElements={1}/> : (
            <Collapse style={{borderRadius: '5px'}}>
                {
                    debugs.map((it, index) => (
                        <Panel header={it.createdAt} key={index}>
                            <pre style={{
                                overflow: 'auto',
                                whiteSpace: 'pre-wrap',
                        }}>
                            {JSON.stringify(JSON.parse(it.debug),null,2)}
                            </pre>
                    </Panel>
                    ))
                }
            </Collapse>
        )
    }
    </>

}

export default ProductVariantDebug;
import React, { useReducer } from "react";
import {
    initialState,
    ProductBaseOnProductVariantEditorReducers
} from "../reducers/ProductBasedOnProductVariantEditorReducers";

const ProductBasedOnProductVariantEditorContext = React.createContext();

export const ProductBasedOnProductVariantEditorProvider = ({children}) => {

    const [state,dispatch] = useReducer(ProductBaseOnProductVariantEditorReducers, initialState);
    return <ProductBasedOnProductVariantEditorContext.Provider value={{state, dispatch}}>
        {children}
    </ProductBasedOnProductVariantEditorContext.Provider>
}

export default ProductBasedOnProductVariantEditorContext;
import styled from "styled-components";
import StatusLabel from "../../../layout/StatusLabel";
import {Button, Card, Collapse, Image, Input} from "antd";
import PaymentStatusLabel from "../../Labels/PaymentStatusLabel";
import {DownloadOutlined} from "@ant-design/icons";

const {Panel} = Collapse;

const OrderDetailRow = styled.div`
  display: flex;
  flex-direction: row;
  height: 56px;
  border-top: 1px solid #f0f0f0;
`;

const OrderDetailRowLeft = styled.div`
  width: 30%;
  background-color: #fafafa;
  height: 100%;
  display: flex;
  align-items: center;
  padding-left: 10px;
  border-right: 1px solid #f0f0f0;
`;

const OrderDetailRowRight = styled.div`
  width: 70%;
  height: 100%;
  display: flex;
  align-items: center;
  padding-left: 25px;
`;

const OrderMainInfoComponent = styled.div`
  flex: 2;
  background-color: white;
`;

const ContentPriceDetail = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  flex-direction: row;
`;


const OrderDetailsMobile = ({data, orderSettlement}) => {

    console.log(orderSettlement);

    return <>
        <OrderMainInfoComponent>
            <OrderDetailRow>
                <OrderDetailRowLeft>Status Zamówienia</OrderDetailRowLeft>
                <OrderDetailRowRight>
                    <StatusLabel status={data.status}/>
                </OrderDetailRowRight>
            </OrderDetailRow>
            <OrderDetailRow>
                <OrderDetailRowLeft>Status Zamówienia</OrderDetailRowLeft>
                <OrderDetailRowRight>
                    {data.orderNumber}
                </OrderDetailRowRight>
            </OrderDetailRow>
            <OrderDetailRow>
                <OrderDetailRowLeft>Nazwa sklepu</OrderDetailRowLeft>
                <OrderDetailRowRight>
                    {data.productOwner.logo ?
                        <Image style={{width: '100px', height: '100px', objectFit: 'contain'}}
                               src={data.productOwner.logo}/> : data.productOwner.name}
                </OrderDetailRowRight>
            </OrderDetailRow>
            <OrderDetailRow>
                <OrderDetailRowLeft>
                    Data złożenia zamówienia
                </OrderDetailRowLeft>
                <OrderDetailRowRight>{data.createdAt}</OrderDetailRowRight>
            </OrderDetailRow>
            <OrderDetailRow>
                <OrderDetailRowLeft>Status płatności</OrderDetailRowLeft>
                <OrderDetailRowRight>
                    <PaymentStatusLabel paid={data.orderPaymentView.paid}/>
                </OrderDetailRowRight>
            </OrderDetailRow>
            <OrderDetailRow>
                <OrderDetailRowLeft>Forma płatności</OrderDetailRowLeft>
                <OrderDetailRowRight>
                    {data.orderPaymentView.paymentMethod.logo ? <Image
                        preview={false}
                        src={data.orderPaymentView.paymentMethod.logo}
                        style={{width: '36px', height: '36px', objectFit: 'contain'}}
                    /> : data.orderPaymentView.paymentMethod.name}
                </OrderDetailRowRight>
            </OrderDetailRow>
        </OrderMainInfoComponent>
        {
            data.items.map((it) => <Card
                size="small"
                type="inner"
                title={it.product.name}
                style={{
                    width: '100%',
                    marginTop: '5px',
                    marginBottom: '5px'
                }}
            >
                <div style={{display: 'flex', width: '100%', gap: '5px'}}>
                    <div style={{ flex: 1}}>
                        <Image preview={true}
                               src={it.productVariant.photos.length ? it.productVariant.photos[0].fullPath : it.product.photos[0]}
                               style={{width: '70px', height: '110px', objectFit: 'contain'}}/>
                    </div>
                    <div style={{display: 'flex', flexDirection: 'column', flex: 5}}>
                        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                            <div>Producent:</div>
                            {it.product.brand ? it.product.brand.name : 'Brak producenta'}</div>
                        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                            <div>Cen brutto prod:</div>
                            {
                                data.discount && data.discount.type !== 'FREE_SHIPPING' ? <div
                                    style={{textDecoration: 'line-through'}}>{Math.round((it.originalPriceGross / it.quantity) * 100) / 100} zł</div> : ''
                            }

                            {Math.round((it.priceGross / it.quantity) * 100) / 100} zł
                        </div>
                        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                            <div>Ilość</div>
                            {it.quantity}x
                        </div>
                        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                            <div>Wartość netto </div> {it.priceNet} zł
                        </div>
                        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                            <div>Wartość brutto </div> {it.priceGross} zł
                        </div>
                    </div>
                </div>
            </Card>)
        }
        {
            data.discount || data.deliverView.giftDelivery ?
                <Card type="inner"
                      title="Rabaty">
                    {
                        data.discount && data.discount.type !== 'FREE_SHIPPING' ? <div style={{display: 'flex', flexDirection: 'column'}}>
                            <div>Aktywowane rabaty:</div>
                            <div style={{fontWeight: 700}}>{data.discount.code}</div> ({data.discount.discountValueFormatted})
                            <div style={{color: 'grey'}}>
                                Koszt promocji w całości ponosi Appet - przychód Sprzedawcy nie ulega zmianie. Różnica kwoty sprzedaży wynikająca z powyższego rabatu zostanie wyrównana przez Appet.
                            </div>
                        </div> : null
                    }

                    {
                        data.discount && data.deliverView.giftDelivery ? <div style={{display: 'flex', flexDirection: 'column'}}>
                            <div>Aktywowane rabaty:</div>
                            <div style={{fontWeight: 700}}>{data.discount.code}</div> ({data.discount.discountValueFormatted})
                            <div style={{color: 'grey'}}>
                                Koszt promocji w całości ponosi Appet - przychód Sprzedawcy nie ulega zmianie. Różnica kwoty sprzedaży wynikająca z powyższego rabatu zostanie wyrównana przez Appet.
                            </div>
                        </div> : null
                    }
                </Card>
                : ' '
        }

        <Card type="inner"
              title="Podsumowanie">
            <div>

                {
                    data.discount ? <ContentPriceDetail>
                        <div>Wartość produktów przed rabatem:</div>
                        <div>{data.originalTotalItemPriceGross} zł</div>
                    </ContentPriceDetail> : null
                }
                <ContentPriceDetail>
                    {
                        data.discount && data.discount.type !== 'FREE_SHIPPING' ?
                            <>
                                <div>Rabaty pokrywane przez {!data.discount.isProductOwnerCostSide ? 'Appet' : 'Sprzedawce'}:</div>
                                <div> -{Math.round((data.originalTotalItemPriceGross  - data.totalItemPriceGross) * 100) / 100} zł</div>
                            </>
                            : null
                    }
                </ContentPriceDetail>
                <ContentPriceDetail>
                    {
                        data.deliverView.giftDelivery ?
                            <>
                                <div>Rabat pokryty przez Appet jako darmowa zostawa: </div>
                                <div>-{data.deliverView.deliveryProductOwner.price}zł</div>
                            </>
                            : null
                    }
                </ContentPriceDetail>
                <ContentPriceDetail>
                    <div>Wartość produktów {data.discount ? 'po rabacie:' : null}</div>
                    <div>{data.totalItemPriceGross} zł</div>
                </ContentPriceDetail>
                <ContentPriceDetail>
                    <div>Wysyłka:</div>
                    {data.discount && data.discount.type === 'FREE_DELIVERY' ? <div style={{
                        flexDirection: 'row',
                        display: 'flex',
                        gap: '10px'
                    }}>
                        <div style={{textDecoration: 'line-through'}}>{data.deliverView.price} zł</div>
                        <div>0 zł</div>
                    </div> : <div>{data.deliverView.price} zł</div>}
                </ContentPriceDetail>
                <ContentPriceDetail>
                    <div>Suma za zamówienie:</div>
                    <div>
                        {data.totalPriceGross} zł
                    </div>
                </ContentPriceDetail>
            </div>

        </Card>

        <Card
            type="inner"
            title="Adres do wysyłki"
        >
            {data.address.name}
            <br/>
            ul. {data.address.street} {data.address.localNumber}
            <br/>
            {data.address.postCode} {data.address.city}
            <br/>
            email: {data.address ? data.address.email : "Brak"}
            <br/>
            +48 {data.address ? data.address.mobilePhone : "Brak"}
        </Card>
        <Card type="inner" title="Metoda dostawy">
            <>
                {data.deliverView.deliveryProductOwner.deliveryView.logo ? (
                    <Image
                        preview={false}
                        src={
                            data.deliverView.deliveryProductOwner.deliveryView.logo
                        }
                        width={50}
                        height={30}
                    />
                ) : (
                    data.deliverView.deliveryProductOwner.deliveryView.name
                )}
            </>
            <div>{data.deliverView.notes ? 'Punkt odbioru ' + data.deliverView.notes : ''}</div>
        </Card>
        <Card type="inner" title="Numer śledzenia przesyłki">
            <Input
                placeholder={data.deliverView.trackingNumber}
                disabled={true}
            />
        </Card>
        {data.billingAddressView ? (
            <>
                <Card
                    type="inner"
                    title="Adres do faktury"

                >
                    {data.billingAddressView.name}
                    <br/>
                    NIP: {data.billingAddressView.taxNumber}
                    <br/>
                    ul. {data.billingAddressView.street}{" "}
                    {data.billingAddressView.localNumber}
                    <br/>
                    {data.billingAddressView.postCode}{" "}
                    {data.billingAddressView.city}
                </Card>
            </>
        ) : null}
        <Card type="inner" title="Faktura do zamowienia">
            {
                data.orderInvoiceView ?
                    <Button style={{marginRight: '10px'}} icon={<DownloadOutlined/>} size={'middle'}><a
                        rel="noreferrer" target="_blank"
                        href={data.orderInvoiceView.fullUrl}>Pobierz
                        fakturę</a></Button> : 'Brak załączonej faktury'
            }
        </Card>
        <Card type="inner" title="Notatka od użytkownika">
            {
                data.userNote ? data.userNote : 'Brak'

            }
        </Card>

            <Collapse style={{marginTop: '10px'}} key='collapse_1'>
                <Panel header="Rozliczenie zamówienia" key="1">
                    {
                        orderSettlement.orderSettlementItems.map((it) => <Card
                            size="small"
                            type="inner"
                            title={it.productVariantView.name}
                            style={{
                                width: '100%',
                                marginTop: '5px',
                                marginBottom: '5px'
                            }}
                        >
                            <div style={{display: 'flex', width: '100%', gap: '5px'}}>
                                <div style={{ flex: 1}}>
                                    <Image preview={true}
                                           src={it.productVariantView.photo ? it.productVariantView.photo.fullPath : ''}
                                           style={{width: '70px', height: '110px', objectFit: 'contain'}}/>
                                </div>
                                <div style={{display: 'flex', flexDirection: 'column', flex: 5}}>
                                    <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                                        <div>Ilosć:</div>
                                        {it.quantity + ' x ' + it.productVariantView.productGroup.variantValue.formattedValueWithUnit}</div>
                                    <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                                        <div>Cena netto</div>
                                        {it.totalPriceNet} zł
                                    </div>
                                    <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                                        <div>Prowizja dla Appet </div> {it.totalCommissionNet} zł
                                    </div>
                                </div>
                            </div>
                        </Card>)
                    }
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                            }}
                        >
                            <ContentPriceDetail>
                                <div>Wartość zamówienia brutto:</div>
                                <div>{orderSettlement.orderPriceGross} zł</div>
                            </ContentPriceDetail>
                            <ContentPriceDetail>
                                <div>Wartość zamówienia netto:</div>
                                <div>{orderSettlement.orderPriceNet} zł</div>
                            </ContentPriceDetail>
                            <ContentPriceDetail>
                                <div>Prowizja dla Appet:</div>
                                <div>{orderSettlement.totalCommission} zł</div>
                            </ContentPriceDetail>
                        </div>
                </Panel>
            </Collapse>
    </>
}

export default OrderDetailsMobile;
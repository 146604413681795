import useAlert from "../useAlert";
import axiosInstance from "../../lib/axios";

interface AssignIngredientsToIngredientGroupProps {
    ingredientsIds: Array<string>,
    id: string
}

const useAssignIngredientsToIngredientGroup = () => {

    const {successMessage, errorMessage} = useAlert();

    const update = async (
        obj: AssignIngredientsToIngredientGroupProps
    ): Promise<any> => {

        try {
            await axiosInstance.put(
                `/admin/ingredient_group/assign-ingredient/${obj.id}`,
                obj
            );

            successMessage("Składniki zostały zaaktualizowane");

        } catch (err) {
            errorMessage("Wystąpił błąd, spróbuj ponownie");
            throw err;
        }
    };

    return {update};

}

export default useAssignIngredientsToIngredientGroup;
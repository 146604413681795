import {useState} from "react";
import axiosInstance from "../../../lib/axios";

const useGetAlgorytmLogs = () => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [data, setData] = useState<{} | null>(null);

    const getLogsAlgroytm = async (id: string): Promise<any> => {

        try {
            setIsLoading(true);
            const res = await axiosInstance.get(`/admin/product/appet-algorithm/${id}`);

            if (res.status === 200) {
                setData(res.data);
                setIsLoading(false);
            }
        } catch (err) {
            throw err;
        }
    };

    return {getLogsAlgroytm, data, isLoading}

}

export default useGetAlgorytmLogs;
import useCreateTag from "../../../hooks/tags/useCreateTag";
import TagForm from "../../Forms/Tags/TagForm";

const CreateTagModal = ({reload, setOpenModal, openModal }) => {

    const { create } = useCreateTag();

    const handleSave = (obj) => {
        create(obj)
            .then(() => {
                setOpenModal(false);
                reload();
            });
    };

    return <TagForm open={openModal} onCancel={() => setOpenModal(false)}
                    handleOK={handleSave}
                    title={'Dodaj tag'}/>
}

export default CreateTagModal;
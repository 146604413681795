import styled from "styled-components";
import Paper from "../../../layout/Paper";
import React, {useContext} from "react";
import UserFiltersContext from "../../../context/UserFiltersContext";
import {
    CHANGE_PAGE,
    CLEAR_ALL,
    UPDATE_EMAIL, UPDATE_LAST_NAME, UPDATE_NAME,
} from "../../../reducers/UserFiltersReducres";
import {Button, Form} from "antd";
import TextSearchInput from "./TextSearchInput";
import RoleSelect from "./RoleSelect";

const Content = styled(Form)`
  display: grid;
  width: 100%;
  height: 100%;
  grid-template-columns: 25% 25% 25% 25%;
  column-gap: 5px;
`;

const FilterUserList = ({reload}) => {

    const [form] = Form.useForm();
    const {dispatch, state} = useContext(UserFiltersContext);

    const handleOnReset = () => {
        form.resetFields();
        dispatch({type: CLEAR_ALL});
    }

    const handleOnFinish = () => {
        dispatch({type: CHANGE_PAGE, payload: 1});
        reload({
            ...state, page: 1
        })
    }

    return <Paper>
        <Content
            form={form} name="control-hooks"
            onReset={handleOnReset}
            onFinish={() => handleOnFinish()}
            labelCol={{span: 24}}
        >
            <Form.Item label="Email" name="queryEmail">
                <TextSearchInput type={UPDATE_EMAIL} context={UserFiltersContext} state={state.queryEmail}/>
            </Form.Item>
            <Form.Item label="Imię" name="queryName">
                <TextSearchInput type={UPDATE_NAME} context={UserFiltersContext} state={state.queryName}/>
            </Form.Item>
            <Form.Item label="Nazwisko" name="queryLastName">
                <TextSearchInput type={UPDATE_LAST_NAME} context={UserFiltersContext} state={state.queryLastName}/>
            </Form.Item>
            <Form.Item label="Rola" name="role">
                <RoleSelect />
            </Form.Item>
            <Form.Item>
                <Button
                    style={{marginRight: "10px"}}
                    type="primary"
                    htmlType="submit"
                >
                    Zastosuj
                </Button>
                <Button htmlType="button" onClick={handleOnReset}>
                    Wyczyść filtry
                </Button>
            </Form.Item>
        </Content>
    </Paper>
}

export default FilterUserList;
import {Space, Switch, Table} from "antd";
import {CheckOutlined, CloseOutlined} from "@ant-design/icons";
import {themes} from "../../../layout/styles/ColorStyles";
import React from "react";
import useUpdateStaticPageActivity from "../../../hooks/staticPage/useUpdateStaticPageActivity";
import {Link} from "react-router-dom";

const TableStaticPage = ({data, reload}) => {

    const {update} = useUpdateStaticPageActivity();

    const updateActivity = (item, val) => {
        update({id: item.id, isActive: val}).then(() => reload());
    };

    const columns = [
        {
            title: 'Aktywność',
            dataIndex: '',
            key: 'active',
            render: row => <Switch
                checkedChildren={<CheckOutlined/>}
                unCheckedChildren={<CloseOutlined/>}
                style={{
                    backgroundColor: row.isActive
                        ? themes.activeMenuItemColor
                        : themes.switchInActive,
                }}
                onClick={(e) => updateActivity(row, e)}
                checked={row.isActive}
            />
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Utworzono',
            dataIndex: 'createdAt',
            key: 'createdAt',
        },
        {
            title: '',
            dataIndex: 'id',
            key: 'x',
            render: id => <Space key={id} size='middle'><Link
                key={id + '1'}
                to={`/static-pages/${id}`}
            >
                Edytuj
            </Link>
            </Space>
        },
    ];


    return <Table columns={columns} dataSource={data.map((it) => {
        return {
            ...it,
            key: it.id
        }
    })}/>
}

export default TableStaticPage;
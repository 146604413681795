import axiosInstance from "../../lib/axios";
import useAlert from "../useAlert";

const useUpdateMainPhotoBlogPost = () => {
    const { successMessage, errorMessage} = useAlert();

    const updateLogo = async (data: FormData, id: string): Promise<any> => {
        try {
            await axiosInstance.post(
                `/admin/blog/post/photo/${id}`,
                data,
            );
            successMessage('Zdjęcie zostało dodane')
        } catch (err) {
            errorMessage('Wystąpił błąd podczas dodawania zdjęcia')
            throw err;
        }
    };

    return { updateLogo };
}

export default useUpdateMainPhotoBlogPost;
import {Table, Typography} from "antd";
import StatusLabel from "../../../layout/StatusLabel";
import PaymentStatusLabel from "../../Labels/PaymentStatusLabel";

function getTranslationOrderSettlementType(orderSettlementType) {
    switch (orderSettlementType) {
        case 'sell':
            return 'Sprzedaz';
        case 'refund':
            return 'Zwrot kosztów';
        case 'return':
            return 'Zwrot produktów';
    }
}

const OrderSettlementTable = ({data}) => {

    const columns = [
        {
            title: 'Utworzono',
            dataIndex: 'orderView',
            key: 'createdAt',
            width: '2%',
            render: row => row.createdAt
        },
        {
            title: 'Sklep',
            dataIndex: 'orderView',
            key: 'productOwnerView',
            width: "2%",
            render: (row) => row.productOwner.name
        },
        {
            title: "Numer",
            dataIndex: "orderView",
            key: "orderNumber",
            render: (row) => row.orderNumber,
            width: "2%",
        },
        {
            title: 'Status',
            dataIndex: 'orderView',
            key: 'status',
            width: '2%',
            render: (row) => <StatusLabel status={row.status}/>
        },
        {
            title: 'Zwrot',
            dataIndex: 'orderSettlementType',
            key: 'orderSettlementType',
            width: '2%',
            render: (row) => <PaymentStatusLabel paid={row !== 'sell'}/>
        },
        {
            title: 'Rodzaj transakcji',
            dataIndex: '',
            key: '',
            width: '2%',
            render: (row) => getTranslationOrderSettlementType(row.orderSettlementType)
        },
        {
            title: 'Rabat',
            dataIndex: 'orderView',
            key: '',
            width: '2%',
            render: (row) => <Typography>{row.discount ? row.discount.code : '-'}</Typography>
        },
        {
            title: 'Wartość rabat',
            dataIndex: 'orderView',
            key: '',
            width: '2%',
            render: (row) => <Typography>
                {row.discount && row.discount.type !== 'FREE_SHIPPING' ? Math.round((row.originalTotalItemPriceGross - row.totalItemPriceGross) * 100) / 100 + 'zł' : ''}
                {row.discount && row.discount.type === 'FREE_SHIPPING' ? Math.round((row.deliverView.originalPrice) * 100) / 100 + 'zł' : ''}
            </Typography>
        },
        {
            title: 'Wartość produktów(przed rabatem)',
            dataIndex: '',
            key: '',
            width: '2%',
            render: (row) => <Typography>{row.orderView.originalTotalItemPriceGross ? row.orderView.originalTotalItemPriceGross : row.orderView.totalItemPriceGross} zł</Typography>
        },
        {
            title: 'Wartość produktów(po rabatem)',
            dataIndex: '',
            key: '',
            width: '2%',
            render: (row) => row.orderView.totalItemPriceGross + ' zł'
        },
        {
            title: 'Prowizja',
            dataIndex: '',
            key: '',
            width: '2%',
            render: (row) => row.totalCommission + ' zł'
        },
        {
            title: 'Rabat na dostawę',
            dataIndex: 'orderView',
            key: '',
            width: '2%',
            render: (row) => <div><PaymentStatusLabel paid={row.deliverView.giftDelivery}/> {row.deliverView.giftDelivery ? row.discount.code : ''} </div>
        },
        {
            title: 'Cena dostawy',
            dataIndex: 'orderView',
            key: '',
            width: '2%',
            render: (row) => row.deliverView.price + ' zł'
        },
        {
            title: 'Cena total',
            dataIndex: '',
            key: '',
            width: '2%',
            render: (row) => Math.round((row.orderPriceGross + row.orderView.deliverView.price) * 100) / 100 + ' zł'
        },
        {
            title: 'Sposób rozliczenia',
            dataIndex: '',
            key: '',
            width: '2%',
            render: (row) => row.billView && row.billView.orderSettlementType === 'compensation' ? 'Kompensacja' : 'FV'
        },
    ];


    return <Table dataSource={data?.map((it, index) => {
        return {
            ...it,
            key: index
        }
    })} columns={columns} pagination={false}/>
}

export default OrderSettlementTable;
import {Form, Input, Modal} from "antd";

const ProductOwnerAddress = ({handleOk, onCancel, open, title, headquarter = null}) => {
    const [form] = Form.useForm();
    return (
        <Modal title={title}
               open={open}
               onOk={() => {
                   form
                       .validateFields()
                       .then((values) => {
                           form.resetFields();
                           handleOk(values);
                       })
                       .catch((info) => {
                           console.log("Validate Failed:", info);
                       });
               }}
               onCancel={onCancel}
               okText={!headquarter ? "Dodaj nowy adres" : "Zapisz"}
               cancelText={"Anuluj"}>
            <Form
                layout="vertical"
                form={form}
                name="form_in_modal"
                initialValues={{
                    street: headquarter? headquarter.street : '',
                    city: headquarter ? headquarter.city : '',
                    postCode: headquarter ? headquarter.postCode : '',
                    localNumber: headquarter ? headquarter.localNumber : '',
                }}
            >
                <Form.Item
                    label="Miasto"
                    name="city"
                    rules={[
                        { required: true, message: "Wpisz miasto, pole jest wymagane!" },
                    ]}
                >
                    <Input placeholder="Wpisz miasto" />
                </Form.Item>
                <Form.Item
                    label="Ulica"
                    name="street"
                    rules={[
                        { required: true, message: "Wpisz ulicę, pole jest wymagane!" },
                    ]}
                >
                    <Input placeholder="Wpisz ulicę" />
                </Form.Item>
                <Form.Item
                    label="Numer lokalu"
                    name="localNumber"
                    rules={[
                        { required: true, message: "Wpisz numer lokalu, pole jest wymagane!" },
                    ]}
                >
                    <Input placeholder="Wpisz numer lokalu" />
                </Form.Item>
                <Form.Item
                    label="Kod pocztowy"
                    name="postCode"
                    rules={[
                        { required: true, message: "Wpisz kod pocztowy, pole jest wymagane!" },
                    ]}
                >
                    <Input placeholder="Wpisz kod pocztowy" />
                </Form.Item>
            </Form>
        </Modal>
    );

}

export default ProductOwnerAddress;
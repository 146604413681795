import {useState} from "react";
import useAlert from "../../useAlert";
import axiosInstance from "../../../lib/axios";

const useUploadInvoiceFile = () => {

    const [isLoading, setisLoading] = useState<boolean>(false);
    const {successMessage, errorMessage} = useAlert();

    const update = async (data: FormData, id: string): Promise<any> => {
        try {
            setisLoading(true);
            const config = {
                headers: { "content-type": "multipart/form-data" },
            };
            await axiosInstance.post(
                `/admin/b2b/settlement/invoice/${id}`,
                data,
                config
            );

            successMessage("Faktura została dodana");
        } catch (err) {
            errorMessage("Błąd dodawania faktury, skontaktuj się z administratorem. ");
            setisLoading(false);
            throw err;
        }
    };

    return { update, isLoading };
}

export default useUploadInvoiceFile;
import useAlert from "../useAlert";
import axiosInstance from "../../lib/axios";

interface AssignPhotoVariantToProductProps {
    photoId: string,
    productId: string
}

const useAssignPhotoVariantToProduct = () => {

    const {successMessage, errorMessage } = useAlert();

    const update = async (obj: AssignPhotoVariantToProductProps) : Promise<any> =>  {
        try {

            await axiosInstance.put(
                `/admin/product/photo/assign_photo/${obj.productId}/`, obj
            );

            successMessage("Smaki zostały dodane");

        } catch (err) {
            errorMessage("Wystąpił błąd");
            throw err;
        }
    }

    return {update};
}

export default useAssignPhotoVariantToProduct;
import { useState } from "react";
import axiosInstance from "../../lib/axios";

const useUpdateLogoDelivery = () => {
  const [isLoading, setisLoading] = useState<boolean>(false);

  const update = async (data: FormData, id: string): Promise<any> => {
    try {
      setisLoading(true);
      const config = {
        headers: { "content-type": "multipart/form-data" },
      };
      await axiosInstance.post(`/admin/delivery/photo/${id}`, data, config);
    } catch (err) {
      setisLoading(false);
      throw err;
    }
  };

  return { update, isLoading };
};

export default useUpdateLogoDelivery;

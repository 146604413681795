import styled from "styled-components";
import {Button, Form, Input, Select} from "antd";
import React, {useState} from "react";
import CategorySelect from "../../Selects/CategorySelect";

const Content = styled(Form)`
  display: grid;
  width: 100%;
  height: 100%;
  grid-template-columns: 25% 25% 25% 25%;
  column-gap: 5px;
`;

const CreateProductCustomItemForm = ({brands, categories, flavours, tags, handleOk}) => {

    const [form] = Form.useForm();
    const [category, setCategory] = useState(null);
    const [btndisabled, setbtndisabled] = useState(true);

    const handleOnFinish = (values) => {

        let obj = {
            ...values,
            categoryIds: category
        }


        handleOk(obj);
    }

    const handleOnReset = () => {
        form.resetFields();
    }

    const onValuesChange = (changedValues, allValues) => {
        if (
            allValues.name ||
            allValues.priceFrom ||
            allValues.priceTo ||
            allValues.description ||
            allValues.categoryIds ||
            allValues.flavourIds ||
            allValues.tagIds ||
            allValues.brandIds ||
            allValues.weightFrom ||
            allValues.weightTo
        ) {
            setbtndisabled(false);

        } else {
            setbtndisabled(true);
        }
    };

    return <Content
        form={form}
        name="control-hooks"
        onReset={handleOnReset}
        onFinish={(values) => handleOnFinish(values)}
        labelCol={{span: 24}}
        onValuesChange={onValuesChange}
    >
        <Form.Item label="Nazwa produktu, ID Produktu" name="name">
            <Input/>
        </Form.Item>
        <Form.Item label="Cena brutto produktu">
            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                }}
            >
                <Form.Item name="priceFrom" noStyle>
                    <Input style={{width: "100%"}}/>
                </Form.Item>
                <Form.Item noStyle>
                    <Input
                        style={{
                            width: "15%",
                            borderLeft: 0,
                            borderRight: 0,
                            pointerEvents: "none",
                        }}
                        placeholder="~"
                        disabled
                    />
                </Form.Item>
                <Form.Item
                    name="priceTo"
                    noStyle
                >
                    <Input type='number' style={{width: "100%"}}/>
                </Form.Item>
            </div>
        </Form.Item>
        <Form.Item label="Waga karmy">
            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                }}
            >
                <Form.Item name="weightFrom" noStyle>
                    <Input style={{width: "100%"}}/>
                </Form.Item>
                <Form.Item noStyle>
                    <Input
                        style={{
                            width: "15%",
                            borderLeft: 0,
                            borderRight: 0,
                            pointerEvents: "none",
                        }}
                        placeholder="~"
                        disabled
                    />
                </Form.Item>
                <Form.Item
                    name="weightTo"
                    noStyle
                >
                    <Input type='number' style={{width: "100%"}}/>
                </Form.Item>
            </div>
        </Form.Item>
        <Form.Item name={'brandIds'} label="Producent">
            <Select mode="multiple"
                    placeholder="Wybierz producenta"
                    allowClear={true}
                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                    showSearch
            >
                {brands.map((it) => (
                    <Select.Option key={it.id} value={it.id}>
                        {it.name}
                    </Select.Option>
                ))}
            </Select>
        </Form.Item>
        <Form.Item label='Kategoria' name='categoryIds'>
            <CategorySelect categories={categories} setCategories={setCategory}/>
        </Form.Item>
        <Form.Item name={'tagIds'} label="Cecha karmy">
            <Select mode="multiple"
                    placeholder="Wybierz cechę karmy"
                    allowClear={true}
                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                    showSearch
            >
                {tags.map((it) => (
                    <Select.Option key={it.id} value={it.id}>
                        {it.name}
                    </Select.Option>
                ))}
            </Select>
        </Form.Item>
        <Form.Item name={'flavorIds'} label="Smak karmy">
            <Select mode="multiple"
                    placeholder="Wybierz smak karmy"
                    allowClear={true}
                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                    showSearch
            >
                {flavours.map((it) => (
                    <Select.Option key={it.id} value={it.id}>
                        {it.name}
                    </Select.Option>
                ))}
            </Select>
        </Form.Item>
        <Form.Item>
            <Button
                style={{marginRight: "10px", marginTop: '40px'}}
                type="primary"
                htmlType="submit"
                disabled={btndisabled}
            >
                Zastosuj
            </Button>
            <Button htmlType="button" onClick={handleOnReset}>
                Wyczyść filtry
            </Button>
        </Form.Item>
    </Content>;
}

export default CreateProductCustomItemForm;
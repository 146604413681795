import {Switch, Table} from "antd";
import useUpdateFlavourIsFlavour from "../../../hooks/flavours/useUpdateFlavourIsFlavour";

const TableFlavours = ({data, reload}) => {

    const {update: updateIsFlavour} = useUpdateFlavourIsFlavour();

    const columns = [
        {
            title: 'Smak',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Czy ma być widoczny?',
            dataIndex: '',
            key: 'isShown',
            render: item => <Switch onClick={(val) => updateIsFlavour(item.id, val).then(reload)}
                                    checked={item.isShown}/>
        },
    ];

    return <Table dataSource={data.map((it) => {
        return {
            ...it,
            key: it.id
        }
    })} columns={columns} pagination={false}/>
}

export default TableFlavours;
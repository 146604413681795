import {useState} from "react";
import axiosInstance from "../../lib/axios";

const useGetCategoryById = () => {

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [data, setData] = useState<{} | null>(null);

    const getCategory = async (id: string): Promise<any> => {
        try {
            setIsLoading(true);
            const res = await axiosInstance.get(`/admin/category/`+id);
            if (res.status === 200) {
                setData(res.data ? res.data : []);
                setIsLoading(false);
            }
        } catch (err) {
            console.log("err");
            setIsLoading(false);
        }
    }

    return {isLoading, getCategory, data};
}

export default useGetCategoryById;
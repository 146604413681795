import Modal from "react-modal";
import useAlert from "../../../hooks/useAlert";
import useCreateProductOwner from "../../../hooks/productOwners/useCreateProductOwner";
import {customStyles, IconWrapper, Title} from "../../../layout/styles/ModalStyle";
import {FaPlus} from "react-icons/fa";
import CreateProductOwner from "../../Forms/ProductOwner/CreateProductOwner";

Modal.setAppElement("#root");

const CreateProductOwnerModal = ({open, setOpenModal, reload}) => {

    const { errorMessage } = useAlert();
    const {create} = useCreateProductOwner();

    const closeModal = () => {
        setOpenModal(false);
    };

    const handleSave = (obj) => {
        create(obj)
            .then(() => {
                setOpenModal(false);
                reload();
            })
            .catch((err) => {
                console.log(err);
                errorMessage("Błąd dodawania nowej hurtownii. Spróbuj ponownie.");
            });
    };

    return (
        <Modal
            isOpen={open}
            onRequestClose={closeModal}
            style={customStyles}
            contentLabel="Example Modal"
        >
            <div style={{ width: "600px" }}>
                <Title>
                    <IconWrapper>
                        <FaPlus style={{ color: "white" }} />
                    </IconWrapper>
                    Dodaj Sklep
                </Title>
                <CreateProductOwner save={handleSave}/>
            </div>
        </Modal>
    );
}

export default CreateProductOwnerModal;
import {useEffect, useState} from "react";
import axiosInstance from "../../lib/axios";

const useGetAllBlogCategories = () => {


    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [data, setData] = useState<{} | null>(null);

    useEffect(() => {
        getAllBlogCategoires();
    }, []);

    const getAllBlogCategoires = async (): Promise<any> => {

        try {
            const res = await axiosInstance.get(`/admin/blog/category/`);

            if (res.status === 200) {
                setData(res.data ? res.data : []);
                setIsLoading(false);
            }
        } catch (err) {
            setIsLoading(false);
        }
    };

    const reload = () => {
        getAllBlogCategoires();
    };

    return { isLoading, data, reload };
}

export default useGetAllBlogCategories;
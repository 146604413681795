import Modal from "react-modal";
import useUpdatePasswordUser from "../../../hooks/users/useUpdatePasswordUser";
import {customStyles, IconWrapper, Title} from "../../../layout/styles/ModalStyle";
import {FaPlus} from "react-icons/fa";
import { Typography } from 'antd';
import {useEffect, useState} from "react";
import CustomButton from "../../Forms/Buttons/CustomButton";

const { Title: Text } = Typography;

Modal.setAppElement("#root");

const UpdateUserPasswordModal = ({handleClose, openModal, item}) => {

    const {update} = useUpdatePasswordUser();
    const [password, setPassword ] = useState();

    const handleSave = () => {
        update(item.id, password).finally(() => handleClose());
    }

    useEffect(() => {
        handleChange();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleChange = () => {
        var today = new Date();
        var md5 = require('md5');
        setPassword(md5(item.email + today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate() + today.getSeconds()));
    }

    return (
        <>
            <Modal
                isOpen={openModal}
                onRequestClose={handleClose}
                style={customStyles}
                contentLabel="Example Modal"
            >
                <div style={{ width: "600px" }}>
                    <Title>
                        <IconWrapper>
                            <FaPlus style={{ color: "white" }} />
                        </IconWrapper>
                        Zmień hasło użytkownika:
                    </Title>
                    Nowe hasło:<Text level={5}>{password}</Text>

                    <div style={{display: 'flex', gap: '10px'}}>
                        <CustomButton title={'Zapisz'} onClick={() => handleSave()}/>
                        <CustomButton title={'Zmień Hasło'} onClick={() => handleChange()}/>
                    </div>
                </div>
            </Modal>
        </>
    );
}

export default UpdateUserPasswordModal;
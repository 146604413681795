import { useState, useEffect } from "react";
import axiosInstance from "../../lib/axios";

const useGetAllVatRates = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [data, setData] = useState<{} | null>(null);

  useEffect(() => {
    getVatRates();
  }, []);

  const getVatRates = async (): Promise<any> => {
    try {
      const res = await axiosInstance.get(`/vat-rate/`);

      if (res.status === 200) {
        setData(res.data);
        setIsLoading(false);
      }
    } catch (err) {
      setIsLoading(false);
    }
  };

  return { isLoading, data };
};

export default useGetAllVatRates;

import {useParams} from "react-router-dom";
import OrderSettlementInvoiceTable from "../../Tables/ProductOwners/OrderSettlementInvoiceTable";
import useGetOrderSettlementInvoice
    from "../../../hooks/productOwners/OrderSettlementInvoice/useGetOrderSettlementInvoice";
import {useEffect, useState} from "react";
import {Button} from "antd";
import FormIndicator from "../../Forms/FormIndicator";
import CreateOrderSettlementInvoiceModal
    from "../../Modals/ProductOwner/OrderSettlementInvoice/CreateOrderSettlementInvoiceModal";

const OrderSettlementTab = () => {

    const params = useParams();
    const {data, getOrderSettlementInvoice, isLoading} = useGetOrderSettlementInvoice();
    const [openModal, setOpenModal] = useState(false);

    useEffect(() => {
        getOrderSettlementInvoice(params.id);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <div style={{display: 'flex', flexDirection: 'column', gap: '10px'}}>
        <Button type={'primary'} style={{width: "190px"}} onClick={() => setOpenModal(true)}>Wygeneruj fakturę</Button>
        {
            isLoading ? <FormIndicator numberOfElements={3}/> : <OrderSettlementInvoiceTable data={data} />
        }
        <CreateOrderSettlementInvoiceModal open={openModal} setOpenModal={setOpenModal} reload={() => getOrderSettlementInvoice(params.id)}/>
    </div>;
}

export default OrderSettlementTab;
import FormTitle from "../../FormTitle";
import React from "react";
import {Typography} from "antd";
import CompositionTab from "../../../Menu/ProductMenuTabs/CompositionTab";
import ProductIngredientTable from "../../../Tables/ProductIngredient/ProductIngredientTable";
const { Title } = Typography;

const ProductCompositionTab = ({product, grammages, reload})=>{

    return <>
        <FormTitle title={"Skład"}/>
        <div style={{display: 'flex', height: '100%', width: '100%'}}>
            <div style={{width: '45%' }}>
                <Title level={5}>Skład analityczny</Title>
                <CompositionTab data={product} reload={reload}/>
            </div>
            <div style={{width: '55%' }}>
                <Title level={5}>Składniki</Title>
                <ProductIngredientTable product={product} grammages={grammages} reload={reload} />
            </div>
        </div>
    </>
}

export default ProductCompositionTab;
import useCreateOrderSettlementInvoice
    from "../../../../hooks/productOwners/OrderSettlementInvoice/useCreateOrderSettlementInvoice";
import OrderSettlementInvoiceForm from "../../../Forms/ProductOwner/OrderSettlementInvoice/OrderSettlementInvoiceForm";
import {useParams} from "react-router-dom";

const CreateOrderSettlementInvoiceModal = ({reload, setOpenModal, open}) => {

    const {create} = useCreateOrderSettlementInvoice();
    const params = useParams();

    const handleSave = (obj) => {
        let tmp = {
            ...obj,
            dateFrom: obj.dateFrom.format('YYYY-MM-DD'),
            dateTo: obj.dateTo.format('YYYY-MM-DD'),
            payTo: obj.payTo.format('YYYY-MM-DD'),
        }

        create({
            productOwnerId: params.id,
            ...tmp
        })
            .then(() => {
                reload();
            });

        setOpenModal(false);
    };

    return <OrderSettlementInvoiceForm open={open} onCancel={() => setOpenModal(false)}
                                       handleOK={handleSave}/>
}

export default CreateOrderSettlementInvoiceModal;
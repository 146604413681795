import {useState} from "react";
import axiosInstance from "../../lib/axios";

const useGetProductsDirectoryFilters = () => {

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [data, setData] = useState<{} | null>(null);

    const getDirectory = async (): Promise<any> => {
        try {
            setIsLoading(true);
            const res = await axiosInstance.get(`/admin/product/variant/filter`);

            if (res.status === 200) {
                setData(res.data ? res.data : []);
            }
            setIsLoading(false);
        } catch (err) {
            throw err;
        }
    };

    return {isLoading, getDirectory, data}
}

export default useGetProductsDirectoryFilters;
import { useEffect, useContext } from "react";
import App from "./App";
import AlertContainer from "./components/AlertsContainer";
import Indicator from "./components/Indicator";
import AppContextProvider from "./context/AppContextProvider";
import AuthContext from "./context/AuthContext";
import useAuth from "./hooks/auth/useAuth";
import authStorage from "./lib/authStorage";
import LoginPage from "./pages/LoginPage";
import Theme from "./layout/Theme";

const Wrapper = (props) => {
  const { isLoading, refresh, getUserInfo } = useAuth();
  const { user, setUser } = useContext(AuthContext);

  useEffect(() => {
    const userFromStorage = authStorage.getUser();

    if (userFromStorage) {
      refresh();
      getUserInfo();
    } else {
      setUser(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading) return <Indicator />;

  return (
    <>
      {user ? (
        <AppContextProvider>
          <Theme>
            <App />
          </Theme>
        </AppContextProvider>
      ) : (
        <LoginPage />
      )}
      <AlertContainer />
    </>
  );
};

export default Wrapper;

import useAlert from "../useAlert";
import axiosInstance from "../../lib/axios";

const useRemoveVariant = () => {
    const {successMessage, errorMessage} = useAlert();

    const removeProductVariant = async (  id: string): Promise<any> => {
        try {
            await axiosInstance.delete(`/admin/product/variant/` + id);
            successMessage("Usunięto");
        } catch (err) {
            errorMessage("Błąd usuwania, spróbuj ponownie");
        }
    }

    return {removeProductVariant};
}

export default useRemoveVariant;
import {Form, Input, Modal} from "antd";
import React from "react";

const BlogPostForm = ({handleOK, onCancel, open}) => {

    // const [category, setCategory] = useState(null);
    // const [selectedCategories, setSelectedCategories] = React.useState([]);

    const [form] = Form.useForm();

    // useEffect(() => {
    //     setCategory(blogCategories.map((it) => {
    //         return {
    //             ...it,
    //             value: it.name
    //         }
    //     }));
    // }, []);

    return (
        <Modal title={'Utwórz nowy post na bloga - Appet'}
               open={open}
               onOk={() => {
                   form
                       .validateFields()
                       .then((values) => {
                           let tmp = {
                               ...values,
                               blogCategoriesIds: []
                           }
                           form.resetFields();
                           handleOK(tmp);
                       })
                       .catch((info) => {
                           console.log("Validate Failed:", info);
                       });
               }}
               onCancel={onCancel}
               okText={'Utwórz'}
               cancelText={"Anuluj"}>
            <Form
                layout="vertical"
                form={form}
                name="form_in_modal"
            >
                <Form.Item
                    label="Tytuł"
                    name="title"
                    rules={[
                        {required: true, message: "Wpisz nazwę, pole jest wymagane!"},
                    ]}
                >
                    <Input placeholder="Wpisz tytuł posta "/>
                </Form.Item>
                {/*<Form.Item>*/}
                {/*    <CategorySelect categories={category} setCategories={setCategory}/>*/}
                {/*</Form.Item>*/}
            </Form>
        </Modal>
    );
}

export default BlogPostForm;
import axiosInstance from "../../lib/axios";

interface UpdateDeliveryPOActivity {
    deliveryProductOwnerId: string,
    isActive: boolean
}

const useUpdateDeliveryProductOwnerActivity = () => {

    const update = async (obj: UpdateDeliveryPOActivity): Promise<any> => {
        try {
            await axiosInstance.put("/admin/delivery/productOwner/activity/" + obj.deliveryProductOwnerId, obj);

        } catch (err) {
            throw err;
        }
    };

    return { update};
}

export default useUpdateDeliveryProductOwnerActivity;
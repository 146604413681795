import useAlert from "../useAlert";
import axiosInstance from "../../lib/axios";

const useUpdateReviewStatus = () => {

    const { errorMessage, successMessage } = useAlert();

    const updateReviewStatus = async (
        reviewProductItemIds: string[],
        status: string
    ): Promise<any> => {

        try {
            const obj = { reviewProductItemIds: reviewProductItemIds, status: status };
            await axiosInstance.put(
                "/admin/review_product/",
                obj
            );
            successMessage("Status dla opini zostął zmienioney");
        } catch (err) {
            errorMessage("Błąd zapisu, spróbuj ponownie");
        }
    }

    return { updateReviewStatus };
}

export default useUpdateReviewStatus;
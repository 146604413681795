import React, {useRef, useState} from "react";
import {NavLink} from "react-router-dom";
import {Button, Input, Space, Switch, Table} from "antd";
import {themes} from "../../layout/styles/ColorStyles";
import {CheckOutlined, CloseOutlined, SearchOutlined} from "@ant-design/icons";
import Highlighter from 'react-highlight-words';
import useUpdateDiscountActivity from "../../hooks/discount/useUpdateDiscountActivity";

const TableDiscounts = ({data, reload}) => {

    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);
    const {update} = useUpdateDiscountActivity();

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({setSelectedKeys, selectedKeys, confirm, clearFilters}) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Input
                    ref={searchInput}
                    placeholder={`Szukaj po naziwe`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: 'block',
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined/>}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Szukaj
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Wyczyść
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? '#1890ff' : undefined,
                }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{
                        backgroundColor: '#ffc069',
                        padding: 0,
                    }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    const columns = [
        {
            title: 'Aktywny',
            dataIndex: '',
            key: 'active',
            render: (row) => <Switch style={{
                width: '80%',
                backgroundColor: row.active
                    ? themes.activeMenuItemColor
                    : themes.switchInActive,
            }}
                                     checkedChildren={<CheckOutlined/>}
                                     unCheckedChildren={<CloseOutlined/>} onClick={(e) => update(row.id, e).then(() => reload())}
                                     checked={row.active}/>,
            width: '2%'
        },
        {
            title: 'Nazwa',
            key: 'name',
            dataIndex: 'name',
            width: '15%',
            ...getColumnSearchProps('name'),
        },
        {
            title: 'Rodzaj kody',
            key: 'type',
            dataIndex: 'type',
            render: row => getDiscountType(row),
            width: '7%',
        },
        {
            title: 'Wartość promocji',
            key: 'discountValue',
            dataIndex: '',
            render: row => row.discountValue + getDiscountTypeSign(row.type),
            width: '5%',
        },
        {
            title: 'Aktywny od - do',
            key: 'activityFromTo',
            dataIndex: '',
            render: row => <div>{row.activeFrom} - {row.activeTo}</div>,
            width: '20%',
        },
        {
            title: 'Użycie kodu',
            key: 'action',
            dataIndex: 'usedCodes',
            width: "6%"
        },
        {
            title: 'Min. zamówienie',
            key: 'minOrderValue',
            dataIndex: 'minOrderValue',
            render: row => row ? row + 'zł' : 'Brak minimalnej kwoty',
            width: "6%"
        },
        {
            title: 'Ilość kodów',
            key: 'action',
            dataIndex: 'totalCodes',
            width: "6%"
        },
        {
            title: '',
            key: 'action',
            render: row => <div style={{display: 'flex', alignItems: 'center', gap: '10px'}}>
                <NavLink to={'/discount/edit/' + row.id}
                         key={1}>
                    <div>Edytuj</div>
                </NavLink>
                <NavLink to={'/discount/codes/' + row.id} key={2}>
                    <div>Lista kodów</div>
                </NavLink>
            </div>
        },

    ];

    return <Table columns={columns} dataSource={data.map((it) => {
        return {
            ...it,
            key: it.id
        }
    })}/>
}

export default TableDiscounts;

const getDiscountType = (type) => {

    switch (type) {
        case 'FREE_SHIPPING':
            return 'Darmowa dostawa';
        case 'AMOUNT':
            return 'Kwotowy';
        case 'PERCENTAGE':
            return 'Procentowy'
        default:
            return 'Brak';
    }
}

const getDiscountTypeSign = (type) => {
    switch (type) {
        case 'FREE_SHIPPING':
            return '-';
        case 'AMOUNT':
            return ' zł';
        case 'PERCENTAGE':
            return ' %'
        default:
            return 'Brak';
    }
}
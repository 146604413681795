import ProductOwnerCommunication from "../../../Forms/ProductOwner/ProductOwnerCommunication";
import useCreateProductOwnerCommunication from "../../../../hooks/productOwners/useCreateProductOwnerCommunication";


const CreateProductOwnerCommunicationModal = ({openModal, handleClose, item, reload}) => {
    const {create} = useCreateProductOwnerCommunication();
    const handleOk = (values) => {
        let obj = {
            id: item.id,
            ...values
        }

        create(obj).finally(() => {
            handleClose() && reload();
        });
    }

    return (
        <ProductOwnerCommunication
            title={'Dodaj dane kontaktowe'}
            handleOk={handleOk}
            onCancel={handleClose}
            open={openModal}
        />
    );
}

export default CreateProductOwnerCommunicationModal;
import {Link, useParams} from "react-router-dom";
import {useEffect} from "react";
import styled from "styled-components";
import useGetOrderById from "../../hooks/orders/useGetOrderById";
import FormIndicator from "../../components/Forms/FormIndicator";
import {Breadcrumb, Button, Card, Collapse, Image, Input} from "antd";
import OrderItemsTable from "../../components/Tables/Orders/OrderItemsTable";
import PaymentStatusLabel from "../../components/Labels/PaymentStatusLabel";
import {DownloadOutlined} from "@ant-design/icons";
import useGetOrderSettlmentByOrderId from "../../hooks/orders/useGetOrderSettlmentByOrderId";
import OrderSettlementTable from "../../components/Tables/Orders/OrderSettlementTable";
import {isMobile} from "react-device-detect";
import OrderDetailsMobile from "../../components/Cards/OrderDetails/OrderDetailsMobile";
import StatusDropdown from "../../components/Dropdown/Orders/StatusDropdown";
import useUpdateOrderStatus from "../../hooks/orders/useUpdateOrderStatus";
import ORderDetailIntegrationInfo from "../../components/OrdersComponents/ORderDetailIntegrationInfo";
import OrderDetailP24Info from "../../components/OrdersComponents/OrderDetailP24Info";

const {Panel} = Collapse;

const Wrapper = styled.div`
`;

const OrderInfo = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 20px;
`;

const OrderMainInfoComponent = styled.div`
  flex: 2;
  background-color: white;
`;

const OrderDetailComponent = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
`;

const OrderDetailRow = styled.div`
  display: flex;
  flex-direction: row;
  height: 56px;
  border-top: 1px solid #f0f0f0;
`;

const OrderDetailRowLeft = styled.div`
  width: 25%;
  background-color: #fafafa;
  height: 100%;
  display: flex;
  align-items: center;
  padding-left: 25px;
  border-right: 1px solid #f0f0f0;
`;

const OrderDetailRowRight = styled.div`
  width: 75%;
  height: 100%;
  display: flex;
  align-items: center;
  padding-left: 25px;
`;

const OrderDetails = () => {

    const params = useParams();
    const {data, isLoading, getOrderById} = useGetOrderById();
    const {data: orderSettlement, getOrderSettlement} = useGetOrderSettlmentByOrderId();
    const {updateStatus} = useUpdateOrderStatus();

    useEffect(() => {

        getOrderById(params.id);
        getOrderSettlement(params.id);
// eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleStatusChange = (id, status) => {
        updateStatus({status, orderId: id}).then(() => getOrderById(id));
    }

    return (<>
        {isLoading ? <FormIndicator numberOfElements={3}/> : (
            <Wrapper>
                {
                    isMobile ? <OrderDetailsMobile data={data} orderSettlement={orderSettlement.orderSettlement}/> : <>
                        <Breadcrumb style={{paddingBottom: "24px"}}>
                            <Breadcrumb.Item>Profil Sprzedawcy</Breadcrumb.Item>
                            <Breadcrumb.Item>Zamówienia</Breadcrumb.Item>
                            <Breadcrumb.Item><Link to={'/orders'}>Lista zamówień</Link></Breadcrumb.Item>

                            <Breadcrumb.Item>Zamówienie {data.orderNumber}</Breadcrumb.Item>
                        </Breadcrumb>

                        <OrderInfo>
                            Zamówienie numer: {data.orderNumber} (Suma:{" "}
                            {data.totalItemPriceGross} zł)
                        </OrderInfo>
                        <OrderDetailComponent>
                            <OrderMainInfoComponent>
                                <OrderDetailRow>
                                    <OrderDetailRowLeft>Status Zamówienia</OrderDetailRowLeft>
                                    <OrderDetailRowRight>
                                        <StatusDropdown
                                            row={data}
                                            handleMenuClick={(e) => handleStatusChange(data.orderId, e)}
                                        />
                                    </OrderDetailRowRight>
                                </OrderDetailRow>
                                {
                                    data.orderExternalAdminView ? (<OrderDetailRow>
                                        <OrderDetailRowLeft>Zewnętrzny Status Zamówienia</OrderDetailRowLeft>
                                        <OrderDetailRowRight>{data.orderExternalAdminView.status}</OrderDetailRowRight>
                                    </OrderDetailRow>) : null
                                }
                                <OrderDetailRow>
                                    <OrderDetailRowLeft>Nazwa sklepu</OrderDetailRowLeft>
                                    <OrderDetailRowRight>
                                        {data.productOwner.logo ?
                                            <Image style={{width: '100px', height: '100px', objectFit: 'contain'}}
                                                   src={data.productOwner.logo}/> : data.productOwner.name}
                                    </OrderDetailRowRight>
                                </OrderDetailRow>
                                <div style={{padding: "10px"}}>
                                    <OrderItemsTable orderItems={data.items} data={data}/>
                                </div>
                                <OrderDetailRow>
                                    <OrderDetailRowLeft>
                                        Data złożenia zamówienia
                                    </OrderDetailRowLeft>
                                    <OrderDetailRowRight>{data.createdAt}</OrderDetailRowRight>
                                </OrderDetailRow>
                                <OrderDetailRow>
                                    <OrderDetailRowLeft>Status płatności</OrderDetailRowLeft>
                                    <OrderDetailRowRight>
                                        <PaymentStatusLabel paid={data.orderPaymentView.paid}/>
                                    </OrderDetailRowRight>
                                </OrderDetailRow>
                            </OrderMainInfoComponent>

                            <div style={{flex: 1}}>
                                <Card
                                    type="inner"
                                    title="Adres do wysyłki"
                                >
                                    {data.address.name}
                                    <br/>
                                    ul. {data.address.street} {data.address.localNumber}
                                    <br/>
                                    {data.address.postCode} {data.address.city}
                                    <br/>
                                    email: {data.address ? data.address.email : "Brak"}
                                    <br/>
                                    +48 {data.address ? data.address.mobilePhone : "Brak"}
                                </Card>
                                <Card type="inner" title="Metoda dostawy">
                                    <>
                                        {data.deliverView.deliveryProductOwner.deliveryView.logo ? (
                                            <Image
                                                preview={false}
                                                src={
                                                    data.deliverView.deliveryProductOwner.deliveryView.logo
                                                }
                                                width={50}
                                                height={30}
                                            />
                                        ) : (
                                            data.deliverView.deliveryProductOwner.deliveryView.name
                                        )}
                                    </>
                                    <div>{data.deliverView.notes ? 'Punkt odbioru ' + data.deliverView.notes : ''}</div>
                                </Card>
                                <Card type="inner" title="Numer śledzenia przesyłki">
                                    <Input
                                        placeholder={data.deliverView.trackingNumber}
                                        disabled={true}
                                    />
                                </Card>
                                {data.billingAddressView ? (
                                    <>
                                        <Card
                                            type="inner"
                                            title="Adres do faktury"

                                        >
                                            {data.billingAddressView.name}
                                            <br/>
                                            NIP: {data.billingAddressView.taxNumber}
                                            <br/>
                                            ul. {data.billingAddressView.street}{" "}
                                            {data.billingAddressView.localNumber}
                                            <br/>
                                            {data.billingAddressView.postCode}{" "}
                                            {data.billingAddressView.city}
                                        </Card>
                                    </>
                                ) : null}
                                <Card type="inner" title="Faktura do zamowienia">
                                    {
                                        data.orderInvoiceView ?
                                            <Button style={{marginRight: '10px'}} icon={<DownloadOutlined/>}
                                                    size={'middle'}><a
                                                rel="noreferrer" target="_blank"
                                                href={data.orderInvoiceView.fullUrl}>Pobierz
                                                fakturę</a></Button> : 'Brak załączonej faktury'
                                    }
                                </Card>
                                <Card type="inner" title="Notatka od użytkownika">
                                    {
                                        data.userNote ? data.userNote : 'Brak'
                                    }
                                </Card>
                            </div>
                        </OrderDetailComponent>
                        <Collapse style={{marginTop: '10px'}} key='collapse_1'>
                            <Panel header="Rozliczenie zamówienia" key="1">
                                <OrderSettlementTable orderSettlement={orderSettlement} orderId={data.orderId}
                                                      reload={() => getOrderSettlement(params.id)}/>
                            </Panel>
                        </Collapse>
                        <Collapse style={{marginTop: '10px'}} key='collapse_2'
                            // collapsible={data.orderExternalAdminView ? data.orderExternalAdminView : 'disabled'}
                        >
                            <Panel
                                header={data.orderExternalAdminView ? 'Integracja zwnętrzna: ' + data.orderExternalAdminView.integrationType : 'Integracja zwnętrzna:  brak'}
                                key="2">
                                <ORderDetailIntegrationInfo data={data.orderExternalAdminView} orderId={data.orderId}
                                                            reload={() => getOrderById(params.id)}/>
                            </Panel>
                        </Collapse>
                        <Collapse style={{marginTop: '10px'}} key='collapse_3'>
                            <Panel header='Przelewy 24' key="3">
                                <OrderDetailP24Info payment={data.orderPaymentView} orderId={data.orderId}
                                                    orderSettlement={orderSettlement}/>
                            </Panel>
                        </Collapse>
                    </>
                }

            </Wrapper>)}
    </>)
}

export default OrderDetails;
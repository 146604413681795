import {useState} from "react";
import {Button} from "antd";
import CreateProductOwnerWorkerForm from "../../../Forms/ProductOwner/CreateProductOwnerWorkerForm";
import useCreateNewUserPartner from "../../../../hooks/users/useCreateNewUserPartner";
import {useParams} from "react-router-dom";

const CreateProductOwnerWorker = ({reload}) => {

    const [open, setIsOpen] = useState(false);
    const { create } = useCreateNewUserPartner();
    const params = useParams();

    const handleOk = (values) => {

        let user = {
            ...values,
            productOwnerId: params.id
        }

        create(user).then(() => {
            reload();
            setIsOpen(false);
        });
    };
    return (
        <>
            <Button
                style={{ marginBottom: "24px" }}
                type="primary"
                onClick={() => setIsOpen(!open)}
            >
                Dodaj nowego użytkownika
            </Button>
            <CreateProductOwnerWorkerForm
                title={"Dodaj nowe konto użytkownika"}
                handleOk={handleOk}
                onCancel={() => setIsOpen(false)}
                open={open}
            />
        </>
    );
}

export default CreateProductOwnerWorker;
import {useState} from "react";
import axiosInstance from "../../lib/axios";

const useGetMarketingBoxInfo = () => {

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [data, setData] = useState<{} | null>(null);


    const getMarketingBoxInfoByGroupName = async (name: string): Promise<any> => {
        try {
            const res = await axiosInstance.get(`/admin/marketing-box/info/${name}`);
            if (res.status === 200) {
                setData(res.data ? res.data : []);
            }
            setIsLoading(false);
        } catch (err) {
            console.log("err");
            setIsLoading(false);
        }
    }

    return {isLoading, data, getMarketingBoxInfoByGroupName};
}

export default useGetMarketingBoxInfo;
import styled from "styled-components";
import Title from "../../components/Title";
import Paper from "../../layout/Paper";
import React, {useEffect, useState} from "react";
import ItemInfoWithInput, {Label} from "../../components/ProductVariantComponents/ItemInfoWithInput";
import {useParams} from "react-router-dom";
import useGetProductVariantById from "../../hooks/productVariants/useGetProductVariantById";
import FormIndicator from "../../components/Forms/FormIndicator";
import SearchSelect from "../../components/ProductVariantComponents/SearchSelect";
import DescriptionWrapper from "../../components/ProductVariantComponents/DescriptionWrapper";
import ActivityLabel from "../../components/Labels/ActivityLabel";
import useGetProductVariantDebugInfo from "../../hooks/debug/useGetProductVariantDebugInfo";
import {Button, Collapse, Image} from "antd";
import {MultipleItemsWrapper, NoFoto} from "./ProductVariantStyle";
import useAssignProductVariantToProduct from "../../hooks/productVariants/useAssignProductVariantToProduct";
import useGetProductBySearchQuery from "../../hooks/products/useGetProductBySearchQuery";
import CreateProductBasedOnProductVariantModal
    from "../../components/Modals/Products/CreateProductBasedOnProductVariantModal";
import {themes} from "../../layout/styles/ColorStyles";
import EditProductVariantInfo from "../../components/Forms/ProductVariants/EditProductVariantInfo";
import useUpdateProductVariant from "../../hooks/productVariants/useUpdateProductVariant";
import useRemoveProductFromProductVariant from "../../hooks/productVariants/useRemoveProductFromProductVariant";
import useGetAllBrands from "../../hooks/brands/useGetAllBrands";

const {Panel} = Collapse;

const Wrapper = styled.div`
  position: relative;
`;


const ProductVariantDetails = () => {

    const params = useParams();
    const {data, isLoading, getProductVariantById} = useGetProductVariantById();
    const {isLoadingDebugs, debugs, getProductVariantDebugByPvId} = useGetProductVariantDebugInfo();
    const {assignProductToProductVariant} = useAssignProductVariantToProduct();
    const {getProductsBySearchQuery, isLoading: isLoadingProductSearch} = useGetProductBySearchQuery();
    const [open, setIsOpen] = useState(false);
    const {updateProductVariant} = useUpdateProductVariant();
    const {remove} = useRemoveProductFromProductVariant();
    const {data: brands, isLoading: isLoadingBrands} = useGetAllBrands();

    const [query, setQuery] = useState('');
    const [options, setOptions] = useState([]);
    const [openModal, setOpenModal] = useState(false);


    useEffect(() => {
        if (query.length > 0) {
            getProductsBySearchQuery({search: query}).then((test) => test ? setOptions(test) : setOptions([]));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [query]);

    useEffect(() => {
        get();
        return null;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const get = () => {
        getProductVariantById(params.id);
        getProductVariantDebugByPvId(params.id);
    }

    const onChange = (val) => {
        assignProductToProductVariant({productId: val.id, ids: [params.id]})
        setQuery(val.name);
    }

    const handleOk = (values) => {
        updateProductVariant(values, params.id).then(() => getProductVariantById(params.id));
        setIsOpen(false)
    }

    const handleRemoveProduct = () => {
        remove({productGroupId: data.productGroup.id}).then(() => getProductVariantById(params.id));
    }

    return <><Wrapper>
        <Title title={"Aktualizacja wariantu"}/>
        {
            (isLoading || isLoadingBrands) ? <FormIndicator numberOfElements={1}/> : (
                <Paper>
                    <EditProductVariantInfo openModal={open} setOpenModal={setIsOpen} item={data} handleOk={handleOk}/>
                    <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between'
                    }}>
                        <Image src={data.owner.logo} height={50} width={150}/>
                        <div>
                            <Button type='primary' style={{
                                marginRight: '10px',
                                backgroundColor: themes.activeMenuItemColor,
                                borderColor: themes.activeMenuItemColor
                            }} onClick={() => setOpenModal(true)}>Przypisz produkt</Button>
                            <Button type='primary' style={{
                                backgroundColor: themes.activeMenuItemColor,
                                borderColor: themes.activeMenuItemColor
                            }} onClick={() => setIsOpen(true)}>Edytuj</Button>
                        </div>
                    </div>
                    <div style={{display: 'flex'}}>
                        <div>
                            <Label htmlFor={'product_variant_activity'}>Aktyność</Label>
                            <ActivityLabel active={data.active}/>
                        </div>
                        <div style={{marginLeft: '5px'}}>
                            <Label htmlFor={'product_variant_status'}>Posiada stan magazynowy</Label>
                            <ActivityLabel active={data.hasStock}/>
                        </div>
                    </div>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        flex: 1,
                        justifyContent: 'flex-start',
                        gap: '10px'
                    }}>
                        <ItemInfoWithInput label={'Nazwa wariantu'} value={data.name}/>
                        <ItemInfoWithInput label={'Nazwa hurtowni'} value={data.owner.name}/>
                    </div>

                    <div style={{display: 'flex', flexDirection: 'row'}}>
                        <div style={{width: "100%"}}>
                            <SearchSelect
                                productGroup={data.productGroup}
                                onChange={val => onChange(val)}
                                query={query}
                                setQuery={setQuery}
                                options={options}
                                setOptions={setOptions}
                                isLoading={isLoadingProductSearch}
                            />
                        </div>
                        <Button type='primary' style={{
                            backgroundColor: themes.activeMenuItemColor,
                            borderColor: themes.activeMenuItemColor
                        }} onClick={() => handleRemoveProduct()}>Usuń przypisany produkt</Button>
                    </div>
                    <ItemInfoWithInput label={'EAN'} value={data.ean}/>
                    <ItemInfoWithInput label={'Marka'} value={data.brand ? data.brand.name : 'Brak marki'}/>
                    {/*<Label>Kategoria Appet</Label>*/}
                    {/*{data.categories.map((it) => <ItemInfoWithInput label={''} value={it.fullPath}/>)}*/}
                    <ItemInfoWithInput label={'Kategoria Producenta'}
                                       value={data.ownerCategories.length ? data.ownerCategories[0].name : ''}/>
                    {
                        data.photo.length ? <Label htmlFor={'photos'}>Zdjęcia</Label> : <NoFoto>Brak Zdjęć</NoFoto>
                    }
                    <br/>
                    {
                        data.photo.map((it, index) => <Image key={index} src={it.fullPath} width="200px"
                                                             height="250px"/>
                        )
                    }
                    <MultipleItemsWrapper>
                        <ItemInfoWithInput width={'25%'} label={'Wartość'}
                                           value={data.productGroup.variantValue ? data.productGroup.variantValue.variantValue : '-'}/>
                        <ItemInfoWithInput width={'25%'} label={'Gramatura'} value={data.grammage}/>
                        <ItemInfoWithInput width={'25%'} label={'Vat'}
                                           value={data.price.length ? data.price[0].vatRate.value : 'brak danych'}/>
                    </MultipleItemsWrapper>
                    {
                        data.price.map((it) => (
                            <MultipleItemsWrapper key={it.id}>
                                <ItemInfoWithInput label={'Cena netto'} value={it.priceNet}/>
                                <ItemInfoWithInput label={'Cena brutto'} value={it.priceGross}/>
                                <ItemInfoWithInput label={'Cena brutto po rabacie'}
                                                   value={it.priceGrossSale ? it.priceGrossSale : 'Brak'}/>
                                <ItemInfoWithInput label={'Czy promocyjna cena?'}
                                                   value={it.isSale ? 'tak' : 'nie'}/>
                                <ItemInfoWithInput label={'Waluta'} value={it.currency.code}/>
                            </MultipleItemsWrapper>
                        ))
                    }
                    <MultipleItemsWrapper>
                        <ItemInfoWithInput width={'25%'} label={'Stan'} value={data.stock}/>
                        <ItemInfoWithInput width={'25%'} label={'Ostania aktualizacja stanów'}
                                           value={data.lastStockUpdate}/>
                    </MultipleItemsWrapper>
                    <DescriptionWrapper description={data.description} label={'Opis wariantu'}/>
                    <ItemInfoWithInput label={'Meta Keywords'} value={data.metaKeywords}/>
                    <ItemInfoWithInput label={'Meta Description'} value={data.metaDescription}/>
                    <ItemInfoWithInput label={'Ostatnia aktualizacja'} value={data.updatedAt}/>
                    <ItemInfoWithInput label={'Utworzono'} value={data.createdAt}/>
                    <Label htmlFor={'debug_text'}>Debug</Label>
                    {
                        isLoadingDebugs ? <FormIndicator numberOfElements={1}/> : (
                            <Collapse style={{borderRadius: '5px'}}>
                                {
                                    debugs.map((it, index) => (
                                        <Panel header={it.createdAt} key={index}>
                                        <pre style={{
                                            overflow: 'auto',
                                            whiteSpace: 'pre-wrap',
                                        }}>
                                   {
                                       JSON.stringify(
                                           JSON.parse(it.debug), null, 2)
                                   }
                                        </pre>
                                        </Panel>
                                    ))
                                }
                            </Collapse>
                        )
                    }
                    <CreateProductBasedOnProductVariantModal open={openModal} setOpen={setOpenModal} brands={brands}
                                                             reload={() => getProductVariantById(params.id)}/>
                </Paper>
            )
        }
    </Wrapper>
    </>;
}

export default ProductVariantDetails;


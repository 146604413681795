import useAlert from "../useAlert";
import axiosInstance from "../../lib/axios";

const useUpdateProductVariant = () => {
    const { errorMessage, successMessage } = useAlert();

    const updateProductVariant = async (
        obj: any, id: string
    ): Promise<any> => {
        try {
            await axiosInstance.put(
                `/admin/product/variant/${id}`,
                obj
            );
            successMessage('Product Variant został zapisany');

        } catch (err) {
            errorMessage('Błąd zapisu, spróbuj ponownie');
            throw err;
        }
    };

    return {updateProductVariant};
}

export default useUpdateProductVariant;
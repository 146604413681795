import {useState} from "react";
import axiosInstance from "../../lib/axios";

interface CreateBreedProps {
    name: string;
}

const useCreateBreed = () => {

    const [isLoading, setisLoading] = useState<boolean>(false);

    const create = async (obj: CreateBreedProps): Promise<any> => {
        try {
            setisLoading(true);
            const { data } = await axiosInstance.post("/admin/breed/", obj);

            if (data.id) {
                setisLoading(false);
            }
        } catch (err) {
            setisLoading(false);
            throw err;
        }
    };

    return {create, isLoading};
}

export default useCreateBreed;
import {Avatar, Card} from "antd";
import {NavLink} from "react-router-dom";
import StatusLabel from "../../layout/StatusLabel";
import PaymentStatusLabel from "../Labels/PaymentStatusLabel";
import Paper from "../../layout/Paper";

const OrderCardsMobile = ({data}) => {

return <>
    {
        data.map((it) => {
            return  <Card
                key={it.orderId}
                size="small"
                title={<div style={{display: 'flex', gap: '5px'}}>
                    <Avatar src={it.productOwner.logo}/>
                    <div style={{fontWeight: 400}}>
                        <div>{it.createdAt}</div>
                        <div>{it.address.name}</div>
                    </div>
                    </div>}
                extra={<NavLink to={`/order/items/${it.orderId}`}>{it.orderNumber}</NavLink>}
                style={{
                    width: '100%',
                    marginTop: '5px'
                }}
            >
                <div style={{display: 'flex', alignItems: 'center'}}>Status zamówienia: <StatusLabel status={it.status}/></div>
                {it.discount ? <div>Rabat: {it.discount.code} ({it.discount.discountValueFormatted})</div> : ''}
                {it.discount && it.discount.type !== 'FREE_SHIPPING' ? Math.round((it.originalTotalItemPriceGross-it.totalItemPriceGross)*100)/100 + 'zł' : ''}
                <div>Cena bez rabatu: {it.originalTotalItemPriceGross} zł</div>
                <div>Cena z rabatem: {it.totalItemPriceGross} zł</div>
                <div>Cena Total: {it.totalItemPriceGross} zł</div>
                <div style={{display: 'flex', gap: '10px'}}>Płatność: <PaymentStatusLabel paid={it.orderPaymentView.paid}/></div>
                <div>Dostawa: {it.deliverView.deliveryProductOwner.deliveryView.name}, {it.deliverView.price} zł</div>
            </Card>
        })
    }
    <div>{getFooter(data)}</div>
</>

}

export default OrderCardsMobile;


const getFooter = (data) => {
    let sumDiscount = 0;
    data.forEach((it) => sumDiscount += it.originalTotalPriceGross ? Math.round((it.originalTotalItemPriceGross - it.totalItemPriceGross) * 100) / 100 : 0)

    let sumPriceWithOutDiscount = 0;
    data.forEach((it) => sumPriceWithOutDiscount += it.originalTotalItemPriceGross ? it.originalTotalItemPriceGross : it.totalItemPriceGross)

    let sumPriceWithDiscount = 0;
    data.forEach((it) => sumPriceWithDiscount += it.totalItemPriceGross)

    let sumPriceTotal = 0;
    data.forEach((it) => sumPriceTotal += it.totalPriceGross)

    return <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-end'}}>
        <Paper>
            <div>Podsumowanie (ceny brutto)</div>
            <div>Suma Wartości rabatów {Math.round((sumDiscount) * 100) / 100} zł</div>
            <div>Suma cen bez rabatów {Math.round((sumPriceWithOutDiscount) * 100) / 100} zł</div>
            <div>Suma cen z rabatem {Math.round((sumPriceWithDiscount) * 100) / 100} zł</div>
            <div>Suma cen total {Math.round((sumPriceTotal) * 100) / 100} zł</div>
        </Paper>
    </div>
}
import {Button} from "antd";
import styled from "styled-components";

const ButtonLinkCss = styled(Button)`

  background: none !important;
  border: none;
  padding: 0 !important;
  /*optional*/
  font-family: arial, sans-serif;
  /*input has OS specific font-family*/
  color: #069;
  text-decoration: underline;
  cursor: pointer;
`

const ButtonLink = ({text, onClick}) => {

    return <ButtonLinkCss onClick={onClick}>{text}</ButtonLinkCss>
}

export default ButtonLink;
import {useEffect, useState} from "react";
import axiosInstance from "../../lib/axios";

const useGetAllIngredients = () => {

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [data, setData] = useState<{} | null>(null);

    useEffect(() => {
        getIngredients();
    }, []);

    const getIngredients = async (): Promise<any> => {
        try {

            const res = await axiosInstance.get(`/admin/ingredient/`);
            if (res.status === 200) {
                setData(res.data ? res.data : []);
            }
                setIsLoading(false);
        } catch (err) {
            console.log("err");
            setIsLoading(false);
        }
    };

    const reload = () => {
        getIngredients();
    };

    return { isLoading, data, reload };
}

export default useGetAllIngredients;
import styled from "styled-components";

export const FotoWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  //background-color: red;
  order: 5;
  width: 100%;
  //padding: 10px;
  //align-items: center;
`;

export const MultipleItemsWrapper = styled.div`
    display: flex;
    gap: 5px;
`;

export const NoFoto = styled.div`
  color: #e32d2d;
  font-weight: 500;
`;

import {Form, Input, Modal} from "antd";

const ProductOwnerCommunication = ({handleOk, onCancel, open, title, communication = null}) => {
    const [form] = Form.useForm();

    return (
        <Modal title={title}
               open={open}
               onOk={() => {
                   form
                       .validateFields()
                       .then((values) => {
                           handleOk(values);
                           form.resetFields();
                       })
                       .catch((info) => {
                           console.log("Validate Failed:", info);
                       });
               }}
               onCancel={onCancel}
               okText={!communication ? "Zapisz komunikację" : "Zapisz"}
               cancelText={"Anuluj"}>
            <Form
                layout="vertical"
                form={form}
                name="form_in_modal"
                initialValues={{
                    email: communication? communication.email : '',
                    mobileNumber: communication ? communication.number : '',
                    bankAccountNumber: communication ? communication.accountBankNumber : '',
                }}
            >
                <Form.Item
                    label="Email"
                    name="email"
                    rules={[
                        { required: true, message: "Wpisz email, pole jest wymagane!" },
                    ]}
                >
                    <Input placeholder="Wpisz email" />
                </Form.Item>
                <Form.Item
                    label="Numer kontaktowy"
                    name="mobileNumber"
                    rules={[
                        { required: true, message: "Wpisz numer, pole jest wymagane!" },
                    ]}
                >
                    <Input placeholder="Wpisz numer" />
                </Form.Item>
                <Form.Item
                    label="Numer konta bankowego"
                    name="bankAccountNumber"
                    rules={[
                        { required: true, message: "Wpisz numer konta bankowego, pole jest wymagane!" },
                        { len: 26, message: "Numer konta bankowego musi mieć 26 znaków!" },
                    ]}
                >
                    <Input placeholder="Numer konta bankowego" disabled={communication}/>
                </Form.Item>
            </Form>
        </Modal>
    );
}

export default ProductOwnerCommunication;
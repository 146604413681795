import {Select} from "antd";
import {UPDATE_PRODUCT_OWNER_ID} from "../../../reducers/OrderFiltersReducers";
import React, {useContext, useEffect, useState} from "react";
import OrderFiltersContext from "../../../context/OrderFiltersContext";

const OrderProductOwnerSelect = ({productOwners = [], context = OrderFiltersContext, action=UPDATE_PRODUCT_OWNER_ID}) => {

    const {dispatch, state} = useContext(context);
    const [po, setPO] = useState([]);

    useEffect(() => {
        let tmp = productOwners.map((it) => {
            return {
                ...it,
                label: it.name,
                value: it.id
            }
        })

        setPO(tmp);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    return <Select
        showSearch
        placeholder="Wybierz sprzedawcę"
        allowClear
        onClear={() => dispatch({type: action, payload: null})}
        onChange={(e, obj) => {
            if (obj) {
                dispatch({type: action, payload: obj.value})
            }
        }}
        filterOption={(input, option) =>
            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
        }
        optionFilterProp="children"
        value={state.productOwnerId}
        options={po}
    >
    </Select>
}


export default OrderProductOwnerSelect;
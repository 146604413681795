import {useState} from "react";
import axiosInstance from "../../lib/axios";

interface CreateProductOwner {
    name: string;
}

const useCreateProductOwner = () => {
    const [isLoading, setisLoading] = useState<boolean>(false);

    const create = async (obj: CreateProductOwner): Promise<any> => {
        try {
            setisLoading(true);
            await axiosInstance.post("/admin/product_owner/", obj);
        } catch (err) {
            setisLoading(false);
            throw err;
        }
    };

    return { create, isLoading };

};

export default useCreateProductOwner;
import {Dropdown, Image, Space, Table} from "antd";
import {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import ProductStatusLabel from "../Labels/ProductStatusLabel";
import styled from "styled-components";
import AssignProductsByNameModal from "../Modals/Products/AssignProductsByNameModal";
import ButtonLink from "../Buttons/ButtonLink";
import useRemoveProduct from "../../hooks/products/useRemoveProduct";
import DeleteProducts from "../Modals/Products/DeleteProducts";
import useUpdateProductStatus from "../../hooks/products/useUpdateProductStatus";
import ActivityLabel from "../Labels/ActivityLabel";


const NameContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
`;


const TableProductsTest = ({data, reload}) => {
    const [products, setProducts] = useState([]);
    const [selectedRow, setSelectedRow] = useState([]);
    const [openModal, setOpenModal] = useState(false);
    const [openModalDelete, setOpenModalDelete] = useState(false);
    const {removeProduct} = useRemoveProduct();
    const {update} = useUpdateProductStatus();

    useEffect(() => {
            setProducts(data.map((it) => {
                return {
                    ...it,
                    key: it.id
                }
            }))
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, []
    );

    const handleChangeStatus = () => {
        let tmp = selectedRow.map((it) => it.id);
        update({productIds: tmp, status: 'waiting'}).then(reload);
    }

    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedRow(selectedRows);
        },
    };

    const hasSelected = selectedRow.length > 0;

    const handleRemove = (id) => {
        removeProduct(id).then(reload);
    }

    const columns = [
        {
            title: 'Zdjęcie',
            dataIndex: 'photos',
            key: 'photos',
            render: (row) =>
                <Image
                    width={50}
                    height={50}
                    src={row[0] ? row[0].fullPath : 'graphic-placeholder.png'}
                    preview={!!row[0]}
                />

        },
        {
            title: 'Nazwa',
            dataIndex: '',
            key: 'name',
            render: (row) => <NameContent>
                <div>{row.name}</div>
                <div>{row.categories.map((it, index) => (
                    <div key={it.id + index} style={{fontSize: '11px'}}>{it.fullPath}</div>))}</div>
            </NameContent>
        },
        {
            title: 'Vat',
            dataIndex: 'vatRate',
            key: 'totalStock',
            align: 'center',
            width: '5%',
            render: row => row ? row.value + ' %' : 'Brak informacji'
        },
        {
            title: 'Wariantów',
            dataIndex: 'variants',
            key: 'variants',
            align: 'center',
            width: '5%',
            render: row => <>{row.length}</>
        },
        {
            title: 'Aktywność',
            dataIndex: 'active',
            key: 'active',
            width: '6%',
            render: row => <ActivityLabel active={row}/>
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            width: '6%',
            render: row => <ProductStatusLabel status={row}/>
        },
        {
            title: '',
            dataIndex: '',
            key: 'x',
            render: row => <Space key={row.id} size='middle'><Link
                key={row.id + '1'}
                to={`/product/update/${row.id}`}
            >
                Szczegóły
            </Link>
                <Link
                    key={row.id + '2'}
                    to={`/product/variants/${row.id}`}
                >
                    Warianty
                </Link>
                {
                    row.status === 'deleted' ? <ButtonLink text={'Usuń'} onClick={() => handleRemove(row.id)}/> : null
                }
            </Space>
        },
    ];

    const items = [
        {
            label: 'Przypisz produkty',
            key: '1',
            onClick: () => {
                setOpenModal(true)
            }
        }, // remember to pass the key prop
        {
            label: 'Usuń produkty',
            key: '2',
            onClick: () => {
                setOpenModalDelete(true)
            }
        },
        {
            label: 'Status oczekujący',
            key: '3',
            onClick: () => handleChangeStatus()
        },
    ];

    return (
        <>
            <Dropdown.Button
                disabled={!hasSelected}
                type="primary"
                menu={{items}}
            >
                Zarządzaj
            </Dropdown.Button>

            <div>
                <DeleteProducts setOpenModal={setOpenModalDelete} selectedProducts={selectedRow}
                                openModal={openModalDelete}/>
                <AssignProductsByNameModal setOpenModal={setOpenModal} openModal={openModal}
                                           selectedProducts={selectedRow}/>
                <Table rowSelection={rowSelection} columns={columns} dataSource={products} pagination={false}/>
            </div>
        </>
    );
}

export default TableProductsTest;

import {useState} from "react";
import axiosInstance from "../../lib/axios";
import useAlert from "../useAlert";
import {useNavigate} from "react-router-dom";

type CreateDiscountProps = {
    name: string;
    discountValue: number;
    minOrderValue?: number;
    isSingleUse: boolean;
    description?: string;
    isActive: boolean;
    activeFrom?: string;
    activeTo?: string;
    discountProductOwnerType: string;
    productOwnerIds: string;
    isProductOwnerDiscountCost: boolean;
};

const useCreateDiscount = () => {
    const [isLoading, setIsLoading] = useState(false);
    const {errorMessage, successMessage} = useAlert();
    const navigate = useNavigate();

    const create = async (obj: CreateDiscountProps): Promise<any> => {
        try {
            setIsLoading(true);
            await axiosInstance.post("/admin/discount/type", obj);
            successMessage('Utworzono promocje');
            navigate('/discounts');
        } catch (err) {
            setIsLoading(false);
            errorMessage("Błąd zapisu, spróbuj ponownie");
            throw err;
        }
    };

    return {isLoading, create};
};

export default useCreateDiscount;

import {Form, Input, Modal, Select} from "antd";
import GrammageSelect from "../../Selects/GrammageSelect";
import React from "react";

const NewProductForm = ({handleOK, onCancel, open, grammages}) => {

    const [form] = Form.useForm();

    return (
        <Modal title={'Utwórz nowy produkt'}
               open={open}
               onOk={() => {
                   form
                       .validateFields()
                       .then((values) => {
                           form.resetFields();
                           handleOK(values);
                       })
                       .catch((info) => {
                           console.log("Validate Failed:", info);
                       });
               }}
               onCancel={onCancel}
               okText={"Zapisz"}
               cancelText={"Anuluj"}>
            <Form
                layout="vertical"
                form={form}
                name="form_in_modal"
            >
                <Form.Item
                    label="Nazwa"
                    name="name"
                    rules={[
                        {required: true, message: "Wpisz nazwę, pole jest wymagane!"},
                    ]}
                >
                    <Input placeholder="Wpisz markę "/>
                </Form.Item>
                <Form.Item
                    label="Gramatura"
                    name="grammageId"
                    rules={[
                        {required: true, message: "Gramatura jest wymagana"},
                    ]}
                >
                    <GrammageSelect grammages={grammages} form={form} updateDefaultValue={(value) => form.setFieldsValue('grammageId', value)}/>
                </Form.Item>
                <Form.Item
                    label="Vat Rate"
                    name="vatRate"
                    rules={[
                        {required: true, message: "Vat jest jest wymagany"},
                    ]}
                >
                    <Select
                    mode="single"
                    style={{width: '100%'}}
                    >
                    <Select.Option key={'vat_a'} value={23}>
                        Vat A - 23%
                    </Select.Option>
                    <Select.Option key={'vat_b'} value={8}>
                        Vat B - 8%
                    </Select.Option>
                    <Select.Option key={'vat_c'} value={5}>
                        Vat C - 5%
                    </Select.Option>
                    <Select.Option key={'vat_d'} value={0}>
                        Vat D - 0%
                    </Select.Option>

                </Select>
                </Form.Item>
            </Form>
        </Modal>
    );
}

export default NewProductForm;
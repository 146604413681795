import useAddIngredientToProduct from "../../../../hooks/products/productIngredients/useAddIngredientToProduct";
import AssignIngredientToProductForm from "./AssignIngredientToProductForm";
import useGetAllIngredients from "../../../../hooks/ingredient/useGetAllIngredients";
import {useParams} from "react-router-dom";
import useGetProductFullInformation from "../../../../hooks/products/useGetProductFullInformation";
import useKeypress from "../../../../hooks/useKeypress";

const AssignIngredientToProduct = ({openModal, handleClose, grammages, handleOpenModal}) => {
    const {add} = useAddIngredientToProduct();
    const {data: ingredients, isLoading: isLoadingIngredients} = useGetAllIngredients();
    const params = useParams();
    const {reload} = useGetProductFullInformation();

    const handleOk = (values) => {
        add({productId: params.id, ...values}).then(() => reload(params.id));
        handleClose();
    };

    useKeypress('Alt', 'n', () => {
        handleOpenModal();
        document.getElementById('select-ing').focus();
    });

    if (isLoadingIngredients) return null;

    return <><AssignIngredientToProductForm
        open={openModal}
        grammages={grammages}
        ingredients={ingredients}
        handleOk={handleOk}
        onCancel={handleClose}
    /></>
}

export default AssignIngredientToProduct;
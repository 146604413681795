import useAlert from "../useAlert";
import axiosInstance from "../../lib/axios";

interface UpdateInfoMarketingBoxProps {
    groupName: string
    isActive: boolean
    isOpenInNewTab: boolean
    description?: string
    headerTitle?: string
    actionType?: string
    actionText?: string
    altValue?: string
}

const useUpdateMarketingBoxInfo = () => {

    const {successMessage, errorMessage} = useAlert();

    const update = async (obj: UpdateInfoMarketingBoxProps): Promise<any> => {
        try {
            await axiosInstance.put("/admin/marketing-box/info/" + obj.groupName, obj);
            successMessage("Dane zapisne");
        } catch (err) {
            errorMessage("Błąd podczas zapisu");
            throw err;
        }
    };

    return { update };

}

export default useUpdateMarketingBoxInfo;
import useAlert from "../useAlert";
import axiosInstance from "../../lib/axios";

interface UseAssignTagsToBreed {
    other: Array<string>,
    puppy: Array<string>,
    old: Array<string>,
    mature: Array<string>,
    id: string
}


const useAssignTagsToBreed = () => {

    const {successMessage, errorMessage} = useAlert();

    const update = async (
        obj: UseAssignTagsToBreed
    ): Promise<any> => {

        try {
            await axiosInstance.put(
                `/admin/breed/assign-tags/${obj.id}`,
                obj
            );
            successMessage("Tagi zostały dodane do rasy");
        } catch (err) {
            errorMessage("Wystąpił błąd, spróbuj ponownie");
            throw err;
        }
    };

    return {update};

}

export default useAssignTagsToBreed;
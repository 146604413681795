import styled from "styled-components";
import Title from "../../components/Title";
import useGetAllStaticPages from "../../hooks/staticPage/useGetAllStaticPages";
import TableStaticPage from "../../components/Tables/StaticPages/TableStaticPage";
import FormIndicator from "../../components/Forms/FormIndicator";
import {Button} from "antd";
import CreateStaticPage from "../../components/Modals/StaticPage/CreateStaticPage";
import {useState} from "react";

const Wrapper = styled.div`
  position: relative;
`;


const StaticPages = () => {

    const {isLoading, data, getStaticPages} = useGetAllStaticPages();
    const [openModal, setOpenModal] = useState(false);

    return <>
        <Wrapper>
            <Title title={"Strony Statyczne"} />
            <Button onClick={() => setOpenModal(true)} style={{marginBottom: '10px'}} type='primary'>Dodaj nową stronę</Button>
            <CreateStaticPage
                handleClose={() => setOpenModal(false)}
                reload={getStaticPages}
                openModal={openModal}
            />
            {isLoading ? <FormIndicator numberOfElements={3}/> : <TableStaticPage data={data} reload={getStaticPages}/> }
        </Wrapper></>;
}

export default StaticPages;
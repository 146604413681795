import useAlert from "../useAlert";
import axiosInstance from "../../lib/axios";

type UpdateCategorySlugAlt = {
    slugAlt: string;
};

const useUpdateCategorySlugAlt = () => {
    const {errorMessage,successMessage} = useAlert();

    const update = async (id: string, obj: UpdateCategorySlugAlt): Promise<any> => {
        try {
            await axiosInstance.put("/admin/category/slug-alt/" + id, obj);
            successMessage('Slug został zmieniony')
        } catch (err) {
            errorMessage('Podayny slug istnieje, podaj inną wartość.')
            throw err;
        }
    };

    return { update };
}

export default useUpdateCategorySlugAlt;
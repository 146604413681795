import React, {useState} from "react";
import ButtonLink from "../../Buttons/ButtonLink";
import AssignBreedToTagForm from "../../Forms/Breed/AssignBreedToTagForm";
import useAssignTagsToBreed from "../../../hooks/breeds/useAssignTagsToBreed";

const AssignBreedToTag = ({reload, tags, item}) => {

    const [updateOpenModal, setUpdateOpenModal] = useState(false);
    const {update} = useAssignTagsToBreed();

    const handleSave = (values) => {
        update({
            puppy: values.puppy,
            id: item.id,
            old: values.old,
            mature: values.mature,
            other: values.other
        }).then(reload);
        setUpdateOpenModal(false);
    }

    return (
        <>
            <ButtonLink
                text={'Przypisz tagi'}
                onClick={() => setUpdateOpenModal(true)}
            />
            <AssignBreedToTagForm open={updateOpenModal} onCancel={() => setUpdateOpenModal(false)}
                                  handleOK={handleSave} tags={tags} item={item}/>
        </>
    );
}

export default AssignBreedToTag;
import {Form, Input, Modal, Select, Switch, TimePicker, Typography} from "antd";
import {useEffect, useState} from "react";
import moment from "moment";
import LogoDelivery from "../../Image/LogoDelivery";
import {CheckOutlined, CloseOutlined} from "@ant-design/icons";
import {themes} from "../../../layout/styles/ColorStyles";

const { Option } = Select;

const { Text } = Typography;
const NewDeliveryAssigmentForProductOwnerForm = ({
                                                     deliveries = [],
                                                     handleOk,
                                                     onCancel,
                                                     open,
                                                     title,
                                                     row = null,
                                                 }) => {

    const [form] = Form.useForm();
    const [change, setChange] = useState(true);
    const [isActive, setIsActive] = useState(true);
    const [isDeliverySameDay, setIsDeliverySameDay] = useState(true);

    useEffect(()=> {
        setIsActive(row ? row.active : true);
        setIsDeliverySameDay(row ? row.deliverySameDay : true);
        setChange(row ? row.deliveryFreeFromPrice : true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onDeliveryChange = (value) => {
        form.setFieldsValue(value);
    };

    return (
        <Modal
            title={title}
            open={open}
            onOk={() => {
                form
                    .validateFields()
                    .then((values) => {
                        handleOk(values);
                    })
                    .catch((info) => {
                        console.log("Validate Failed:", info);
                    });
            }}
            onCancel={onCancel}
            okText={"Zapisz"}
            cancelText={"Anuluj"}
        >
            <Form
                layout="vertical"
                form={form}
                name="form_in_modal"
                initialValues={{
                    delivery: row ? row.deliveryView.name : null,
                    isFreeFromPrice: row ? row.deliveryFreeFromPrice : false,
                    isActive: isActive,
                    price: row ? row.price : null,
                    deliveryDurationTime: row ? row.deliveryDurationTime : 24,
                    freeFromPrice: row ? row.freeFromPrice : null,
                    maxTimeSendSameDay: row
                        ? moment(row.maxTimeSendSameDay, "HH:mm")
                        : null,
                    isDeliverySameDay: isDeliverySameDay,
                }}
            >
                <Form.Item
                    key="deliveryId"
                    name="delivery"
                    label="Dostawca"
                    rules={[
                        {
                            required: true,
                            message: "Wybierz dostawce",
                        },
                    ]}
                >
                    <Select
                        disabled={row}
                        placeholder={row ? row.deliveryView.name : "Wybierz dostawce"}
                        onChange={onDeliveryChange}
                        allowClear
                    >
                        {deliveries.map((it) => (
                            <Option key={it.id} value={it.id}>
                                <LogoDelivery src={it.logo}/>{" "}
                                {it.name}
                            </Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item
                    label="Aktywność metody dostawy"
                    name="isActive"
                    valuePropName="checked"
                >
                    <Switch
                        checkedChildren={<CheckOutlined />}
                        unCheckedChildren={<CloseOutlined />}
                        style={{
                            backgroundColor: isActive
                                ? themes.activeMenuItemColor
                                : themes.switchInActive,
                        }}
                        onChange={(value) => setIsActive(value)}
                    />
                </Form.Item>
                <Text type="secondary">
                    Ta opcja wyłącza lub włącza metodę dostawy. Każdą metodę dostawy można
                    włączyć lub wyłączyć w dowolnym momencie.
                </Text>
                <Form.Item
                    name="price"
                    label="Koszt dostawy"
                    rules={[
                        {
                            required: true,
                            message: "Cena jest wymagana",
                        },
                    ]}
                >
                    <Input type="number" addonAfter="zł" />
                </Form.Item>
                <Form.Item
                    label="Darmowa dostawa"
                    name="isFreeFromPrice"
                    valuePropName="checked"
                >
                    <Switch
                        defaultChecked={change}
                        style={{
                            backgroundColor: change
                                ? themes.activeMenuItemColor
                                : themes.switchInActive,
                        }}
                        onChange={(value) => setChange(value)}
                    />
                </Form.Item>
                <Text type="secondary">
                    Klient otrzyma możliwość wybrania tej metody dostawy jako darmowej po
                    przekroczeniu określonego progu wartości zamówienia.
                </Text>
                <Form.Item
                    style={{ marginTop: "10px" }}
                    name="freeFromPrice"
                    label="Próg darmowej dostawy"
                    rules={[
                        {
                            required: change,
                            message: "Cena jest wymagana",
                        },
                    ]}
                >
                    <Input type="number" disabled={!change} addonAfter="zł" min={0} />
                </Form.Item>
                <Form.Item
                    label="Możliwość wysyłki tego samego dnia"
                    name="isDeliverySameDay"
                    valuePropName="checked"
                >
                    <Switch
                        defaultChecked={isDeliverySameDay}
                        style={{
                            backgroundColor: isDeliverySameDay
                                ? themes.activeMenuItemColor
                                : themes.switchInActive,
                        }}
                        onChange={(value) => setIsDeliverySameDay(value)}
                    />
                </Form.Item>
                <Text type="secondary">
                    Określa możliwość nadania paczki przez sklep, tego samego dnia w
                    którym nastąpiło zamówienie.
                </Text>

                {isDeliverySameDay ? (
                    <>
                        <Form.Item
                            style={{ marginTop: "10px" }}
                            name="maxTimeSendSameDay"
                            label="Maksymalna godzina zamówienia z gwarancją wysyłki tego samego dnia"
                        >
                            <TimePicker placeholder={"Ustaw czas"} format={"HH:ss"} />
                        </Form.Item>
                        <Text type="secondary">
                            Wybierz godzinę do której klient może zamówić produkt z gwarancją
                            wysyłki zamówienia tego samego dnia. Po tej godziny wysyłka
                            zamówienia następuje następnego dnia roboczego.
                        </Text>
                    </>
                ) : (
                    <>
                        <Form.Item
                            name="deliveryDurationTime"
                            label="Czas nadania zamówienia"
                        >
                            <Select placeholder={row ? row.deliveryDurationTime : "Wybierz"}
                                    onChange={onDeliveryChange}
                                    allowClear
                            >
                                <Option key={1} value={24}>
                                    Następny dzień roboczy
                                </Option>
                                <Option key={2} value={48}>
                                    2 dni robocze
                                </Option>
                                <Option key={3} value={72}>
                                    3 dni robocze
                                </Option>
                                <Option key={4} value={98}>
                                    4 dni robocze
                                </Option>
                            </Select>
                        </Form.Item>
                        <Text type="secondary">
                            Wartość ta określa czas w którym nastąpi nadanie zamówienia
                            dostawcy. Będziemy wyświetlać tę informację klientom jeszcze przed
                            dokonaniem zamówienia co może wpłynąć na konkurencyjność oferty
                            Twojego sklepu.
                        </Text>
                    </>
                )}
            </Form>
        </Modal>
    );

}

export default NewDeliveryAssigmentForProductOwnerForm;
import React, {useContext} from "react";
import {Pagination} from "antd";
import styled from "styled-components";
import {themes} from "../layout/styles/ColorStyles";
import {isMobile} from "react-device-detect";

const limitOptions = [10, 20, 50, 100, 500];

const Wrapper = styled.div`
  @media (min-width: ${(props) => props.theme.breakpoints.mobile}) {
    
  }
  display: flex;
  justify-content: flex-end;
  margin-top: 15px;
  margin-bottom: 15px;
`;

const PaginationWrapper = styled(Pagination)`
  .ant-pagination-item {
    :hover {
      border: 1px solid #a9a9a9;

      a {
        color: #a9a9a9;
      }
    }
  }

  .ant-pagination-item-active {
    border: 1px solid #808080;

    a {
      color: ${themes.activeMenuItemColor};
    }
  }

  .ant-pagination-item-link {
    :hover {
      border: 1px solid #a9a9a9;
      color: #808080;

      a {
        color: #a9a9a9;
      }
    }
  }

  .ant-select-item-option-active {
    background-color: #f5f5f5;
  }

  .ant-select-item-option-selected {
    background-color: #e8e8e8;
  }

  .ant-select-selector {
    border: 1px solid #a9a9a9;

    :hover {
      border: 1px solid #808080 !important;
    }
  }
`;

const PaginationList = ({pagination, FiltersContext, type}) => {
    const {dispatch, state} = useContext(FiltersContext);

    const handleChange = (page, pageSize) => {
        dispatch({
            type: type,
            payload: {page: page, limit: pageSize},
        });
    };

    return (
        <Wrapper>
            <PaginationWrapper
                showTotal={total => `Ilość elementów: ${total} `}
                showSizeChanger
                simple={isMobile}
                current={parseInt(state.page)}
                total={pagination && pagination.totalItems ? pagination.totalItems : 0}
                pageSizeOptions={limitOptions}
                pageSize={pagination && pagination.limit ? pagination.limit : 0}

                onChange={handleChange}
            />
        </Wrapper>
    );
};

export default PaginationList;

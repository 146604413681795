import axiosInstance from "../../lib/axios";
import useAlert from "../useAlert";

const useUpdateProductOwnerDescription = () => {

    const {successMessage, errorMessage} = useAlert();

    const update = async (id: string, description: string): Promise<any> => {
        try {
            const obj = {
                description: description,
            };
            await axiosInstance.put("/admin/product_owner/description/" + id, obj);
            successMessage("Description updated successfully");
        } catch (err) {
            errorMessage("Error updating description");
            throw err;
        }
    }

    return {update};
}
export default useUpdateProductOwnerDescription;
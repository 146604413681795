import {Table} from "antd";
import React, {useContext, useState} from "react";
import {NavLink} from "react-router-dom";
import UpdateUserPasswordModal from "../../Modals/User/UpdateUserPasswordModal";
import EditUser from "../../Modals/User/EditUser";
import ButtonLink from "../../Buttons/ButtonLink";
import UserFiltersContext from "../../../context/UserFiltersContext";
import {UPDATE_ORDER_BY} from "../../../reducers/UserFiltersReducres";
import PaymentStatusLabel from "../../Labels/PaymentStatusLabel";
import useUpdateUserToDelete from "../../../hooks/users/useUpdateUserToDelete";

const UsersTable = ({data, reload}) => {
    const [openModal, setOpenModal] = useState(false);
    const {dispatch, state} = useContext(UserFiltersContext);

    const {updateDel} = useUpdateUserToDelete();

    const setToDelete = (id) => {
        updateDel(id).then(() => reload());
    }

    const columns = [
        {
            title: 'Aktywność',
            dataIndex: 'active',
            key: 'active',
            render: row => <PaymentStatusLabel paid={row}/>
        },
        {
            title: 'Konto usunięte?',
            dataIndex: 'isDeleted',
            key: 'isDeleted',
            render: row => <PaymentStatusLabel paid={row}/>
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            sorter: () => null,
        },
        {
            title: 'Imie',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Nazwisko',
            dataIndex: 'lastName',
            key: 'lastName',
        },
        {
            title: 'Numer telefonu',
            dataIndex: 'number',
            key: 'number',
        },
        {
            title: 'Utworzono',
            dataIndex: 'createdAt',
            key: 'createdAt',
            sorter: () => null,
        },
        {
            title: '',
            key: 'action',
            render: row => <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '10px'}}>
                <EditUser
                    reload={reload}
                    item={row}
                />
                <NavLink
                    to={`/users/info/${row.id}`}
                    key={row.id}
                    style={{textDecotration: "none !important"}}
                >
                    Szczegóły
                </NavLink>
                <ButtonLink text={'Zmień hasło'} onClick={() => setOpenModal(true)}/>
                <UpdateUserPasswordModal
                    openModal={openModal}
                    handleClose={handleClose}
                    item={row}
                />
                <ButtonLink onClick={() => setToDelete()} text={'Usuń użytkownika'}/>
            </div>
        }
    ];

    const handleClose = () => {
        setOpenModal(false);
    };

    const handleChange = (pagination, filters, sorter) => {
        if (sorter.columnKey === "createdAt" && sorter.order === 'ascend') {
            dispatch({type: UPDATE_ORDER_BY, payload: 'createdAsc'})
        }

        if (sorter.columnKey === "createdAt" && sorter.order === 'descend') {
            dispatch({type: UPDATE_ORDER_BY, payload: 'createdDesc'})
        }

        if (sorter.columnKey === "email" && sorter.order === 'descend') {
            dispatch({type: UPDATE_ORDER_BY, payload: 'emailDesc'})
        }

        if (sorter.columnKey === "email" && sorter.order === 'ascend') {
            dispatch({type: UPDATE_ORDER_BY, payload: 'emailAsc'})
        }

        reload({state});
    };

    return <><Table onChange={handleChange}
                    pagination={false}
                    style={{marginTop: '10px'}}
                    columns={columns}
                    dataSource={data.map((it, index) => {
                        return {...it, key: index}
                    })}/>
    </>

}

export default UsersTable;

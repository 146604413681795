import useCreateFlavour from "../../../hooks/flavours/useCreateFlavour";
import FlavourForm from "../../Forms/Flavours/FlavourForm";

const CreateFlavourModal = ({ open, setOpenModal, reload }) => {

    const {create } = useCreateFlavour();

    const handleClose = () => {
        setOpenModal(false);
    };

    const handleSave = (obj) => {
        create(obj)
            .then(() => {
                setOpenModal(false);
                reload();
            });
    };

    return (
     <FlavourForm
                        open={open}
                        onCancel={handleClose}
                        handleOK={(values) => handleSave(values)}/>
    );
}

export default CreateFlavourModal;
import { useState } from "react";
import Title from "../../components/Title";
import styled from "styled-components";
import CustomButton from "../../components/Forms/Buttons/CustomButton";
import { FaPlus } from "react-icons/fa";
import useCreateCategory from "../../hooks/category/useCreateCategory";
import useGetAllCategories from "../../hooks/category/useGetAllCategories";
import FormIndicator from "../../components/Forms/FormIndicator";
import CategoryTreeManager from "../../components/Forms/Category/CategoryTreeManager";
import CreateCategoryModal from "../../components/Modals/Category/CreateCategoryModal";

const Wrapper = styled.div`
  position: relative;
`;

const Category = () => {
  const { create } = useCreateCategory();
  const [openModal, setOpenModal] = useState(false);
  const { data, isLoading, reload } = useGetAllCategories();
  const [helper, setHelper] = useState({ parentId: null, title: null });

  const closeModal = () => {
    setOpenModal(false);
  };

  const onSave = (obj) => {
    create({ name: obj.name, parentId: helper.parentId })
      .then(() => {
        reload();
      })
      .finally(() => {
        setHelper({ parentId: null, title: null });
        closeModal();
      });
  };

  return (
    <>
      <Wrapper>
        <Title title="Zarządzanie kategoriami" />
        {isLoading && !data ? (
          <FormIndicator numberOfElements={3} />
        ) : (
          <>
            <CustomButton
              title="Dodaj kategorię 1 poziomu"
              icon={<FaPlus />}
              onClick={() => {
                setHelper({
                  parentId: null,
                  title: "Dodaj kategorię 1 poziomu",
                });
                setOpenModal(true);
              }}
            />
            <CategoryTreeManager
              categories={data}
              setOpenModal={() => setOpenModal(true)}
              setHelper={setHelper}
              reload={reload}
            />
          </>
        )}
      </Wrapper>
      <CreateCategoryModal
        open={openModal}
        title={helper.title}
        handleSave={onSave}
        handleClose={closeModal}
      />
    </>
  );
};

export default Category;

import Modal from "react-modal";
import { FaPlus } from "react-icons/fa";
import {
  customStyles,
  IconWrapper,
  Title,
} from "../../../layout/styles/ModalStyle";
import CreateCategory from "../../Forms/Category/CreateCategory";

// Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement("#root");

const CreateCategoryModal = ({
  open,
  title,
  handleSave,
  handleClose,
  name = "",
}) => {
  return (
    <div onClick={(e) => e.stopPropagation()}>
      <Modal
        isOpen={open}
        onRequestClose={handleClose}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div style={{ width: "600px" }}>
          <Title>
            <IconWrapper>
              <FaPlus style={{ color: "white" }} />
            </IconWrapper>
            {title ? title : "Dodaj kategorię"}
          </Title>

          <CreateCategory save={handleSave} name={name} />
        </div>
      </Modal>
    </div>
  );
};

export default CreateCategoryModal;

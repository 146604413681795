import {useContext, useState} from "react";
import {Input} from "antd";

const InputSearch = ({context, type, test}) => {

    const {dispatch} = useContext(context);
    const [text, setText] = useState(test);

    return <Input defaultValue={text}
                  onBlur={(e) => dispatch({type: type, payload: e.target.value}) && setText(e.target.value)} />
}

export default InputSearch;
import useAlert from "../useAlert";
import axiosInstance from "../../lib/axios";

type UpdateCartStatusProps = {
    id:string,
    cartStatus: string
}

const useUpdateCartStatus = () => {

    const {successMessage, errorMessage} = useAlert();

    const update = async (obj: UpdateCartStatusProps): Promise<any> => {
        try {
            await axiosInstance.put("/admin/cart/status/" + obj.id, obj);
            successMessage("Zapisano");
        } catch (err) {
            errorMessage("Błąd zapisu, spróbuj ponownie");
            throw err;
        }
    };

    return {update};
}

export default useUpdateCartStatus;
import axiosInstance from "../../lib/axios";
import useAlert from "../useAlert";

const useAssignBrandToProduct = () => {

    const {successMessage, errorMessage} = useAlert();

    const update = async (productId: string, brandId: string): Promise<any> => {
        const obj = {brandId: brandId};

    try {
      await axiosInstance.put(`/admin/product/${productId}/brand`, obj);
      successMessage("Marka została zmieniona, zmiany mogą być widoczne po kliku minutach");
    } catch (err) {
      errorMessage("Wystąpił błąd podczas zmiany marki, sknotaktuj się z administratorem");
      throw err;
    }
  };

  return { update };
};

export default useAssignBrandToProduct;

import styled from "styled-components";
import React, {useContext} from "react";
import Paper from "../../../../layout/Paper";
import {Form, Button} from "antd";
import ProductsFiltersContext from "../../../../context/ProductsFiltersContext";
import {
    CHANGE_PAGE,
    CLEAR_ALL,
    UPDATE_BRAND_IDS,
    UPDATE_CATEGORY_IDS,
    UPDATE_DATE_FROM,
    UPDATE_DATE_TO,
    UPDATE_PRODUCT_OWNER_IDS,
    UPDATE_TEXT_SEARCH,
} from "../../../../reducers/ProductsFiltersReducers";
import CategorySelect from "../../../Selects/CategorySelect";
import TextSearchInput from "../TextSearchInput";
import MultiSelectFilter from "./MultiSelectFilter";
import ActivitySelect from "./ActivitySelect";
import ProductStatusSelect from "./ProductStatusSelect";
import QuantityInput from "./QunatityInput";
import DatePickerFrom from "./DatePickerFrom";
import DatePickerTo from "./DatePickerTo";
import OrderBySelect from "./OrderBySelect";

const Content = styled(Form)`
  display: grid;
  width: 100%;
  height: 100%;
  grid-template-columns: 25% 25% 24% 25%;
  column-gap: 5px;
`;


const FilterProduct = ({brands, categories = [], reload, productOwners}) => {

    const [form] = Form.useForm();

    const {dispatch, state} = useContext(ProductsFiltersContext);
    const [selectedCategories, setSelectedCategories] = React.useState([]);

    const handleOnFinish = () => {
        dispatch({type: UPDATE_CATEGORY_IDS, payload: selectedCategories});
        dispatch({type: CHANGE_PAGE, payload: 1});
        reload({
            ...state,
            categoryIds: selectedCategories,
            page: 1
        });
    }

    const handleOnReset = () => {
        form.resetFields();
        dispatch({type: CLEAR_ALL});
        setSelectedCategories([]);
    }

    return (<Paper>
        <Content
            form={form}
            name="control-hooks"
            onReset={handleOnReset}
            onFinish={() => handleOnFinish()}
            labelCol={{span: 24}}
        >
            <Form.Item label="Nazwa produktu, ID Produktu" name="textSearch">
                <TextSearchInput type={UPDATE_TEXT_SEARCH} context={ProductsFiltersContext}/>
            </Form.Item>
            <Form.Item name="brandIds" label="Producent">
                <MultiSelectFilter brands={brands} type={UPDATE_BRAND_IDS} context={ProductsFiltersContext}
                                   ids={state.brandIds}/>
            </Form.Item>
            <Form.Item name="poIds" label="Sklep">
                <MultiSelectFilter brands={productOwners} type={UPDATE_PRODUCT_OWNER_IDS}
                                   context={ProductsFiltersContext} ids={state.productOwnersIds}/>
            </Form.Item>
            <Form.Item name="categoryIds" label="Kategorie">
                <CategorySelect categories={categories} selectedCategories={state.categoryIds}
                                setCategories={setSelectedCategories}/>
            </Form.Item>
            <Form.Item name="isActive" label="Aktywność">
                <ActivitySelect/>
            </Form.Item>
            <Form.Item name="productStatus" label="Status">
                <ProductStatusSelect/>
            </Form.Item>
            <Form.Item label="Stany magazynowe">
                <QuantityInput/>
            </Form.Item>
            <Form.Item name="dateFrom" label="Data utworzenia od">
                <DatePickerFrom type={UPDATE_DATE_FROM} context={ProductsFiltersContext}/>
            </Form.Item>
            <Form.Item name="dateTo" label="Data utworzenia do">
                <DatePickerTo type={UPDATE_DATE_TO} context={ProductsFiltersContext}/>
            </Form.Item>
            <Form.Item name="orderBy" label="Sortuj po:">
                <OrderBySelect/>
            </Form.Item>
            <Form.Item>
                <Button
                    style={{marginRight: "10px"}}
                    type="primary"
                    htmlType="submit"
                >Zastosuj</Button>

                <Button htmlType="button" onClick={handleOnReset}>
                    Wyczyść filtry
                </Button>
            </Form.Item>
        </Content>
    </Paper>);
}

export default FilterProduct;
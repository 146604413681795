import {useState} from "react";
import axiosInstance from "../../lib/axios";

const useGetProductVariantPhotosByProductId = () => {

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [data, setData] = useState<{} | null>(null);

    const getPhotos = async (id: string, hasIndicator: boolean = true): Promise<any> => {
        try {
            setIsLoading(hasIndicator);
            const res = await axiosInstance.get(`/admin/product/photo/pv_photos/${id}`);

            if (res.status === 200) {
                setData(res.data ? res.data : []);
                setIsLoading(false);
            }
        } catch (err) {
            throw err;
        }
    };


    return { isLoading, getPhotos, data };
}

export default useGetProductVariantPhotosByProductId;
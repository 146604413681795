import {useEffect, useState} from "react";
import axiosInstance from "../../lib/axios";

const useGetAllBrandGroup = () => {

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [data, setData] = useState<{} | null>(null);

    useEffect(() => {
        getBrandGroup();
    }, [])

    const getBrandGroup = async (): Promise<any> => {
        try {

            const res = await axiosInstance.get(`/admin/brand/group/`);
            if (res.status === 200) {
                setData(res.data ? res.data : []);
                setIsLoading(false);
            }
        } catch (err) {
            console.log("err");
            setIsLoading(false);
        }
    };

    const reload = () => {
        setIsLoading(true)
        getBrandGroup();
    };

    return { isLoading, data, reload };

}

export default useGetAllBrandGroup;
import { useState } from "react";
import axiosInstance from "../../lib/axios";

type CreateProductProps = {
  name: string;
  vatRate: number;
};

const useCreateProduct = () => {
  const [isLoading, setisLoading] = useState<boolean>(false);

  const create = async (obj: CreateProductProps): Promise<any> => {
    try {
      setisLoading(true);
      const { data } = await axiosInstance.post("/admin/product/", obj);

      if (data.id) {
        setisLoading(false);
        return data.id;
      }
    } catch (err) {
      setisLoading(false);
      throw err;
    }
  };

  return { create, isLoading };
};

export default useCreateProduct;

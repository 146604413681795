import useCreateIngredientGroup from "../../../hooks/ingredientGroup/useCreateIngredientGroup";
import IngredientGroupForm from "../../Forms/IngredientGroup/IngredientGroupForm";

const CreateIngredientGroup = ({reload, handleClose, openModal}) => {

    const {create} = useCreateIngredientGroup();

    const handleOK = (values) => {
        create(values).then(reload);
        handleClose();
    }

    return (
        <IngredientGroupForm
            open={openModal}
            onCancel={handleClose}
            handleOK={(values) =>handleOK(values)}
        />
    )
}

export default CreateIngredientGroup;
import {useState} from "react";
import axiosInstance from "../../../lib/axios";
import useAlert from "../../useAlert";

type DeleteProductConnection = {
    p1: string,
    p2: string,
}


const useDeleteRelation = () => {

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const {successMessage, errorMessage} = useAlert();

    const deleteApi = async (obj: DeleteProductConnection): Promise<any> => {
        try {
            setIsLoading(true);
            await axiosInstance.delete("/admin/related/", {data: obj});

            successMessage("Product został usunięty z rekomendacji");
            setIsLoading(false);


        } catch (err) {
            setIsLoading(false);
            errorMessage('Błąd podczas usuwania rekomendacji');
            throw err;
        }
    };

    return {deleteApi, isLoading};
}

export default useDeleteRelation;

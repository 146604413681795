import ProductOwnerCommunication from "../../../Forms/ProductOwner/ProductOwnerCommunication";
import useUpdateProductOwnerCommunication from "../../../../hooks/productOwners/useUpdateProductOwnerCommunication";


const UpdateProductOwnerCommunicationModal = ({openModal, handleClose, item, reload}) => {

    const {update } = useUpdateProductOwnerCommunication();

    const handleOk = (values) => {
        let obj = {
            id: item.id,
            ...values
        }

        update(obj).then(() => {
            reload();
            handleClose();
        });
    }

    return (
        <ProductOwnerCommunication
            title={'Dodaj dane kontaktowe'}
            handleOk={handleOk}
            onCancel={handleClose}
            open={openModal}
            communication={item.communicationView}
        />
    );
}

export default UpdateProductOwnerCommunicationModal;
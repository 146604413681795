import { useState } from "react";
import axiosInstance from "../../lib/axios";

interface CreateVariantsProps {
  productId: string;
  name: string;
  code: string;
  stock: number;
  ean: string;
  priceVariants: Array<{
    priceNet: number;
    priceGross: number;
    currencyCode: string;
    vatRateId: string;
    priceGrossSale?: number;
  }>;
}

const useCreateVariantsProduct = () => {
  const [isLoading, setIsLoading] = useState(false);

  const create = async (obj: CreateVariantsProps): Promise<any> => {
    try {
      setIsLoading(true);

      await axiosInstance.post(`/admin/product/variant/`, obj);

      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      throw err;
    }
  };

  return { create, isLoading };
};

export default useCreateVariantsProduct;

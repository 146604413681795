export const UPDATE_PV_IDS_ARRAY = 'update_pv_ids_array';
export const CLEAR_ALL = 'clear_all';
export const ids = "ids";

interface Props {
    [ids]: string[];
}

export const initialState: Props = {
    [ids]: []
};

type ACTIONTYPE =
    | { type: typeof CLEAR_ALL; payload: [] }
    | {
    type: typeof UPDATE_PV_IDS_ARRAY; payload: string
};

export const ProductBaseOnProductVariantEditorReducers = (
    state: typeof initialState, action: ACTIONTYPE
) => {

    switch (action.type) {
        case CLEAR_ALL:
            return {
                [ids]: []
            };

        case UPDATE_PV_IDS_ARRAY:
            const exist: boolean = state[ids].includes(action.payload);
            let newArr = [...state[ids]];
            if (!exist) {
                newArr.push(action.payload);
            } else {
                newArr = state[ids].filter((item) => item !== action.payload);
            }
            return {
                ...state,
                [ids]: newArr,
            };

        default:
            return state;
    }
}
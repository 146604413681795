import {useParams} from "react-router-dom";
import {useEffect} from "react";
import useGetDiscountById from "../../hooks/discount/useGetDiscountById";
import Title from "../../components/Title";
import FormIndicator from "../../components/Forms/FormIndicator";
import styled from "styled-components";
import useUpdateDiscount from "../../hooks/discount/useUpdateDIscount";
import FormDiscountTest from "../../components/Forms/Discount/FormDiscountTest";
import useGetAllProductOwners from "../../hooks/productOwners/useGetAllProductOwners";
import dayjs from "dayjs";

const Wrapper = styled.div`
  position: relative;
`;

const UpdateDiscount = () => {
    const params = useParams();
    const {isLoading, getDiscountById, data} = useGetDiscountById();
    const {update} = useUpdateDiscount();
    const {data: productOwners, isLoading: isLoadingPO} = useGetAllProductOwners();

    const handleOnFinish = async (data) => {
        update({
            ...data,
            id: params.id,
            activeFrom: data.activeFrom ? dayjs(data.activeFrom).format('YYYY-MM-DD HH:mm:ss') : null,
            activeTo: data.activeTo ? dayjs(data.activeTo).format('YYYY-MM-DD HH:mm:ss') : null
        });
    };

    useEffect(() => {
        getDiscountById(params.id);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Wrapper>
            <Title title="Edytuj Promocję"/>
            {/*<Paper>*/}
            {(isLoading || isLoadingPO) ? (
                <FormIndicator numberOfElements={3}/>
            ) : (
                <FormDiscountTest data={data} save={handleOnFinish} productOwners={productOwners} isEditable={false}/>
            )}
            {/*</Paper>*/}
        </Wrapper>
    );
};

export default UpdateDiscount;

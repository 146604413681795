import useCreateNewCodeByName from "../../../../hooks/discount/codes/useCreateNewCodeByName";
import {Form, Input, Modal} from "antd";
import {useParams} from "react-router-dom";

const CreateCodeByNameModal = ({
                                   openNewCodeModal,
                                   setOpenNewCodeModal,
                                   reload,
                               }) => {
    const {create} = useCreateNewCodeByName();

    const params = useParams();
    const [form] = Form.useForm();

    return (
        <Modal title={'Nowy kod rabatowy'}
               open={openNewCodeModal}
               onOk={() => {
                   form
                       .validateFields()
                       .then((values) => {
                           form.resetFields();
                           create({
                               ...values,
                               discountId: params.id,
                               maxUse: 1,
                               isActive: true,
                           }).then(() => setOpenNewCodeModal(false) & reload(params.id));
                       }).catch((info) => {
                           console.log("Validate Failed:", info);
                       });
               }}
               onCancel={() => setOpenNewCodeModal(false)}
               okText={"Zapisz"}
               cancelText={"Anuluj"}>
            <Form
                layout="vertical"
                form={form}
                name="form_in_modal"
            >
                <Form.Item
                    label="Treść kodu rabatowego"
                    name="code"
                    rules={[
                        {required: true, message: "Wpisz nazwę, pole jest wymagane!"},
                    ]}
                >
                    <Input placeholder="Wpisz treść kodu do wpisania w koszyku"/>
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default CreateCodeByNameModal;

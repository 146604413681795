import axiosInstance from "../../lib/axios";
import useAlert from "../useAlert";

const useRemoveProduct = () => {
    const {successMessage, errorMessage} = useAlert();

    const removeProduct = async (  productIds: string[]): Promise<any> => {
        try {
            await axiosInstance.delete(`/admin/product/`, {data: {productIds}});
            successMessage("Usunięto");
        } catch (err) {
            errorMessage("Błąd usuwania, spróbuj ponownie");
        }
    }

    return {removeProduct};
}

export default useRemoveProduct;
import useAlert from "../useAlert";
import axiosInstance from "../../lib/axios";


interface UpdateProductOwnerConfigurationProps {
    id: string;
    isEmailShown: boolean
}

const useUpdateProductOwnerConfiguration = () =>{

    const { successMessage, errorMessage } = useAlert();

    const update = async (
        obj: UpdateProductOwnerConfigurationProps
    ): Promise<any> => {
        try {
            await axiosInstance.put("/admin/product_owner/configuration/" + obj.id, obj);
            successMessage("Dane zmienione");
        } catch (err) {

            errorMessage("Błąd zapisu. Spróbuj ponownie.");
            throw err;
        }
    };

    return { update };
}

export default useUpdateProductOwnerConfiguration;
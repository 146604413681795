import React, {useContext} from "react";
import ProductsFiltersContext from "../../../../context/ProductsFiltersContext";
import {Form, Input} from "antd";
import {UPDATE_QTY_FROM} from "../../../../reducers/ProductsFiltersReducers";

const QuantityInput = () => {

    const {dispatch, state} = useContext(ProductsFiltersContext);

    return <div
        style={{
            display: "flex",
            flexDirection: "row",
        }}
    >
        <Form.Item name="qtyStockFrom" noStyle initialValue={state.qtyStockFrom}>
            <Input style={{width: "100%"}} onBlur={(e) => dispatch({type: UPDATE_QTY_FROM, payload: e.target.value})}/>
        </Form.Item>
        <Form.Item noStyle>
            <Input
                style={{
                    width: "15%",
                    borderLeft: 0,
                    borderRight: 0,
                    pointerEvents: "none",
                }}
                placeholder="~"
                disabled
            />
        </Form.Item>
        <Form.Item
            initialValue={state.qtyStockTo}
            name="qtyStockTo"
            noStyle
            rules={[
                ({getFieldValue}) => ({
                    validator() {
                        if (
                            !getFieldValue("qtyStockFrom") ||
                            !getFieldValue("qtyStockTo") ||
                            getFieldValue("qtyStockTo") >= getFieldValue("qtyStockFrom")
                        ) {
                            return Promise.resolve();
                        }
                        return Promise.reject("Stan do musi być większa od stanu od!");
                    },
                }),
            ]}
        >
            <Input type='number' style={{width: "100%"}} onBlur={(e) => dispatch({type: UPDATE_QTY_FROM, payload: e.target.value})}/>
        </Form.Item>
    </div>;
}

export default QuantityInput;
import React from "react";
import {Table} from "antd";

const TableVatRate = ({data}) => {

    const columns = [
        {
            title: 'Nazwa',
            key: 'name',
            dataIndex: 'name',
            width: '10%'
        },
        {
            title: 'Wartość',
            key: 'value',
            dataIndex: 'value'
        },
    ];


    return <Table pagination={false} columns={columns} dataSource={data.map((it, index) => {
        return {
            ...it,
            key: index
        }
    })}/>;
}

export default TableVatRate;
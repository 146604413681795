import { useState, useEffect } from "react";
import axiosInstance from "../../lib/axios";

const useGetAllProductCustomList = () => {

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [data, setData] = useState<{} | null>(null);

    useEffect(() => {
        getProductCustomList();
    }, []);

    const getProductCustomList = async (): Promise<any> => {
        try {
            const res = await axiosInstance.get(`/admin/product_custom_list/`);

            if (res.status === 200) {
                setData(res.data ? res.data : []);
                setIsLoading(false);
            }
        } catch (err) {
            setIsLoading(false);
        }
    }

    const reload = () => {
        // setIsLoading(true);
        getProductCustomList();
    }

    return {isLoading, data, reload};
}

export default useGetAllProductCustomList;
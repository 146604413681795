import useGetBlogPostById from "../../hooks/blogPost/useGetBlogPostById";
import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {ArrowLeftOutlined, FileSearchOutlined, LinkOutlined} from "@ant-design/icons";
import {Button, Image, Input, Tabs, Typography} from "antd";
import FormIndicator from "../../components/Forms/FormIndicator";
import Paper from "../../layout/Paper";
import BlogIsPublishedSelect from "../../components/Selects/BlogIsPublishedSelect";
import useUpdateMainPhotoBlogPost from "../../hooks/blogPost/useUpdateMainPhotoBlogPost";
import useUpdateIsPublishedBlogPost from "../../hooks/blogPost/useUpdateIsPublishedBlogPost";
import LogoUploader from "../../components/Uploader/LogoUploader";
import Editor from "../../components/HtmlEditor/Editor";
import useUpdateBlogPostContent from "../../hooks/blogPost/useUpdateBlogPostContent";
import CategorySelect from "../../components/Selects/CategorySelect";
import useGetAllBlogCategories from "../../hooks/blogCategories/useGetAllBlogCategories";
import useAssignBlogCategoryToPost from "../../hooks/blogPost/useAssignBlogCategoryToPost";
import useUpdateBlogPostTitle from "../../hooks/blogPost/useUpdateBlogPostTitle";
import BlogPostSeoForm from "../../components/Forms/Blog/BlogPostSeoForm";
import useUpdateBlogPostSeoInfo from "../../hooks/blogPost/useUpdateBlogPostSeoInfo";

const {TextArea} = Input;

const EditBlogPost = () => {

    const params = useParams();
    const {data, isLoading, getBlogPost, setIsLoading} = useGetBlogPostById();
    const {data: blogCategories, isLoading: isLoadCat} = useGetAllBlogCategories();
    const {updateLogo} = useUpdateMainPhotoBlogPost();
    const {update} = useUpdateIsPublishedBlogPost();
    const [content, setContent] = React.useState('');
    const {update: updateContent} = useUpdateBlogPostContent();
    const [categories, setCategories] = useState([]);
    const {update: assign} = useAssignBlogCategoryToPost();
    const {update: updateTitle} = useUpdateBlogPostTitle();
    const {updateSeoInfo} = useUpdateBlogPostSeoInfo();

    useEffect(() => {
        getBlogPost(params.id).then((res) => {
            setContent(res.content);
            setCategories(res.blogCategories.map((it) => it.id));
        }).finally(() => setIsLoading(false));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleChangeIsPublished = (value) => {
        update({id: params.id, isPublished: value})
            .then(() => getBlogPost(params.id))
    }

    const handleUpdateLogo = (data, id) => {
        updateLogo(data, id).then(() => getBlogPost(params.id));
    };

    const handleSave = () => {
        updateContent({id: params.id, content});
    }

    const handleChangeCategories = () => {
        assign(categories, params.id);
    }

    const handleChangeTitle = (value) => {
        updateTitle({id: params.id, title: value});
    }

    const handleSeoInfo = (values) => {
        updateSeoInfo({...values, id: params.id}).then(() => getBlogPost(params.id, false));
    }

    return <>
        <div onClick={() => window.history.go(-1)}>
            <ArrowLeftOutlined/> <Typography.Text strong>Wróc</Typography.Text>
        </div>
        {
            (isLoading || isLoadCat) ? <FormIndicator numberOfElements={1}/> :
                <>
                    <div style={{display: 'flex', padding: '20px'}}>
                        <div style={{flex: 3, display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                            <Typography style={{width: '160px'}}>Nazwa artykułu:</Typography>
                            <Input onBlur={(e) => handleChangeTitle(e.target.value)} defaultValue={data.title}/>
                        </div>
                        <div style={{
                            flex: 2,
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            marginLeft: '30px'
                        }}>
                            <Typography style={{width: '160px'}}>Status:</Typography>
                            <BlogIsPublishedSelect value={data.isPublished}
                                                   handleChange={(value) => handleChangeIsPublished(value)}/>
                        </div>
                    </div>
                    <div style={{display: 'flex', padding: '20px'}}>
                        <div style={{flex: 3, display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                            <Typography style={{width: '160px'}}>Kategorie:</Typography>
                            <CategorySelect categories={blogCategories} selectedCategories={categories} setCategories={setCategories} />
                            <Button style={{marginTop: "10px"}} type='primary' onClick={() => handleChangeCategories()}>Zapisz</Button>
                        </div>
                        <div style={{
                            flex: 2,
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            marginLeft: '30px'
                        }}>
                            <Typography style={{width: '160px'}}>Miniatura:</Typography>
                            {data.mainPhotoUrl ? <Image src={data.mainPhotoUrl}/> : 'Brak zdjecia'}
                            <LogoUploader
                                update={handleUpdateLogo}
                                item={{logo: data.mainPhotoUrl, id: data.id}}
                                reload={() => getBlogPost(params.id)}
                            />
                        </div>
                    </div>
                    <div style={{display: 'flex', padding: '20px'}}>
                        <div style={{flex: 3, display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                            <Typography style={{width: '160px'}}>Link do artykułu:</Typography>
                            <Input disabled={true} defaultValue={'https://appet.pl/blog/' + data.slug} addonBefore={<LinkOutlined onClick={() => window.open('https://appet.pl/blog/' + data.slug, "_blank")}/>}/>
                        </div>

                    </div>
                    <Paper>
                        <Tabs defaultActiveKey="1">
                            <Tabs.TabPane tab="Edytor HTML" key="1">
                                <Editor value={content} setValue={setContent}/>
                            </Tabs.TabPane>
                            <Tabs.TabPane tab="Tekst" key="2">
                                <TextArea showCount
                                          maxLength={10000000}
                                          style={{height: '100%'}}
                                          onChange={(e) => setContent(e.target.value)}
                                          defaultValue={content}/>
                            </Tabs.TabPane>
                        </Tabs>
                        {/*<Editor value={content} setValue={setContent}/>*/}
                        <Button style={{marginTop: "10px"}} type='primary' onClick={() => handleSave()}>Zapisz</Button>
                    </Paper>

                    <Paper>
                        <div style={{display: 'flex', alignItems: 'center', gap: '10px', fontWeight: '600'}}>
                            <FileSearchOutlined /> <Typography>Ustawienia SEO</Typography>
                        </div>

                        <BlogPostSeoForm handleSave={handleSeoInfo} data={data.blogPostSeoView}/>
                    </Paper>
                </>
        }
    </>;
}

export default EditBlogPost;
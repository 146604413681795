import {Switch, Table, Tag} from "antd";
import React from "react";
import UpdateCategorySlugAlt from "../../Modals/Category/UpdateCategorySlugAlt";
import useUpdateCategoryActivity from "../../../hooks/category/useUpdateCategoryActivity";


const CategoryTable = ({data, reload}) => {

    const {update} = useUpdateCategoryActivity();

    const updateActivity = (item, val) => {

        update(item.id, val).then(() => reload());
    };

    const columns = [
        {
            title: 'Aktywny',
            dataIndex: '',
            key: 'active',
            render: (row) => <Switch onClick={(e) => updateActivity(row, e)} checked={row.active}/>
        },
        {
            title: 'Pełna ścieżka',
            key: 'fullPath',
            dataIndex: 'fullPath',
        },
        {
            title: 'Nazwa',
            key: 'name',
            dataIndex: 'name',
            width: '10%',
        },
        {
            title: 'Typ',
            key: 'type',
            width: '5%',
            render: row => row.food ? <Tag>Karma</Tag> : <Tag>Akcesoria</Tag>
        },
        {
            title: 'Slug',
            key: 'slug',
            dataIndex: 'slug',
            width: '10%',
        },
        {
            title: 'Alternatywny Slug',
            key: 'slugAlt',
            dataIndex: 'slugAlt',
            width: '10%',
        },
        {
            title: '',
            key: 'action',
            width: '15%',
            render: row => <div style={{display: 'flex', gap: '10px'}}>
                <UpdateCategorySlugAlt
                    reload={reload}
                    item={row}
                />
            </div>
        },
    ]


    return <Table pagination={false} columns={columns} dataSource={data.map((it) => {
        return {
            ...it,
            key: it.id
        }
    })}/>
}

export default CategoryTable;
import axiosInstance from "../../lib/axios";

interface UpdatePCLButtonTextProps {
    id: string
    buttonText: string
}

const useUpdateProductCustomListButtonText = () => {
    const update = async (obj: UpdatePCLButtonTextProps): Promise<any> => {
        try {
            await axiosInstance.put("/admin/product_custom_list/button_text/" + obj.id, obj);
        } catch (err) {
            throw err;
        }
    };

    return { update };
}

export default useUpdateProductCustomListButtonText;
import useAlert from "../useAlert";
import axiosInstance from "../../lib/axios";

const usePlaceOrderInExternalService = () => {
    const {successMessage, errorMessage} = useAlert();

    const placeOrder = async (id: string): Promise<any> => {
        try {
            await axiosInstance.post("/admin/order/place_order/" + id);
            successMessage("Zamówienie zostało złożone");
        } catch (err) {
            errorMessage("Błąd zapisu. Spróbuj ponownie.");
            throw err;
        }
    };

    return {placeOrder};

}

export default usePlaceOrderInExternalService;
import {Button, Form, Input} from "antd";

const BlogPostSeoForm = ({data, handleSave}) => {

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    return <Form
        name="basic"
        onFinish={handleSave}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        layout={'vertical'}
        initialValues={{
            metaTitle: data ? data.metaTitle : '',
            metaDescription: data ? data.metaDescription : '',
        }}
    >
        <Form.Item
            label="Meta Title"
            name="metaTitle"
        >
            <Input/>
        </Form.Item>
        <Form.Item
            label="Meta Description"
            name="metaDescription"
        >
            <Input.TextArea rows={4} />
        </Form.Item>
        <Form.Item>
            <Button type="primary" htmlType="submit">
                Zapisz
            </Button>
        </Form.Item>
    </Form>
}

export default BlogPostSeoForm;
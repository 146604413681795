import styled from "styled-components";
import Title from "../../Title";
import TagsSelect from "./TagsSelect";
import {useParams} from "react-router-dom";
import FlavoursSelect from "./FlavoursSelect";

const Wrapper = styled.div`
  position: relative;
`;

const UpdateDetailProduct = ({selectedTags, selectedFlavours, reload}) => {

    const params = useParams();

    return <Wrapper>
        <Title title="Szczegóły produktu"/>
        <TagsSelect productId={params.id} selectedApi={selectedTags} reload={reload}/>
        <FlavoursSelect productId={params.id} selectedApi={selectedFlavours} reload={reload}/>
    </Wrapper>
}

export default UpdateDetailProduct;
import {useState} from "react";
import useAlert from "../useAlert";
import axiosInstance from "../../lib/axios";

interface UpdateNumberEmailContactOfProductOwner {
    id: string;
    phone: string;
    email: string;
}

const useUpdateProductOwnerCommunication = () => {
    const [isLoading, setisLoading] = useState<boolean>(false);
    const { successMessage, errorMessage } = useAlert();

    const update = async (
        obj: UpdateNumberEmailContactOfProductOwner
    ): Promise<any> => {
        try {
            setisLoading(true);
            await axiosInstance.put("/admin/product_owner/communication/" + obj.id, obj);
            successMessage("Dane zmienione");
        } catch (err) {
            setisLoading(false);
            errorMessage("Błąd zapisu. Spróbuj ponownie.");
            throw err;
        }
    };

    return { update, isLoading };
}

export default useUpdateProductOwnerCommunication;
import {Select} from "antd";
import PaymentStatusLabel from "../Labels/PaymentStatusLabel";

const BlogIsPublishedSelect = ({value, handleChange}) => {

    return <Select
        mode="single"
        style={{width: '100%'}}
        value={value}
        onChange={handleChange}
        name="isPublished"
    >
        <Select.Option key={'isPublishedTrue'} value={true}>
            <PaymentStatusLabel paid={true} textTrue={'Opublikowany'}/>
        </Select.Option>
        <Select.Option key={'isPublishedFalse'} value={false}>
            <PaymentStatusLabel paid={false} textFalse={'Wersja robocza'}/>
        </Select.Option>
    </Select>
}

export default BlogIsPublishedSelect;
import useCreateProductCustomList from "../../../hooks/productCustomList/useCreateProductCustomList";
import {Form, Modal} from "antd";
import ProductCustomListForm from "../../Forms/ProductCustomList/ProductCustomListForm";

const CreateProductCustomList = ({reload, handleClose, openModal}) => {

    const {create} = useCreateProductCustomList();
    const [form] = Form.useForm();

    const handleOK = (values) => {
        create(values).then(reload);
        handleClose();
    }

    return <Modal title="Utwórz customową listę produktową"
                  open={openModal}
                  onOk={() => {
                      form
                          .validateFields()
                          .then((values) => {
                              form.resetFields();
                              handleOK(values);
                          })
                          .catch((info) => {
                              console.log("Validate Failed:", info);
                          });
                  }}
                  onCancel={handleClose}
                  okText={"Utwórz nową listę produktów"}
                  cancelText={"Anuluj"}>
        <ProductCustomListForm form={form} />
        </Modal>
}

export default CreateProductCustomList;
import { useState } from "react";
import axiosInstance from "../../lib/axios";

const useUpdateProductOwnerLogo = () => {
    const [isLoading, setisLoading] = useState<boolean>(false);

    const updateLogo = async (data: FormData, id: string): Promise<any> => {
        try {
            setisLoading(true);
            await axiosInstance.post(
                `/admin/product_owner/logo/${id}`,
                data,
            );

        } catch (err) {
            setisLoading(false);
            throw err;
        }
    };

    return { updateLogo, isLoading };
}

export default useUpdateProductOwnerLogo;
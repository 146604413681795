import useDeleteIngredientFromProduct from "../../../hooks/products/useDeleteIngredientFromProduct";
import {Input, Table} from "antd";
import CustomButton from "../../Forms/Buttons/CustomButton";
import {useContext, useEffect, useState} from "react";
import AssignIngredientToProduct from "../../Forms/Products/ProductIngredient/AssignIngredientToProduct";
import ButtonLink from "../../Buttons/ButtonLink";
import useUpdateIngredientProductValue
    from "../../../hooks/products/productIngredients/useUpdateIngredientProductValue";
import ProductContext from "../../../context/ProductContext";
import useGetProductFullInformation from "../../../hooks/products/useGetProductFullInformation";
import {useParams} from "react-router-dom";
import FormIndicator from "../../Forms/FormIndicator";

const ProductIngredientTable = ({product, grammages}) => {
    const {deleteRel} = useDeleteIngredientFromProduct();
    const [openModal, setOpenModal] = useState(false);
    const {update} = useUpdateIngredientProductValue();
    const {product: productTest} = useContext(ProductContext);
    const {reload} = useGetProductFullInformation();
    const params = useParams();
    const [isChange, setIsChange] = useState(false);

    useEffect(() => {
        setIsChange(false);
    }, [productTest]);

    const changeValue = (e, row) => {

        update({
            ingredientId: row.ingredientView.id,
            value: e.target.value,
            productId: params.id
        }).then(() => {
            setIsChange(true);
            reload(params.id)
        });
    }

    const columns = [
        {
            title: 'Nazwa',
            dataIndex: 'ingredientView',
            key: 'name',
            render: (row) => row.name
        },
        {
            title: 'Wartość',
            dataIndex: '',
            key: '',
            render: (row) => <Input defaultValue={row.value} onBlur={(e) => changeValue(e, row)}/>
        },
        {
            title: 'Gramatura',
            dataIndex: 'ingredientView',
            key: 'ingredientView',
            render: (row) => row.grammage ? row.grammage.name : '-'
        },
        {
            title: 'Action',
            key: 'action',
            render: (row) => <ButtonLink onClick={() => handleDeselect(row)} text={'Usuń'}/>
        }
    ];

    if (isChange) return <FormIndicator numberOfElements={1}></FormIndicator>;

    const handleDeselect = (option) => {

        deleteRel({productId: product.id, ingredientId: option.ingredientView.id}).then(() => {
            setIsChange(true);
            reload(params.id)
        });
    };

    return <>

        <CustomButton title='Dodaj składnik [Alt+N]' onClick={() => setOpenModal(true)}/>

        <Table pagination={false} style={{width: '100%'}} columns={columns}
               dataSource={productTest.ingredients.map((it, index) => {
                   return {
                       key: index,
                       ...it
                   }
               })}/>

        <AssignIngredientToProduct handleClose={() => setOpenModal(false)}
                                   openModal={openModal} handleOpenModal={() => setOpenModal(true)} reload={reload}
                                   grammages={grammages}/>
    </>;
}

export default ProductIngredientTable;

import React from "react";
import styled from "styled-components";
import { FaCheck, FaTimes } from "react-icons/fa";
import {themes} from "../../layout/styles/ColorStyles";

const Wrapper = styled.div`
  height: 20px;
  width: 70px;
  background-color: ${(props) => (props.isActive ? themes.status.send : themes.status.returned)};
  border-radius: 15px;
  display: flex;
  flex-direction: row;
  padding: 5px 15px 5px 15px;
  justify-content: space-between;
  align-items: center;
  color: #ffffff;
  gap: 5px
`;

const ActivityLabel = ({ active }) => {
  return (
    <Wrapper isActive={active}>
      {active ? <FaCheck /> : <FaTimes />}
      <div>{active ? "TAK" : "NIE"}</div>
    </Wrapper>
  );
};

export default ActivityLabel;

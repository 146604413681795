import {useParams} from "react-router-dom";
import useGenerateNewCodes from "../../../../hooks/discount/codes/useGenerateNewCodes";
import {Form, Input, InputNumber, Modal} from "antd";

const GenerateNewCodesModal = ({
                                   openGenerateCodeModal,
                                   setOpenGenerateCodeModal,
                                   reload,
                               }) => {
    const {generate} = useGenerateNewCodes();
    const params = useParams();
    const [form] = Form.useForm();

    return (
        <Modal title={'Wygeneruj kody rabatowe'}
               open={openGenerateCodeModal}
               onOk={() => {
                   form
                       .validateFields()
                       .then((values) => {
                           form.resetFields();
                           generate({
                               ...values,
                               discountId: params.id,
                               isActive: true,
                           }).then(() => setOpenGenerateCodeModal(false) & reload(params.id));
                       })
                       .catch((info) => {
                           console.log("Validate Failed:", info);
                       });
               }}
               onCancel={() => setOpenGenerateCodeModal(false)}
               okText={"Zapisz"}
               cancelText={"Anuluj"}>
            <Form
                layout="vertical"
                form={form}
                name="form_in_modal"
            >
                <Form.Item
                    label="Długość kodu - minimum 6 znaków"
                    name="length"
                    rules={[
                        {required: true, message: "Wpisz nazwę, pole jest wymagane!"},
                    ]}
                >
                    <InputNumber style={{width:'100%'}} min={6} placeholder="Wpisz liczę, aby określić jak długie mają być wygenerowane kody"/>
                </Form.Item>
                <div style={{color: 'grey'}}>Wpisana wartość doda się do ewentualnego prefiksu.</div>
                <Form.Item
                    label="Ilość kodów do wygenerowania"
                    name="quantity"
                    rules={[
                        {required: true, message: "Wpisz ilość kodów, pole jest wymagane!"},
                    ]}
                >
                    <InputNumber style={{width:'100%'}} placeholder="Wpisz ilość kodów do wygenerowania"/>
                </Form.Item>
                <Form.Item
                    label="Maksymalna ilość użyć kodu rabatowego dla wszystkich użytkowników"
                    name="maxUse"
                    rules={[
                        {required: true, message: "Wpisz maksymalna ilość użyć kodu, pole jest wymagane!"},
                    ]}
                >
                    <InputNumber style={{width:'100%'}} placeholder="Wpisz ile razy łącznie może być użyty ten kod rabatowy"/>
                </Form.Item>
                <Form.Item
                    label="Przedrostek kodu / prefiks (opcjonalne)"
                    name="prefix"
                >
                    <Input placeholder="Wpisz jaki ma być przedrostek kodu"/>
                </Form.Item>
                <div style={{color: 'grey'}}>Przedrostek to stały fragment kodu powtarzalny za każdym razem na samym początku.</div>
            </Form>
        </Modal>
    );
};

export default GenerateNewCodesModal;

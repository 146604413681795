import useAlert from "../useAlert";
import axiosInstance from "../../lib/axios";

const useRefundOrder = () => {

    const {successMessage, errorMessage} = useAlert();

    const refundOrder = async (id: string): Promise<any> => {
        try {
            await axiosInstance.post("/admin/order/refund/" + id);
            successMessage("Zwrot zamóweinia został zlecony");
        } catch (err) {
            errorMessage("Błąd zapisu. Spróbuj ponownie.");
            throw err;
        }
    };

    return {refundOrder};
}

export default useRefundOrder;
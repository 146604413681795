import useAlert from "../useAlert";
import axiosInstance from "../../lib/axios";

type UpdateCategoryDescriptions = {
    descriptionBelowListing: string;
    descriptionAboveListing: string;
    metaTitle: string;
    metaDescription: string;
};


const useUpdateListingDescriptions = () => {
    const {successMessage, errorMessage } = useAlert();

    const update = async (categoryId: string, obj: UpdateCategoryDescriptions): Promise<any> => {
        try {
            await axiosInstance.put("/admin/category/listing_description/" + categoryId, obj);
            successMessage('Zmieniono opisy')
        } catch (err) {
            errorMessage("Błąd zmiany pozycji kategorii");
        }
    }

    return {update};
}

export default useUpdateListingDescriptions;
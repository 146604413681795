import React, {useContext} from "react";
import {Select} from "antd";
import {UPDATE_ROLE} from "../../../reducers/UserFiltersReducres";
import UserFiltersContext from "../../../context/UserFiltersContext";

const RoleSelect = () => {
    const {dispatch, state} = useContext(UserFiltersContext);

    return <Select mode="single" placeholder="Wybierz role" allowClear={true} defaultValue={state.role}
                   onChange={(e) => {
                       dispatch({type: UPDATE_ROLE, payload: e})
                   }}>
        <Select.Option value=''>
            Wszystkie
        </Select.Option>
        <Select.Option key={0} value='ROLE_ADMIN'>
            Admin
        </Select.Option>
        <Select.Option key={1} value='ROLE_ADMIN_EDITOR'>
            Admin Edytor
        </Select.Option>
        <Select.Option key={2} value='ROLE_USER'>
            User
        </Select.Option>
        <Select.Option key={3} value='ROLE_PARTNER'>
            Sklep
        </Select.Option>
    </Select>
}

export default RoleSelect;
import axiosInstance from "../../lib/axios";
import useAlert from "../useAlert";

type UpdateNameBreed = {
    name: string;
};

const useUpdateNameBreed = () => {

    const {errorMessage,successMessage} = useAlert();

    const update = async (id: string, obj: UpdateNameBreed): Promise<any> => {
        try {
            await axiosInstance.put("/admin/breed/" + id, obj);
            successMessage('Nazwa została zmieniona')
        } catch (err) {
            errorMessage('Błąd zmiany nazwy')
            throw err;
        }
    };

    return { update };
}

export default useUpdateNameBreed;
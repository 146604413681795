import useAlert from "../useAlert";
import axiosInstance from "../../lib/axios";

interface AssignFlavoursToIngredientGroupProps {
    flavoursIds: Array<string>,
    id: string
}


const useAssignFlavourToIngredientGroup = () => {
    const {successMessage, errorMessage} = useAlert();

    const update = async (
        obj: AssignFlavoursToIngredientGroupProps
    ): Promise<any> => {

        try {
            await axiosInstance.put(
                `/admin/ingredient_group/assign-flavours/${obj.id}`,
                obj
            );

            successMessage("Składniki zostały zaaktualizowane");

        } catch (err) {
            errorMessage("Wystąpił błąd, spróbuj ponownie");
            throw err;
        }
    };

    return {update};


}

export default useAssignFlavourToIngredientGroup;
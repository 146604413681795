import React from "react";
import { ThemeProvider } from "styled-components";
import { breakpoints } from "./Breakpoints";

const Theme = ({ children }) => (
    <ThemeProvider
        theme={{
            breakpoints,
        }}
    >
        {children}
    </ThemeProvider>
);

export default Theme;
import useCreateStaticPage from "../../../hooks/staticPage/useCreateStaticPage";
import StaticPageForm from "../../Forms/StaticPage/StaticPageForm";

const CreateStaticPage = ({reload, handleClose, openModal}) => {

    const {create} = useCreateStaticPage();

    const handleOk = (values) => {
        create(values).then(reload);
        handleClose();
    }

    return <StaticPageForm
        open={openModal}
        onCancel={handleClose}
        handleOK={(values) => handleOk(values)}
    />
}

export default CreateStaticPage;
import styled from "styled-components";
import {Typography} from "antd";
import {ArrowLeftOutlined} from "@ant-design/icons";
import {useEffect} from "react";
import useUpdateListingDescriptions from "../../hooks/category/useUpdateListingDescriptions";
import {useParams} from "react-router-dom";
import useGetCategoryById from "../../hooks/category/useGetCategoryById";
import FormIndicator from "../../components/Forms/FormIndicator";
import CategoryDescriptionForm from "../../components/Forms/Category/CategoryDescriptionForm";
import Paper from "../../layout/Paper";

const Wrapper = styled.div`
  position: relative;
`;

const Title = styled.div`
  /* position: absolute; */
  display: flex;
  color: #364a63;
  margin-bottom: 15px;
  border-bottom: 1.5px solid #e5e9f2;`;


const CategoryDetails = () => {

    const {update} = useUpdateListingDescriptions();
    const {getCategory, data, isLoading} = useGetCategoryById();

    const params = useParams();

    useEffect(() => {
        getCategory(params.id);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleSave = (values) => {
        update(params.id, values);
    }

    return <>
        {
            isLoading ? <FormIndicator numberOfElements={1}/> : <Wrapper>
                <Title>
                    <div onClick={() => window.history.go(-1)}>
                        <ArrowLeftOutlined/> <Typography.Text strong>Wróc</Typography.Text>
                    </div>
                    <div style={{marginLeft: "10px"}}><Typography.Text strong>Zmiana opisu dla
                        kategorii: {data.name}</Typography.Text></div>
                </Title>
                <Paper>
                    <CategoryDescriptionForm data={data.categorySeo} handleSave={handleSave}/>
                </Paper>
            </Wrapper>
        }
    </>;
}

export default CategoryDetails;
import {Select} from "antd";

const GrammageSelect = ({grammages, form, value = null, updateDefaultValue = {}}) => {

    const handleChange = (e) => {
        form.setFieldsValue({"grammageId": e});
        updateDefaultValue(e);
    }


    return <Select
        mode="single"
        allowClear
        style={{width: '100%'}}
        placeholder="Wybierz gramature"
        value={value ? value : null}
        onChange={handleChange}
        name="grammageId"
    >
        {grammages.map((grammage) => (
            <Select.Option key={grammage.id} value={grammage.id}>
                {grammage.name}
            </Select.Option>
        ))}
    </Select>
}

export default GrammageSelect;
import React, {useReducer} from "react";
import {CartFilterReducers, initialState} from "../reducers/CartFilterReducers";

const CartFilterContext = React.createContext();

export const CartFilterProvider = ({children}) => {

    const [state, dispatch] = useReducer(CartFilterReducers, initialState);

    return <CartFilterContext.Provider value={{state,dispatch}}>
        {children}
    </CartFilterContext.Provider>
}

export default CartFilterContext;
import { useState} from "react";
import axiosInstance from "../../lib/axios";

const useGetAllUsers = () => {

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [data, setData] = useState<{} | null>(null);

    const getUsers = async (state: any): Promise<any> => {
        try {

            const res = await axiosInstance.get(`/admin/users/paginated`, {params: state});

            if (res.status === 200) {
                setData(res.data ? res.data : []);
                setIsLoading(false);
            }
        } catch (err) {
            setIsLoading(false);
        }
    };

    return {isLoading, data, getUsers};

}

export default useGetAllUsers;
import styled from "styled-components";

const Wrapper = styled.div`
  position: relative;
  width: auto;
  height: auto;
  background-color: #e60000;
  color: #ffffff;
  border-radius: 5px;
  padding: 0px 10px 0px 10px;
  position: absolute;
  margin-bottom: 10px;
  bottom: -40px;
  z-index: 2;
`;

const Arrow = styled.div`
  width: 15px;
  height: 15px;
  background-color: #e60000;
  position: absolute;
  z-index: 1;
  transform: rotate(45deg);
  bottom: -20px;
  left: 10px;
`;

const CustomError = ({ error }) => {
  return (
    <>
      <Wrapper>{error}</Wrapper>
      <Arrow />
    </>
  );
};

export default CustomError;

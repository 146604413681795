import useGetAllFlavours from "../../hooks/flavours/useGetAllFlavours";
import { useState} from "react";
import styled from "styled-components";
import Title from "../../components/Title";
import FormIndicator from "../../components/Forms/FormIndicator";
import CreateFlavourModal from "../../components/Modals/Flavours/CreateFlavourModal";
import {FaPlus} from "react-icons/fa";
import CustomButton from "../../components/Forms/Buttons/CustomButton";
import TableFlavours from "../../components/Tables/Flavour/TableFlavours";

const Wrapper = styled.div`
  position: relative;
`;

const Flavour = () => {

    const {isLoading, data, reload} = useGetAllFlavours();
    const [openModal, setOpenModal] = useState(false);

    return (
        <>
            <Wrapper>
                <Title title="Smaki"/>
                <CustomButton
                    title="Utwórz"
                    icon={<FaPlus />}
                    onClick={() => setOpenModal(true)}
                />
                {(isLoading) ? <FormIndicator numberOfElements={3} /> : <TableFlavours data={data} reload={reload}/>}
            </Wrapper>
            <CreateFlavourModal
                open={openModal}
                setOpenModal={setOpenModal}
                reload={reload}
            />
        </>
    )
}

export default Flavour;
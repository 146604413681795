import React, {useState} from "react";
import useUpdateNameBreed from "../../../hooks/breeds/useUpdateNameBreed";
import ButtonLink from "../../Buttons/ButtonLink";
import BreedForm from "../../Forms/Breed/BreedForm";

const UpdateBreed = ({reload, item}) => {

    const [updateOpenModal, setUpdateOpenModal] = useState(false);
    const {update} = useUpdateNameBreed();

    const handleSave = (obj) => {
        update(item.id, obj)
            .then(() => {
                setUpdateOpenModal(false);
                reload();
            })
            .catch((err) => {
                console.log(err);
            });
    };

    return (
        <>
            <ButtonLink
                text={'Zmień nazwę'}
                onClick={() => setUpdateOpenModal(true)}
            />
            <BreedForm item={item} open={updateOpenModal} onCancel={() => setUpdateOpenModal(false)}
                       handleOK={handleSave}
                       title={'Edytuj nazwe'}/>
        </>
    );
}

export default UpdateBreed;
import styled from "styled-components";
import {Button, Form} from "antd";
import ReviewProductStatusSelect from "./ReviewProductStatusSelect";
import TextSearchInput from "../../Users/TextSearchInput";
import ReviewProductFiltersContext from "../../../../context/ReviewProductFiltersContext";
import {
    CHANGE_PAGE, CLEAR_ALL,
    UPDATE_DATE_FROM,
    UPDATE_DATE_TO,
    UPDATE_TEXT_SEARCH
} from "../../../../reducers/ReviewProductFiltersReducer";
import React, {useContext} from "react";
import DatePickerFrom from "../../Products/ProductFilters/DatePickerFrom";
import DatePickerTo from "../../Products/ProductFilters/DatePickerTo";
import RateInput from "./RateInput";

const Content = styled(Form)`
  display: grid;
  width: 100%;
  height: 100%;
  grid-template-columns: 25% 25% 25% 25%;
  column-gap: 5px;
`;


const ReviewProductFilter = ({reload}) => {

    const [form] = Form.useForm();
    const {dispatch, state} = useContext(ReviewProductFiltersContext);

    const handleOnReset = () => {
        form.resetFields();
        dispatch({type: CLEAR_ALL});
    }

    const handleOnFinish = () => {
        dispatch({type: CHANGE_PAGE, payload: 1});
        reload({
            ...state,
            page: 1
        });
    }

    return <Content
            form={form}
            name="control-hooks"
            labelCol={{span: 24}}
            onReset={handleOnReset}
            onFinish={(values) => handleOnFinish(values)}
        >
            <Form.Item name='statusReview' label='Status opinii'>
                <ReviewProductStatusSelect/>
            </Form.Item>
            <Form.Item label="Nazwa produktu, ID Produktu" name="textSearch">
                <TextSearchInput type={UPDATE_TEXT_SEARCH} context={ReviewProductFiltersContext}/>
            </Form.Item>
            <Form.Item name="dateFrom" label="Data utworzenia od">
                <DatePickerFrom type={UPDATE_DATE_FROM} context={ReviewProductFiltersContext}/>
            </Form.Item>
            <Form.Item name="dateTo" label="Data utworzenia do">
                <DatePickerTo type={UPDATE_DATE_TO} context={ReviewProductFiltersContext}/>
            </Form.Item>
        <Form.Item label="Ocena produktu" name='rates'>
            <RateInput />
        </Form.Item>
        <Form.Item></Form.Item>
        <Form.Item></Form.Item>
        <Form.Item></Form.Item>
            <Form.Item>
                <Button
                    style={{marginRight: "10px"}}
                    type="primary"
                    htmlType="submit"
                >
                    Zastosuj
                </Button>
                <Button htmlType="button" onClick={handleOnReset}>
                    Wyczyść filtry
                </Button>
            </Form.Item>
        </Content>
}

export default ReviewProductFilter;
export const CHANGE_PAGE = "change_page";
export const CHANGE_LIMIT = "change_limit";
export const CHANGE_PAGE_AND_LIMIT = "change_page_and_limit";

export const CLEAR_ALL = 'clear_all';


export const UPDATE_DATE_FROM = 'updated_at_from';
export const UPDATE_DATE_TO = 'updated_at_to';
export const UPDATE_QUERY_EMAIL = 'update_query_email';
export const UPDATE_QUERY_NAME = 'update_query_name';
export const UPDATE_QUERY_LASTNAME = 'update_query_lastname';
export const UPDATE_QUERY_PHONENUMBER = 'update_query_phonenumber';
export const UPDATE_ORDER_BY = 'update_order_by';
export const UPDATE_CART_STATUS = 'update_cart_status';

export const limit = "limit";
export const page = "page";
export const updatedAtTo = 'updatedAtTo';
export const updatedAtFrom = 'updatedAtFrom';
export const status = 'status';
export const email = 'email';
export const name = 'name';
export const lastName = 'lastName';
export const phoneNumber = 'phoneNumber';
export const orderBy = 'orderBy';


export const initialState = {
    [limit]: 10,
    [page]: 1,
    [updatedAtTo]: '',
    [updatedAtFrom]: '',
    [status]: '',
    [email]: '',
    [name]: null,
    [lastName]: "",
    [phoneNumber]: "",
    [orderBy]: "updatedAtDesc"
}

type ACTIONTYPE =
    | { type: typeof CHANGE_PAGE; payload: number }
    | { type: typeof CLEAR_ALL; payload: null }
    | { type: typeof CHANGE_LIMIT; payload: number }
    | { type: typeof CHANGE_PAGE_AND_LIMIT; payload: { [page]: number; [limit]: number } }
    | { type: typeof UPDATE_DATE_FROM; payload: string }
    | { type: typeof UPDATE_DATE_TO; payload: string }
    | { type: typeof UPDATE_QUERY_EMAIL; payload: string }
    | { type: typeof UPDATE_QUERY_NAME; payload: string }
    | { type: typeof UPDATE_QUERY_LASTNAME; payload: string }
    | { type: typeof UPDATE_QUERY_PHONENUMBER; payload: string }
    | { type: typeof UPDATE_CART_STATUS; payload: string }
    | { type: typeof UPDATE_ORDER_BY; payload: string };


export const CartFilterReducers = (state: typeof initialState, action: ACTIONTYPE) => {

    switch (action.type) {
        case CHANGE_PAGE:
            return {
                ...state,
                [page]: action.payload,
            };
        case CHANGE_LIMIT:
            return {
                ...state,
                [limit]: action.payload,
            };
        case CHANGE_PAGE_AND_LIMIT:
            return {
                ...state,
                [page]: action.payload[page],
                [limit]: action.payload[limit],
            };
        case UPDATE_DATE_FROM:
            return {
                ...state,
                [updatedAtFrom]: action.payload,
            }
        case UPDATE_DATE_TO:
            return {
                ...state,
                [updatedAtTo]: action.payload,
            }
        case UPDATE_QUERY_EMAIL:
            return {
                ...state,
                [email]: action.payload,
            }
        case UPDATE_QUERY_NAME:
            return {
                ...state,
                [name]: action.payload,
            }
        case UPDATE_QUERY_LASTNAME:
            return {
                ...state,
                [lastName]: action.payload,
            }
        case UPDATE_QUERY_PHONENUMBER:
            return {
                ...state,
                [phoneNumber]: action.payload,
            }
        case UPDATE_CART_STATUS:
            return {
                ...state,
                [status]: action.payload,
            }
        case UPDATE_ORDER_BY:
            return {
                ...state,
                [orderBy]: action.payload
            }
        case CLEAR_ALL:
            return initialState;
        default:
            return state;
    }
}
import {useState} from "react";
import useAlert from "../useAlert";
import axiosInstance from "../../lib/axios";

interface UpdateProductGroupValueVariantProps {
    variantValue: string;
}

const useUpdateProductGroupValueVariant = () => {
    const [isLoading, setIsLoading] = useState(false);
    const {successMessage} = useAlert();

    const update = async(obj: UpdateProductGroupValueVariantProps, productGroupId: string) : Promise<any> => {
        try {
            setIsLoading(true);
            await axiosInstance.put(
                `/admin/product_group/${productGroupId}`,
                obj
            );

            setIsLoading(false);
            successMessage('Dane zostały zapisane');

        } catch (err) {
            setIsLoading(false);
            throw err;
        }
    };

    return {update, isLoading}
}

export default useUpdateProductGroupValueVariant;
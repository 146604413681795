import { Modal, Select, Spin, Typography} from "antd";
import useGetProductBySearchQuery from "../../../hooks/products/useGetProductBySearchQuery";
import React, {useEffect, useState} from "react";
import useCreateProductConnection from "../../../hooks/products/productCrossSelling/useCreateProductConnection";
import MultiSelect from "../../Selects/MultiSelect";

const {Title} = Typography;

const CreateCrossSellingModal = ({openModal, setOpenModal, item, reload, brands = []}) => {

    const {getProductsBySearchQuery, isLoading: isLoadingProductSearch} = useGetProductBySearchQuery();
    const {create} = useCreateProductConnection();
    const [query, setQuery] = useState('');
    const [options, setOptions] = useState([]);
    const [ids, setIds] = useState('');
    const [selectedBrands, setSelectedBrands] = useState([]);
    const [selectedIds, setSelectedIds] = useState([]);

    useEffect(() => {
        if (query.length > 0) {
            getProductsBySearchQuery({
                search: query,
                brandIds: selectedIds
            }).then((test) => test ? setOptions(test.map((it) => {
                return {
                    label: it.name,
                    value: it.id
                }
            })) : setOptions([]));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [query]);



    const handleOk = () => {
        create({p1: item.id, p2Ids: ids}).then(() => {
            setOpenModal(false);
            reload();
        });
    };

    const handleCancel = () => {
        setOpenModal(false);
    };


    return (
        <>
            <Modal title="Dodaj produkt powiązany" open={openModal} onOk={handleOk} onCancel={handleCancel}
                   width={1000} bodyStyle={{height: "300px"}} closable={true}
                   maskClosable={false}
                   cancelText={'Anuluj'} okText={'Dodaj'}
            >
                <Title level={5}>Wyszukaj marki dla których mają być wyszukiwane produkty</Title>
                <MultiSelect
                    values={brands}
                    selected={selectedBrands}
                    setSelected={setSelectedBrands}
                    setSelectedIds={setSelectedIds}

                />
                <Title level={5}>Wyszukaj produkt</Title>
                <Select
                    mode={'multiple'}
                    placeholder="Wybierz produkty powiązane"
                    filterOption={false}
                    onSearch={(value) => setQuery(value)}
                    notFoundContent={isLoadingProductSearch ? <Spin size="small" /> : null}
                    onChange={(value) => setIds(value)}
                    options={options}
                    style={{width: '100%'}}
                />
            </Modal>
        </>
    );
}

export default CreateCrossSellingModal;
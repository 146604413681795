import {Select} from "antd";

const VatRateSelect = ({value = null, handleChange}) => {



    return <Select
        mode="single"
        style={{width: '100%'}}
        defaultValue={value}
        onChange={handleChange}
        name="vatRate"
    >
        <Select.Option key={'vat_a'} value={23}>
            Vat A - 23%
        </Select.Option>
        <Select.Option key={'vat_b'} value={8}>
            Vat B - 8%
        </Select.Option>
       <Select.Option key={'vat_c'} value={5}>
            Vat C - 5%
        </Select.Option>
        <Select.Option key={'vat_d'} value={0}>
            Vat D - 0%
        </Select.Option>

    </Select>
}

export default VatRateSelect;
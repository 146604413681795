import { useState } from "react";
import axiosInstance from "../../lib/axios";

const useUpdateLogoPayment = () => {
  const [isLoading, setisLoading] = useState<boolean>(false);

    const updateLogo = async (data: FormData, id: string): Promise<any> => {
        try {
            setisLoading(true);
            const config = {
                headers: { "content-type": "multipart/form-data" },
            };
            await axiosInstance.post(
                `/admin/payment/photo/${id}`,
                data,
                config
            );

        } catch (err) {
            setisLoading(false);
            throw err;
        }
    };

    return { updateLogo, isLoading };
}

export default useUpdateLogoPayment;
import { useState } from "react";
import Title from "../../components/Title";
import styled from "styled-components";
import { FaPlus } from "react-icons/fa";
import CustomButton from "../../components/Forms/Buttons/CustomButton";
import useGetAllBrands from "../../hooks/brands/useGetAllBrands";
import CreateBrandModal from "../../components/Modals/Brands/CreateBrandModal";
import FormIndicator from "../../components/Forms/FormIndicator";
import TableBrandsTest from "../../components/Tables/TableBrandsTest";

const Wrapper = styled.div`
  position: relative;
`;

const Brands = () => {
  const [openModal, setOpenModal] = useState(false);
  const { data, isLoading, reload } = useGetAllBrands();

  return (
    <>
      <Wrapper>
        <Title title="Marki" />
        <CustomButton
          title="Utwórz"
          icon={<FaPlus />}
          onClick={() => setOpenModal(true)}
        />
        {isLoading ? <FormIndicator numberOfElements={1} /> : <TableBrandsTest data={data} reload={reload} />}
      </Wrapper>
      <CreateBrandModal
        open={openModal}
        setOpenModal={setOpenModal}
        reload={reload}

      />
    </>
  );
};
export default Brands;

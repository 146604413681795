import {useEffect, useState} from "react";
import {Image, Space, Table, Typography} from "antd";
import {Link} from "react-router-dom";
import StatusLabel from "../../layout/StatusLabel";
import PaymentStatusLabel from "../Labels/PaymentStatusLabel";
import ProductListTooltip from "../Tooltips/Orders/ProductListTooltip";
import {DesktopOutlined, MobileOutlined} from "@ant-design/icons";

const TableOrder = ({data}) => {

    const [orders, setOrders] = useState([]);
    useEffect(() => {
            setOrders(data.map((it) => {
                return {
                    ...it,
                    key: it.id
                }
            }))
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, []
    );

    const columns = [
        {
            title: 'Sklep',
            dataIndex: 'productOwner',
            key: 'productOwnerView',
            width: "2%",
            render: (row) =>
                row.logo ? <div style={{display: 'flex', flexDirection: 'column'}}><Image
                    style={{width: '40px', height: '40px', objectFit: 'contain'}}
                    src={row.logo}
                    preview={!!row[0]}
                />{row.name}</div> : row.name

        },
        {
            title: "Numer",
            dataIndex: "",
            key: "",
            render: (row) => (
                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}} >
                <Link to={"/order/items/" + row.orderId}>
                    <ProductListTooltip products={row.items} text={row.orderNumber}/>
                </Link>
                    {row.channel.name === 'Mobile Application' ? <MobileOutlined /> : <DesktopOutlined />}
                </div>
            ),
            width: "2%",
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            width: '2%',
            render: (row) => <StatusLabel status={row}/>
        },
        {
            title: 'Utworzono',
            dataIndex: 'createdAt',
            key: 'createdAt',
            width: '2%',
        },
        {
            title: 'Klient',
            dataIndex: 'address',
            key: 'user',
            render: (row) => row ? <div style={{display: 'flex', flexDirection: 'column'}}>
                <Typography>{row.name}</Typography>
                <Typography>Email: {row.email}</Typography>
                <Typography>Tel: {row.mobilePhone}</Typography>
            </div> : 'Brak danych',
            width: "30%",
        },
        {
            title: 'Rabat',
            dataIndex: 'discount',
            key: '',
            width: '2%',
            render: (row) => <Typography>{row ? row.code : '-'}</Typography>
        },
        {
            title: 'Wartość rabatu',
            dataIndex: '',
            key: '',
            width: '2%',
            render: (row) =>
                <Typography>{row.discount ? Math.round((row.originalTotalItemPriceGross - row.totalItemPriceGross) * 100) / 100 + 'zł' : '-'}</Typography>
        },
        {
            title: 'Cena bez rabatu',
            dataIndex: '',
            key: 'originalTotalItemPriceGross',
            width: '2%',
            render: (row) => <Typography>{row.originalTotalItemPriceGross ? row.originalTotalItemPriceGross : row.totalItemPriceGross} zł</Typography>
        },
        {
            title: 'Cena z rabatem',
            dataIndex: 'totalItemPriceGross',
            key: 'totalItemPriceGross',
            width: '2%',
            render: (row) => <Typography>{row} zł</Typography>
        },
        {
            title: 'Cena Total',
            dataIndex: 'totalPriceGross',
            key: 'totalPriceGross',
            width: '2%',
            render: (row) => <Typography>{row} zł</Typography>
        },
        {
            title: "Płatność",
            dataIndex: "",
            key: "",
            width: "2%",
            algin: 'center',
            render: (row) => <PaymentStatusLabel paid={row.orderPaymentView.paid}/>

        },
        {
            title: 'Dostawa',
            dataIndex: '',
            key: 'deliveryView',
            width: "5%",
            render: (row) =>
                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                    {row.deliverView.deliveryProductOwner.deliveryView.logo ? <Image
                        style={{width: '36px', height: '36px', objectFit: 'contain'}}
                        src={row.deliverView.deliveryProductOwner.deliveryView.logo}
                        preview={!!row[0]}
                    /> : row.deliverView.deliveryProductOwner.deliveryView.name}
                    {row.deliverView.price} zł
                </div>

        },
        {
            title: '',
            dataIndex: 'orderId',
            key: 'x',
            width: '2%',
            render: id => <Space key={id} size='middle'><Link
                key={id + '1'}
                to={`/order/items/${id}`}
            >
                Szczegóły
            </Link>
            </Space>
        },

    ];

    return <Table dataSource={orders.map((it, index) => {
        return {
            ...it,
            key: index
        }
    })} columns={columns} pagination={false}
                  footer={() => getFooter(data)}
    />
}

export default TableOrder;

const getFooter = (data) => {
    let sumDiscount = 0;
    data.forEach((it) => sumDiscount += it.originalTotalPriceGross ? Math.round((it.originalTotalItemPriceGross - it.totalItemPriceGross) * 100) / 100 : 0)

    let sumPriceWithOutDiscount = 0;
    data.forEach((it) => sumPriceWithOutDiscount += it.originalTotalItemPriceGross ? it.originalTotalItemPriceGross : it.totalItemPriceGross)

    let sumPriceWithDiscount = 0;
    data.forEach((it) => sumPriceWithDiscount += it.totalItemPriceGross)

    let sumPriceTotal = 0;
    data.forEach((it) => sumPriceTotal += it.totalPriceGross)

    return <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-end'}}>
        <div style={{width: '40%'}}>
            <div>Podsumowanie (ceny brutto)</div>
            <div>Suma Wartości rabatów {Math.round((sumDiscount) * 100) / 100} zł</div>
            <div>Suma cen bez rabatów {Math.round((sumPriceWithOutDiscount) * 100) / 100} zł</div>
            <div>Suma cen z rabatem {Math.round((sumPriceWithDiscount) * 100) / 100} zł</div>
            <div>Suma cen total {Math.round((sumPriceTotal) * 100) / 100} zł</div>
        </div>
    </div>
}
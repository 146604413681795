import {Space, Switch, Table} from "antd";
import React, {useEffect, useState} from "react";
import {CheckOutlined, CloseOutlined} from "@ant-design/icons";
import {themes} from "../../../layout/styles/ColorStyles";
import {Link} from "react-router-dom";
import useUpdateActivityMarketingBox from "../../../hooks/marketingBox/useUpdateActivityMarketingBox";

const MarketingBoxTable = ({data, reload}) => {

    const [marketingBox, setMarketingBox] = useState([]);

    function groupBy(arr, property) {
        return arr.reduce(function (memo, x) {
            if (!memo[x[property]]) { memo[x[property]] = []; }
            memo[x[property]].push(x);
            return memo;
        }, {});
    }

    useEffect(() => {

        let test = groupBy(data,'groupName');

        let tmp = Object.values(test).map((it) => {
            return it[0];
        });

        setMarketingBox(tmp);
    }, [data]);


    const {update} = useUpdateActivityMarketingBox();

    const updateActivity = (item, val) => {
        update({groupName: item.groupName, isActive: val}).then(() => reload());
    };


    const columns = [
        {
            title: 'Aktywność',
            dataIndex: '',
            width: '5%',
            key: 'active',
            render: row => <Switch
                checkedChildren={<CheckOutlined/>}
                unCheckedChildren={<CloseOutlined/>}
                style={{
                    backgroundColor: row.isActive
                        ? themes.activeMenuItemColor
                        : themes.switchInActive,
                }}
                onClick={(e) => updateActivity(row, e)}
                checked={row.isActive}
            />
        },
        {
            title: 'Rodzaj',
            dataIndex: 'type',
            key: 'type',
            width: '5%',
        },
        {
            title: 'Nazwa',
            dataIndex: 'name',
            key: 'name',
            width: '70%',
        },
        {
            title: 'Grafika',
            dataIndex: '',
            key: '',
            width: '10%',
        },
        {
            title: '',
            dataIndex: 'groupName',
            key: 'x',
            render: groupName => <Space key={groupName} size='middle'><Link
                key={groupName + '1'}
                to={`/marketing-box/${groupName}`}
            >
                Edytuj
            </Link>
            </Space>
        },
    ];

    return <Table
        pagination={false}
        columns={columns} dataSource={marketingBox.map((it) => {
        return {
            ...it,
            key: it.id
        }
    })}/>
}

export default MarketingBoxTable;
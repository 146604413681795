import {useState} from "react";
import axiosInstance from "../../lib/axios";
import useAlert from "../useAlert";

interface AssignFlavourToProductProps {
    flavourIds: Array<string>,
    productId: string
}

const useAssignFlavourToProduct = () => {

    const [isLoading, setIsLoading] = useState(false);
    const {successMessage, errorMessage } = useAlert();

    const update = async (
        obj: AssignFlavourToProductProps
    ): Promise<any> => {

        try {
            setIsLoading(true);
            await axiosInstance.put(
                `/admin/product/flavours/${obj.productId}`,
            obj
            );

            successMessage("Smaki zostały dodane");
            setIsLoading(false);

        } catch (err) {
            setIsLoading(false);
            errorMessage("Wystąpił błąd");
            throw err;
        }
    };

    return { update, isLoading };

}

export default useAssignFlavourToProduct;

import {useEffect, useState} from "react";
import {Button, Modal, Table, Timeline, Typography} from "antd";
import useGetOrderSettlementBills
    from "../../../../hooks/productOwners/OrderSettlementInvoice/useGetOrderSettlementBills";
import PaymentStatusLabel from "../../../Labels/PaymentStatusLabel";
import ButtonLink from "../../../Buttons/ButtonLink";
import {themes} from "../../../../layout/styles/ColorStyles";
import FormIndicator from "../../../Forms/FormIndicator";

const OrderSettlementBillModal = ({id, title}) => {

    const [isModalVisible, setIsModalVisible] = useState(false);
    const {data, getOrderSettlementBills, isLoading} = useGetOrderSettlementBills();
    // const { rePay } = useDispatchP24Transaction();

    useEffect(() => {
        if (isModalVisible) {
            getOrderSettlementBills(id);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isModalVisible])

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const onRePayment = (row) => {
        console.log(row.orderSettlementId);
        // rePay(id).then(() => getOrderSettlementBills(id))
    }

    const columns = [
        {
            title: 'Data',
            dataIndex: 'orderDate',
            key: 'orderDate',
        },
        {
            title: 'Numer zamówienia',
            dataIndex: 'orderNumber',
            key: 'orderNumber',
        },
        {
            title: 'Kwota zamówienia',
            dataIndex: 'orderPrice',
            key: 'orderPrice',
        },
        {
            title: 'Prowizja Appet',
            dataIndex: 'orderCommission',
            key: 'orderCommission',
        },
        {
            title: 'Rozliczono',
            dataIndex: 'billPaid',
            key: 'orderSettlement',
            render: (row) => <PaymentStatusLabel paid={row} textFalse={'Nie'} textTrue={'Tak'}/>
        },
        {
            title: '',
            dataIndex: '',
            key: 'rePay',
            render: (row) => <ButtonLink text={'Rozlicz ponownie'} onClick={() => onRePayment(row)}/>

        }
    ];

    const footer = () => {
        let comTmp = data.map((it) => parseFloat(it.orderCommission));
        const sumCommision = comTmp.reduce((partialSum, a) => partialSum + a, 0);

        let test = data.map((it) => parseFloat(it.orderPrice));
        const sumOrder = test.reduce((partialSum, a) => partialSum + a, 0);


        return <div style={{display: 'flex', flexDirection: 'column'}}>
            <p>Suma prowizji: {Math.round(sumCommision * 100) / 100} PLN</p>
            <p>Suma zamówienia: {Math.round(sumOrder * 100) / 100} PLN</p>
        </div>

    }

    return (
        <>
            <Button type="primary" onClick={showModal}>
                Zobacz transakcję
            </Button>
            <Modal
                title={title}
                okButtonProps={false}
                open={isModalVisible}
                footer={null}
                onCancel={handleCancel}
                width={1000}>
                {isLoading ? <div><FormIndicator numberOfElements={1}/></div> :
                    <Table footer={footer}
                        dataSource={data.map((it, index) => {
                        return {...it, key: index}
                    })}
                           columns={columns}
                           pagination={false}
                           expandable={{
                               expandedRowRender: (record, index) => (
                                   <Timeline key={index}>
                                       {
                                           record.logs.map((log, index) => <Timeline.Item key={index}
                                                                                          color={themes.activeMenuItemColor}>{log.message}
                                               <Typography.Text
                                                   type='secondary'>{log.createdAt}</Typography.Text></Timeline.Item>)
                                       }
                                   </Timeline>
                               ),
                               rowExpandable: (record) => record.logs !== [],
                           }}
                    />}
            </Modal>
        </>
    );

}

export default OrderSettlementBillModal;
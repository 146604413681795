import useAlert from "../useAlert";
import axiosInstance from "../../lib/axios";

const useAssignBlogCategoryToPost = () => {
    const {successMessage, errorMessage } = useAlert();

    const update = async (
        blogCategoriesIds: Array<string>,
        blogPostId: string
    ): Promise<any> => {

        const parseToString: string = blogCategoriesIds.join(",");
        const obj = { blogCategoriesIds: parseToString };
        try {
            await axiosInstance.put(
                `/admin/blog/post/blog-categories/${blogPostId}`,
                obj
            );

            successMessage("Smaki zostały dodane");

        } catch (err) {
            errorMessage("Wystąpił błąd");
            throw err;
        }
    };

    return { update };

}

export default useAssignBlogCategoryToPost;
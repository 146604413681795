import React, {useEffect, useState} from "react";
import FormTitle from "../FormTitle";
import styled from "styled-components";
import ImageUploading from "react-images-uploading";
import useAddPhotoProduct from "../../../hooks/products/useAddPhotoProduct";
import useGetAllPhotosProduct from "../../../hooks/products/useGetAllPhotosProduct";
import FormIndicator from "../FormIndicator";
import useRemovePhotoProduct from "../../../hooks/products/useRemovePhotoProduct";
import {FaFileImage} from "react-icons/fa";
import useUpdatePhotoProductPosition from "../../../hooks/products/useUpdatePhotoProductPosition";
import ProductMediaList from "./ProductMedia/ProductMediaList";
import {Button, Image, Typography} from "antd";
import AssignProductVariantPhotoToProductModal from "../../Modals/Products/AssignProductVariantPhotoToProductModal";

const Wrapper = styled.div``;

const BigButton = styled.button`
  border: none;
  color: #364a63;
  font-weight: 600;
  height: 150px;
  width: 50%;
  text-align: center;
  font-size: 14px;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.2s ease-in;

  :hover {
    background-color: #f8f8f8;
  }
`;

const TopActionsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-bottom: 20px;
`;

const Media = ({productId, product}) => {
    const {data: photos, getProductPhotosById, reload, isLoading} = useGetAllPhotosProduct();
    const [openModal, setOpenModal] = useState(false);
    const {remove} = useRemovePhotoProduct();
    const maxNumber = 5;
    const {update} = useAddPhotoProduct();
    const {updatePosition} = useUpdatePhotoProductPosition();

    useEffect(() => {
        getProductPhotosById(productId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onChange = (imageList, addUpdateIndex) => {

        const fotoItem = imageList[addUpdateIndex];

        const data = new FormData();
        data.append("name", fotoItem.file.name);
        data.append("file", fotoItem.file);

        update(data, productId).then(() => reload(productId));
    };

    const handleRemove = (index) => {

        const id = photos[index].photoId;
        remove(productId, id).then(() => reload(productId));
    };

    const handleChangePosition = (productPhotoId, newPosition) => {

        updatePosition(productId, productPhotoId, newPosition).then(() => {
            reload(productId);
        });
    };

    return (
        <>
            <FormTitle title={"Media"}/>
            <>
                {!isLoading ? (
                    <Wrapper>
                        <AssignProductVariantPhotoToProductModal product={product} openModal={openModal}
                                                                 setOpenModal={setOpenModal} reload={() => reload(productId)}/>
                        <ImageUploading
                            multiple
                            value={photos ? photos : []}
                            onChange={onChange}
                            maxNumber={maxNumber}
                            dataURLKey="src"
                        >
                            {({
                                  onImageUpload,
                                  isDragging,
                                  dragProps,
                              }) => (
                                // write your building UI
                                <div className="upload__image-wrapper">
                                    <TopActionsWrapper>
                                        <BigButton
                                            style={isDragging ? {color: "red"} : undefined}
                                            onClick={onImageUpload}
                                            {...dragProps}
                                        >
                                            <FaFileImage
                                                style={{fontSize: "40px", color: "#ffffff"}}
                                            />
                                            Wybierz lub przeciągnij plik tutaj
                                        </BigButton>
                                    </TopActionsWrapper>
                                    {photos ?
                                        <ProductMediaList data={photos} handleChangePosition={handleChangePosition}
                                                          handleRemove={handleRemove}/> : null}
                                </div>
                            )}
                        </ImageUploading>
                        <div style={{display: 'flex', gap: '10px', alignItems: 'center', paddingTop: '10px'}}>
                            <Typography>Zdjęcie produktu głównego</Typography><Button type='primary'
                                                                                      onClick={() => setOpenModal(
                                                                                          true
                                                                                      )}>Zmień zdjęcie</Button>
                        </div>
                        {photos.length ? <Image src={photos[0].fullPath}/> : null}
                    </Wrapper>
                ) : (
                    <FormIndicator numberOfElements={3}/>
                )}
            </>
        </>
    );
};

export default Media;

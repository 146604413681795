import {useState} from "react";
import useAlert from "../useAlert";
import axiosInstance from "../../lib/axios";

type CreateIngredientGroupProps = {
    name: string;
}

const useCreateIngredientGroup = () => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const {successMessage, errorMessage} = useAlert();


    const create = async (obj: CreateIngredientGroupProps): Promise<any> => {
        try {
            setIsLoading(true);
            await axiosInstance.post("/admin/ingredient_group/", obj);
            setIsLoading(false);
            successMessage("Zapisano");
        } catch (err) {
            setIsLoading(false);
            errorMessage("Błąd zapisu, spróbuj ponownie");
            throw err;
        }
    };

    return {create, isLoading};

}
export default useCreateIngredientGroup;
import { useState, useEffect } from "react";
import axiosInstance from "../../lib/axios";

const useGetAllBrands = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [data, setData] = useState<{} | null>(null);

  useEffect(() => {
    getBrands();
  }, []);

  const getBrands = async (): Promise<any> => {

    try {
      const res = await axiosInstance.get(`/admin/brand/`);

      if (res.status === 200) {
        setData(res.data ? res.data : []);
        setIsLoading(false);
      }
    } catch (err) {
      setIsLoading(false);
    }
  };

  const reload = () => {
    getBrands();
  };

  return { isLoading, data, reload };
};

export default useGetAllBrands;

import { Switch, Table} from "antd";
import {themes} from "../../../layout/styles/ColorStyles";
import {CheckOutlined, CloseOutlined} from "@ant-design/icons";
import CreateProductOwnerWorker from "../../Modals/ProductOwner/ProductOwnerWorker/CreateProductOwnerWorker";
import ButtonLink from "../../Buttons/ButtonLink";
import React, {useState} from "react";
import ChangePasswordModal from "../../Modals/User/ChangePasswordModal";

const WorkersTab = ({data, reload}) => {

    const [openModal, setOpenModal] = useState(false);
    const columns = [
        {
            title: "Aktywność",
            dataIndex: "",
            align: "center",
            render: (row) => (
                <Switch
                    style={{
                        backgroundColor: row.active
                            ? themes.activeMenuItemColor
                            : themes.switchInActive,
                    }}
                    checkedChildren={<CheckOutlined />}
                    unCheckedChildren={<CloseOutlined />}
                    defaultChecked={row.active}
                    // onChange={(checked) => handleUpdateUserActivity(row, checked)}
                />
            ),
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: 'Imie',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Nazwisko',
            dataIndex: 'lastName',
            key: 'lastName',
        },
        {
            title: 'Numer',
            dataIndex: 'number',
            key: 'number',
        },
        {
            title: '',
            key: 'action',
            render: row => <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '10px'}}>
                <ButtonLink text={'Zmień hasło'} onClick={() => setOpenModal(true)}/>
                <ChangePasswordModal
                    user={row}
                    openModal={openModal}
                    setOpenModal={setOpenModal}
                    reload={reload}
                />
            </div>
        }
    ];

    return <>
        <CreateProductOwnerWorker reload={reload}/>

        <Table pagination={false} columns={columns} dataSource={data.map((it) => {
            return {
                key: it.id,
                ...it
            }
        })} /></>;

}


export default WorkersTab;
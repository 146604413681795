import useAlert from "../useAlert";
import axiosInstance from "../../lib/axios";

const useMakeBillForOrder = () => {

    const {successMessage, errorMessage} = useAlert();

    const makeBillForOrder = async (id: string): Promise<any> => {
        try {
            await axiosInstance.post("/admin/b2b/settlement/order/" + id);
            successMessage("Zamówienie zostało podzielone");
        } catch (err) {
            errorMessage("Błąd zapisu. Spróbuj ponownie.");
            throw err;
        }
    };

    return {makeBillForOrder};
}

export default useMakeBillForOrder;
import styled from "styled-components";
import {Button, Form, Image, Input, Select, Switch} from "antd";
import {CheckOutlined, CloseOutlined} from "@ant-design/icons";
import LogoUploader from "../../Uploader/LogoUploader";
import React from "react";

const {Option} = Select;
const HeaderComponent = styled.div`
  display: flex;
  background-color: #FAFAFA;
  height: 46px;
  align-items: center;
  padding-left: 24px;
  padding-top: 10px;
  padding-bottom: 10px;
`;


const MarketingBoxForm = ({data, updateInfo, handleUpdateBanner, getMarketingBoxInfoByGroupName}) => {

    const [form] = Form.useForm();

    const onFinish = (values) => {
        let test = {
            ...values,
            isOpenInNewTab: values.hasOwnProperty('isOpenInNewTab') ? values.isOpenInNewTab : data[0].isOpenInNewTab
        }
        updateInfo(test);
    }

    return <div style={{
        position: 'relative',
        width: '100%',
        height: 'auto',
        backgroundColor: '#ffffff',
        borderRadius: '5px',
        border: '1px solid #e5e9f2'
    }}
    ><Form
        form={form}
        onFinish={onFinish}
        labelCol={{span: 6}}
        wrapperCol={{span: 15}}
        labelAlign="left"
        labelWrap
        layout="horizontal"
        colon={false}
    >
        <HeaderComponent>Podstawowe informację </HeaderComponent>
        <Form.Item style={{paddingLeft: '24px'}} label='Aktywność' name='isActive'
                   rules={[{required: true, message: 'Aktywność musi być oznaczona'}]}
                   valuePropName="checked" initialValue={data[0].isActive}>
            <Switch checkedChildren={<CheckOutlined/>}
                    unCheckedChildren={<CloseOutlined/>}/>
        </Form.Item>
        <Form.Item style={{paddingLeft: '24px'}} label='Rodzaj' name='type' initialValue={data[0].type}>
            <Input disabled={true}/>
        </Form.Item>
        <Form.Item style={{paddingLeft: '24px'}} label='Nagłówek' name='headerTitle' initialValue={data[0].headerTitle}>
            <Input/>
        </Form.Item>
        <div style={{paddingBottom: '24px', paddingLeft: '24px', fontWeight: '400', color: 'grey'}}>
            Jeśli pole jest uzupełnione to obok grafiki wyświetlany jest nagłówek ze zdefiniowaną treścią.
        </div>
        <Form.Item style={{paddingLeft: '24px'}} label='Wewnętrzny opis' name='description'
                   initialValue={data[0].description}>
            <Input/>
        </Form.Item>
        <div style={{paddingBottom: '24px', paddingLeft: '24px', fontWeight: '400', color: 'grey'}}>
            Opis widoczny tylko dla administratora.
        </div>
        {
            data[0].type === 'WEB' ? <div><Form.Item style={{paddingLeft: '24px'}} label='Wartość alt' name='altValue'
                                                     initialValue={data[0].description}>
                <Input/>
            </Form.Item>
                <div style={{paddingBottom: '24px', paddingLeft: '24px', fontWeight: '400', color: 'grey'}}>
                    Opis grafiki pod SEO
                </div>
            </div> : null
        }
        <HeaderComponent>Akcja</HeaderComponent>
        <Form.Item
            style={{paddingLeft: '24px', marginTop: '10px'}}
            name="actionType"
            label="Wybierz akcję"
            rules={[
                {
                    required: true,
                    message: 'Pole wymagane'
                },
            ]}
            initialValue={data[0].actionType ?? 'none'}
        >
            <Select
                allowClear
            >
                <Option key={1} value="none">Brak</Option>
                <Option key={2} value="EXTERNAL_LINK">Link na zewnątrz aplikacji</Option>
                {data[0].type !== 'WEB' ?  <Option key={3} value="COPY_CODE">Skopiowanie kodu rabatowego</Option>  : null}
            </Select>
        </Form.Item>
        <Form.Item
            shouldUpdate={(prevValues, currentValues) => prevValues.actionType !== currentValues.actionType}
        >
            {({getFieldValue}) =>
                getFieldValue('actionType') !== 'none' ? (
                    <Form.Item
                        initialValue={data[0].actionText}
                        style={{paddingLeft: '24px'}}
                        name="actionText"
                        label={getFieldValue('actionType') === 'EXTERNAL_LINK' ? 'Link' : 'Treść kodu do skopiowania'}
                        rules={[
                            {
                                required: true,
                                message: 'Pole wymagane'
                            },
                        ]}
                    >
                        <Input/>
                    </Form.Item>
                ) : null
            }
        </Form.Item>
        <Form.Item
            shouldUpdate={(prevValues, currentValues) => prevValues.actionType !== currentValues.actionType}
        >
            {({getFieldValue}) =>
                getFieldValue('actionType') === 'EXTERNAL_LINK' && data[0].type === 'WEB' ? (
                    <Form.Item style={{paddingLeft: '24px'}} label='Otwórz w nowej karcie' name='isOpenInNewTab'
                               valuePropName="checked" initialValue={data[0].isOpenInNewTab}>
                        <Switch checkedChildren={<CheckOutlined/>}
                                unCheckedChildren={<CloseOutlined/>}/>
                    </Form.Item>
                ) : null
            }
        </Form.Item>
        <Form.Item wrapperCol={{offset: 1}}>
            <Button type="primary" htmlType="submit">
                Zapisz
            </Button>
        </Form.Item>
    </Form>
        {data.map((it) =>
            <div><HeaderComponent>Wymagany rozmiar ({it.type === 'WEB' ? it.webType : null})
                - {it.sizeInfo}</HeaderComponent>
                <div style={{padding: '24px'}}>
                    <LogoUploader textButton={'Aktualizuj grafikę'} update={handleUpdateBanner}
                                  item={{
                                      logo: it.banner ?? null,
                                      id: it.id
                                  }}
                                  reload={getMarketingBoxInfoByGroupName}/>
                </div>
                <div style={{padding: '24px'}}>
                    {it.banner ? <Image src={it.banner}/> : 'Brak zdjecia'}
                </div>
            </div>
        )}
    </div>
}

export default MarketingBoxForm;
const useCalculatePrices = () => {

    const calculateBruttoPrice = (price, vatRate) => {
        let priceBrutto = parseFloat(parseFloat(price) + (parseFloat(price) * (vatRate / 100)));
        return priceBrutto.toFixed(2);
    }

    const calculateNettoPrice = (priceBrutto, vatRate) => {
        let priceNetto = priceBrutto/(1+(vatRate/100));
        return priceNetto.toFixed(2);
    }

    return {calculateBruttoPrice, calculateNettoPrice};
}

export default useCalculatePrices;
import React, {useContext} from "react";
import ReviewProductFiltersContext from "../../../../context/ReviewProductFiltersContext";
import {Select} from "antd";
import {UPDATE_STATUS_REVIEW} from "../../../../reducers/ReviewProductFiltersReducer";

const ReviewProductStatusSelect = () => {

    const {dispatch, state} = useContext(ReviewProductFiltersContext);

    return <Select
        mode='single'
        placeholder='Wybierz status'
        allowClear={true}
        defaultValue={state.statusReview}
        onChange={(e) => {
            dispatch({type: UPDATE_STATUS_REVIEW, payload: e})
        }}>
        <Select.Option key={0}>
            Wszystkie
        </Select.Option>
        <Select.Option key={1} value='submitted'>
            Zaakceptowane
        </Select.Option>
        <Select.Option key={3} value='waiting'>
            Oczekujące
        </Select.Option>
        <Select.Option key={2} value='deleted'>
            Do usunięcia
        </Select.Option>
    </Select>
}

export default ReviewProductStatusSelect;
import { useState } from "react";
import axiosInstance from "../../lib/axios";
import useAlert from "../useAlert";

const useAddPhotoProduct = () => {
  const [isLoading, setisLoading] = useState<boolean>(false);
  const { successMessage, errorMessage} = useAlert();

  const update = async (data: FormData, id: string): Promise<any> => {
    try {
      setisLoading(true);

      await axiosInstance.post(
        `/admin/product/photo/${id}`,
        data,
      );
      successMessage('Zdjęcie zostało dodane, może pojawić się po kliku minutach')

    } catch (err) {
      setisLoading(false);
      errorMessage('Wystąpił błąd podczas dodawania zdjęcia')
      throw err;
    }
  };

  return { update, isLoading };
};

export default useAddPhotoProduct;

import axiosInstance from "../../lib/axios";
import {useState} from "react";
import useAlert from "../useAlert";

interface CreateTagProps {
    name: string;
}

const useCreateTag = () => {

    const {successMessage} = useAlert();
    const [isLoading, setisLoading] = useState<boolean>(false);

    const create = async (obj: CreateTagProps): Promise<any> => {
        try {
            setisLoading(true);
            const { data } = await axiosInstance.post("/admin/tag/", obj);

            if (data.id) {
                setisLoading(false);
                successMessage('Utworzono tag');
            }
        } catch (err) {
            setisLoading(false);
            throw err;
        }
    };

    return {create, isLoading};
}

export default useCreateTag;
import {useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import useGetCodesForDiscount from "../../hooks/discount/codes/useGetCodesForDiscount";
import styled from "styled-components";
import Title from "../../components/Title";
import FormIndicator from "../../components/Forms/FormIndicator";
import CustomButton from "../../components/Forms/Buttons/CustomButton";
import useExportListOfCodes from "../../hooks/discount/codes/useExportListOfCodes";
import CreateCodeByNameModal from "../../components/Modals/Discounts/Codes/CreateCodeByNameModal";
import GenerateNewCodesModal from "../../components/Modals/Discounts/Codes/GenerateNewCodesModal";
import DiscountCodesTabs from "../../components/Tabs/DiscountCodes/DiscountCodesTabs";
import CreateGroupCodeByNameModal from "../../components/Modals/Discounts/Codes/CreateGroupCodeByNameModal";

const Wrapper = styled.div`
  position: relative;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  padding: 10px;
`;

const DiscountCodes = () => {

    const params = useParams();
    const [openNewCodeModal, setOpenNewCodeModal] = useState(false);
    const [openNewGroupCodeModal, setOpenNewGroupCodeModal] = useState(false);
    const [openGenerateCodeModal, setOpenGenerateCodeModal] = useState(false);
    const {data, isLoading, getCodesForDiscount, reload} =
        useGetCodesForDiscount();
    const {getListOfCodesForFile} = useExportListOfCodes();

    const exportCodesFile = () => {
        getListOfCodesForFile(params.id);
    };

    useEffect(() => {
        getCodesForDiscount(params.id);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <Wrapper>
                <Title title="Lista kodów"/>
                {isLoading ? (
                    <FormIndicator numberOfElements={3}/>
                ) : (
                    <>
                        <ButtonContainer>
                            <CustomButton
                                title="Utwórz kod grupowy"
                                onClick={() => setOpenNewGroupCodeModal(true)}
                            />
                            <CustomButton
                                title="Utwórz pojedynczy kod rabatowy"
                                onClick={() => setOpenNewCodeModal(true)}
                            />
                            <CustomButton
                                title="Generuj grupowo"
                                onClick={() => setOpenGenerateCodeModal(true)}
                            />
                            <CustomButton
                                title="Exportuj kody jednarozwe"
                                onClick={() => exportCodesFile()}
                            />
                        </ButtonContainer>
                        <DiscountCodesTabs data={data}/>
                    </>
                )}
            </Wrapper>

            <CreateCodeByNameModal
                openNewCodeModal={openNewCodeModal}
                setOpenNewCodeModal={setOpenNewCodeModal}
                reload={reload}
            />
            <CreateGroupCodeByNameModal
                openNewGroupCodeModal={openNewGroupCodeModal}
                setOpenNewGroupCodeModal={setOpenNewGroupCodeModal}
                reload={reload}
            />
            <GenerateNewCodesModal
                openGenerateCodeModal={openGenerateCodeModal}
                setOpenGenerateCodeModal={setOpenGenerateCodeModal}
                reload={reload}
            />
        </>
    );
};

export default DiscountCodes;

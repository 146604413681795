import {useState} from "react";
import axiosInstance from "../../lib/axios";

const useGetProductVariantsCollection = () => {

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [data, setData] = useState<{} | null>(null);

    const getProductVariants = async(state: any ): Promise<any> => {
        try {
            const res = await axiosInstance.get(`/admin/product/variant/`, { params: state });

            if (res.status === 200) {
                setData(res.data ? res.data : []);
                setIsLoading(false);
            }
        } catch (err) {
            setIsLoading(false);
        }
    }

    const reload = (filters: any) => {
        setIsLoading(true);
        getProductVariants(filters);
    };

    return { isLoading, data, reload, getProductVariants };
}

export default useGetProductVariantsCollection;
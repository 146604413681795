import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  /* position: absolute; */
  font-size: 20px;
  color: #364a63;
  font-weight: 800;
  margin-bottom: 15px;
  border-bottom: 1.5px solid #e5e9f2;
`;

const Title = ({ title }) => {
  return <Wrapper>{title}</Wrapper>;
};

export default Title;

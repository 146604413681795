export const themes = {
  // left menu
  activeMenuItemColor: "#FFBD3C",

  //login page
  loginButtonColor: "#FFBD3C",
  logginButtonColorHover: "#FFBD3C",
  fotterColor: "#8094ae",
  switchInActive: '#BFBFBF',
  black: '#000000',
  editButton: '#1890FF',

  light: {
    backgroundColor: `#f5f6fa`,
    backgroundColorLeftMenu: `#ffffff`,
  },
  dark: {
    backgroundColor: `#1F1F47`,
    backgroundColorLeftMenu: `#000000`,
  },

  status: {
    created: '#E77E22',
    submitted: '#3A8BE2',
    packaged: '#6A2E85',
    send: '#3D770F',
    completed: '#1130A7',
    returned: '#F63C45',
    canceled: '#797979',
    cancelled_not_paid: '#5C0011',
    packaged_lost: '#5C0011',
    complaint: '#5C0011',
    preready: '#F89C35',
  },

  menu: {
    text: '#000000',
    arrow: '#000000',
    background: '#ffffff',
    backgroundHover: '#F4F6F9',
    backgroundActive: '#E4E4E4',

  }
};


import {useState} from "react";
import useAlert from "../../useAlert";
import axiosInstance from "../../../lib/axios";

interface AddIngredientForProduct {
    ingredientId: string,
    productId: string
}
const useAddIngredientToProduct = () => {

    const [isLoading, setIsLoading] = useState(false);
    const {successMessage, errorMessage } = useAlert();

    const add = async (
        obj: AddIngredientForProduct
    ): Promise<any> => {

        try {
            setIsLoading(true);
            await axiosInstance.post(
                `/admin/product/ingredient/${obj.productId}`,
                obj
            );

            successMessage("Smak został dodany");
            setIsLoading(false);

        } catch (err) {
            setIsLoading(false);
            errorMessage("Wystąpił błąd");
            throw err;
        }
    };

    return { add, isLoading };

}

export default useAddIngredientToProduct;

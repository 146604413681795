import {useState} from "react";
import axiosInstance from "../../../lib/axios";

const useGetSettlmenetCommissionByPOId = () => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [data, setData] = useState<{} | null>(null);

    const getProductOwnerSettlementCommission = async (id: string): Promise<any> => {
        try {
            setIsLoading(true);
            const res = await axiosInstance.get(`/admin/b2b/settlement/commission/`+id);
            if (res.status === 200) {
                setData(res.data ? res.data : []);
                setIsLoading(false);
            }
        } catch (err) {
            console.log("err");
            setIsLoading(false);
        }
    };

    return { isLoading, getProductOwnerSettlementCommission , data};
}

export default useGetSettlmenetCommissionByPOId;
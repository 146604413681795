import {Routes, Route, BrowserRouter as Router} from "react-router-dom";
import {getRouting} from "./routing/routing";
import LayoutTest from "./layout/layout";

function App() {
    const routing = getRouting();

    return (
        <Router>
            <LayoutTest>
                <Routes>
                    {routing.map((it, index) => {
                        return (
                            <Route path={it.path} exact key={index} element={it.component}/>
                        );
                    })}
                    {/*<Route path="*" component={PageNotFound}/>*/}
                </Routes>
            </LayoutTest>
        </Router>
    );
}

export default App;

// const Error = () => {
//     return (
//         <>
//             <Result
//                 status="warning"
//                 title="Wystąpił błąd podczas wczytywania strony. Skontaktuj się z administratorem"
//                 extra={
//                 <NavLink to={''}>
//                     <Button type="primary" key="console">
//                         Powrót do strony głównej
//                     </Button>
//                 </NavLink>
//                 }
//             />
//         </>
//     );
// }
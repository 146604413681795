import styled from "styled-components";
import {useState} from "react";
import useGetAllBrandGroup from "../../hooks/brandGroup/useGetAllBrandGroup";
import useGetAllBrands from "../../hooks/brands/useGetAllBrands";
import Title from "../../components/Title";
import CustomButton from "../../components/Forms/Buttons/CustomButton";
import {FaPlus} from "react-icons/fa";
import FormIndicator from "../../components/Forms/FormIndicator";
import BrandGroupTable from "../../components/Tables/BrandGroups/BrandGroupTable";
import CreateBrandGroup from "../../components/Modals/BrandGroups/CreateBrandGroup";
import CreateBrandModal from "../../components/Modals/Brands/CreateBrandModal";

const Wrapper = styled.div`
  position: relative;
`;

const BrandGroups = () => {

    const [openModal, setOpenModal] = useState(false);
    const [openModalBrand, setOpenModalBrand] = useState(false);
    const {data, isLoading, reload} = useGetAllBrandGroup();
    const {data: brands, isLoading: isLoadingBrands} = useGetAllBrands();


    return <Wrapper>
        <Title title="Grupy marek"/>
        <div style={{display: 'flex', flexDirection: 'row', gap: '10px'}}>
            <CustomButton title='Utwórz grupę' icon={<FaPlus/>} onClick={() => setOpenModal(true)}/>
            <CustomButton title='Utwórz markę' icon={<FaPlus/>} onClick={() => setOpenModalBrand(true)}/>
        </div>

        {isLoading || isLoadingBrands ? <FormIndicator numberOfElements={3}/> : <>
            <BrandGroupTable dataSource={data} reload={reload} brands={brands}/>
            <CreateBrandModal open={openModalBrand} reload={reload} setOpenModal={() => setOpenModalBrand(false)}/>
        </>}
        <CreateBrandGroup
            handleClose={() => setOpenModal(false)}
            reload={reload}
            openModal={openModal}
        />
    </Wrapper>;
}

export default BrandGroups;
import axiosInstance from "../../lib/axios";
import useAlert from "../useAlert";

interface UpdateStaticPageContentProps {
    id: string
    content: string
}

const useUpdateStaticPageContent = () => {

    const {successMessage, errorMessage} = useAlert();

    const update = async (obj: UpdateStaticPageContentProps): Promise<any> => {
        try {
            await axiosInstance.put("/admin/static-page/content/" + obj.id, obj);
            successMessage("Strona została zaaktualizowana");
        } catch (err) {
            errorMessage("Wystąpił błąd podczas aktualizacji strony");
            throw err;
        }
    };

    return { update };


}

export default useUpdateStaticPageContent;
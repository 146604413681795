import useAlert from "../useAlert";
import axiosInstance from "../../lib/axios";

const useMargeProducts = () => {

    const { errorMessage, successMessage } = useAlert();

    const margeProducts = async (
        productId: string,
        productIds: string[]
    ): Promise<any> => {
        try {
            const obj = { productIds: productIds };
            await axiosInstance.put(
                "/admin/product/merge/" + productId,
                obj
            );
            successMessage("Produkty zostały zmienione");
        } catch (err) {
            errorMessage("Błąd łączenia produktów");
            //   throw err;
        }
    }

    return { margeProducts };
}

export default useMargeProducts;
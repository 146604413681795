import styled from "styled-components";
import {Button, Image, Spin, Tooltip} from "antd";
import PaymentStatusLabel from "../Labels/PaymentStatusLabel";
import useRegenerateP24Payment from "../../hooks/orders/useRegenerateP24Payment";
import {CopyOutlined} from "@ant-design/icons";
import React, {useEffect} from "react";
import useAlert from "../../hooks/useAlert";
import useCheckOrderCanBeRefunded from "../../hooks/orders/useCheckOrderCanBeRefunded";
import useRefundOrder from "../../hooks/orders/useRefundOrder";
import useGetOrderDispatchInformation from "../../hooks/orders/useGetOrderDispatchInformation";

const OrderDetailRow = styled.div`
  display: flex;
  flex-direction: row;
  border-top: 1px solid #f0f0f0;
`;

const OrderDetailRowLeft = styled.div`
  width: 25%;
  background-color: #fafafa;
  display: flex;
  align-items: center;
  padding-left: 25px;
  border-right: 1px solid #f0f0f0;
`;

const OrderDetailRowRight = styled.div`
  width: 75%;
  display: flex;
  align-items: center;
  padding-left: 25px;
  min-height: 56px;
  height: 100%;
`;

const OrderDetailP24Info = ({payment, orderId, orderSettlement}) => {

    const {reload, data, isLoading} = useRegenerateP24Payment();
    const {successMessage} = useAlert();
    const {checkRefund, data: checkRefundData} = useCheckOrderCanBeRefunded();
    const {refundOrder } = useRefundOrder();
    const {isLoading: isLoadingDispatchInfo, data: dispatchInfo, getOrderDispatchInfo} = useGetOrderDispatchInformation();

    console.log(data);
    console.log(checkRefundData);
    console.log(orderSettlement);
    console.log(payment);


    const handleRegeneratePayment = () => {
        reload(orderId);
    }

    const copy = (url) => {
        successMessage('Skopiowano link do schowka');
        return window.navigator.clipboard.writeText(url);
    }

    useEffect(() => {
        getOrderDispatchInfo(orderId);
        checkRefund(orderId);
    }, []);

    const handleRefund = () => {
        refundOrder(orderId);
    }


    return <>
        {
            isLoadingDispatchInfo ? <Spin/> : <>
                <OrderDetailRow>
                    <OrderDetailRowLeft>Status płatności</OrderDetailRowLeft>
                    <OrderDetailRowRight><PaymentStatusLabel paid={payment.paid} textTrue={'Opłacone'}
                                                             textFalse={'Oczekuje na płatność'}/> {payment.paid ? null : <>
                        <Button onClick={() => handleRegeneratePayment()} type='primary' style={{marginLeft: '10px'}}>Wygeneruj
                            link do płatności</Button>
                        {isLoading ? <Spin/> : ''}
                        {data ? <Tooltip placement="top" title={'Skopiuj do schowka'}>
                            <CopyOutlined style={{marginLeft: '10px'}} onClick={() => copy(data.url)}/>
                        </Tooltip> : ''}
                    </>
                    }
                    </OrderDetailRowRight>
                </OrderDetailRow>
                <OrderDetailRow>
                    <OrderDetailRowLeft>Forma płatności</OrderDetailRowLeft>
                    <OrderDetailRowRight>
                        {payment.paymentMethod.logo ? <Image
                            preview={false}
                            src={payment.paymentMethod.logo}
                            style={{width: '36px', height: '36px', objectFit: 'contain'}}
                        /> : payment.paymentMethod.name}
                    </OrderDetailRowRight>
                </OrderDetailRow>
                <OrderDetailRow>
                    <OrderDetailRowLeft>Zwrot płatności </OrderDetailRowLeft>
                    <OrderDetailRowRight>
                        {
                            isLoading ? <Spin/> : null
                        }
                        {
                            payment.paid && !orderSettlement.isRefund && checkRefundData !== null?
                                <Button type='primary' onClick={() => handleRefund()}>Wykonaj zwrot</Button> : ''
                        }
                        {
                            !orderSettlement.isRefund ? null : <span>Zwrot wykonany</span>
                        }
                        {
                            checkRefundData !== null && !orderSettlement.isRefund  ? null : <span>Brak możliwości zwrotu, zamówienie nie zostało podzielone</span>
                        }

                    </OrderDetailRowRight>
                </OrderDetailRow>
                <OrderDetailRow>
                    <OrderDetailRowLeft>ID transakcji</OrderDetailRowLeft>
                    <OrderDetailRowRight>{payment.externalPaymentId}</OrderDetailRowRight>
                </OrderDetailRow>
                <OrderDetailRow>
                    <OrderDetailRowLeft>geispatchinfo (środki do wykorzystania)</OrderDetailRowLeft>
                    <OrderDetailRowRight>
                        <div style={{display: 'flex', flexDirection: 'column'}}>
                            {
                                dispatchInfo.dispatchInformation.map((item, index) => {
                                    return <div key={index} style={{margin: '2px', backgroundColor: '#fafafa', padding: '5px', borderRadius: '4px'}}>
                                        <div>Name: {item.name}</div>
                                        <div>Amount: {item.amount}</div>
                                        <div>Date: {item.date}</div>
                                        <div>OrderId: {item.orderId}</div>
                                        <div>PartnerId: {item.partner_id}</div>

                                    </div>
                                })
                            }
                        </div>
                    </OrderDetailRowRight>
                </OrderDetailRow>
                <OrderDetailRow>
                    <OrderDetailRowLeft>Saldo merchanta</OrderDetailRowLeft>
                    <OrderDetailRowRight>
                        <div style={{display: 'flex', flexDirection: 'column'}}>
                        {
                            dispatchInfo.merchantAccount.map((item, index) => {
                                return <div key={index} style={{margin: '2px', backgroundColor: '#fafafa', padding: '5px', borderRadius: '4px'}}>
                                    <div>Nazwa: {item.name}</div>
                                    <div>Saldo: {item.amount}</div>

                                </div>
                            })
                        }
                    </div> </OrderDetailRowRight>
                </OrderDetailRow>
            </>
        }

        </>
}

export default OrderDetailP24Info;
import useCreateProduct from "../../../hooks/products/useCreateProduct";
import React from "react";
import NewProductForm from "../../Forms/Products/NewProductForm";
import useGetAllGrammage from "../../../hooks/grammage/useGetAllGrammage";

const CreateProductModal = ({ reload, setOpenModal, open }) => {

    const {isLoading: isLoadingGrammage, data: grammages} = useGetAllGrammage();
    const { create } = useCreateProduct();

    const handleSave = (obj) => {

        create(obj)
            .then(() => {
                setOpenModal(false);
                reload();
            });
    };

    if(isLoadingGrammage) return null;

    return (
        <NewProductForm open={open} onCancel={() => setOpenModal(false)}
                   handleOK={handleSave} grammages={grammages}
        />
    );

}

export default CreateProductModal
import {Button, Dropdown, Menu, Table, Tooltip} from "antd";
import React, {useContext, useState} from "react";
import styled from "styled-components";
import SelectReviewStatus from "./SelectReviewStatus";
import useUpdateReviewStatus from "../../../hooks/review/useUpdateReviewStatus";
import {ReviewStatusDot} from "../../../layout/ReviewStatusDot";
import ReviewProductFiltersContext from "../../../context/ReviewProductFiltersContext";
import {UPDATE_SORT_BY} from "../../../reducers/ReviewProductFiltersReducer";

const CutText = styled.div`
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
  white-space: nowrap;`

const TableReview = ({data, reload}) => {

    const {dispatch} = useContext(ReviewProductFiltersContext);
    const [selectedRow, setSelectedRow] = useState([]);
    const {updateReviewStatus} = useUpdateReviewStatus();

    const handleChange = (value) => {

        let arr = selectedRow.map((it) => {
            return it.id;
        });

        updateReviewStatus(arr, value).then(() => {
            reload()
        });

        setSelectedRow([]);
    }


    const menu = (
        <Menu
            items={[
                {
                    value: 'submitted',
                    key: '1',
                    label: (<ReviewStatusDot status={'submitted'} value={'Zaakceptowane'}
                                             onClick={() => handleChange('submitted')}/>),
                },
                {
                    value: 'waiting',
                    key: '2',
                    label: (<ReviewStatusDot status={'waiting'} value={'Oczekujące'}
                                             onClick={() => handleChange('waiting')}/>),
                },
                {
                    value: 'deleted',
                    key: '3',
                    label: (<ReviewStatusDot status={'deleted'} value={'Do usunięcia'}
                                             onClick={() => handleChange('deleted')}/>),
                },
            ]}
        />
    );

    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedRow(selectedRows);
        },
    };

    const handleSort = (sort) => {

        switch (sort.order) {
            case 'ascend':
                dispatch({type: UPDATE_SORT_BY, payload: 'createdAtAsc'});
                break;
            case 'descend':
                dispatch({type: UPDATE_SORT_BY, payload: 'createdAtDesc'});
                break;
            default:
                dispatch({type: UPDATE_SORT_BY, payload: 'createdAtDesc'});
        }
    }

    const hasSelected = selectedRow.length > 0;
    const columns = [
        {
            title: 'Status',
            dataIndex: '',
            key: 'status',
            width: '20%',
            render: (row) => <SelectReviewStatus selected={row} reload={reload}/>
        },
        {
            title: 'Data dodania',
            dataIndex: 'date',
            key: 'date',
            sorter: () => '',
        },
        {
            title: 'Oceniany produkt',
            dataIndex: 'productName',
            key: 'product',
        },
        {
            title: 'Ocena',
            dataIndex: 'rate',
            key: 'rate',
            render: rate => <span>{rate}/5</span>
        },
        {
            title: 'Opinia tekstowa',
            dataIndex: 'comment',
            key: 'comment',
            width: '30%',
            render: comment => <Tooltip placement="top" title={comment}>
                <CutText>{comment}</CutText>
            </Tooltip>
        },
        {
            title: 'Imię',
            dataIndex: 'userName',
            key: 'userName',
        },
    ];


    return <>
        <Dropdown overlay={menu} placement="bottomLeft" arrow disabled={!hasSelected}>
            <Button
                type="primary">Zmień status</Button>
        </Dropdown>
        <Table rowSelection={rowSelection} columns={columns} dataSource={data.map((it) => {
            return {...it, key: it.id};
        })} pagination={false}
               onChange={(pagination, filters, sorter) => {
                   handleSort(sorter)
               }
               }
        /></>;
}

export default TableReview;
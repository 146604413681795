import useGetAllUsers from "../../hooks/users/useGetAllUsers";
import {useEffect, useState} from "react";
import Title from "../../components/Title";
import styled from "styled-components";
import FormIndicator from "../../components/Forms/FormIndicator";
import {FaPlus} from "react-icons/fa";
import CustomButton from "../../components/Forms/Buttons/CustomButton";
import UsersTable from "../../components/Tables/Users/UsersTable";
import CreateUser from "../../components/Modals/User/CreateUser";

const Wrapper = styled.div`
  position: relative;
`;

const Administrators = () => {

    const {data, isLoading, getUsers} = useGetAllUsers();
    const [openModal, setOpenModal] = useState(false);

    useEffect(() => {
        reload();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const reload = () => {
        getUsers({role: 'ROLE_ADMIN'});
    }

    return <Wrapper>
        <Title title="Lista administratorów"/>
        {isLoading ? (
            <FormIndicator numberOfElements={1}/>
        ) : (
            <>
                <CustomButton
                    title="Utwórz"
                    icon={<FaPlus/>}
                    onClick={() => setOpenModal(true)}
                />
                <UsersTable data={data.results} reload={() => reload()}/>
            </>)
        }
        <CreateUser open={openModal}
                    setOpenModal={setOpenModal}
                    reload={() => reload()}
        />
    </Wrapper>;
}

export default Administrators;
import {Button, DatePicker, Form, Input, Radio, Select, Switch} from "antd";
import styled from "styled-components";
import {useState} from "react";
import {CheckOutlined, CloseOutlined} from "@ant-design/icons";
import dayjs from "dayjs";

const HeaderComponent = styled.div`
  display: flex;
  background-color: #FAFAFA;
  height: 46px;
  align-items: center;
  padding-left: 24px;
  padding-top: 10px;
  padding-bottom: 10px;
`;

const {Option} = Select;

const FormDiscountTest = ({data, productOwners, save, isEditable = true}) => {

    const [form] = Form.useForm();

    const [type, setType] = useState(data ? data.type : "PERCENTAGE");
    const [poType, setPoType] = useState(data && data.discountProductOwner !== [] ? data.discountProductOwnerType : 'all');

    //objectForm cały formularz
    const onRequiredTypeChange = (type) => {

        if (type.hasOwnProperty('type')) {
            setType(type.type);
        }
    };

    const onChange = (value) => {
        setPoType(value);
    };

    const onFinish = (values) => {
        if (poType === 'all') {
            save({
                ...values,
                discountProductOwnerType: 'exclude',
                productOwnerIds: []
            })
        } else {
            save(values);
        }

        // console.log('Success:', values);
    };

    return <Form
        form={form}
        onFinish={onFinish}
        labelCol={{span: 6}}
        wrapperCol={{span: 15}}
        labelAlign="left"
        labelWrap
        style={{
            position: 'relative',
            width: '100%',
            height: 'auto',
            backgroundColor: '#ffffff',
            borderRadius: '5px',
            border: '1px solid #e5e9f2'
        }}
        layout="horizontal"
        colon={false}
        initialValues={{
            type: type,
            isSingleUse: data ? data.singleUse : false,
            discountProductOwnerType: poType,
            discountValue: data ? data.discountValue : null,
            isActive: data ? data.active : true,
            name: data ? data.name : null,
            activeFrom: data && data.activeFrom ? dayjs(data.activeFrom, 'YYYY-MM-DD') : null,
            activeTo: data && data.activeTo ? dayjs(data.activeTo, 'YYYY-MM-DD') : null,
            productOwnerIds: data ? data.discountProductOwner.map((it) => it.id) : [],
            description: data ? data.description : null,
            minOrderValue: data ? data.minOrderValue : null,
            isProductOwnerDiscountCost: data ? data.isProductOwnerDiscountCost : false
        }}
        onValuesChange={(changedValues, values) => onRequiredTypeChange(changedValues, values)}
        type={type}
    >
        <HeaderComponent>Rodzaj rabatu <p style={{color: "red"}}>*</p></HeaderComponent>
        <Form.Item
            rules={[{required: true, message: 'Wybierz typ rabatu!'}]}
            style={{paddingLeft: '24px', paddingTop: '10px'}} name='type'>
            <Radio.Group disabled={!isEditable}>
                <Radio value="FREE_SHIPPING"> Darmowa dostawa </Radio>
                <Radio value="PERCENTAGE"> Procentowy na cały koszyk </Radio>
                <Radio value="AMOUNT"> Kwotowy na cały koszyk </Radio>
            </Radio.Group>
        </Form.Item>


        {
            type !== 'FREE_SHIPPING' ?
                <Form.Item label="Wartość kuponu" style={{paddingLeft: '24px'}} name='discountValue'
                           rules={[{required: type !== 'FREE_SHIPPING', message: 'Wprowadź wartość rabatu!'}]}>
                    <Input disabled={!isEditable} style={{width: '100px'}}
                           addonAfter={type === 'PERCENTAGE' ? '%' : 'zł'}/>
                </Form.Item> : ''
        }
        <HeaderComponent>Podstawowe informację </HeaderComponent>
        <Form.Item style={{paddingLeft: '24px'}} label='Aktywność' name='isActive'
                   rules={[{required: true, message: 'Aktywność musi być oznaczona'}]}
                   valuePropName="checked">
            <Switch checkedChildren={<CheckOutlined/>}
                    unCheckedChildren={<CloseOutlined/>}/>
        </Form.Item>
        <Form.Item style={{paddingLeft: '24px'}} label='Sklep obciążony kodem rabatowym' name='isProductOwnerDiscountCost'
                   rules={[{required: true, message: 'Aktywność musi być oznaczona'}]}
                   valuePropName="checked">
            <Switch disabled={!isEditable}  checkedChildren={<CheckOutlined/>}
                    unCheckedChildren={<CloseOutlined/>}/>
        </Form.Item>
        <div style={{paddingBottom: '24px', paddingLeft: '24px', fontWeight: '400', color: 'grey'}}>
            Jeśli zaznaczono, to Sklep pokrywa różnicę w cenie przed promocją i po promocji.
        </div>
        <Form.Item style={{paddingLeft: '24px'}} label='Nazwa'
                   rules={[{required: true, message: 'Nazwa musi zostać uzupełniona!'}]} name={'name'}>
            <Input/>
        </Form.Item>
        <Form.Item style={{paddingLeft: '24px'}}
                   label='Minimalna wartość zamówienia, dla której ma zostać użyty kupon'
                   name={'minOrderValue'}
        >
            <Input addonAfter={'zł'}/>
        </Form.Item>
        <Form.Item style={{paddingLeft: '24px'}} label='Wewnętrzny opis kuponu' name={'description'}>
            <Input/>
        </Form.Item>
        <div style={{paddingBottom: '24px', paddingLeft: '24px', fontWeight: '400', color: 'grey'}}>Wewnętrzna notatka
            do rabatu widoczna tylko dla admin
        </div>
        <HeaderComponent>Przeznaczenie kuponu</HeaderComponent>
        <Form.Item name='discountProductOwnerType'
                   style={{display: 'flex', alignItems: 'center', paddingTop: '10px', paddingLeft: '24px'}}
                   label={'Sprzedawcy'}
                   rules={[{required: true, message: 'Wartość musi być wybrana'}]}
        >
            <Select
                style={{
                    paddingLeft: '24px', width: '250px'
                }}
                placeholder="Wybierz rodzaj"
                onChange={onChange}
                options={[
                    {
                        value: 'all',
                        label: 'Wszyscy sprzedawcy',
                    },
                    {
                        value: 'include',
                        label: 'Wybrani sprzedawcy',
                    },
                    {
                        value: 'exclude',
                        label: 'Wykluczeni sprzedawcy',
                    },
                ]}
            />
        </Form.Item>
        {
            poType !== 'all' ?
                <Form.Item
                    name="productOwnerIds"
                    label=""
                >
                    <Select
                        style={{paddingTop: '10px', paddingLeft: '24px', width: '100%'}}
                        mode="multiple"
                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                        placeholder="Wybierz sprzedawców">
                        {
                            productOwners.map((it) => <Option
                                key={it.id} value={it.id}>{it.name}</Option>)
                        }
                    </Select>
                </Form.Item>
                : null
        }
        <HeaderComponent>Limit wykorzystania </HeaderComponent>
        <Form.Item style={{paddingLeft: '24px', display: 'flex', alignItems: 'center', paddingTop: '10px'}}
                   label={'Harmonogram aktywności kuponu od-'} name='activeFrom'>
            <DatePicker showTime format="YYYY-MM-DD HH:mm:ss" style={{width: '300px'}} placeholder={'Data od'}/>
        </Form.Item>
        <Form.Item style={{paddingLeft: '24px', display: 'flex', alignItems: 'center'}}
                   label={'Harmonogram aktywności kuponu -do'} name='activeTo'>
            <DatePicker showTime format="YYYY-MM-DD HH:mm:ss" style={{width: '300px'}} placeholder={'Data do'}/>
        </Form.Item>
        <div style={{paddingBottom: '24px', paddingLeft: '24px', fontWeight: '400', color: 'grey'}}>Pozostawienie
            nieuzupełnionych pól oznacza brak czasowego ograniczenia kuponu.
        </div>
        <Form.Item style={{paddingLeft: '24px'}} label='Ograniczenie jednorazowego użycia przez jednego klienta'
                   name={'isSingleUse'}
                   valuePropName="checked"
                   rules={[{required: true, message: 'Pole musi zostać uzupełnione'}]}>
            <Switch checkedChildren={<CheckOutlined/>}
                    unCheckedChildren={<CloseOutlined/>}/>
        </Form.Item>
        <div style={{paddingBottom: '24px', paddingLeft: '24px', fontWeight: '400', color: 'grey'}}>Zaznaczenie powoduje
            możliwość wykorzystania tego kuponu tylko raz przez pojedynczego użytkownika. Wykorzystuje e-mail dla gości
            i zalogowanych użytkowników.
        </div>
        <Form.Item wrapperCol={{offset: 4, span: 12}}>
            <Button type="primary" htmlType="submit">
                Zapisz
            </Button>
        </Form.Item>
    </Form>
}

export default FormDiscountTest;
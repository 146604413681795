import axiosInstance from "../../lib/axios";

interface UpdatePCLButtonLinkProps {
    id: string
    buttonLink: string
}

const useUpdateProductCustomListButtonLink = () => {
    const update = async (obj: UpdatePCLButtonLinkProps): Promise<any> => {
        try {
            await axiosInstance.put("/admin/product_custom_list/button_link/" + obj.id, obj);
        } catch (err) {
            throw err;
        }
    };

    return { update };
}
export default useUpdateProductCustomListButtonLink;
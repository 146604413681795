import axiosInstance from "../../lib/axios";

interface UpdatePCLActivityProps {
    id: string
    isActive: boolean
}
const useUpdateProductCustomListActivity = () => {
    const update = async (obj: UpdatePCLActivityProps): Promise<any> => {
        try {
            await axiosInstance.put("/admin/product_custom_list/activity/" + obj.id, obj);
        } catch (err) {
            throw err;
        }
    };

    return { update };
}

export default useUpdateProductCustomListActivity;
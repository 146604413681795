import styled from "styled-components";
import React from "react";
import {themes} from "../../layout/styles/ColorStyles";

const Wrapper = styled.div`
  height: 20px;
  width: 90px;
  border-radius: 15px;
  background-color: ${(props) => {
    switch (props.status) {
      case 'moderate':
        return themes.status.returned;
      case 'deleted':
        return themes.status.canceled;
      case 'accepted':
        return themes.status.send;
      case 'waiting':
        return themes.status.submitted;
      case 'preready':
        return themes.status.preready;
      default:
        return themes.status.packaged;
    }
  }};
  display: flex;
  flex-direction: row;
  padding: 5px 15px 5px 15px;
  justify-content: space-between;
  align-items: center;
  color: #ffffff;
`;

const statusChoice = (status) => {
    switch (status) {
        case 'preready':
            return 'Preready'
        case 'moderate':
            return 'Moderacja';
        case 'deleted':
            return 'Do usunięcia';
        case 'accepted':
            return 'Gotowe'
        case 'waiting':
            return 'Oczekujący';
        default:
            return 'Brak statusu'
    }

}

const ProductStatusLabel = ({status}) => {

    return (
        <Wrapper status={status}>
            {statusChoice(status)}
        </Wrapper>
    );
}

export default ProductStatusLabel;
import React from "react";
import {Image, Switch, Table} from "antd";
import useUpdateLogoDelivery from "../../hooks/delivery/useUpdateLogoDelivery";
import LogoUploader from "../Uploader/LogoUploader";
import UpdateDelivery from "../Modals/Delivery/UpdateDelivery";
import useUpdateActivityDelivery from "../../hooks/delivery/useUpdateActivityDelivery";

const TableDelivery = ({data, reload}) => {

    const {update: updateLogo} = useUpdateLogoDelivery();
    const {update: updateActivity} = useUpdateActivityDelivery();

    const updateActive = (item, e) => {
        updateActivity(item.id, e).then(reload);
    };

    const columns = [
        {
            title: 'Aktywny',
            dataIndex: '',
            key: 'active',
            render: (row) => <Switch onClick={(e) => updateActive(row, e)} checked={row.isActive}/>,
            width: '10%'
        },
        {
            title: 'Logo',
            key: 'logo',
            dataIndex: '',
            width: '10%',
            render: (row) => <Image src={row.logo} style={{width: '40px', height: '40px', objectFit: 'contain'}}/>
        },
        {
            title: 'Nazwa',
            key: 'name',
            dataIndex: 'name',
            width: '10%',
        },
        {
            title: 'Opis',
            key: 'description',
            dataIndex: 'description'
        },
        {
            title: '',
            key: 'action',
            render: row => <>
                <UpdateDelivery
                    reload={reload}
                    item={row}
                />
                <LogoUploader
                    update={handleUpdateLogo}
                    item={row}
                    reload={reload}
                />
            </>
        },
    ];

    const handleUpdateLogo = (data, id) => {
        updateLogo(data, id).then(() => reload());
    };

    return <Table columns={columns} pagination={false} dataSource={data.map((it, index) => {
        return {
            ...it, key: index
        }
    })}/>
};

export default TableDelivery;
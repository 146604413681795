import {Formik, Form} from "formik";
import CustomError from "../CustomError";
import CustomSubmitButton from "../Buttons/CustomSubmitButton";
import CustomField from "../CutomField";
import styled from "styled-components";
import * as Yup from "yup";
import FormTitle from "../FormTitle";
import {Select, Switch} from "antd";
import React from "react";
import Editor from "../../HtmlEditor/Editor";
import useUpdateProductInfo from "../../../hooks/products/useUpdateProductInfo";
import {useParams} from "react-router-dom";

const {Option} = Select;
const LabelWrapper = styled.div`
  margin-bottom: 10px;
`;

const Label = styled.label`
  color: #344357;
`;

const FormWrapper = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
`;

const FormContent = styled(Form)`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 20px;
`;

const ItemWrapper = styled.div`
  position: relative;
  margin-bottom: ${(props) => (props.error ? "15px" : "0px")};
`;

const CreateProductSchema = Yup.object().shape({
    name: Yup.string().required("Nazwa jest wymagana"),
});

const Podstawowe = ({
                        data,
                        grammage,
                        reload
                    }) => {

    const [test, setTest] = React.useState(data.description);
    const {update} = useUpdateProductInfo();
    const params = useParams();
    const handleSubmit = (values) => {
        let data = {
            ...values,
            description: test,
        };
        update(data, params.id).then(() => reload());
    };

    return (
        <>
            <FormTitle title={"Podstawowe"}/>
            <FormWrapper>
                <Formik
                    initialValues={{
                        active: data.active,
                        name: data ? data.name : '',
                        grammage: data.grammage ? data.grammage.id : '',
                        description: data ? data.description : '',
                        metaKeywords: "",
                        metaDescription: "",
                    }}
                    onSubmit={handleSubmit}
                    validationSchema={CreateProductSchema}
                    validateOnChange={false}
                    validateOnBlur={false}
                >
                    {({values, errors, touched, setFieldValue}) => (
                        <FormContent>
                            <ItemWrapper error={errors.isActive}>
                                <LabelWrapper>
                                    <Label htmlFor={"isActive"}>Czy aktywny?</Label>
                                </LabelWrapper>
                                <Switch
                                    onChange={(value) => setFieldValue("active", value)}
                                    checked={values["active"]}
                                />
                            </ItemWrapper>
                            <ItemWrapper>
                                <LabelWrapper>
                                    <Label htmlFor={"grammageLabel"}>Gramatura:</Label>
                                </LabelWrapper>
                                <Select
                                    style={{
                                        borderRadius: '5px',
                                        border: '1px solid #a0a0a0',
                                        width: '100%',
                                    }}
                                    name="grammage"
                                    defaultValue={values.grammage ? values.grammage: ''}
                                    value={values.grammage}
                                    onChange={(value) => setFieldValue('grammage', value)}>
                                    {
                                        grammage.map((it) => <Option value={it.id} key={it.id}>{it.name},
                                            Skrót: {it.shortcut}</Option>)
                                    }
                                </Select>
                            </ItemWrapper>
                            <ItemWrapper error={errors.name}>
                                <CustomField
                                    name={"name"}
                                    placeholder={""}
                                    labelText={"Nazwa"}
                                />
                                {errors.name && touched.name ? (
                                    <CustomError error={errors.name}/>
                                ) : null}
                            </ItemWrapper>

                            <ItemWrapper error={errors.description}>
                                <LabelWrapper>Opis:</LabelWrapper>
                                <Editor value={test} setValue={setTest}/>
                                {/*<CustomField*/}
                                {/*    name={"description"}*/}
                                {/*    placeholder={""}*/}
                                {/*    labelText={"Opis"}*/}
                                {/*/>*/}
                                {/*{errors.description && touched.description ? (*/}
                                {/*    <CustomError error={errors.description}/>*/}
                                {/*) : null}*/}
                            </ItemWrapper>

                            <ItemWrapper error={errors.metaKeywords}>
                                <CustomField
                                    name={"metaKeywords"}
                                    placeholder={""}
                                    labelText={"metaKeywords"}
                                />
                                {errors.metaKeywords && touched.metaKeywords ? (
                                    <CustomError error={errors.metaKeywords}/>
                                ) : null}
                            </ItemWrapper>

                            <ItemWrapper error={errors.metaDescription}>
                                <CustomField
                                    name={"metaDescription"}
                                    placeholder={""}
                                    labelText={"metaDescription"}
                                />
                                {errors.metaDescription && touched.metaDescription ? (
                                    <CustomError error={errors.metaDescription}/>
                                ) : null}
                            </ItemWrapper>
                            <div
                                style={{
                                    marginTop: "58px",
                                    display: "flex",
                                }}
                            >
                                <CustomSubmitButton title="Zapisz"/>
                            </div>
                        </FormContent>
                    )}
                </Formik>
            </FormWrapper>
        </>
    );
};

export default Podstawowe;

import {Image, Modal, Table} from "antd";
import {useEffect, useState} from "react";
import useAssignPhotoVariantToProduct from "../../../hooks/products/useAssignPhotoVariantToProduct";
import useGetProductVariantPhotosByProductId from "../../../hooks/products/useGetProductVariantPhotosByProductId";
import FormIndicator from "../../Forms/FormIndicator";
import useUpdatePhotoProductPosition from "../../../hooks/products/useUpdatePhotoProductPosition";

const AssignProductVariantPhotoToProductModal = ({openModal, setOpenModal, product, reload}) => {

    const [selectedPhoto, setSelectedPhoto] = useState(null);
    const {update} = useAssignPhotoVariantToProduct();
    const {data, getPhotos, isLoading} = useGetProductVariantPhotosByProductId();
    const {updatePosition} = useUpdatePhotoProductPosition();

    useEffect(() => {
        getPhotos(product.id);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const handleOk = () => {
        if (selectedPhoto) {
            update({productId: product.id, photoId: selectedPhoto}).then(() => {
                updatePosition(product.id, selectedPhoto, 0).then(() => reload(product.id))
            });
        }
        setOpenModal(false)
    };

    const handleCancel = () => {
        setOpenModal(false);
    };

    const onChange = (val) => {
        setSelectedPhoto(val.photoId);
    }

    if (isLoading) return null;

    return <Modal title='Wybierz zdjęcie główne z gotowego wariantu produktu' open={openModal} onOk={handleOk}
                  onCancel={handleCancel}>
        {
            isLoading ? <FormIndicator numberOfElements={1}/> : <PhotoList photoList={data} onChange={onChange}/>
        }
    </Modal>
}

export default AssignProductVariantPhotoToProductModal;

const PhotoList = ({photoList = [], onChange}) => {

    const [photos, setPhotos] = useState([]);

    useEffect(() => {
        let t = photoList.map(photo => {
            return {
                ...photo,
                key: photo.photoId,
            }
        });

        setPhotos(t);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const columns = [
        {
            title: 'Zdjecie',
            dataIndex: 'fullPath',
            render: (it) => <Image src={it} width={100} height={100}/>
        },
    ];
    return <Table
        locale={{emptyText: 'Brak zdjęc przypisanych dla wariantów tego produktu. Lub brak przypisanych wariantów.'}}
        rowSelection={{
            type: 'radio',
            onSelect: onChange
        }}
        pagination={false}
        columns={columns}
        dataSource={photos}

    />
}
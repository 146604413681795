import {Select} from "antd";
import React, {useContext} from "react";

const MultiSelectFilter = ({brands, type, context, ids}) => {

    const {dispatch} = useContext(context);

    return <Select mode="multiple"
                   placeholder="Wybierz"
                   allowClear={true}
                   defaultValue={ids}
                   onChange={(e) => dispatch({type: type, payload: e})}
                   filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                   showSearch
    >
        {brands.map((it) => (
            <Select.Option key={it.id} value={it.id}>
                {it.name}
            </Select.Option>
        ))}
    </Select>
}

export default MultiSelectFilter;
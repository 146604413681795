import styled from "styled-components";
import Title from "../../components/Title";
import useCreateDiscount from "../../hooks/discount/useCreateDisocunt";
import FormDiscountTest from "../../components/Forms/Discount/FormDiscountTest";
import FormIndicator from "../../components/Forms/FormIndicator";
import useGetAllProductOwners from "../../hooks/productOwners/useGetAllProductOwners";
import dayjs from "dayjs";

const Wrapper = styled.div`
  position: relative;
`;

const NewDiscount = () => {
    const {create} = useCreateDiscount();
    const {data, isLoading} = useGetAllProductOwners();

    const handleOnFinish = async (data) => {

        create({
            ...data,
            activeFrom: data.activeFrom ? dayjs(data.activeFrom).format('YYYY-MM-DD HH:mm:ss') : null,
            activeTo: data.activeTo ? dayjs(data.activeTo).format('YYYY-MM-DD HH:mm:ss') : null

        });
    };
    return (
        <Wrapper>
            <Title title="Dodaj Promocję"/>
            {/*<Paper>*/}
            {isLoading ? <FormIndicator numberOfElements={3}/> :
                <FormDiscountTest productOwners={data} save={handleOnFinish}/>}
            {/*</Paper>*/}
        </Wrapper>
    );
};

export default NewDiscount;

import useAlert from "../useAlert";
import axiosInstance from "../../lib/axios";

interface UpdateBlogPostContentProps {
    id: string
    content: string
}

const useUpdateBlogPostContent = () => {

    const {successMessage, errorMessage} = useAlert();

    const update = async (obj: UpdateBlogPostContentProps): Promise<any> => {
        try {
            await axiosInstance.put("/admin/blog/post/content/" + obj.id, obj);
            successMessage("Strona została zaaktualizowana");
        } catch (err) {
            errorMessage("Wystąpił błąd podczas aktualizacji strony");
            throw err;
        }
    };

    return { update };


}

export default useUpdateBlogPostContent;
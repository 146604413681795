import {useEffect} from "react";

const useKeypress = (keysPressMain, keyOptional = null, callback) => {

    let keyPressed = {};

    const handleKeyPress = ((event) => {
        keyPressed[event.key] = true;
        if (keyOptional === null) {
            if (keysPressMain === event.key) {
                callback();
                return;
            }
        }

        if (keyPressed[keysPressMain] && event.key === keyOptional) {
            callback();
        }
    });

    useEffect(() => {
        // attach the event listener
        document.addEventListener('keydown', handleKeyPress);

        // remove the event listener
        return () => {
            document.removeEventListener('keydown', handleKeyPress);
            keyPressed = {};
        };
    }, [handleKeyPress]);

    return {};
}

export default useKeypress;
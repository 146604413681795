import { Drawer } from "antd";

const DrawerAkm = ({ title, children, open, onClose }) => {
  return (
    <Drawer
      title={title}
      placement="right"
      onClose={onClose}
      visible={open}
      width="auto"
    >
      {children}
    </Drawer>
  );
};

export default DrawerAkm;

import styled from "styled-components";
import CustomButton from "../../Forms/Buttons/CustomButton";
import CategorySelect from "../../Selects/CategorySelect";
import {useState} from "react";
import useUpdateProductCategories from "../../../hooks/category/useUpdateProductCategories";
import {useParams} from "react-router-dom";
import FormTitle from "../../Forms/FormTitle";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`;

const CategoryTreeAssigment = ({categories, selectedCategories, reload}) => {

    const [selected, setSelected] = useState(selectedCategories.map((category) => category.id));
    const { update } = useUpdateProductCategories();
    const params = useParams();

    const onSave = () => {
        update(selected, params.id).then(() => reload());
    }

    return <>
        <FormTitle title={"Kategorie"} />
        <Wrapper>
        <CategorySelect categories={categories} selectedCategories={selected} setCategories={setSelected}/>
    </Wrapper>
        <CustomButton title={'Zapisz'} onClick={() => onSave()}/>
    </>
}

export default CategoryTreeAssigment;
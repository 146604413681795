import {Space, Table, Typography} from "antd";
import {Link} from "react-router-dom";
import React from "react";
import AbandonedCartContactSelect from "../../Selects/AbandonedCartContactSelect";
import useUpdateCartStatus from "../../../hooks/cart/useUpdateCartStatus";

const TableAbandonedCart = ({data, reload}) => {

    const {update} = useUpdateCartStatus();

    const handleChange = (value, row) => {
        update({cartStatus: value, id: row.multiId}).then(reload);
    }

    const columns = [
        {
            title: 'Data ostatniej akutlizacji',
            dataIndex: 'lastUpdatedAt',
            key: 'updatedAt',
            width: "7%",
        },
        {
            title: 'Klient',
            dataIndex: '',
            key: 'user',
            render: (row) => row ? <div style={{display: 'flex', flexDirection: 'column'}}>
                <Typography>{row.userName}</Typography>
                <Typography>Email: {row.email}</Typography>
                <Typography>Tel: {row.mobilePhone}</Typography>
            </div> : 'Brak danych',
            width: "20%",
        },
        {
            title: 'Ponowny kontakt',
            dataIndex: '',
            key: '',
            width: '10%',
            render: row => <AbandonedCartContactSelect handleChange={(value) => handleChange(value, row)}
                                                       value={row.status ? row.status : 'no_action'}/>
        },
        {
            title: 'Ilość produktów',
            dataIndex: 'quantity',
            key: 'productCount',
        },
        {
            title: 'Kwota',
            dataIndex: 'totalSumCart',
            key: 'price',
            render: row => row + ' PLN'
        },
        {
            title: '',
            dataIndex: 'multiId',
            key: 'x',
            render: multiOrderId => <Space key={multiOrderId} size='middle'><Link
                key={multiOrderId + '1'}
                to={`/cart-details/${multiOrderId}`}
            >
                Szczegóły
            </Link>
            </Space>
        },

    ];

    return <Table dataSource={data.map((it, index) => {
        return {
            ...it,
            key: index
        }
    })} columns={columns} pagination={false}/>
}

export default TableAbandonedCart;


import styled from "styled-components";
import useGetAllPayments from "../../hooks/payments/useGetAllPayments";
import Title from "../../components/Title";
import TablePayment from "../../components/Tables/TablePayment";
import FormIndicator from "../../components/Forms/FormIndicator";

const Wrapper = styled.div`
  position: relative;
`;

const Payment = () => {
  // const [openModal, setOpenModal] = useState(false);
  const { data, isLoading, reload } = useGetAllPayments();

  return (
    <>
      <Wrapper>
        <Title title="Płatności" />
        {isLoading ? <FormIndicator numberOfElements={1} /> : <TablePayment reload={reload} data={data} />}
      </Wrapper>
    </>
  );
};

export default Payment;

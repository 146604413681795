import styled from "styled-components";
import {Button, Form, Input, Select, Switch, Typography} from "antd";
import React, {useState} from "react";
import useGetAllProductOwners from "../../../hooks/productOwners/useGetAllProductOwners";
import useGetAllVatRates from "../../../hooks/vatRates/useGetAllVatRates";
import useCreateVariantsProduct from "../../../hooks/products/useCreateVariantsProduct";
import {CheckOutlined, CloseOutlined} from "@ant-design/icons";
import {themes} from "../../../layout/styles/ColorStyles";
import useCalculatePrices from "../../../hooks/useCalculatePrices";
import {useParams} from "react-router-dom";

const Wrapper = styled.div`
  width: 400px;
`;

const FormContent = styled(Form)`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 20px;
`;

const AddNewVariant = ({onFinish, updateMode = false}) => {

    const [form] = Form.useForm();
    const [priceNet, setPriceNet] = useState();
    const [priceBrutto, setPriceBrutto] = useState();
    const [hasStock, setHasStock] = useState(false);
    const [vatRate, setVatRate] = useState(23);
    const [vatRateId, setVatRateId] = useState('vat_a');
    const {data: owners, isLoading: isLoadingOwners} = useGetAllProductOwners();
    const {data: dataVatRates, isLoading: isLoadingVatRates} = useGetAllVatRates();
    const {calculateBruttoPrice, calculateNettoPrice} = useCalculatePrices();
    const params = useParams();

    // const {update} = useUpdateVariantsProduct();
    const {create} = useCreateVariantsProduct();

    const onChangePriceBrutto = (values) => {
        setPriceBrutto(parseFloat(values));
        setPriceNet(calculateNettoPrice(values, vatRate));
    }

    const onChangePriceNet = (values) => {
        setPriceNet(parseFloat(values))
        setPriceBrutto(calculateBruttoPrice(values, vatRate));
    }

    const onTaxChange = (values, obj) => {
        setPriceBrutto(calculateBruttoPrice(priceNet, values));
        setVatRate(values);
        setVatRateId(obj.key);
    }


    if (isLoadingVatRates || isLoadingOwners) return null;

    const onFinishForm = (values) => {

        let priceVariants = [];
        priceVariants.push({
            priceNet: priceNet,
            priceGross: priceBrutto,
            vatRateId: vatRateId,
            currencyCode: 'PLN',
            isSale: false
        });

        const parseVariants = {
            name: values.name,
            productId: params.id,
            ean: values.ean,
            productOwnerCode: values.productOwnerCode,
            hasStock: values.hasStock,
            stock: values.stock ? parseInt(values.stock) : 0,
            priceVariants: priceVariants,
        };

        if (!updateMode) {
            create(parseVariants)
                .finally(() => onFinish());
        }

    }

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    return (
        <Wrapper>
            <FormContent
                form={form}
                layout="vertical"
                onFinish={onFinishForm}
                onFinishFailed={onFinishFailed}
            >
                <Form.Item
                    label="Nazwa"
                    name="name"
                    rules={[
                        {
                            required: true,
                            message: 'Podaj nazwę wariantu!',
                        },
                    ]}
                >
                    <Input/>
                </Form.Item>
                <Form.Item
                    label="EAN"
                    name="ean"
                    rules={[
                        {
                            required: true,
                            message: 'Podaj wartość EAN!',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label="Czy posiada stan magazynowy?"
                    name="hasStock"
                    valuePropName="checked"
                    initialValue={hasStock}
                >
                    <Switch
                        checkedChildren={<CheckOutlined/>}
                        unCheckedChildren={<CloseOutlined/>}
                        style={{
                            backgroundColor: hasStock
                                ? themes.activeMenuItemColor
                                : themes.switchInActive,
                        }}
                        onChange={(value) => setHasStock(value)}
                    />
                </Form.Item>
                {
                    hasStock && <Form.Item
                        label="Stan magazynowy"
                        name="stock"
                        rules={[
                            {
                                required: hasStock,
                                message: 'Podaj wartość stanów!',
                            },
                        ]}
                    >
                        <Input type={'number'}/>
                    </Form.Item>
                }
                <Form.Item
                    name="productOwnerCode"
                    label="Wybierz sklep"
                    rules={[
                        {
                            required: true,
                            message: "Wybierz sklep",
                        },
                    ]}
                >
                    <Select placeholder={"Wybierz"}
                            allowClear
                    >
                        {owners.map((it, index) => (<Select.Option key={index} value={it.code}>
                            {it.name}
                        </Select.Option>))
                        }

                    </Select>
                </Form.Item>
                <Typography.Text >Cena netto</Typography.Text>
                <Input type={'number'}
                       min={0}
                       step="0.01"
                       value={priceNet}
                       required={true}
                       onChange={(e) => onChangePriceNet(e.target.value)}/>

                <Typography.Text >Cena brutto</Typography.Text>
                <Input min={0}
                       step="0.01"
                       type={'number'}
                       required={true}
                       value={priceBrutto}
                       onChange={(e) => onChangePriceBrutto(e.target.value)}/>
                <Form.Item>

                    <Form.Item
                        initialValue={23}
                        name="varRateId"
                        label="Wybierz podatek"
                        rules={[
                            {
                                required: true,
                                message: "Wybierz podatek",
                            },
                        ]}
                    >
                        <Select placeholder={"Wybierz"}
                                onChange={(e, obj) => onTaxChange(e, obj)}
                                allowClear
                        >
                            {dataVatRates.map((it) => (<Select.Option key={it.id} value={it.value}>
                                {it.name} [{it.value} %]
                            </Select.Option>))
                            }

                        </Select>
                    </Form.Item>
                    <Button
                        type="primary"
                        htmlType="submit"
                        // onClick={onFinishForm}
                    >
                        Zapisz
                    </Button>
                </Form.Item>
            </FormContent>
        </Wrapper>
    );
}

export default AddNewVariant;
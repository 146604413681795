import ProductOwnerAddress from "../../../Forms/ProductOwner/ProductOwnerAddress";
import useCreateProductOwnerHeadquarterAddress
    from "../../../../hooks/productOwners/useCreateProductOwnerHeadquarterAddress";

const CreateProductOwnerHeadquarterAddress = ({openModal, handleClose, item, reload}) => {

    const {create} = useCreateProductOwnerHeadquarterAddress();

    const handleOk = (values) => {
        create({...values, id: item.id}).then(() => reload());
        handleClose();
    };

    return (
        <ProductOwnerAddress
            title={'Dodaj nowy adres siedziby firmy'}
            handleOk={handleOk}
            onCancel={handleClose}
            open={openModal}
        />
    );
}

export default CreateProductOwnerHeadquarterAddress;
import React, { useReducer } from "react";
import {
  initialState,
  ProductsFiltersReducer,
} from "../reducers/ProductsFiltersReducers";

const ProductsFiltersContext = React.createContext();

export const ProductsFiltersProvider = ({ children }) => {
  const [state, dispatch] = useReducer(ProductsFiltersReducer, initialState);

  return (
    <ProductsFiltersContext.Provider value={{ state, dispatch }}>
      {children}
    </ProductsFiltersContext.Provider>
  );
};

export default ProductsFiltersContext;

import { useState } from "react";
import axiosInstance from "../../lib/axios";

const useGetBrandAndCategoryDirectory = () => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [data, setData] = useState<{} | null>(null);
    const [categories, setCategories ] = useState<{} | null>(null);


    const getDirectory = async (): Promise<any> => {
        try {
            setIsLoading(true);
            const res = await axiosInstance.get(`/admin/product/filter`);


            if (res.status === 200) {
                setData(res.data ? res.data : []);
                let tmpCat  = res.data.categories.map((it: any) => {

                    return {
                        ...it,
                        value: it.hash,
                        title: it.name
                    }
                })

                setCategories(tmpCat);
            }

            setIsLoading(false);
        } catch (err) {
            throw err;
        }
    };

    return {isLoading, getDirectory, data, categories}
}

export default useGetBrandAndCategoryDirectory;
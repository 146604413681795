import React, { useState, useEffect } from "react";
import styled from "styled-components";
import CustomButton from "../../components/Forms/Buttons/CustomButton";
import Title from "../../components/Title";
import { FaPlus } from "react-icons/fa";
import DrawerAkm from "../../components/DrawerAkm";
import { useParams } from "react-router-dom";
import useGetProductFullInformation from "../../hooks/products/useGetProductFullInformation";
import ProductVariantTable from "../../components/Tables/ProductVariants/ProductVariantTable";
import AddNewVariant from "../../components/Forms/ProductVariants/AddNewVariant";

const Wrapper = styled.div`
  position: relative;
`;

const ManageVariants = () => {
  const [openDrawer, setOpenDrawer] = useState(false);
  const params = useParams();
  const { isLoading, getProductById, data, reload } =
    useGetProductFullInformation();
  const [selectedVariantId, setSelectedVariantId] = useState(null);

  useEffect(() => {
    getProductById(params.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (selectedVariantId) {
      setOpenDrawer(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedVariantId]);

  if (isLoading) return null;

  const onClose = () => {
    setOpenDrawer(false);
    setSelectedVariantId(null);
  };

  const onFinish = () => {
    onClose();
    reload(params.id);
  };

  return (
    <>
      <Wrapper>
        <Title title="Zarządzanie variantami" />
        <CustomButton
          title="Utwórz"
          icon={<FaPlus />}
          onClick={() => setOpenDrawer(true)}
        />
        <ProductVariantTable
          data={data.variants ? data.variants : []}
          setSelectedVariantId={setSelectedVariantId}
          onFinish={onFinish}
        />
      </Wrapper>
      <DrawerAkm
        title={"Utwórz"}
        open={openDrawer}
        onClose={onClose}
        updateMode={!!selectedVariantId}
      >
        <AddNewVariant onFinish={onFinish}/>
        {/*<Warianty*/}
        {/*  onFinish={onFinish}*/}
        {/*  updateMode={!!selectedVariantId}*/}
        {/*  variantToUpdate={*/}
        {/*    selectedVariantId*/}
        {/*      ? data.variants.filter((it) => it.id === selectedVariantId)[0]*/}
        {/*      : false*/}
        {/*  }*/}
        {/*/>*/}
      </DrawerAkm>
    </>
  );
};

export default ManageVariants;

import { useState } from "react";
import axiosInstance from "../../lib/axios";
import {createdAtFrom, createdAtTo} from "../../reducers/ProductsFiltersReducers";

const useGetProductsCollections = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [data, setData] = useState<{} | null>(null);

  const getProducts = async (state: any): Promise<any> => {
    try {

      let modifiedState = {
        ...state,
        createdAtFrom: state[createdAtFrom] ? state[createdAtFrom] : null,
        createdAtTo: state[createdAtTo] ? state[createdAtTo] : null
      }

      const res = await axiosInstance.get(`/admin/product/`, { params: modifiedState });

      if (res.status === 200) {
        setData(res.data ? res.data : []);
        setIsLoading(false);
      }
    } catch (err) {
      // window.location.replace('/admin/login');
      setIsLoading(false);
    }
  };

  const reload = (filters: any) => {
    setIsLoading(true);
    getProducts(filters);
  };

  return { isLoading, data, reload };
};

export default useGetProductsCollections;

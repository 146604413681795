import React from "react";
import { AuthProvider } from "./context/AuthContext";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import Wrapper from "./Wrapper";

import { createRoot } from 'react-dom/client';

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render( <React.StrictMode>
    <AuthProvider>
        <Wrapper />
    </AuthProvider>
</React.StrictMode>);

// reportWebVitals();

import styled from "styled-components";

const Dot = styled.div`
  height: 5px;
  width: 5px;
  background-color: ${(props) => {
        switch (props.value) {
            case 'submitted':
                return '#52C41A';
            case 'waiting':
                return '#1890FF';
            case 'deleted':
                return '#FF4D4F';
          default:
            return 'white';
        }
  }};
  border-radius: 50%;
  display: inline-block;
`;

export const ReviewStatusDot = ({status, value, onClick}) => {
    return <div style={{display: 'flex', alignItems: 'center', gap: '5px'}} onClick={onClick}><Dot value={status}/>{value}</div>
}
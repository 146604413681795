import useUpdateNameTag from "../../../hooks/tags/useUpdateNameTag";
import ButtonLink from "../../Buttons/ButtonLink";
import React, {useState} from "react";
import TagForm from "../../Forms/Tags/TagForm";

const UpdateTagModal = ({reload, item}) => {

    const {update} = useUpdateNameTag();
    const [updateOpenModal, setUpdateOpenModal] = useState(false);

    const handleSave = (obj) => {
        update(item.id, obj.name)
            .then(() => {
                reload();
                setUpdateOpenModal(false);
            });
    };

    return (
        <>
            <ButtonLink
                text={'Zmień nazwę'}
                onClick={() => setUpdateOpenModal(true)}
            />
            <TagForm name={item.name} open={updateOpenModal}
                            onCancel={() => setUpdateOpenModal(false)}
                            handleOK={handleSave}
                            title={'Edytuj nazwe'}/>
        </>
    );
}

export default UpdateTagModal;
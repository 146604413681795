import Title from "../../components/Title";
import CustomButton from "../../components/Forms/Buttons/CustomButton";
import {FaPlus} from "react-icons/fa";
import FormIndicator from "../../components/Forms/FormIndicator";
import styled from "styled-components";
import {useState} from "react";
import useGetAllBreeds from "../../hooks/breeds/useGetAllBreeds";
import CreteBreed from "../../components/Modals/Breed/CreteBreed";
import TableBreed from "../../components/Tables/Breed/TableBreed";
import useGetAllTags from "../../hooks/tags/useGetAllTags";

const Wrapper = styled.div`
  position: relative;
`;

const Breeds = () => {

    const {data, isLoading, reload} = useGetAllBreeds();
    const {data: tags, isLoading: isLoadTags} = useGetAllTags();
    const [openModal, setOpenModal] = useState(false);

    return (
        <>
            <Wrapper>
                <Title title="Rasy"/>
                <CustomButton
                    title="Utwórz"
                    icon={<FaPlus/>}
                    onClick={() => setOpenModal(true)}
                />
                {isLoading || isLoadTags ? <FormIndicator numberOfElements={1}/> :
                    <TableBreed data={data.map((it) => {
                            return {...it, key: it.id}
                        }
                    )} reload={reload} tags={tags}/>}
            </Wrapper>
            <CreteBreed
                open={openModal}
                setOpenModal={setOpenModal}
                reload={reload}
            />
        </>
    );
}

export default Breeds;
import {useState} from "react";
import axiosInstance from "../../../lib/axios";

interface CreateOrderSettlementInvoiceProps {
    productOwnerId: string,
    invoiceNumber: string,
    dateFrom: string,
    dateTo: string,
    payTo: string
}


const useCreateOrderSettlementInvoice = () => {
    const [isLoading, setisLoading] = useState<boolean>(false);

    const create = async (obj: CreateOrderSettlementInvoiceProps): Promise<any> => {
        try {
            setisLoading(true);
            await axiosInstance.post("/admin/b2b/settlement/invoice/manual", obj);
        } catch (err) {
            setisLoading(false);
            throw err;
        }
    };

    return { create, isLoading };

}

export default useCreateOrderSettlementInvoice;
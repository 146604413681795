import {useState} from "react";
import axiosInstance from "../../lib/axios";

const useGetProductBySearchQuery = () => {

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [data, setData] = useState<[] | null>([]);


    const getProductsBySearchQuery = async (filter: any): Promise<any> => {
        setIsLoading(true);
        try {
            const res = await axiosInstance.get(`/admin/product/search/`, {params: filter});

            if (res.status === 200) {
                setData(res.data ? res.data : []);
                setIsLoading(false);
            }

            return res.data;
        } catch (err) {
            setIsLoading(false);
        }
    };

    return {getProductsBySearchQuery, isLoading, data}

}

export default useGetProductBySearchQuery;


import {Select} from "antd";

const {Option} = Select;

const MultiSelect = ({values, selected, setSelected, setSelectedIds}) => {

    const handleChange = (value, option) => {
        let newArr = [];
        let newTest = [];
        option.map(it => newArr.push(it.key) && newTest.push(it.value));
        setSelected(newTest);
        setSelectedIds(newArr);
    }

    return <Select
        maxTagCount={'responsive'}
        style={{
            borderRadius: '5px',
            // border: '1px solid #a0a0a0',
            width: '100%',
            marginBottom: '10px'
        }}
        mode="multiple"
        onChange={handleChange}
        value={selected}
        allowClear
    >
        {
            values.map((it) => <Option
                 key={it.id} value={it.name}>{it.name}</Option>)
        }
    </Select>
}

export default MultiSelect;
import {useState} from "react";
import axiosInstance from "../../lib/axios";

const useGetProductCustomListResult = () => {


    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [data, setData] = useState<{} | null>(null);

    const getProductCustomListResult = async (sid: string): Promise<any> => {
        try {
            const res = await axiosInstance.get(`/product/product-custom-list/` + sid);

            if (res.status === 200) {
                setData(res.data ? res.data : []);
                setIsLoading(false);
            }
        } catch (err) {
            setIsLoading(false);
        }
    }

    return {isLoading, data, getProductCustomListResult};
}

export default useGetProductCustomListResult;
import { useState } from "react";
import axiosInstance from "../../lib/axios";

const useGetAllPhotosProduct = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [data, setData] = useState<{} | null>(null);

  const getProductPhotosById = async (id: string): Promise<any> => {
    try {
      setIsLoading(true);
      const res = await axiosInstance.get(`/admin/product/photo/${id}`);

      if (res.status === 200) {
        setData(res.data ? res.data : []);
      }
        setIsLoading(false);
    } catch (err) {
      throw err;
    }
  };

  const reload = (id: string) => {
    setIsLoading(true);
    getProductPhotosById(id);
  };

  return { isLoading, getProductPhotosById, data, reload };
};

export default useGetAllPhotosProduct;

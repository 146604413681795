import useAlert from "../useAlert";
import axiosInstance from "../../lib/axios";

type DeleteProductFromPVPros = {
    productGroupId: string
}

const useRemoveProductFromProductVariant = () => {
    const {successMessage, errorMessage} = useAlert();

    const remove = async (obj: DeleteProductFromPVPros): Promise<any> => {

        try{
            await axiosInstance.delete("/admin/product_group/product", {data: obj});
            successMessage("Product został usunięty z rekomendacji");
        }catch (err) {
            errorMessage('Błąd usuwania, spróbuj ponownie');
            throw err;
        }

    }

    return {remove};
}

export default useRemoveProductFromProductVariant;
import styled from "styled-components";
import {Form, Button} from "antd";
import React, {useContext} from "react";
import Paper from "../../../layout/Paper";
import {
    CLEAR_ALL,
    UPDATE_BRAND_IDS, UPDATE_PRODUCT_OWNER_ID,
    UPDATE_TEXT_SEARCH
} from "../../../reducers/ProductVariantsFiltersReducer";
import ProductVariantsFiltersContext from "../../../context/ProductVariantsFiltersContext";
import TextSearchInput from "../Products/TextSearchInput";
import MultiSelectFilter from "../Products/ProductFilters/MultiSelectFilter";
import ProductOwnerSelect from "./ProductOwnerSelect";
import HasProductGroupSelect from "./HasProductGroupSelect";
import OrderBySelect from "./OrderBySelect";

const Content = styled(Form)`
  display: grid;
  width: 100%;
  height: 100%;
  grid-template-columns: 25% 25% 25% 25%;
  column-gap: 5px;
`;

const FilterProductVariants = ({brands, productOwners, reload}) => {

    const {dispatch, state} = useContext(ProductVariantsFiltersContext);
    const [form] = Form.useForm();

    const handleOnFinish = () => {
        reload({...state, page: 1});
    }

    const handleOnReset = () => {
        form.resetFields();
        dispatch({type: CLEAR_ALL})
    }

    return <Paper>
        <Content name="control-hooks" form={form} onFinish={handleOnFinish} labelCol={{span: 24}}>
            <Form.Item label="Nazwa produktu, ID Produktu lub Ean" name="textSearch" >
                <TextSearchInput type={UPDATE_TEXT_SEARCH} context={ProductVariantsFiltersContext}/>
            </Form.Item>
            <Form.Item name="brandIds" label="Producent">
                <MultiSelectFilter brands={brands} context={ProductVariantsFiltersContext} type={UPDATE_BRAND_IDS}/>
            </Form.Item>
            <Form.Item label="Sklep" name="productOwnerId">
                <ProductOwnerSelect type={UPDATE_PRODUCT_OWNER_ID} context={ProductVariantsFiltersContext} productOwners={productOwners}/>
            </Form.Item>
            <Form.Item label="Czy produkt jest przypisany?" name="hasProductGroup">
                <HasProductGroupSelect />
            </Form.Item>
            <Form.Item label="Sortuj" name="orderBy">
                <OrderBySelect />
            </Form.Item>
            <Form.Item>

            </Form.Item>
            <Form.Item>

            </Form.Item>
            <Form.Item>

            </Form.Item>
            <Form.Item>
                <Button
                    style={{marginRight: "10px"}}
                    type="primary"
                    htmlType="submit"
                >
                    Zastosuj
                </Button>
                <Button htmlType="button" onClick={() => handleOnReset()}>
                    Wyczyść filtry
                </Button>
            </Form.Item>
        </Content>
    </Paper>
}
export default FilterProductVariants;
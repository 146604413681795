import {Checkbox, Image, Input, Table} from "antd";
import {UPDATE_PV_IDS_ARRAY} from "../../../reducers/ProductBasedOnProductVariantEditorReducers";
import {useContext, useState} from "react";
import ProductBasedOnProductVariantEditorContext from "../../../context/ProductBasedOnProductVariantEditorContext";
import {PlusOutlined} from "@ant-design/icons";
import CreateProductBasedOnProductVariantModal from "../../Modals/Products/CreateProductBasedOnProductVariantModal";
import ActivityLabel from "../../Labels/ActivityLabel";
import useUpdateProductGroupValueVariant from "../../../hooks/productGroups/useUpdateProductGroupValueVariant";
import {Link, NavLink} from "react-router-dom";
import styled from "styled-components";
import {themes} from "../../../layout/styles/ColorStyles";
import DeleteProductVariant from "./DeleteProductVariant";

export const ButtonFloating = styled.a`
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 40px;
  right: 40px;
  background-color: ${themes.logginButtonColorHover};
  color: #FFF;
  border-radius: 50px;
  text-align: center;
  box-shadow: 2px 2px 3px #999;
  cursor: pointer;
  opacity: 0.8;
`;

const ProductVariantTable = ({data, reload, brands}) => {

    const [openModal, setOpenModal] = useState(false);
    const {dispatch, state} = useContext(ProductBasedOnProductVariantEditorContext);
    const {update} = useUpdateProductGroupValueVariant();
    const [selectedProducts, setSelectedProducts] = useState([]);

    const onChange = (e, item) => {

        const index = selectedProducts.findIndex(object => object.id === item.id);

        if (index === -1) {
            setSelectedProducts([...selectedProducts, item]);
        } else {
            setSelectedProducts(selectedProducts.filter(object => object.id !== item.id));
        }

        dispatch({type: UPDATE_PV_IDS_ARRAY, payload: item.id})
    }

    const handleOpen = () => {
        setOpenModal(!openModal);
    }

    const handleOnBlur = (value, item) => {
        update({variantValue: value}, item.id);
    }

    const columns = [
        {
            title: '',
            dataIndex: '',
            key: '',
            render: (row) => !row.productGroup.name ? <Checkbox onChange={(e) => onChange(e, row)}/> : ''
        },
        {
            title: 'Zdjęcie',
            dataIndex: 'photo',
            key: 'photo',
            render: (row) =>
                <Image
                    style={{width: '50px', height: '50px', objectFit: 'contain'}}
                    src={row[0] ? row[0].fullPath : 'https://appet-prod-upload.s3.eu-central-1.amazonaws.com/static/placeholder-yellow.png'}
                    preview={!!row[0]}
                />
        },
        {
            title: 'Nazwa',
            dataIndex: '',
            key: 'name',
            render: row => <a rel="noreferrer" target="_blank" href={process.env.REACT_APP_MAIN + '/product/' + row.owner.code + '/' + row.slug}>{row.name}</a>
        },
        {
            title: 'Produkt główny',
            dataIndex: '',
            key: 'productGroup',
            render: row => (row.productGroup
                &&
                row.productId
            ) ?
                <Link target="_blank" rel="noopener noreferrer" to={'/product/update/' + row.productId}>{row.productGroup.name}</Link> : ''


        },
        {
            title: 'Ean',
            dataIndex: 'ean',
            key: 'ean',
            width: '10%',
            // render: (item) => <Input onBlur={(e) => handleOnChangeEan(e.target.value, item)} defaultValue={item.ean}/>
        },
        {
            title: 'Stan',
            dataIndex: 'stock',
            key: 'stock',
            align: 'center',
        },
        {
            title: 'Sklep',
            dataIndex: 'owner',
            key: 'owner',
            render: (row) => <div>{row.logo ? <Image preview={false} src={row.logo} style={{
                width: '60px',
                height: '60px',
                objectFit: 'contain'
            }}/> : row.name}</div>,
        },
        {
            title: 'Cena Brutto',
            dataIndex: '',
            key: 'price',
            render: row => row.price.length ? row.price[0].priceGross : 'brak ceny'
        },
        {
            title: 'Gramatura',
            dataIndex: 'grammage',
            key: 'grammage',
            align: 'center',
        },
        {
            title: 'Wartość',
            dataIndex: 'productGroup',
            key: 'variantValue',
            align: 'center',
            render: (item) => <Input
                defaultValue={item.variantValue ? item.variantValue.variantValue : ''}
                style={{width: '80px'}}
                type='text'
                onBlur={(value) => handleOnBlur(value.target.value, item)}/>
        },
        {
            title: 'Czy produkt przypisany?',
            dataIndex: 'productGroup',
            key: 'hasProductGroup',
            render: (row) => <ActivityLabel active={row.name}/>,
        },
        {
            title: 'Czy produkt aktywny?',
            dataIndex: 'active',
            key: 'active',
            render: (row) => <ActivityLabel active={row}/>,
        },
        {
            title: 'Akcja',
            dataIndex: '',
            key: '',
            render: (row) => <div style={{display: 'flex', gap: '5px', alignItems: 'center'}}><NavLink
                to={`/show/variant/${row.id}`}
                key={row.id}
                style={{textDecotration: "none !important"}}
            >
                Szczegóły
            </NavLink>
                <DeleteProductVariant row={row} reload={reload}/>

            </div>
        },
    ];

    return <><Table columns={columns} dataSource={data} pagination={false}/>
        {state.ids.length > 0 ? (<><ButtonFloating onClick={() => handleOpen()}>
            <PlusOutlined style={{marginTop: '18px', fontSize: '26px'}}/>
        </ButtonFloating>
            <CreateProductBasedOnProductVariantModal
                brands={brands}
                open={openModal}
                setOpen={setOpenModal}
                reload={reload}
                products={selectedProducts}
                setSelectedProducts={setSelectedProducts}/></>) : null}</>;
}

export default ProductVariantTable
import {Input} from "antd";
import {useContext} from "react";


const TextSearchInput = ({context, type}) => {

    const {dispatch, state} = useContext(context);

    return <Input defaultValue={state.textSearch}
                  onBlur={(e) => dispatch({type: type, payload: e.target.value})}/>
}

export default TextSearchInput;
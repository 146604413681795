import styled from "styled-components";
import {useState} from "react";
import Title from "../../components/Title";
import CustomButton from "../../components/Forms/Buttons/CustomButton";
import {FaPlus} from "react-icons/fa";
import FormIndicator from "../../components/Forms/FormIndicator";
import useGetAllBlogPosts from "../../hooks/blogPost/useGetAllBlogPosts";
import TableBlogPosts from "../../components/Tables/BlogPost/TableBlogPosts";
import CreateBlogPostModal from "../../components/Modals/Blog/CreateBlogPostModal";
import useGetAllBlogCategories from "../../hooks/blogCategories/useGetAllBlogCategories";

const Wrapper = styled.div`
  position: relative;
`;


const BlogPost = () => {

    const [openModal, setOpenModal] = useState(false);
    const { data, isLoading, reload } = useGetAllBlogPosts();
    const {data: blogCategories, isLoading: isLoadBlogCat } = useGetAllBlogCategories();

    if(isLoadBlogCat) return null;

    return (
        <>
            <Wrapper>
                <Title title="Posty z bloga" />
                <CustomButton
                    title="Utwórz"
                    icon={<FaPlus />}
                    onClick={() => setOpenModal(true)}
                />
                {isLoading ? <FormIndicator numberOfElements={1} /> : <TableBlogPosts data={data} reload={reload}/>}
            </Wrapper>

            <CreateBlogPostModal
                open={openModal}
                setOpenModal={setOpenModal}
                reload={reload}
                blogCategories={blogCategories}
            />
        </>
    );
}

export default BlogPost;
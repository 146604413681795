import {Switch, Table, Tag} from "antd";
import React, {useEffect, useState} from "react";
import UpdateIngredientGroup from "../../Modals/IngredientGroup/UpdateIngredientGroup";
import AssignIngredientToIngredientGroup from "../../Modals/IngredientGroup/AssignIngredientToIngredientGroup";
import AssignFlavourToIngredientGroup from "../../Modals/IngredientGroup/AssignFlavourToIngredientGroup";
import useUpdateIngredientGroupIsFlavour from "../../../hooks/ingredientGroup/useUpdateIngredientGroupIsFlavour";


const IngredientGroupTable = ({data, reload, ingredients, flavours}) => {

    const [ingredientsGroup, setIngredientsGroup] = useState([]);
    const {update: updateIsFlavour} = useUpdateIngredientGroupIsFlavour();

    useEffect(() => {
        let tmp = data.map((it) => {
            return {...it, key: it.id}
        });
        setIngredientsGroup(tmp)
// eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Czy ma być widoczny?',
            dataIndex: '',
            key: 'isShown',
            render: item => <Switch onClick={(val) => updateIsFlavour(item.id, val).then(() => reload())}
                                    checked={item.isShown}/>
        },
        {
            title: 'Przypisane składniki',
            key: 'tags',
            dataIndex: '',
            render: row => {
                return row.ingredients.map((it) => {
                    return <Tag key={it.id}>{it.name}</Tag>
                })
            },
            width: '60%'
        },
        {
            title: 'Przypisane smaki',
            key: 'tags',
            dataIndex: '',
            render: row => {
                return row.flavours.map((it) => {
                    return <Tag key={it.id}>{it.name}</Tag>
                })
            },
            width: '30%'
        },
        {
            title: '',
            key: 'action',
            dataIndex: '',
            render: (row) => (
                <div style={{flexDirection: 'row', display: 'flex', gap: '10px'}}>
                    <UpdateIngredientGroup reload={reload} item={row}/>
                    <AssignIngredientToIngredientGroup reload={reload} item={row} ingredients={ingredients}/>
                    <AssignFlavourToIngredientGroup reload={reload} item={row} flavours={flavours}/>
                </div>
            ),
        }
    ]
    return <Table dataSource={ingredientsGroup} columns={columns} pagination={false}/>
}

export default IngredientGroupTable;
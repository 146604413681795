import useCreateUser from "../../../hooks/users/useCreateUser";
import UserFormAnt from "../../Forms/Users/UserFormAnt";

const CreateUser = ({reload, setOpenModal, open}) => {

    const {create} = useCreateUser();

    const handleSave = (obj) => {
        create(obj)
            .then(() => {
                setOpenModal(false);
                reload();
            });
    };

    return <UserFormAnt
        open={open}
        onCancel={() => setOpenModal(false)}
        handleOK={handleSave}
        title={'Dodaj użytkownika'}
    />
}

export default CreateUser;
import useAlert from "../useAlert";
import axiosInstance from "../../lib/axios";

const useUpdateUserToDelete = () => {

    const { errorMessage, successMessage} = useAlert();
    const updateDel = async (id: string): Promise<any> => {
        try {
            await axiosInstance.put("/admin/users/set_user_delete/" +id);
            successMessage('Dane zmienione')

        } catch (err) {
            errorMessage("Błąd usuwania, spróbuj ponownie");
            throw err;
        }
    };

    return {updateDel};
}

export default useUpdateUserToDelete;
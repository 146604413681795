import styled from "styled-components";
import useGetProductVariantsCollection from "../../hooks/productVariants/useGetProductVariantsCollection";
import Title from "../../components/Title";
import FormIndicator from "../../components/Forms/FormIndicator";
import {useContext, useEffect} from "react";
import PaginationList from "../../components/PaginationList";
import ProductVariantsFiltersContext from "../../context/ProductVariantsFiltersContext";
import FilterProductVariants from "../../components/Forms/ProductVariants/FilterProductVariants";
import ProductVariantTable from "../../components/Tables/ProductVariants/ProductVariantTable";
import {CHANGE_PAGE_AND_LIMIT} from "../../reducers/ProductVariantsFiltersReducer";
import useGetProductsDirectoryFilters from "../../hooks/productVariants/useGetProductsDirectoryFilters";

const Wrapper = styled.div`
  position: relative;
`;

const ProductVariants = () => {

    const {isLoading: isLoadingFilters, data: filters, getDirectory} = useGetProductsDirectoryFilters();
    const {data, isLoading, reload} = useGetProductVariantsCollection();
    const {state} = useContext(ProductVariantsFiltersContext);

    const test = () => {
        reload(state);
        getDirectory();
    }

    useEffect(() => {
        window.scrollTo(0, 0);
        test();
        // return null;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.page, state.limit]);


    return <Wrapper>
        <Title title="Lista Wariantów Produktów"/>
        {(isLoading || isLoadingFilters)
            ? (
                <FormIndicator numberOfElements={3}/>
            ) : (
                <>
                    <FilterProductVariants
                    reload={reload}
                    brands={filters.brand}
                    productOwners={filters.productOwners}
                />
                    <PaginationList pagination={data.pagination} FiltersContext={ProductVariantsFiltersContext}
                                    type={CHANGE_PAGE_AND_LIMIT}/>
                    <ProductVariantTable data={data ? data.results && data.results : []} reload={test} brands={filters.brand}/>
                    <PaginationList pagination={data.pagination} FiltersContext={ProductVariantsFiltersContext}
                                    type={CHANGE_PAGE_AND_LIMIT}/>
                </>
            )
        }
    </Wrapper>
}

export default ProductVariants;
import {useContext, useState} from "react";
import axiosInstance from "../../lib/axios";
import ProductContext from "../../context/ProductContext";

const useGetProductFullInformation = () => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [data, setData] = useState<{} | null>(null);
    const {setProduct} = useContext(ProductContext);

    const getProductById = async (id: string, hasIndicator: boolean = true): Promise<any> => {
        try {
            setIsLoading(hasIndicator);
            const res = await axiosInstance.get(`/admin/product/show/${id}`);

            if (res.status === 200) {
                setData(res.data);
                setProduct(res.data);
                setIsLoading(false);
            }
        } catch (err) {
            throw err;
        }
    };

    const reload = async (id: string): Promise<any> => {
        try {
            const res = await axiosInstance.get(`/admin/product/show/${id}`);

            if (res.status === 200) {
                setData(res.data);
                setProduct(res.data);
            }
        } catch (err) {
            throw err;
        }
    };

    return {isLoading, getProductById, data, reload, setIsLoading};
};

export default useGetProductFullInformation;

import styled from "styled-components";
import Title from "../../components/Title";
import ReviewProductFilter from "../../components/Forms/Review/ReviewProductFilter/ReviewProductFilter";
import useGetCollectionOfReviewProduct from "../../hooks/review/useGetCollectionOfReviewProduct";
import FormIndicator from "../../components/Forms/FormIndicator";
import {useContext, useEffect} from "react";
import ReviewProductFiltersContext from "../../context/ReviewProductFiltersContext";
import TableReview from "../../components/Tables/Review/TableReview";
import PaginationList from "../../components/PaginationList";
import FilterCollapse from "../../components/Collaps/FilterCollapse";
import {CHANGE_PAGE_AND_LIMIT} from "../../reducers/ReviewProductFiltersReducer";

const Wrapper = styled.div`
  position: relative;
`;

const ReviewProduct = () => {


    const {data, isLoading, reload} = useGetCollectionOfReviewProduct();
    const {state} = useContext(ReviewProductFiltersContext);

    useEffect(() => {
        reload(state);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.page, state.limit, state.sortBy]);


    return <Wrapper><Title title={'Opinie dotyczące produktów'}/>

        <FilterCollapse children={<ReviewProductFilter reload={reload}/>}/>

        {isLoading ? <FormIndicator numberOfElements={1}/> : <>
            <PaginationList pagination={data.pagination} FiltersContext={ReviewProductFiltersContext}
                            type={CHANGE_PAGE_AND_LIMIT}/>
            <TableReview data={data.results} reload={() => reload(state)}/></>}
    </Wrapper>;
}

export default ReviewProduct;
import { useState, useRef, useEffect, useContext } from "react";
import styled from "styled-components";
import { FaUserAlt, FaShareSquare } from "react-icons/fa";
import { themes } from "../../layout/styles/ColorStyles";
import useAuth from "../../hooks/auth/useAuth";
import AuthContext from "../../context/AuthContext";

const UserProfileWrapper = styled.div`
  // position: absolute;
   right: 20px;
   height: 40px;
   width: 40px;
   border-radius: 50%;
   background-color: ${themes.activeMenuItemColor};
   display: flex;
   justify-content: center;
   align-items: center;
   color: #ffffff;
   :hover {
     cursor: pointer;
   }
`;

const TooltipWrapper = styled.div`
  position: absolute;
  top: 60px;
  right: 20px;
  width: 200px;
  height: auto;
  background-color: #ffffff;
  border: 1px solid #e5e9f2;
  border-radius: 10px;
  z-index: 1;
  visibility: ${(props) => (props.showTooltip ? "visible" : "hidden")};

  .email {
    background-color: #f5f5f5;
    width: 100%;
    height: 50px;
    border-bottom: 1px solid #e5e9f2;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const TooltipContent = styled.div`
  padding: 15px;
`;

const TooltipContentItem = styled.div`
  color: #4d5c78;
  .actions {
    display: flex;
    flex-direction: row;
    gap: 5px;
  }

  .icon {
    font-size: 20px;
  }

  .title {
    font-size: 15px;
  }

  :hover {
    color: ${themes.activeMenuItemColor};
    cursor: pointer;
  }
`;

const UserProfile = () => {
  const [showTooltip, setShowTooltip] = useState(false);
  const tooltipRef = useRef();
  const { logout } = useAuth();
  const { user } = useContext(AuthContext);

  const handleClick = (event) => {
    setShowTooltip(!showTooltip);
    event.preventDefault();
  };

  const handleClickOutside = (event) => {
    if (!tooltipRef.current.contains(event.target)) {
      setShowTooltip(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>
      <UserProfileWrapper onClick={(e) => handleClick(e)}>
        <FaUserAlt />
      </UserProfileWrapper>
      <div ref={tooltipRef}>
        <TooltipWrapper showTooltip={showTooltip}>
          <div className="email">{user.username}</div>
          <TooltipContent>
            <TooltipContentItem>
              <div onClick={logout} className="actions">
                <div className="icon">
                  <FaShareSquare />
                </div>
                <div className="title">Wyloguj</div>
              </div>
            </TooltipContentItem>
          </TooltipContent>
        </TooltipWrapper>
      </div>
    </>
  );
};

export default UserProfile;

import {useEffect, useState} from "react";
import axiosInstance from "../../lib/axios";


const useGetFilteredDiscounts = () => {

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [data, setData] = useState<{} | null>(null);

    useEffect(() => {
        getFilteredDiscounts();
    }, []);

    const getFilteredDiscounts = async (): Promise<any> =>{
        try{

            const res = await axiosInstance.get(`/admin/discount/`);
            if (res.status === 200) {
                setData(res.data ? res.data : []);
                setIsLoading(false);
            }
        }catch (err){
            console.log("err");
            setIsLoading(false);
        }
    }

    const reload = () => {
        getFilteredDiscounts();
    }

    return {isLoading, data, reload}
}

export default useGetFilteredDiscounts;
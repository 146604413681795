import {useState} from "react";
import useAlert from "../useAlert";
import axiosInstance from "../../lib/axios";

type CreateUserProps = {
    email: string;
    role: string;
    password: string;
    name: string,
    lastName: string;
    number: string;
};

const useCreateUser = () => {

    const [isLoading, setIsLoading] = useState(false);
    const { errorMessage, successMessage} = useAlert();

    const create = async (obj: CreateUserProps): Promise<any> => {
        try {
            setIsLoading(true);
            await axiosInstance.post("/admin/users/", obj);

            successMessage('Użytkownik został utworzony')
        } catch (err) {
            setIsLoading(false);
            errorMessage("Błąd zapisu, spróbuj ponownie");
            throw err;
        }
    };

    return {isLoading, create};
}

export default useCreateUser;
import useCacheClear from "../../hooks/main/useCacheClear";
import CustomButton from "../../components/Forms/Buttons/CustomButton";


const MainSettings = () => {

    const {deleteCache} = useCacheClear();
    const handleClick = () => {
        deleteCache();
    }
    return <div><CustomButton title={'Wyczyść cache'} onClick={() => handleClick()}/></div>;
}

export default MainSettings;
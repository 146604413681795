export const CHANGE_PAGE = "change_page";
export const CHANGE_LIMIT = "change_limit";
export const CHANGE_PAGE_AND_LIMIT = "change_page_and_limit";
export const CLEAR_ALL = 'clear_all';
export const UPDATE_HAS_PRODUCT_TO_NULL = 'update_has_product';
export const UPDATE_TEXT_SEARCH = 'update_text_search';
export const UPDATE_BRAND_IDS = 'update_brand_ids';
export const UPDATE_PRODUCT_HAS_GROUP = 'update_product_has_group';
export const UPDATE_PRODUCT_OWNER_ID = 'update_product_owner_id';
export const UPDATE_ORDER_BY = 'update_order_by';

export const limit = "limit";
export const page = "page";
export const textSearch = "textSearch";
export const brandIds = "brandIds";
export const productOwnerId = 'productOwnerId';
export const hasProductGroup = 'hasProductGroup';
export const orderBy = 'orderBy';

export const initialState = {
    [limit]: 30,
    [page]: 1,
    [textSearch]: '',
    [brandIds]: [],
    [productOwnerId]: '',
    [hasProductGroup]: null,
    [orderBy]: ''
};

type ACTIONTYPE =
    | { type: typeof CHANGE_PAGE; payload: number }
    | { type: typeof CHANGE_LIMIT; payload: number }
    | { type: typeof CHANGE_PAGE_AND_LIMIT; payload: { [page]: number; [limit]: number } }
    | { type: typeof CLEAR_ALL; payload: null }
    | { type: typeof UPDATE_HAS_PRODUCT_TO_NULL; payload: boolean }
    | { type: typeof UPDATE_BRAND_IDS; payload: string[] }
    | { type: typeof UPDATE_TEXT_SEARCH; payload: string }
    | { type: typeof UPDATE_PRODUCT_HAS_GROUP; payload: string }
    | { type: typeof UPDATE_PRODUCT_OWNER_ID; payload: string }
    | { type: typeof UPDATE_ORDER_BY; payload: string };

export const ProductVariantsFiltersReducer = (
    state: typeof initialState,
    action: ACTIONTYPE
) => {
    switch (action.type) {
        case CLEAR_ALL: {
            return initialState;
        }

        case CHANGE_PAGE:
            return {
                ...state,
                [page]: action.payload,
            };
        case CHANGE_LIMIT:
            return {
                ...state,
                [limit]: action.payload,
            };
        case CHANGE_PAGE_AND_LIMIT:
            return {
                ...state,
                [page]: action.payload[page],
                [limit]: action.payload[limit],
            };

        case UPDATE_PRODUCT_OWNER_ID:
            return {
                ...state,
                [productOwnerId]: action.payload,
            }

        case UPDATE_HAS_PRODUCT_TO_NULL:
            return {
                ...state,
                [hasProductGroup]: null
            }

        case UPDATE_BRAND_IDS:
            return {
                ...state,
                [brandIds]: action.payload,
            };

        case UPDATE_TEXT_SEARCH:
            return {
                ...state,
                [textSearch]: action.payload,
            }
        case UPDATE_PRODUCT_HAS_GROUP:
            return {
                ...state,
                [hasProductGroup]: action.payload,
            }
        case UPDATE_ORDER_BY:
            return {
                ...state,
                [orderBy]: action.payload,
            }
        default:
            return state;
    }
}
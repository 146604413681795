import styled from "styled-components";
import React, {useContext} from "react";
import OrderSettlementFilterContext from "../../../context/OrderSettlementFilterContext";
import {Button, ConfigProvider, DatePicker, Input, Select} from "antd";
import status from "../../../const/status";
import locale from "antd/locale/pl_PL";
import dayjs from "dayjs";
import OrderProductOwnerSelect from "../Orders/OrderProductOwnerSelect";
import CustomButton from "../Buttons/CustomButton";
import PaymentStatusLabel from "../../Labels/PaymentStatusLabel";
import {
    UPDATE_IS_BILL_PAID,
    UPDATE_TYPE_OF_ORDER_SETTLEMENT,
    CLEAR_ALL,
    UPDATE_DATE_FROM,
    UPDATE_DATE_TO,
    UPDATE_ORDER_NUMBER,
    UPDATE_ORDER_STATUS,
    UPDATE_PRICE_FROM,
    UPDATE_PRICE_TO, UPDATE_PRODUCT_OWNER_ID
} from "../../../reducers/OrderSettlementFilterReducers";

const Content = styled.div`
  display: grid;
  width: 99%;
  height: 100%;
  column-gap: 10px;
  grid-template-columns: 20% 19% 20% 20% 20%;
`;

const FormItem = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
`

const FilterForm = ({reload, productOwners, reloadSummary}) => {

    const {dispatch, state} = useContext(OrderSettlementFilterContext);

    const handleSubmit = () => {
        reload(state);
        reloadSummary(state);
    }

    const handleClear = () => {
        dispatch({type: CLEAR_ALL})
    }


    return <Content>
        <FormItem>
            <p>Numer zamówienia</p>
            <Input value={state.orderNumber}
                   allowClear
                   onChange={(e) => dispatch({type: UPDATE_ORDER_NUMBER, payload: e.target.value})}
            />
        </FormItem>
        <FormItem>
            <p>Cena od - do</p>
            <div
                style={{
                    display: "flex", flexDirection: "row",
                }}
            >
                <Input value={state.priceFrom} style={{width: "80%"}}
                       allowClear
                       onChange={(e) => dispatch({
                           type: UPDATE_PRICE_FROM, payload: e.target.value
                       })}/>
                <Input
                    style={{
                        width: "15%", borderLeft: 0, borderRight: 0, pointerEvents: "none",
                    }}
                    placeholder="~"
                    disabled
                />
                <Input value={state.priceTo} style={{width: "80%"}}
                       allowClear
                       onChange={(e) => dispatch({
                           type: UPDATE_PRICE_TO, payload: e.target.value
                       })}/>
            </div>
        </FormItem>
        <FormItem>
            <p>Status zamówienia</p>
            <Select
                placeholder="Wybierz status"
                value={state.status}
                onChange={(e) => dispatch({type: UPDATE_ORDER_STATUS, payload: e})}
                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                showSearch
            >
                {status.map((it) => (<Select.Option key={it.value} value={it.value}>
                    {it.label}
                </Select.Option>))}
                <Select.Option key={0} value="">Wszystkie</Select.Option>
            </Select>
        </FormItem>
        <ConfigProvider locale={locale}>
            <FormItem>
                <p>Data utworzenia od</p>
                <DatePicker
                    allowClear={false}
                    style={{width: '100%'}}
                    placeholder={'Data utworzenia od'}
                    value={state.createdAtFrom ? dayjs(state.createdAtFrom, 'YYYY-MM-DD') : null}
                    onChange={(date, dateString) => dispatch({
                        type: UPDATE_DATE_FROM, payload: dateString + ' 00:00:00'
                    })}
                />
            </FormItem>
            <FormItem>
                <p>Data utworzenia do</p>
                <DatePicker allowClear={false} style={{width: '100%'}} placeholder={'Data utworzenia do'}
                            value={state.createdAtTo ? dayjs(state.createdAtTo, 'YYYY-MM-DD') : null}
                            onChange={(date, dateString) => dispatch({
                                type: UPDATE_DATE_TO, payload: dateString + ' 23:59:59'
                            })}
                />
            </FormItem>
        </ConfigProvider>
        <FormItem>
            <p>Sklep</p>
            <OrderProductOwnerSelect productOwners={productOwners} context={OrderSettlementFilterContext} action={UPDATE_PRODUCT_OWNER_ID}/>
        </FormItem>
        <FormItem>
            <p>
                Rozliczenie
            </p>
            <Select mode="single"
                    placeholder="Rozliczenie"
                    value={state.isBillPaid}
                    onChange={(e) => dispatch({type: UPDATE_IS_BILL_PAID, payload: e})}
                    onClear={() => dispatch({type: UPDATE_IS_BILL_PAID, payload: null})}
                    allowClear={true}
            >
                <Select.Option key={1} value={1}>
                    <PaymentStatusLabel paid={1}/>
                </Select.Option>
                <Select.Option key={2} value={0}>
                    <PaymentStatusLabel paid={0}/>
                </Select.Option>
            </Select>
        </FormItem>
        <FormItem>
            <p>
                Rodzaj transakcji
            </p>
            <Select mode="single"
                    placeholder="Rodzaj transakcji"
                    value={state.typeOfOrderSettlement}
                    onChange={(e) => dispatch({type: UPDATE_TYPE_OF_ORDER_SETTLEMENT, payload: e})}
                    onClear={() => dispatch({type: UPDATE_TYPE_OF_ORDER_SETTLEMENT, payload: null})}
                    allowClear={true}
            >
                <Select.Option key={1} value={'refund'}>
                    Zwrot kosztów
                </Select.Option>
                <Select.Option key={2} value={'return'}>
                    Zwrot produktów
                </Select.Option>
                <Select.Option key={3} value={'sell'}>
                    Sprzedaż
                </Select.Option>
            </Select>
        </FormItem>
        <div style={{display: 'flex', gap: '10px', alignItems: 'flex-end', justifyContent: 'flex-end'}}>
            <CustomButton title={'Zastosuj'} onClick={() => handleSubmit()}/>
            <Button style={{borderRadius: '10px'}} onClick={() => handleClear()}>Wyczyść</Button>
        </div>
    </Content>
}

export default FilterForm;
import useUpdateActivityBrand from "../../hooks/brands/useUpdateActivityBrand";
import LogoDelivery from "../Image/LogoDelivery";
import {Switch, Table} from "antd";
import React, {useState} from "react";
import useUpdateLogoBrand from "../../hooks/brands/useUpdateLogoBrand";
import UpdateBrandModal from "../Modals/Brands/UpdateBrandModal";
import LogoUploader from "../Uploader/LogoUploader";

const TableBrandsTest = ({data, reload}) => {

    const [openModal, setOpenModal] = useState(false);

    const {updateLogo} = useUpdateLogoBrand();
    const {update} = useUpdateActivityBrand();

    const handleUpdateLogo = (data, id) => {
        updateLogo(data, id).then(() => reload());
    };

    const handleClose = () => {
        setOpenModal(false);
    };

    const updateActivity = (item, val) => {
        update(item.id, val).then(() => reload());
    };

    const columns = [
        {
            title: 'Logo',
            dataIndex: 'logo',
            key: 'logo',
            render: (row) => <LogoDelivery src={row}/>
        },
        {
            title: 'Nazwa',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Aktywny',
            dataIndex: '',
            key: 'active',
            render: (row) => <Switch onClick={(e) => updateActivity(row, e)} checked={row.active}/>
        },

        {
            title: '',
            dataIndex: '',
            key: '',
            render: row => <div style={{flexDirection: 'row', display: 'flex', gap: '10px'}}>
                <UpdateBrandModal
                    reload={reload}
                    item={row}
                    openModal={openModal}
                    handleClose={handleClose}
                />
                <LogoUploader
                    update={handleUpdateLogo}
                    item={row}
                    reload={reload}
                />
            </div>
        },
    ];


    return <>
        <Table pagination={false} columns={columns} dataSource={data.map((it) => {
            return {
                ...it,
                key: it.id
            }
        })}/>
    </>
}

export default TableBrandsTest;
import React, {useState} from "react";
import UpdateTagModal from "../Modals/Tags/UpdateTagModal";
import {Table} from "antd";

const TableTags = ({data, reload}) => {

    const [openModal, setOpenModal] = useState(false);

    const columns = [
        {
            title: 'Nazwa',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: '',
            dataIndex: '',
            key: '',
            render: row => <div style={{flexDirection: 'row', display: 'flex', gap: '10px' }}>
                <UpdateTagModal
                    reload={reload}
                    item={row}
                    handleClose={handleClose}
                    openModal={openModal}
                />
            </div>
        },
    ];

    const handleClose = () => {
        setOpenModal(false);
    };

    return <Table dataSource={data.map((it) => {
        return{
            ...it,
            key: it.id
        }
    })} pagination={false} columns={columns}/>

};

export default TableTags;
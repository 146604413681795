import {Form, Input, Modal} from "antd";

const UpdatePasswordForm = ({handleOK, onCancel, open, name = null, title}) => {


    const [form] = Form.useForm();

    return (
        <Modal title={title}
               visible={open}
               onOk={() => {
                   form
                       .validateFields()
                       .then((values) => {
                           form.resetFields();
                           handleOK(values);
                       })
                       .catch((info) => {
                           console.log("Validate Failed:", info);
                       });
               }}
               onCancel={onCancel}
               okText={"Zapisz"}
               cancelText={"Anuluj"}>
            <Form
                layout="vertical"
                form={form}
                name="form_in_modal"
                initialValues={{
                    name: name ? name : '',
                }}
            >
                <Form.Item
                    name="password"
                    label="Hasło"
                    rules={[
                        {
                            required: true,
                            message: 'Wprowadź hasło!',
                        },
                    ]}
                    hasFeedback
                >
                    <Input.Password />
                </Form.Item>

                <Form.Item
                    name="confirm"
                    label="Potwierdź hasło"
                    dependencies={['password']}
                    hasFeedback
                    rules={[
                        {
                            required: true,
                            message: 'Potiwerdź hasło!',
                        },
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                                if (!value || getFieldValue('password') === value) {
                                    return Promise.resolve();
                                }
                                return Promise.reject(new Error('Hasła nie są takie same!'));
                            },
                        }),
                    ]}
                >
                    <Input.Password />
                </Form.Item>
            </Form>
        </Modal>
    );

}

export default UpdatePasswordForm;
import {useState} from "react";
import useAlert from "../../useAlert";
import axiosInstance from "../../../lib/axios";

interface RegisterProductOwnerPrzelewy24Props {
    id: string;
    business_type: string;
    name: string;
    phone_number: string;
    bank_account: string;
    invoice_email: string;
    shop_url: string;
    trade: string;
    nip: string;
    regon: string;
    contact_person_name: string;
    contact_person_email: string;
    contact_person_phone_number: string;
    technical_contact_name: string;
    technical_contact_email: string;
    technical_contact_phone_number: string;
    address_city: string;
    address_post_code: string;
    address_street: string;
    correspondence_address_city: string;
    correspondence_address_street: string;
    correspondence_address_post_code: string;
    pesel?: string;
    services_description?: string;
    krs?: string;
}

const useRegisterPOPrzelewy24 = () => {
    const [isLoading, setisLoading] = useState<boolean>(false);
    const [data, setData] = useState('');
    const { successMessage, errorMessage } = useAlert();

    const create = async (
        obj: RegisterProductOwnerPrzelewy24Props
    ): Promise<any> => {
        try {
            setisLoading(true);
            const res = await axiosInstance.post("/admin/product_owner/p24/" + obj.id, obj);
            if (res.status === 200) {
                setData(res.data ? res.data : '');
                setisLoading(false);

                successMessage("Pomyślnie zarejestrowano");
            }
        } catch (err) {
            setisLoading(false);
            errorMessage("Błąd zapisu. Spróbuj ponownie.");
            throw err;
        }
    };

    return { create, isLoading, data };
}

export default useRegisterPOPrzelewy24;
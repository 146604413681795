import {Form, Input, Modal, Select} from "antd";

const {Option} = Select;

const BreedForm = ({handleOK, onCancel, open, item = null, title}) => {
    const [form] = Form.useForm();

    return (
        <Modal title={title}
               open={open}
               onOk={() => {
                   form
                       .validateFields()
                       .then((values) => {
                           form.resetFields();
                           handleOK(values);
                       })
                       .catch((info) => {
                           console.log("Validate Failed:", info);
                       });
               }}
               onCancel={onCancel}
               okText={!item ? "Dodaj nową rasę" : "Zapisz"}
               cancelText={"Anuluj"}>
            <Form
                layout="vertical"
                form={form}
                name="form_in_modal"
                initialValues={{
                    name: item ? item.name : '',
                }}
            >
                <Form.Item
                    label="Nazwa"
                    name="name"
                    rules={[
                        {required: true, message: "Wpisz nazwę, pole jest wymagane!"},
                    ]}
                >
                    <Input placeholder="Wpisz nazwę "/>
                </Form.Item>
                <Form.Item
                    initialValue={item ? item.type : null}
                    name="type"
                    label="Typ"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Select
                        placeholder="Wybierz typ"
                        allowClear
                    >
                        <Option value="CAT">Kot</Option>
                        <Option value="DOG">PIES</Option>
                    </Select>
                </Form.Item>
            </Form>
        </Modal>
    );
}

export default BreedForm;
import {useState} from "react";
import {createdAtFrom, createdAtTo} from "../../reducers/ProductsFiltersReducers";
import axiosInstance from "../../lib/axios";

const useGetCollectionOfReviewProduct = () => {

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [data, setData] = useState<{} | null>(null);


    const getReviewProduct = async (state: any): Promise<any> => {
        try {

            let modifiedState = {
                ...state,
                createdAtFrom: state[createdAtFrom] ? state[createdAtFrom] : null,
                createdAtTo: state[createdAtTo] ? state[createdAtTo] : null
            }

            const res = await axiosInstance.get(`/admin/review_product/collection/full/`, { params: modifiedState });

            if (res.status === 200) {
                setData(res.data ? res.data : []);
                setIsLoading(false);
            }
        } catch (err) {
            setIsLoading(false);
        }
    };

    const reload = (filters: any) => {
        setIsLoading(true);
        getReviewProduct(filters);
    };

    return { isLoading, data, reload };
}

export default useGetCollectionOfReviewProduct;
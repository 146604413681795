import Paper from "../../layout/Paper";
import CreateProductCustomItemForm from "../Forms/ProductCustomList/CreateProductCustomItemForm";
import ProductCustomListItemTable from "../Tables/ProductCustomList/ProductCustomListItemTable";
import useAddProductCustomItem from "../../hooks/productCustomList/useAddProductCustomItem";
import {Link, useParams} from "react-router-dom";
import {Typography} from "antd";
import {FilterOutlined} from "@ant-design/icons";
import useRemoveProductCustomItem from "../../hooks/productCustomList/useRemoveProductCustomItem";
import useUpdateProductCustomItemActivity from "../../hooks/productCustomList/useUpdateProductCustomItemActivity";


const ProductCustomListItem = ({brands, categories, flavours, tags, data, reload}) => {

    console.log(data);

    const {add} = useAddProductCustomItem();
    const {removeProductCustomItem} = useRemoveProductCustomItem();
    const {update} = useUpdateProductCustomItemActivity();

    const params = useParams();

    const handleOk = (values) => {
        add(values, params.id).then(() => reload());
    }

    const handleDelete = (values) => {
        removeProductCustomItem(values.id).then(() => reload());
    }

    const handleActive = (values, e) => {
        update({isActive: e, productCustomItemId: values.id}).then(() => reload());
    }

    return <Paper>
        <div><FilterOutlined/> WYBIERZ PRODUKTY</div>
        <CreateProductCustomItemForm brands={brands}
                                     categories={categories}
                                     flavours={flavours}
                                     tags={tags}
                                     handleOk={handleOk}/>

        <Typography.Title level={3}>Dodane produkty: ({data.totalItems})</Typography.Title> <Link to={'/custom-product-list/products/' + data.productCustomList.sid}>Zobacz produkty</Link>
        <ProductCustomListItemTable data={data.productCustomList.productCustomListItems}
                                    brands={brands}
                                    categories={categories}
                                    flavours={flavours}
                                    tags={tags}
                                    handleActive={handleActive}
                                    handleDelete={handleDelete}
        />
    </Paper>
}


export default ProductCustomListItem;
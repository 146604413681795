import useCreateDelivery from "../../../hooks/delivery/useCreateDelivery";
import React from "react";
import DeliveryForm from "../../Forms/Delivery/DeliveryForm";

const CreateDelivery = ({ reload, setOpenModal, open }) => {

    const {create} = useCreateDelivery();

    const handleSave = (obj) => {
        create(obj)
            .then(() => {
                setOpenModal(false);
                reload();
            });
    };

    return (
        <DeliveryForm open={open} onCancel={() => setOpenModal(false)}
                   handleOK={handleSave}
                   title={'Dodaj dostawę'}/>
    );
}

export default CreateDelivery;
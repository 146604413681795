import React, { useState, useContext, useEffect } from "react";
import styled from "styled-components";
import { FaChevronRight, FaEdit, FaListAlt, FaPlusSquare } from "react-icons/fa";
import PositionButton from "../Buttons/PositionButton";
import {
  CustomIcon,
  CustomIconWrapper,
  ItemTooltipWrapper,
} from "../../../layout/styles/TableStyles";
import CategoryContext from "../../../context/CategoryContext";
import useUpdatePositionCategory from "../../../hooks/category/useUpdatePositionCategory";
import useAlert from "../../../hooks/useAlert";
import TooltipActions from "../../Tables/TooltipActions";
import CreateCategoryModal from "../../Modals/Category/CreateCategoryModal";
import useUpdateNameCategory from "../../../hooks/category/useUpdateNameCategory";
import useCreateCategory from "../../../hooks/category/useCreateCategory";
import {NavLink} from "react-router-dom";
import {Tag} from "antd";

const Wrapper = styled.div`
  position: relative;
  margin-top: 10px;
`;

const CategoryWrapper = styled.div`
  display: flex;
  flex-direction: column;
  /* gap: 1px; */
`;

const CategoryItemWrapper = styled.div`
  position: relative;
  font-size: 16px;
  background-color: #ffffff;
  height: 50px;
  border-radius: 5px;
  padding-left: ${(props) =>
    props.level > 0 ? `calc(${props.level} * 30px + 15px)` : "15px"};
  border-bottom: 1px solid #c9ccd4;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #526484;
  transition: 0.1s ease-in;
  gap: 5px;

  :hover {
    cursor: pointer;
  }
`;

const CategoryItemAction = styled.div`
  position: absolute;

  right: 15px;
  display: flex;
  flex-direction: row;
  gap: 10px;
`;

const Icon = styled(FaChevronRight)`
  font-size: 12px;
  color: #c0c5cf;
  transition: 0.2s ease-in;
  transform: ${(props) => (props.open ? "rotate(90deg)" : null)};
`;

const CategoryTreeManager = ({
  categories,
  setOpenModal,
  setHelper,
  reload,
}) => {
  const handleAdd = (parentId, name) => {
    setHelper({ parentId: parentId, title: `Dodaj podkategorie do ${name}` });
    setOpenModal();
  };

  return (
    <Wrapper>
      <CategoryWrapper>
        {categories
          .filter((it) => it.parentId === null && it.active)
          .sort((a, b) => a.position - b.position)
          .map((it, index) => (
            <CategoryItem
              lengthData={
                categories.filter((it) => it.parentId === null && it.active)
                  .length
              }
              level={0}
              key={index}
              category={it}
              children={categories.filter((el) => el.parentId === it.id)}
              data={categories}
              handleAdd={handleAdd}
              reload={reload}
            />
          ))}
      </CategoryWrapper>
    </Wrapper>
  );
};

export default CategoryTreeManager;

const CategoryItem = ({
  lengthData,
  level,
  category,
  children,
  data,
  handleAdd,
  reload,
}) => {
  const [open, setOpen] = useState(false);
  const [actualLevel] = useState(level + 1);
  const { categories, setCategories } = useContext(CategoryContext);
  const { updatePosition } = useUpdatePositionCategory();
  const { successMessage } = useAlert();

  useEffect(() => {
    setOpen(categories.opened.includes(category.id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOpen = () => {
    const exist = categories.opened.includes(category.id);
    let newArr = [...categories.opened];

    if (!exist) {
      newArr.push(category.id);
      setCategories({
        ...categories,
        opened: newArr,
      });
    } else {
      setCategories({
        ...categories,
        opened: newArr.filter((it) => it !== category.id),
      });
    }
    setOpen(!open);
  };

  const handleChangePosition = (newPosition) => {
    updatePosition(category.id, newPosition).then(() => {
      successMessage("Pozycja kategori zaaktualizowana pomyślnie");
      reload();
    });
  };

  return (
    <CategoryWrapper>
      <CategoryItemWrapper onClick={handleOpen} level={level}>
        {
          children.length ? (<Icon open={open && children.length} />) : null
        }
        {category.name} {category.food ? <Tag>Karma</Tag> : <Tag>Akcesoria</Tag>}
        Slug: {category.slug}
        <CategoryItemAction>

          <PositionButton
            direction="up"
            onClick={() => handleChangePosition(category.position - 1)}
            active={category.position !== 0}
          />
          <PositionButton
            direction="down"
            onClick={() => handleChangePosition(category.position + 1)}
            active={lengthData - 1 !== category.position}
          />
          <div style={{ marginLeft: "40px" }}>
            <ActionsCategory
              name={category.name}
              id={category.id}
              reload={reload}
            />
          </div>
        </CategoryItemAction>
      </CategoryItemWrapper>
      <div>
        {open ? (
          <>
            {children
              .sort((a, b) => a.position - b.position)
              .map((it, index) => (
                <div key={index + level}>
                  <CategoryItem
                    lengthData={children.length}
                    level={actualLevel}
                    category={it}
                    children={data.filter(
                      (el) => el.parentId === it.id && it.active
                    )}
                    data={data}
                    handleAdd={handleAdd}
                    reload={reload}
                  />
                </div>
              ))}
          </>
        ) : null}
      </div>
    </CategoryWrapper>
  );
};

const ActionsCategory = ({ id, name, reload}) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openModalNextLevelCategory, setOpenModalNextLevelCategory] = useState(false);
  const { updateName } = useUpdateNameCategory();
  const {create}= useCreateCategory();
  const { successMessage } = useAlert();

  const handleClick = (event) => {
    setShowTooltip(!showTooltip);
    event.stopPropagation();
  };

  const handleClose = () => {
    setOpenModal(false);
    setOpenModalNextLevelCategory(false);
  };

  const onCreate = (obj) => {

    create({parentId: id, name: obj.name}).then(() => {
      successMessage("Nazwa kategori zaaktualizowana pomyślnie");
      reload();
    })
        .finally(() => {
          handleClose();
        });

  }

  const onEdit = (obj) => {
    updateName(id, obj.name)
      .then(() => {
        successMessage("Dodano podkategorię");
        reload();
      })
      .finally(() => {
    handleClose();
      });
  };

  return (
    <>
      <CustomIconWrapper onClick={(e) => handleClick(e)}>
        <CustomIcon>
          <FaListAlt />
        </CustomIcon>
        <TooltipActions
          showTooltip={showTooltip && !openModal}
          setShowTooltip={setShowTooltip}
        >
          <ItemTooltipWrapper onClick={() => setOpenModalNextLevelCategory(true)}>
            <div>
              <FaPlusSquare />
            </div>
            <div>Dodaj Podkategorię</div>
          </ItemTooltipWrapper>
          <ItemTooltipWrapper
            onClick={(e) => {
              setOpenModal(true);
              e.stopPropagation();
              e.preventDefault();
            }}
          >
            <div>
              <FaEdit />
            </div>
            <div>Edytuj</div>
          </ItemTooltipWrapper>
          <NavLink
              to={"/category/" + id}
              key={id}
              style={{ textDecotration: "none" }}
          >
          <ItemTooltipWrapper onClick={() => setOpenModalNextLevelCategory(true)}>
            <div>
              <FaPlusSquare />
            </div>
            <div>Edytuj opisy</div>
          </ItemTooltipWrapper>
          </NavLink>
          {/*<ItemTooltipWrapper onClick={() => setOpenModalDelete(true)}>*/}
          {/*  <div>*/}
          {/*    <FaTrash />*/}
          {/*  </div>*/}
          {/*  <div>Usuń</div>*/}
          {/*</ItemTooltipWrapper>*/}

        </TooltipActions>
      </CustomIconWrapper>
      <CreateCategoryModal
          open={openModalNextLevelCategory}
          title={`Dodaj podkategorię`}
          handleSave={onCreate}
          handleClose={()=>setOpenModalNextLevelCategory(false)}
      />
      <CreateCategoryModal
        open={openModal}
        name={name}
        title={`Edytuj kategorię ${name}`}
        handleSave={onEdit}
        handleClose={handleClose}
      />

      {/*<DeleteConfirmModal*/}
      {/*  open={openModalDelete}*/}
      {/*  title={`Czy na pewno chcesz usunąć kategorię ${name} ?`}*/}
      {/*  handleDelete={handleDelete}*/}
      {/*  handleClose={() => setOpenModalDelete(false)}*/}
      {/*/>*/}
    </>
  );
};

import {Button, Image, Input, Space, Switch, Table} from "antd";
import React, {useEffect, useRef, useState} from "react";
import useUpdateProductOwnerLogo from "../../../hooks/productOwners/useUpdateProductOwnerLogo";
import {CustomIcon, CustomIconWrapper, ItemTooltipWrapper} from "../../../layout/styles/TableStyles";
import {FaListAlt} from "react-icons/fa";
import TooltipActions from "../TooltipActions";
import LogoUploader from "../../Uploader/LogoUploader";
import useUpdateProductOwnerActivity from "../../../hooks/productOwners/useUpdateProductOwnerActivity";
import ActivityLabel from "../../Labels/ActivityLabel";
import {NavLink} from "react-router-dom";
import UpdateProductOwnerModal from "../../Modals/ProductOwner/UpdateProductOwnerModal";
import {SearchOutlined} from "@ant-design/icons";
import Highlighter from 'react-highlight-words';

const ProductOwnerTable = ({data, reload}) => {
    const {update} = useUpdateProductOwnerActivity();
    const [productOwners, setProductOwners] = useState([]);

    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);

    useEffect(() => {
        let test = data.map((po) => {
            return {
                key: po.id,
                ...po
            }
        });

        setProductOwners(test);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div
                style={{
                    padding: 8,
                }}
            >
                <Input
                    ref={searchInput}
                    placeholder={`Szukaj po nazwie`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: 'block',
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Szukaj
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Wyczyść
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? '#1890ff' : undefined,
                }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{
                        backgroundColor: '#ffc069',
                        padding: 0,
                    }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    const updateActivity = (item, val) => {
        update(item.id, val).finally(() => reload());
    };
    const columns = [
        {
            title: 'Logo',
            dataIndex: '',
            render: row => row.logo ? <Image src={row.logo} height={40} width={80}/> : null
        },
        {
            title: 'Aktywnosć',
            width: '10%',
            dataIndex: '',
            render: row => <Switch
                onClick={(e) => updateActivity(row, e)}
                checked={row.isActive}
            />
        },
        {
            title: 'Nazwa',
            dataIndex: 'name',
            key: 'name',
            ...getColumnSearchProps('name'),
        },
        {
            title: 'Typ Integracji',
            dataIndex: 'integrationType',
            key: 'integrationType',
            render: row => row ? row : 'Brak informacji'
        },
        {
            title: 'Dane kontaktowe',
            dataIndex: '',
            render: row => row.communicationView ? (<div style={{display: 'flex', flexDirection: 'column'}}><div>{row.communicationView.email}</div>tel: {row.communicationView.number}</div>) : 'Brak danych'
        },
        {
            title: 'Dane siedziby',
            dataIndex: '',
            width: '8%',
            render: (row) => <ActivityLabel active={row.headquarterView}/>
        },
        {
            title: 'Dane do zwrotów',
            dataIndex: '',
            width: '8%',
            render: (row) => <ActivityLabel active={row.returnAddressView}/>
        },
        {
            title: 'Dane do kontaktu',
            dataIndex: '',
            width: '8%',
            render: (row) => <ActivityLabel active={row.communicationView}/>
        },
        {
            title: 'Action',
            key: 'action',
            render: (row) => (
                <ActionsProductOwner item={row} reload={reload}/>
            ),
        },
    ];

    return <Table pagination={false} columns={columns} dataSource={productOwners}/>
}

export default ProductOwnerTable;

const ActionsProductOwner = ({reload, item}) => {

    const [showTooltip, setShowTooltip] = useState(false);
    const [openModal, setOpenModal] = useState(false);

    const {updateLogo} = useUpdateProductOwnerLogo();

    const handleClick = (event) => {
        setShowTooltip(!showTooltip);
        event.stopPropagation();
    };

    const handleUpdateLogo = (data, id) => {
        updateLogo(data, id).then(() => reload());
    };

    return (
        <>
            <CustomIconWrapper onClick={(e) => handleClick(e)}>
                <CustomIcon>
                    <FaListAlt/>
                </CustomIcon>
                <TooltipActions
                    showTooltip={showTooltip}
                    setShowTooltip={setShowTooltip}
                >
                    <ItemTooltipWrapper>
                        <LogoUploader
                            update={handleUpdateLogo}
                            item={item}
                            reload={reload}
                        />
                    </ItemTooltipWrapper>
                    <ItemTooltipWrapper>
                        <NavLink
                            to={"/product-owner/" + item.id}
                            key={item.id}
                            style={{ textDecotration: "none" }}
                        >
                            Szczegóły
                        </NavLink>
                    </ItemTooltipWrapper>
                    <ItemTooltipWrapper onClick={() => setOpenModal(true)}>
                        Edytuj
                    </ItemTooltipWrapper>
                </TooltipActions>
            </CustomIconWrapper>
            <UpdateProductOwnerModal reload={reload} item={item} openModal={openModal} handleClose={() => setOpenModal(false)}/>
        </>
    );
}
import Title from "../../components/Title";
import FilterCollapse from "../../components/Collaps/FilterCollapse";
import FormFilterCart from "../../components/Forms/Cart/FormFilterCart";
import {useContext, useEffect} from "react";
import useGetPaginatedCarts from "../../hooks/cart/useGetPaginatedCarts";
import CartFilterContext from "../../context/CartFilterContext";
import FormIndicator from "../../components/Forms/FormIndicator";
import PaginationList from "../../components/PaginationList";
import {CHANGE_PAGE_AND_LIMIT} from "../../reducers/CartFilterReducers";
import TableAbandonedCart from "../../components/Tables/Cart/TableAbandonedCart";

const AbandonedCarts = () => {

    const {data, isLoading, reload} = useGetPaginatedCarts();
    const {state} = useContext(CartFilterContext);

    useEffect(() => {
        reload(state);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.page, state.limit])

    return <>
        <Title title={'Porzucone koszyki'}/>
        <FilterCollapse children={<FormFilterCart reload={reload}/>}/>
        {
            isLoading ? <FormIndicator numberOfElements={3}/> :
                <>
                    <PaginationList pagination={
                        data.pagination
                    } FiltersContext={CartFilterContext} type={CHANGE_PAGE_AND_LIMIT}/>
                    <TableAbandonedCart data={data ? data.results : []} reload={() => reload(state)}/>

                    <PaginationList pagination={
                        data.pagination
                    } FiltersContext={CartFilterContext} type={CHANGE_PAGE_AND_LIMIT}/>

                </>
        }
    </>;
}

export default AbandonedCarts;
import styled from "styled-components";
import {Switch, Typography} from "antd";
import {themes} from "../../../layout/styles/ColorStyles";
import {CheckOutlined, CloseOutlined} from "@ant-design/icons";
import useUpdateProductOwnerConfiguration from "../../../hooks/productOwners/useUpdateProductOwnerConfiguration";

const Wrapper = styled.div`
  background-color: #fff;
  padding: 20px;
`;
const ConfigurationSettingTab = ({data, reload}) => {

    const {update} = useUpdateProductOwnerConfiguration();

    function handleUpdateActivityDelivery(checked) {
        update({id: data.id, isEmailShown: checked}).then(reload);
    }

    return <Wrapper>
        <div style={{display: 'flex', gap: '10px'}}>
            <Typography>Pokaż adres email w zamówieniach (shope.appet.pl)</Typography>
            <Switch checkedChildren={<CheckOutlined/>}
                    unCheckedChildren={<CloseOutlined/>} style={{
                backgroundColor: data.productOwnerConfigurationView
                    ? themes.activeMenuItemColor
                    : themes.switchInActive,
            }}
                    checked={data.productOwnerConfigurationView ? data.productOwnerConfigurationView.emailShown : false}
                    onChange={(checked) => handleUpdateActivityDelivery(checked)}
            />
        </div>
    </Wrapper>;
}

export default ConfigurationSettingTab;
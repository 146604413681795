import FormTitle from "../FormTitle";
import React, {useEffect, useState} from "react";
import useGetAllTags from "../../../hooks/tags/useGetAllTags";
import FormIndicator from "../FormIndicator";
import MultiSelect from "../../Selects/MultiSelect";
import useAssignTagToProduct from "../../../hooks/products/useAssignTagToProduct";
import CustomButton from "../Buttons/CustomButton";

const TagsSelect = ({productId, selectedApi, reload}) => {

    const {data, isLoading} = useGetAllTags();
    const {update, isLoading: isLoadingAssigment} = useAssignTagToProduct();
    const [selectedTags, setSelectedTags] = useState([]);
    const [selectedIds, setSelectedIds] = useState([]);

    useEffect(() => {

        setSelectedTags(selectedApi.map((it) => it.name));
        setSelectedIds(selectedApi.map((it) => it.id));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleSave = () => {
        update(selectedIds, productId).then(() => reload());
    }

    return <>
        <FormTitle title={"Przypisywanie tagów"}/>
        {
            (isLoading) ? <FormIndicator numberOfElements={3}/> :

                <div style={{gap: '10px', marginBottom: "10px"}}>
                    <MultiSelect
                        values={data}
                        selected={selectedTags}
                        setSelected={setSelectedTags}
                        setSelectedIds={setSelectedIds}
                    />
                    <CustomButton loading={isLoadingAssigment} title={'Zapisz'} onClick={handleSave}/>
                </div>
        }
    </>;
}

export default TagsSelect;
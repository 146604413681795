import styled from "styled-components";
import {ArrowLeftOutlined} from "@ant-design/icons";
import {Typography} from "antd";
import FormIndicator from "../../components/Forms/FormIndicator";
import {useParams} from "react-router-dom";
import useGetFullInformationProductCustomList
    from "../../hooks/productCustomList/useGetFullInformationProductCustomList";
import {useEffect} from "react";
import ProductCustomListInfo from "../../components/ProductCustomList/ProductCustomListInfo";
import ProductCustomListItem from "../../components/ProductCustomList/ProductCustomListItem";
import useGetAllBrands from "../../hooks/brands/useGetAllBrands";
import useGetAllCategories from "../../hooks/category/useGetAllCategories";
import useGetAllTags from "../../hooks/tags/useGetAllTags";
import useGetAllFlavours from "../../hooks/flavours/useGetAllFlavours";

const Wrapper = styled.div`
  position: relative;
`;

const Title = styled.div`
  /* position: absolute; */
  display: flex;
  color: #364a63;
  margin-bottom: 15px;
  border-bottom: 1.5px solid #e5e9f2;`;


const UpdateProductCustomList = () => {

    const params = useParams();

    const {data, isLoading, getProductCustomListInfo} = useGetFullInformationProductCustomList();
    const {data: brands, isLoading: isLoadingBrands} = useGetAllBrands();
    const {data: categories, isLoading: isLoadingCategories} = useGetAllCategories();
    const {data: tags, isLoading: isLoadingTags} = useGetAllTags();
    const {data: flavours, isLoading: isLoadingFlavours} = useGetAllFlavours();

    useEffect(() => {
        getProductCustomListInfo(params.id);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return <>
        {
            isLoading || isLoadingBrands || isLoadingCategories || isLoadingTags || isLoadingFlavours ?
                <FormIndicator numberOfElements={1}/> : <Wrapper>
                    <Title>
                        <div onClick={() => window.history.go(-1)}>
                            <ArrowLeftOutlined/> <Typography.Text strong>Wróc</Typography.Text>
                        </div>
                        <div style={{marginLeft: "10px"}}><Typography.Text strong>{data.name}</Typography.Text></div>
                    </Title>
                    <ProductCustomListInfo data={data.productCustomList} />
                    <ProductCustomListItem brands={brands} categories={categories} tags={tags} flavours={flavours}
                                           data={data} reload={() => getProductCustomListInfo(params.id)}/>
                </Wrapper>
        }
    </>;
}

export default UpdateProductCustomList;
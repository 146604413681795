import useAlert from "../useAlert";
import axiosInstance from "../../lib/axios";

const useUpdateIngredientGroupIsFlavour = () => {

    const {successMessage, errorMessage} = useAlert();

    const update = async (id: string, isFlavour: boolean): Promise<any> => {
        try {
            const obj = {
                isShown: isFlavour,
            };
            await axiosInstance.put("/admin/ingredient_group/is_shown/" + id, obj);
            successMessage('Zmiana została zapisana')
        } catch (err) {

            errorMessage('Błąd zapisu, spróbuj ponownie');
            throw err;
        }
    };

    return { update };

}

export default useUpdateIngredientGroupIsFlavour;
import useGetDeliveriesForProductOwner from "../../../hooks/productOwnerDeliveries/useGetDeliveriesForProductOwner";
import {useEffect} from "react";
import {useParams} from "react-router-dom";
import FormIndicator from "../../Forms/FormIndicator";
import DeliveryProductOwnerTable from "../../Tables/DeliveryProductOwner/DeliveryProductOwnerTable";
import useGetAllDeliveries from "../../../hooks/delivery/useGetAllDeliveries";
import AssignNewDeliveryToProductOwnerModel
    from "../../Modals/ProductOwnerDeliveries/AssignNewDeliveryToProductOwnerModel";

const DeliveryTab = () => {

    const params = useParams();
    const {getDeliveries, data, isLoading} = useGetDeliveriesForProductOwner();
    const { data: deliveries, isLoading: isLoadingDeliveries } =
        useGetAllDeliveries();

    useEffect(() => {
        getDeliveries(params.id);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    return <>
        {
            (isLoadingDeliveries || isLoading) ? <FormIndicator numberOfElements={3}/> : <>
                <AssignNewDeliveryToProductOwnerModel
                    deliveries={deliveries}
                    reload={() => getDeliveries(params.id)}
                />
                <DeliveryProductOwnerTable data={data} reload={() => getDeliveries(params.id) }/>
            </>
        }
    </>
}

export default DeliveryTab;
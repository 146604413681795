import {Input} from "antd";
import React from "react";
import styled from "styled-components";

export const Label = styled.label`
  color: #3B4759;
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 10px;
`;

const ItemWrapper = styled.div`
    height: 70px;
    margin-bottom: 10px;
    width: ${props => props.width ? props.width : '100%'};
`;

const ItemInfoWithInput = ({label, value, width, hasLabel = true}) => {

    return <ItemWrapper width={width}>
        {hasLabel ? <Label htmlFor={label}>{label}</Label> : null}
        <Input
            style={{
                backgroundColor: '#EFEFEF',
                fontStyle: 'normal',
                color: 'black',
                fontWeight: '400',
                overflowWrap: 'break-word'
            }} disabled={true} defaultValue={value}/>
    </ItemWrapper>
}

export default ItemInfoWithInput;
import axiosInstance from "../../lib/axios";
import useAlert from "../useAlert";
import {useNavigate} from "react-router-dom";

type UpdateDiscountProps = {
    id: string;
    name: string;
    description: string;
    isSingleUse: boolean;
    isActive: boolean;
    activeFrom?: string;
    activeTo?: string;
    minOrderValue?: number;
    isProductOwnerDiscountCost: boolean;
};

const useUpdateDiscount = () => {
    const {successMessage, errorMessage} = useAlert();
    const navigate = useNavigate();

    const update = async (obj: UpdateDiscountProps): Promise<any> => {
        try {
            await axiosInstance.put(
                "/admin/discount/" + obj.id,
                obj
            );
            successMessage('Zaktualizowane dane');

            navigate('/discounts')
        } catch (err) {
            errorMessage('Błąd zapisu, spróbuj ponownie.')
            throw err;
        }
    };

    return {update};
};

export default useUpdateDiscount;

import useAlert from "../../useAlert";
import axiosInstance from "../../../lib/axios";

interface UpdateSettlementCommission {
    id: string;
    foodCommission: number;
    otherCommission: number;
    settlementCommission: string;
}

const useUpdateSettlementCommission = () => {

    const { successMessage, errorMessage } = useAlert();

    const update = async (
        obj: UpdateSettlementCommission
    ): Promise<any> => {
        try {
            await axiosInstance.put("/admin/b2b/settlement/commission/" + obj.id, obj);
            successMessage("Dane zmienione");
        } catch (err) {
            errorMessage("Błąd zapisu. Spróbuj ponownie.");
            throw err;
        }
    };

    return { update };
}

export default useUpdateSettlementCommission;
import React, {useContext} from "react";
import {Select} from "antd";
import ProductsFiltersContext from "../../../../context/ProductsFiltersContext";
import {UPDATE_ORDER_BY} from "../../../../reducers/ProductsFiltersReducers";

const OrderBySelect = () => {

    const {dispatch, state} = useContext(ProductsFiltersContext);

    return  <Select
        placeholder="Wybierz rodzaj sortowania?"
        mode="single"
        onChange={(e) => dispatch({type: UPDATE_ORDER_BY, payload: e})}
        defaultValue={state.orderBy}
        allowClear={true}
    >
        <Select.Option value={'priceDesc'}>Cena od najwyższej</Select.Option>
        <Select.Option value={'priceAsc'}>Cena od najniższej</Select.Option>
        <Select.Option value={'newset'}>Najnowsze</Select.Option>
        <Select.Option value={'recommended'}>Rekomendowane</Select.Option>
    </Select>

}

export default OrderBySelect;
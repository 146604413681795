import ProductCompositionForm from "../../Forms/Products/ProductCOmposition/ProductCompositionForm";
import useUpdateProductComposition from "../../../hooks/products/productComposition/useUpdateProductComposition";

const CompositionTab = ({data, reload}) => {

    const {update} = useUpdateProductComposition();

    const handleSave = (values) => {
        update(values, data.id).then(() => reload());
    };

    let compositionView = data.compositionView;
    return (
        <>
            <p>Kcal: {compositionView ? compositionView.kcal : 0 }kcal/[100g]</p>
            <ProductCompositionForm handleSave={handleSave} data={data.compositionView}/>
        </>
    )
}

export default CompositionTab;
import useGetAllMarketingBoxes from "../../hooks/marketingBox/useGetAllMarketingBoxes";
import Title from "../../components/Title";
import FormIndicator from "../../components/Forms/FormIndicator";
import MarketingBoxTable from "../../components/Tables/MarketingBox/MarketingBoxTable";
const MarketingBox = () => {

    const {isLoading, data, getMarketingBoxes} = useGetAllMarketingBoxes();

    return <>
            <Title title={"Boxy Marketingowe"} />
            {isLoading ? <FormIndicator numberOfElements={3}/> : <MarketingBoxTable data={data} reload={getMarketingBoxes}/> }
        </>;
}

export default MarketingBox;
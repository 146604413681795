import {Form, Input, Modal} from "antd";

const DeliveryForm = ({handleOK, onCancel, open, item = null, title}) => {

    const [form] = Form.useForm();

    return (
        <Modal title={title}
               open={open}
               onOk={() => {
                   form
                       .validateFields()
                       .then((values) => {
                           form.resetFields();
                           handleOK(values);
                       })
                       .catch((info) => {
                           console.log("Validate Failed:", info);
                       });
               }}
               onCancel={onCancel}
               okText={!item ? "Dodaj nową dostawę" : "Zapisz"}
               cancelText={"Anuluj"}>
            <Form
                layout="vertical"
                form={form}
                name="form_in_modal"
                initialValues={{
                    name: item ? item.name : '',
                    description: item ? item.description : ''
                }}
            >
                <Form.Item
                    label="Nazwa"
                    name="name"
                    rules={[
                        {required: true, message: "Wpisz nazwę, pole jest wymagane!"},
                    ]}
                >
                    <Input placeholder="Wpisz nazwę "/>
                </Form.Item>
                <Form.Item
                    label="Opis"
                    name="description"
                >
                    <Input placeholder="Wpisz opis "/>
                </Form.Item>
            </Form>
        </Modal>
    );
}

export default DeliveryForm;
import { useState } from "react";
import axiosInstance from "../../../lib/axios";

const useGetCodesForDiscount = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [data, setData] = useState<{} | null>(null);

  const getCodesForDiscount = async (id: string): Promise<any> => {
    try {
      const res = await axiosInstance.get(`/admin/discount-code/${id}`);
      if (res.status === 200) {
        setData(res.data ? res.data : []);
        setIsLoading(false);
      }
    } catch (err) {
      console.log("err");
      setIsLoading(false);
    }
  };

  const reload = (id: string) => {
    setIsLoading(true);
    getCodesForDiscount(id);
  };

  return { isLoading, data, getCodesForDiscount, reload };
};

export default useGetCodesForDiscount;

import {Button, Input, Space, Table} from "antd";
import { SearchOutlined} from "@ant-design/icons";
import PaymentStatusLabel from "../../Labels/PaymentStatusLabel";
import React, {useRef, useState} from "react";
import Highlighter from "react-highlight-words";

const DiscountOnceCodeTable = ({data}) => {

    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);
    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };
    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };
    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Input
                    ref={searchInput}
                    placeholder={`Szukaj po naziwe`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: 'block',
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Szukaj
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Wyczyść
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? '#1890ff' : undefined,
                }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{
                        backgroundColor: '#ffc069',
                        padding: 0,
                    }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    const columns = [
        // {
        //     title: "Aktywność",
        //     dataIndex: "",
        //     width: "10%",
        //     render: (row) => (
        //         <Switch
        //             style={{
        //                 backgroundColor: row.active
        //                     ? themes.activeMenuItemColor
        //                     : themes.switchInActive,
        //             }}
        //             checkedChildren={<CheckOutlined/>}
        //             unCheckedChildren={<CloseOutlined/>}
        //             checked={row.active}
        //             onChange={(checked) => handleUpdateCodeActivity(row, checked)}
        //         />
        //     ),
        // },
        {
            title: "Użyto kodu?",
            dataIndex: "",
            width: "15%",
            render: row => <PaymentStatusLabel textTrue={'Tak'} textFalse={'Nie'} paid={row.usageCount === 1}/>
        },
        {
            title: "Kod rabatowy",
            dataIndex: "code",
            width: "15%",
            ...getColumnSearchProps('code'),
        },
        {
            title: "Data utworzenia",
            dataIndex: "createdAt",
            width: "15%",
        },
    ];

    return <Table pagination={false} columns={columns} dataSource={data.map((it) => {
        return {
            key: it.id, ...it
        }
    })}/>;
}


export default DiscountOnceCodeTable;
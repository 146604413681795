import {Input, Space, Switch, Table} from "antd";
import React from "react";
import useUpdateProductCustomListActivity from "../../../hooks/productCustomList/useUpdateProductCustomListActivity";
import {Link} from "react-router-dom";
import {CheckOutlined, CloseOutlined} from "@ant-design/icons";
import {themes} from "../../../layout/styles/ColorStyles";

const ProductCustomListTable = ({data, reload}) => {

    const {update} = useUpdateProductCustomListActivity();

    const updateActivity = (item, val) => {
        update({id: item.id, isActive: val}).then(() => reload());
    };

    const columns = [
        {
            title: 'Aktywnosć',
            width: '10%',
            dataIndex: '',
            render: row => <Switch
                checkedChildren={<CheckOutlined/>}
                unCheckedChildren={<CloseOutlined/>}
                style={{
                    backgroundColor: row.isActive
                        ? themes.activeMenuItemColor
                        : themes.switchInActive,
                }}
                onClick={(e) => updateActivity(row, e)}
                checked={row.isActive}
            />
        },
        {
            title: 'Nazwa',
            dataIndex: 'name',
        },
        // {
        //     title: 'Button Link',
        //     dataIndex: 'buttonLink',
        // },
        // {
        //     title: 'Button Text',
        //     dataIndex: 'buttonText',
        // },
        {
            title: 'Data utworzenia',
            dataIndex: 'createdAt',
        },
        {
            title: 'Shortcode',
            dataIndex: 'sid',
            render: row => <Input disabled placeholder={row}/>
        },
        {
            title: '',
            dataIndex: 'id',
            key: 'x',
            render: id => <Space key={id} size='middle'><Link
                key={id + '1'}
                to={`/custom-product-list/update/${id}`}
            >
                Edytuj
            </Link>
            </Space>
        },
    ];

    return <Table columns={columns} dataSource={data.map((it) => {
        return {...it, key: it.id};
    })} pagination={false}/>;
}

export default ProductCustomListTable;
import Title from "../../components/Title";
import styled from "styled-components";
import useGetAllUsers from "../../hooks/users/useGetAllUsers";
import FormIndicator from "../../components/Forms/FormIndicator";
import {FaPlus} from "react-icons/fa";
import CustomButton from "../../components/Forms/Buttons/CustomButton";
import PaginationList from "../../components/PaginationList";
import UserFiltersContext from "../../context/UserFiltersContext";
import FilterUsersList from "../../components/Forms/Users/FormFilterUsers";
import {useContext, useEffect, useState} from "react";
import {CHANGE_PAGE_AND_LIMIT} from "../../reducers/UserFiltersReducres";
import UsersTable from "../../components/Tables/Users/UsersTable";
import CreateUser from "../../components/Modals/User/CreateUser";

const Wrapper = styled.div`
  position: relative;
`;

const Users = () => {

    const {data, isLoading, getUsers} = useGetAllUsers();
    const {state} = useContext(UserFiltersContext);
    const [openModal, setOpenModal] = useState(false);


    useEffect(() => {
        getUsers(state);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.page, state.limit]);

    return (
        <>
            <Wrapper>
                <Title title="Lista użytkowników"/>

                {isLoading ? (
                    <FormIndicator numberOfElements={1}/>
                ) : (
                    <>
                        <CustomButton
                            title="Utwórz"
                            icon={<FaPlus/>}
                            onClick={() => setOpenModal(true)}
                        />
                        <FilterUsersList reload={getUsers}/>
                        <PaginationList pagination={data.pagination} FiltersContext={UserFiltersContext}
                                        type={CHANGE_PAGE_AND_LIMIT}/>
                        <UsersTable data={data.results} reload={() => getUsers(state)}/>
                        <PaginationList pagination={data.pagination} FiltersContext={UserFiltersContext}
                                        type={CHANGE_PAGE_AND_LIMIT} />
                    </>
                )}
            </Wrapper>
            <CreateUser open={openModal}
                        setOpenModal={setOpenModal}
                        reload={() => getUsers(state)}
            />
        </>
    );
};

export default Users;

import {useEffect, useState} from "react";
import axiosInstance from "../../lib/axios";

const useGetAllBlogPosts = () => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [data, setData] = useState<{} | null>(null);

    useEffect(() => {
        getAllBlogPosts();
    }, []);

    const getAllBlogPosts = async (): Promise<any> => {

        try {
            const res = await axiosInstance.get(`/admin/blog/post/`);

            if (res.status === 200) {
                setData(res.data ? res.data : []);
                setIsLoading(false);
            }
        } catch (err) {
            setIsLoading(false);
        }
    };

    const reload = () => {
        getAllBlogPosts();
    };

    return { isLoading, data, reload };
}

export default useGetAllBlogPosts;
import {useState} from "react";
import useAlert from "../../useAlert";
import axiosInstance from "../../../lib/axios";

type UpdateProductComposition = {
    kcal: number,
    protein: number,
    fat: number,
    crude_ash: number,
    moisture: number,
    pet_type: string,
}

const useUpdateProductComposition = () => {

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const {successMessage, errorMessage} = useAlert();

    const update = async (obj: UpdateProductComposition, id: string): Promise<any> => {
        try {
            setIsLoading(true);
            await axiosInstance.put("/admin/product/product_composition/" + id, obj);

            setIsLoading(false);
            successMessage("Zapisano");

        } catch (err) {
            setIsLoading(false);
            errorMessage('Bład podczas zapisu');
            throw err;
        }
    };

    return {update, isLoading};

}

export default useUpdateProductComposition;
import {Button, Form, Input} from "antd";
import useUpdateProductOwnerDescription from "../../../hooks/productOwners/useUpdateProductOwnerDescription";

const ProductOwnerDescriptionTab = ({data}) => {

    const {update} = useUpdateProductOwnerDescription();
    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    }

    const handleSave = (value) => {

        update(data.id, value.description)
    }

    return <Form
        name="basic"
        onFinish={handleSave}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        layout={'vertical'}
        initialValues={{
            description: data ? data.description : '',
            }}
    >
        <Form.Item
            label="Opis sklepu (widoczny na Appet.pl)"
            name="description"
            >
            <Input.TextArea rows={4} />
        </Form.Item>
        <Form.Item>
            <Button type="primary" htmlType="submit">
                Zapisz
            </Button>
        </Form.Item>
    </Form>

}

export default ProductOwnerDescriptionTab;
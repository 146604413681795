import React, {useEffect, useRef, useState} from 'react';
import styled from "styled-components";
import {Image, Spin} from "antd";

const ItemWrapper = styled.div`
  height: 50px;
`;

const DropDown = styled.div`
  position: relative;
  color: #333;
  cursor: default;
`;

const SelectedValue = styled(DropDown)`
  line-height: 1.5;
  font-size: 1rem;
  background-color: #EFEFEF;
  border: 1px solid #ccc;
  border-radius: 2px;
  box-sizing: border-box;
  cursor: default;
  outline: none;
  transition: all 200ms ease;
  width: 100%;
`;

const Options = styled(DropDown)`

  display: ${props => props.value ? 'none' : 'block'};
  background-color: #fff;
  border: 1px solid #ccc;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
  box-sizing: border-box;
  margin-top: -1px;
  max-height: 200px;
  overflow-y: auto;
  position: absolute;
  top: 100%;
  width: 100%;
  z-index: 1000;
  -webkit-overflow-scrolling: touch;
`;

const Option = styled(DropDown)`
  border-bottom: 1px solid #ccc;
  box-sizing: border-box;
  color: rgba(51, 51, 51, 0.8);
  cursor: pointer;
  display: block;
  padding: 8px 10px;
  height: 80px;
`;

const SearchSelect = ({
                          productGroup,
                          onChange,
                          query,
                          setQuery,
                          options,
                          setOptions,
                          isLoading = false
                      }) => {

    const [open, setOpen] = useState(false);
    const ref = useRef(null);

    useEffect(() => {
        document.addEventListener("click", close);
        return () => document.removeEventListener("click", close);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    function close(e) {
        setOpen(e && e.target !== ref.current);
        setOptions([]);
    }

    return <ItemWrapper>
        <DropDown>
            <div
                onClick={() => setOpen((prev) => !prev)}>
                <SelectedValue>
                    <input
                        style={{border: '0px solid #ccc', width: '100%'}}
                        type='text'
                        ref={ref}
                        placeholder={productGroup ? productGroup.name : 'Wprowadź nazwę produktu głównego...'}
                        value={query}
                        onChange={e => setQuery(e.target.value)}
                        onClick={() => setOpen(prev => !prev)}
                    />
                </SelectedValue>
            </div>
            {
                isLoading ? <Options key='options'><Spin/></Options> : <Options key='options'
                                                                                value={open}>
                    {
                        options.map((option) => <Option
                            onClick={() => onChange(option) && setOpen(false) && setQuery("")}
                            key={option.id}>
                            <Image height={50} width={50}
                                   src={option.photo ? option.photo.fullPath : 'graphic-placeholder.png'}/>{option.name}
                        </Option>)
                    }
                </Options>
            }
        </DropDown>
    </ItemWrapper>
}

export default SearchSelect;
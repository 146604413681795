import {useContext, useState} from "react";
import axiosInstance from "../../lib/axios";
import useAlert from "../useAlert";
import jwtDecode from "jwt-decode";
import authStorage from "../../lib/authStorage";
import AuthContext from "../../context/AuthContext";

type AuthCredentials = {
    email: string;
    password: string;
};

const useAuth = () => {
    const {setUser, setIsSa} = useContext(AuthContext);
    const [isLoading, setIsLoading] = useState(false);
    const {errorMessage} = useAlert();

    const login = async (credentials: AuthCredentials): Promise<void> => {
        try {
            setIsLoading(true);
            const {data} = await axiosInstance.post("/auth/hello", credentials);
            if (data.token) {
                authStorage.storeToken(data.token);
                const user = jwtDecode(data.token);

                // @ts-ignore
                if (user.roles.indexOf("ROLE_ADMIN") === -1 && user.roles.indexOf("ROLE_ADMIN_EDITOR") === -1) {
                    throw new Error("Access Denied");
                }

                setIsLoading(false);
                setUser(user);
            }
        } catch (err) {
            setUser(null);
            errorMessage("Błędne dane logowania. Spróbuj ponownie");
            setIsLoading(false);
            authStorage.clearToken();
        }
    };

    const logout = (): void => {
        authStorage.clearToken();
        setUser(null);
    };

    const refresh = async (): Promise<void> => {
        try {
            setIsLoading(true);
            const {data} = await axiosInstance.post("/auth/refresh");

            if (data.token) {
                authStorage.storeToken(data.token);
                const user = jwtDecode(data.token);

            // @ts-ignore
                if (user.roles.indexOf("ROLE_ADMIN") === -1 && user.roles.indexOf("ROLE_ADMIN_EDITOR") === -1) {
                    throw new Error("Access Denied");
                }
                setUser(user);
                setIsLoading(false);
                return;
            }
        } catch (err) {
            setUser(null);
            setIsLoading(false);
        }
    };

    const getUserInfo = async (): Promise<void> => {
        setIsLoading(true);
        const {data} = await axiosInstance.get("/user/");
        setIsSa(data.sA);
        setIsLoading(false);
    }

    return {login, logout, refresh, isLoading, getUserInfo};
};

export default useAuth;

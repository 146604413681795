import ProductOwnerAddress from "../../../Forms/ProductOwner/ProductOwnerAddress";
import useCreateProductOwnerReturnAddress from "../../../../hooks/productOwners/useCreateProductOwnerReturnAddress";

const CreateProductOwnerReturnAddress = ({openModal, handleClose, item}) => {

    const {create} = useCreateProductOwnerReturnAddress();
    const handleOk = (values) => {
      create({id: item.id, ...values}).then(() => handleClose())
    };

    return (
        <ProductOwnerAddress
            title={'Dodaj nowy adres do zwrotów'}
            handleOk={handleOk}
            onCancel={handleClose}
            open={openModal}
        />
    );
}

export default CreateProductOwnerReturnAddress;
export const CHANGE_PAGE = "change_page";
export const CHANGE_LIMIT = "change_limit";
export const CHANGE_PAGE_AND_LIMIT = "change_page_and_limit";
export const CLEAR_ALL = 'clear_all_products_filter';
export const UPDATE_TEXT_SEARCH = 'update_text_search';
export const UPDATE_BRAND_IDS = 'update_brand_ids';
export const UPDATE_PRODUCT_OWNER_IDS = 'update_product_owners_ids';
export const UPDATE_IS_ACTIVE = 'update_is_active';
export const UPDATE_PRODUCT_STATUS = 'update_product_status';
export const UPDATE_QTY_FROM = 'update_qty_from';
export const UPDATE_QTY_TO = 'update_qty_to';
export const UPDATE_CATEGORY_IDS = 'update_category_ids';
export const UPDATE_DATE_FROM = 'update_date_from';
export const UPDATE_DATE_TO = 'update_date_to';
export const UPDATE_ORDER_BY = 'update_order_by';

export const limit = "limit";
export const page = "page";
export const textSearch = "textSearch";
export const brandIds = "brandIds";
export const productOwnerIds = "productOwnerIds";
export const categoryIds = "categoryIds";
export const isActive = "isActive";
export const qtyStockFrom = "qtyStockFrom";
export const qtyStockTo = "qtyStockTo";
export const brand = "brand";
export const createdAtTo = 'createdAtTo';
export const createdAtFrom = 'createdAtFrom';
export const productStatus = 'productStatus';
export const orderBy = 'orderBy';


export const initialState = {
    [limit]: 20,
    [page]: 1,
    [textSearch]: '',
    [brandIds]: [],
    [categoryIds]: [],
    [isActive]: null,
    [qtyStockFrom]: '',
    [qtyStockTo]: '',
    [createdAtFrom]: '',
    [createdAtTo]: '',
    [productStatus]: '',
    [orderBy]: '',
    [productOwnerIds]: []
};

type ACTIONTYPE =
    | { type: typeof CHANGE_PAGE; payload: number }
    | { type: typeof CHANGE_LIMIT; payload: number }
    | { type: typeof UPDATE_TEXT_SEARCH; payload: string }
    | { type: typeof UPDATE_BRAND_IDS; payload: string[] }
    | { type: typeof UPDATE_IS_ACTIVE; payload: boolean }
    | { type: typeof UPDATE_PRODUCT_STATUS; payload: string }
    | { type: typeof UPDATE_QTY_FROM; payload: number }
    | { type: typeof UPDATE_QTY_TO; payload: number }
    | { type: typeof UPDATE_CATEGORY_IDS; payload: string[] }
    | { type: typeof UPDATE_DATE_FROM; payload: string }
    | { type: typeof UPDATE_DATE_TO; payload: string }
    | { type: typeof CHANGE_PAGE_AND_LIMIT; payload: { [page]: number; [limit]: number } }
    | { type: typeof CLEAR_ALL; payload: null }
    | { type: typeof UPDATE_ORDER_BY; payload: string }
    | { type: typeof UPDATE_PRODUCT_OWNER_IDS; payload: string[]}
;

export const ProductsFiltersReducer = (
        state: typeof initialState,
        action: ACTIONTYPE
    ) => {
        switch (action.type) {
            case CLEAR_ALL:
                return initialState;

            case CHANGE_PAGE:
                return {
                    ...state,
                    [page]: action.payload,
                };
            case CHANGE_LIMIT:
                return {
                    ...state,
                    [limit]: action.payload,
                    [page]: 1
                };
            case CHANGE_PAGE_AND_LIMIT:
                return {
                    ...state,
                    [page]: action.payload[page],
                    [limit]: action.payload[limit],
                };

            case UPDATE_TEXT_SEARCH:
                return {
                    ...state,
                    [textSearch]: action.payload,
                };

            case UPDATE_BRAND_IDS:
                return {
                    ...state,
                    [brandIds]: action.payload,
                };

            case UPDATE_PRODUCT_OWNER_IDS:
                return {
                    ...state,
                    [productOwnerIds]:action.payload
                }

            case UPDATE_IS_ACTIVE:
                return {
                    ...state,
                    [isActive]: action.payload,
                };
            case UPDATE_PRODUCT_STATUS:
                return {
                    ...state,
                    [productStatus]: action.payload,
                };

            case UPDATE_QTY_FROM:
                return {
                    ...state,
                    [qtyStockFrom]: action.payload,
                };

            case UPDATE_QTY_TO:
                return {
                    ...state,
                    [qtyStockTo]: action.payload,
                };

            case UPDATE_CATEGORY_IDS:
                return {
                    ...state,
                    [categoryIds]: action.payload,
                };
            case UPDATE_DATE_FROM:
                return {
                    ...state,
                    [createdAtFrom]: action.payload,
                };
            case UPDATE_DATE_TO:
                return {
                    ...state,
                    [createdAtTo]: action.payload,
                };

            case UPDATE_ORDER_BY:
                return {
                    ...state,
                    [orderBy]: action.payload,
                }

            default:
                return state;
        }
    }
;

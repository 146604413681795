import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  font-size: 16px;
  font-weight: 500;
  color: #364a63;
  border-bottom: 1px solid #e5e9f2;
  margin-bottom: 20px;
`;

const FormTitle = ({ title }) => {
  return <Wrapper>{title}</Wrapper>;
};

export default FormTitle;

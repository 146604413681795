import useAlert from "../useAlert";
import axiosInstance from "../../lib/axios";

interface UseAssignBrandToBrandGroupProps {
    brandsIds: Array<string>,
    id: string
}

const useAssignBrandToBrandGroup = () => {
    const {successMessage, errorMessage} = useAlert();

    const update = async (
        obj: UseAssignBrandToBrandGroupProps
    ): Promise<any> => {

        try {
            await axiosInstance.put(
                `/admin/brand/group/assign/${obj.id}`,
                obj
            );
            successMessage("Marki zostały zaaktualizowane");
        } catch (err) {
            errorMessage("Wystąpił błąd, spróbuj ponownie");
            throw err;
        }
    };

    return {update};
}

export default useAssignBrandToBrandGroup;
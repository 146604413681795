import {useState} from "react";
import axiosInstance from "../../lib/axios";

const useRemovePhotoProduct = () => {
    const [isLoading, setisLoading] = useState<boolean>(false);

    const remove = async (productId: string, id: string): Promise<any> => {
        // const {successMessage, errorMessage} = useAlert();
        try {
            setisLoading(true);
            await axiosInstance.delete(`/admin/product/photo/${productId}/${id}`);
            // successMessage("Zdjęcie zostalo usunięte, odśwież stronę");
        } catch (err) {
            setisLoading(false);
            // errorMessage("Nie udało się usunąć zdjęcia");
            throw err;
        }
    };

    return {remove, isLoading};
};

export default useRemovePhotoProduct;

import React, {useState} from "react";
import useUpdateInfoDelivery from "../../../hooks/delivery/useUpdateInfoDelivery";
import ButtonLink from "../../Buttons/ButtonLink";
import DeliveryForm from "../../Forms/Delivery/DeliveryForm";

const UpdateDelivery = ({reload, item}) => {

    const [updateOpenModal, setUpdateOpenModal] = useState(false);
    const {update} = useUpdateInfoDelivery();

    const handleSave = (obj) => {
        update(item.id, obj)
            .then(() => {
                setUpdateOpenModal(false);
                reload();
            })
            .catch((err) => {
                console.log(err);
            });
    };

    return (
        <>
            <ButtonLink
                text={'Zmień nazwę'}
                onClick={() => setUpdateOpenModal(true)}
            />
            <DeliveryForm item={item} open={updateOpenModal} onCancel={() => setUpdateOpenModal(false)}
                       handleOK={handleSave}
                       title={'Edytuj nazwe'}/>
        </>
    );
}

export default UpdateDelivery;
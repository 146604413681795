import {useEffect, useState} from "react";
import {Form, Input, Modal, Switch, Typography} from "antd";
import {themes} from "../../../layout/styles/ColorStyles";
import {CheckOutlined, CloseOutlined} from "@ant-design/icons";
import MD5 from "crypto-js/md5";

const { Text } = Typography;
const CreateProductOwnerWorkerForm = ({ handleOk, onCancel, open, title, user = null }) => {

    const [form] = Form.useForm();
    const [isActive, setIsActive] = useState(true);

    useEffect(() => {
        setIsActive(user ? user.isActive : true)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <Modal
            title={title}
            open={open}
            onOk={() => {
                form
                    .validateFields()
                    .then((values) => {
                        handleOk(values);
                    })
                    .catch((info) => {
                        console.log("Validate Failed:", info);
                    });
            }}
            onCancel={onCancel}
            okText={!user ? "Dodaj nowego użytkownika" : "Zapisz"}
            cancelText={"Anuluj"}
        >
            <Form
                autoComplete='off'
                layout="vertical"
                form={form}
                name="form_in_modal"
                initialValues={{
                    password: MD5(new Date().toString()).toString(),
                    isActive: user ? user.isActive : true,
                    name: user ? user.name : "",
                    lastName: user ? user.lastName : "",
                    number: user ? user.number : "",
                    email: user ? user.email : "",
                }}
            >
                <Form.Item
                    label="Aktywność użytkownika"
                    name="isActive"
                    valuePropName="checked"
                    required={true}
                >
                    <Switch
                        style={{
                            backgroundColor: isActive
                                ? themes.activeMenuItemColor
                                : themes.switchInActive,
                        }}
                        checkedChildren={<CheckOutlined />}
                        unCheckedChildren={<CloseOutlined />}
                        onChange={(value) => setIsActive(value)}
                    />
                </Form.Item>
                <Text type="secondary">
                    Ta opcja decyduje czy użytkownik będzie mógł się zalogować do panelu
                    sprzedawcy Appet.pl.
                </Text>
                <Form.Item
                    label="Imię"
                    name="name"
                    rules={[
                        { required: true, message: "Wpisz imię, pole jest wymagane!" },
                    ]}
                >
                    <Input placeholder="Wpisz imię" />
                </Form.Item>
                <Form.Item
                    label="Nazwisko"
                    name="lastName"
                    rules={[
                        { required: true, message: "Wpisz nazwisko, pole jest wymagane!" },
                    ]}
                >
                    <Input placeholder="Wpisz nazwisko" />
                </Form.Item>
                <Form.Item
                    label="Adres e-mail"
                    name="email"
                    rules={[
                        {
                            required: true,
                            message: "Wpisz adres e-mail, pole jest wymagane!",
                        },
                        {
                            type: "email",
                            message: "Błąd walidacji, popraw wartość!",
                        },
                    ]}
                >
                    <Input placeholder="Wpisz adres e-mail" type='email' name="fakeusernameremembered" />
                </Form.Item>
                <Form.Item label="Numer telefonu" name="number">
                    <Input placeholder="Wpisz numer telefonu" />
                </Form.Item>
                {!user ? (
                    <>
                        <Form.Item
                            name="password"
                            label="Hasło"
                            hasFeedback
                            rules={[
                                {
                                    required: true,
                                    message: "Wprowadź hasło, pole jest wymagane!",
                                },
                            ]}
                        >
                            <Input
                                addonBefore={<div>Wygenerowane hasło</div>}
                                style={{ width: "100%" }}
                            />
                        </Form.Item>
                        <Form.Item
                            name="confirm"
                            label="Potwierdź hasło"
                            dependencies={["password"]}
                            hasFeedback
                            rules={[
                                {
                                    required: true,
                                    message: "Potwierdź hasło!",
                                },
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        if (!value || getFieldValue("password") === value) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(
                                            new Error("Hasła nie są takie same!")
                                        );
                                    },
                                }),
                            ]}
                        >
                            <Input.Password />
                        </Form.Item>
                    </>
                ) : null}
            </Form>
        </Modal>
    );
}

export default CreateProductOwnerWorkerForm;
import styled from "styled-components";
import {Link, useParams} from "react-router-dom";
import useGetFullInfoUser from "../../hooks/users/useGetFullInfoUser";
import {useEffect} from "react";
import FormIndicator from "../../components/Forms/FormIndicator";
import {Breadcrumb, Card, Col, Image, Row, Table, Tooltip, Typography} from "antd";
import useGetOrdersByUserId from "../../hooks/orders/useGetOrdersByUserId";
import StatusLabel from "../../layout/StatusLabel";
import ProductListTooltip from "../../components/Tooltips/Orders/ProductListTooltip";
import useGetPetsByUserId from "../../hooks/pets/useGetPetsByUserId";
import {InfoCircleOutlined} from "@ant-design/icons";

const Wrapper = styled.div`
`;

const UserInfoContent = styled.div`
  padding: 10px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 20px;
`;

const UserInfo = () => {

    const params = useParams();
    const {getUserInfo, data, isLoading} = useGetFullInfoUser();
    const {getOrdersByUserId, data: orders, isLoading: isLoadingOrders} = useGetOrdersByUserId();
    const {data: pets, isLoading: isLoadingPets, getPetsUser} = useGetPetsByUserId();

    useEffect(() => {
        getUserInfo(params.id);
        getOrdersByUserId(params.id);
        getPetsUser(params.id);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const columns = [
        {
            title: "Data",
            dataIndex: "createdAt",
            key: "createdAt",
            render: (row) => <>{row}</>,
            width: "10%",
        },
        {
            title: "Numer zamówienia",
            dataIndex: "",
            key: "",
            render: (row) => (
                <Link to={"/order/items/" + row.orderId}>
                    <ProductListTooltip products={row.items} text={row.orderNumber}/>
                </Link>
            ),
            width: "10%",
        },
        {
            title: "Status",
            dataIndex: "status",
            key: "status",
            render: (row) => <StatusLabel status={row}/>,
            width: "10%",
        },
        {
            title: "Klient",
            dataIndex: "userView",
            key: "user",
            render: (row) =>
                row ? <>{row.name + " " + row.lastName}</> : "brak danych",
            width: "10%",
        },
        {
            title: "Płatność",
            dataIndex: "orderPaymentView",
            key: "",
            render: (row) => <>{row.paymentMethod.name}</>,
            width: "10%",
        },
        {
            title: "Numer śledzenia przesyłki",
            dataIndex: "",
            key: "",
            render: (row) => <>{row.deliverView.trackingNumber ? row.deliverView.trackingNumber : 'Numer nie został uzupełniony'}</>,
            width: "22%",
        },
        {
            title: "Dostawa",
            dataIndex: "",
            key: "",
            render: (row) => row.deliverView.deliveryProductOwner.deliveryView.logo ?
                <Image width={40} height={30} src={row.deliverView.deliveryProductOwner.deliveryView.logo}/> :
                row.deliverView.deliveryProductOwner.deliveryView.name,
            width: "8%",
        },
        {
            title: "Kwota",
            dataIndex: "totalPriceGross",
            key: "totalPriceGross",
            width: "10%",
            render: (row) => <>{row} zł</>,
        },
    ];

    return <>
        {
            (isLoading || isLoadingOrders || isLoadingPets) ? (<FormIndicator numberOfElements={3}/>) : (
                <Wrapper>
                    <Breadcrumb style={{paddingBottom: "24px"}}>
                        <Breadcrumb.Item>Użytkownicy</Breadcrumb.Item>
                        <Breadcrumb.Item><Link to={'/users'}>Lista użytkowników</Link></Breadcrumb.Item>

                        <Breadcrumb.Item>Szczegóły</Breadcrumb.Item>
                    </Breadcrumb>
                    <UserInfoContent>
                        <div>Email: {data.email}</div>
                        <div>Tel. {data.number}</div>
                        <div>{data.name} {data.lastName}</div>
                    </UserInfoContent>
                    <div className="site-card-wrapper">
                        <Row gutter={16}>
                            <Col span={8}>
                                <Card title="Przypisane zwierzaki" bordered={true}>
                                    <div style={{height: "100%"}}>{pets ?
                                        <div><TooltipDetailPet pets={pets}/></div> : 'Brak danych'}</div>
                                </Card>
                            </Col>
                            <Col span={8}>
                                <Card title="Przypisane adresy" bordered={false}>
                                    <div style={{display: "flex", flexDirection: 'column'}}>


                                        {data.address ? data.address.map((row) => {
                                            return (
                                                <div>{row.name}, {row.city}, {row.street}, {row.localNumber}, {row.postCode}, {row.mobileNumber}</div>)
                                        }) : 'Brak danych'}
                                    </div>
                                </Card>
                            </Col>
                            <Col span={8}>
                                <Card title="Przypisane dane do faktury" bordered={false}>
                                    <div style={{display: "flex", flexDirection: 'column'}}>
                                        {data.billings.map((row) => {
                                            return (
                                                <div>{row.name}, {row.city}, {row.street}, {row.localNumber}, {row.postCode},
                                                    NIP: {row.taxNumber}</div>)
                                        })}
                                    </div>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                    <Typography.Title level={2}>Zamówienia </Typography.Title>
                    <Table pagination={false} columns={columns} dataSource={orders}/>
                    <Typography.Title level={2}>Opuszczone koszyki</Typography.Title>
                    <Table pagination={false} columns={columns} dataSource={orders}/>
                </Wrapper>
            )
        }
    </>
}

export default UserInfo;

const TooltipDetailPet = ({pets}) => {
    return pets.map((pet, index) => {
        return <div style={{display: 'flex', flexDirection: 'row', gap: '10px', alignItems: 'center'}}
                    key={index}>{pet.name + ', ' + pet.breed.name}
            <Tooltip color={'#06283D'} placement="top" title={<div style={{display: 'flex', flexDirection: 'column', gap: '2px'}}>
                <div>Gatunek: {pet.breed.type === 'DOG' ? 'Pies' : 'Kot'}</div>
                <div>Płeć: {pet.gender === 'female' ? 'Samica' : 'Samiec' }</div>
                <div>Rasa: {pet.breed.name} </div>
                <div>Waga: {pet.weight}</div>
                <div>Wysterelizowany: {pet.sterilized ? 'Tak' : 'Nie'}</div>
                <div>Data urodzenia: {pet.birthday.formattedDate}</div>
                <div>Wykluczenia: {pet.ingredientGroupExclude ? pet.ingredientGroupExclude.map((it) => it.name + ', ') : 'brak'}</div>
                <div>Choroby: {pet.disease ? pet.disease.map((it) => it.name + ', ') : 'brak'}</div>
                <div>Smaki:{pet.flavours ? pet.flavours.map((it) => it.name + ', ') : 'brak'}</div>
            </div>}>
                <InfoCircleOutlined/>
            </Tooltip>
        </div>
    })
}
import {useEffect, useState} from "react";
import axiosInstance from "../../lib/axios";

const useGetAllGrammage = () => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [data, setData] = useState<{} | null>(null);

    useEffect(() => {
        getGrammage();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getGrammage = async (): Promise<any> => {
        try {
            const res = await axiosInstance.get(`/admin/product/grammage/`);

            if (res.status === 200) {
                setData(res.data);
            }
                setIsLoading(false);
        } catch (err) {
            setIsLoading(false);
        }
    };

    return {isLoading, data, getGrammage}
}

export default useGetAllGrammage;
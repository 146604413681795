export const CHANGE_PAGE = "change_page";
export const CHANGE_LIMIT = "change_limit";
export const CHANGE_PAGE_AND_LIMIT = "change_page_and_limit";

export const UPDATE_ALL = 'update_all';
export const CLEAR_ALL = 'clear_all';
export const UPDATE_ROLE = 'update_role';
export const UPDATE_NAME = 'update_name';
export const UPDATE_EMAIL = 'update_email';
export const UPDATE_LAST_NAME = 'update_last_name';
export const UPDATE_ORDER_BY = 'update_order_by';

export const limit = "limit";
export const page = "page";
export const queryEmail = 'queryEmail';
export const queryName = 'queryName';
export const queryLastName = 'queryLastName';
export const role = 'role';
export const orderBy = 'orderBy';

export const initialState = {
    [limit]: 10,
    [page]: 1,
    [queryEmail]: '',
    [role]: '',
    [queryName]: '',
    [queryLastName]: '',
    [orderBy]: 'emailAsc'
};

type ACTIONTYPE = | { type: typeof CHANGE_PAGE; payload: number }
    | { type: typeof UPDATE_ROLE; payload: string }
    | { type: typeof UPDATE_ORDER_BY; payload: string }
    | { type: typeof UPDATE_NAME; payload: string }
    | { type: typeof UPDATE_LAST_NAME; payload: string }
    | { type: typeof UPDATE_EMAIL; payload: string }
    | { type: typeof CHANGE_LIMIT; payload: number }
    | {type: typeof CLEAR_ALL; payload: null}
    | {type: typeof UPDATE_ALL; payload: {
        [queryEmail]: string,
        [role]: string,
        [queryName]: string,
        [queryLastName]:string
    }}
    | {
    type: typeof CHANGE_PAGE_AND_LIMIT; payload: { [page]: number; [limit]: number };
};

export const UserFilterReducer = (
    state: typeof initialState,
    action: ACTIONTYPE
) => {
    switch (action.type) {
        case CHANGE_PAGE:
            return {
                ...state,
                [page]: action.payload,
            };
        case CHANGE_LIMIT:
            return {
                ...state,
                [limit]: action.payload,
            };
        case CHANGE_PAGE_AND_LIMIT:
            return {
                [page]: action.payload.page,
                [limit]: action.payload.limit,
            };
        case UPDATE_ALL:
            return {
                ...state,
                [queryEmail]: action.payload[queryEmail],
                [role]: action.payload[role],
                [queryName]: action.payload[queryName],
                [queryLastName]:action.payload[queryLastName]
            }
        case UPDATE_ROLE:
        {
            return {
                ...state,
                [role]: action.payload
            }
        }
        case UPDATE_NAME:
        {
            return {
                ...state,
                [queryName]: action.payload
            }
        }
        case UPDATE_EMAIL:
            return {
                ...state,
                [queryEmail]: action.payload
            }
        case UPDATE_LAST_NAME:
            return {
                ...state,
                [queryLastName]: action.payload
            }
        case CLEAR_ALL:
            return initialState;
        case UPDATE_ORDER_BY:
            return {
                ...state,
                [orderBy]: action.payload
            }

        default:
            return state;
    }
}
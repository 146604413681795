import styled from "styled-components";
import React, {useContext} from "react";
import OrderFiltersContext from "../../../context/OrderFiltersContext";
import {Button, ConfigProvider, DatePicker, Input, Select} from "antd";
import {
    CLEAR_ALL,
    UPDATE_CLIENT_INFO, UPDATE_DATE_FROM, UPDATE_DATE_TO,
    UPDATE_ORDER_NUMBER, UPDATE_ORDER_STATUS, UPDATE_PAYMENT_STATUS, UPDATE_PRICE_FROM, UPDATE_PRICE_TO,
    UPDATE_QUERY_EMAIL
} from "../../../reducers/OrderFiltersReducers";
import CustomButton from "../Buttons/CustomButton";
import status from "../../../const/status";
import PaymentStatusLabel from "../../Labels/PaymentStatusLabel";
import OrderProductOwnerSelect from "./OrderProductOwnerSelect";
import OrderDeliverySelect from "./OrderDeliverySelect";
import dayjs from "dayjs";
import 'dayjs/locale/pl';
import locale from 'antd/locale/pl_PL';

const Content = styled.div`

  @media (min-width: ${(props) => props.theme.breakpoints.mobile}) {
    display: flex;
    flex-direction: column;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.desktop}) {
    display: grid;
  }

  width: 100%;
  height: 100%;
  column-gap: 10px;
  grid-template-columns: 25% 24% 25% 25%;
`;

const FormItem = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
`

const FiltersForm = ({reload, deliveries = [], productOwners = []}) => {
    const {dispatch, state} = useContext(OrderFiltersContext);

    const handleSubmit = () => {
        reload(state);
    }

    const handleClear = () => {
        dispatch({type: CLEAR_ALL})
    }

    return <Content>
        <FormItem>
            <p>Numer zamówienia</p>
            <Input value={state.orderNumber}
                   allowClear
                   onChange={(e) => dispatch({type: UPDATE_ORDER_NUMBER, payload: e.target.value})}
            />
        </FormItem>
        <FormItem>
            <p>Email</p>
            <Input value={state.queryEmail}
                   allowClear
                   onChange={(e) => dispatch({type: UPDATE_QUERY_EMAIL, payload: e.target.value})}/>
        </FormItem>
        <FormItem>
            <p>Imię i Nazwisko</p>
            <Input value={state.clientInfo}
                   allowClear
                   onChange={(e) => dispatch({type: UPDATE_CLIENT_INFO, payload: e.target.value})}/>
        </FormItem>
        <FormItem>
            <p>Cena od - do</p>
            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                }}
            >
                <Input value={state.priceFrom} style={{width: "100%"}}
                       allowClear
                       onChange={(e) => dispatch({
                           type: UPDATE_PRICE_FROM,
                           payload: e.target.value
                       })}/>
                <Input
                    style={{
                        width: "15%",
                        borderLeft: 0,
                        borderRight: 0,
                        pointerEvents: "none",
                    }}
                    placeholder="~"
                    disabled
                />
                <Input value={state.priceTo} style={{width: "100%"}}
                       allowClear
                       onChange={(e) => dispatch({
                           type: UPDATE_PRICE_TO,
                           payload: e.target.value
                       })}/>
            </div>
        </FormItem>
        <FormItem>
            <p>Status zamówienia</p>
            <Select
                placeholder="Wybierz status"
                value={state.status}
                onChange={(e) => dispatch({type: UPDATE_ORDER_STATUS, payload: e})}
                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                showSearch
            >
                {status.map((it) => (
                    <Select.Option key={it.value} value={it.value}>
                        {it.label}
                    </Select.Option>
                ))}
                <Select.Option key={0} value="">Wszystkie</Select.Option>
            </Select>
        </FormItem>
        <ConfigProvider locale={locale}>
            <FormItem>
                <p>Data utworzenia od</p>
                <DatePicker
                    allowClear={false}
                    style={{width: '100%'}}
                    placeholder={'Data utworzenia od'}
                    value={state.createdAtFrom ? dayjs(state.createdAtFrom, 'YYYY-MM-DD') : null}
                    onChange={(date, dateString) => dispatch({
                        type: UPDATE_DATE_FROM,
                        payload: dateString + ' 00:00:00'
                    })}
                />
            </FormItem>
            <FormItem>
                <p>Data utworzenia do</p>
                <DatePicker allowClear={false} style={{width: '100%'}} placeholder={'Data utworzenia do'}
                            value={state.createdAtTo ? dayjs(state.createdAtTo, 'YYYY-MM-DD') : null}
                            onChange={(date, dateString) => dispatch({
                                type: UPDATE_DATE_TO,
                                payload: dateString + ' 23:59:59'
                            })}
                />
            </FormItem>
        </ConfigProvider>
        <FormItem>
            <p>Kurier</p>
            <OrderDeliverySelect deliveries={deliveries}/>
        </FormItem>
        <FormItem>
            <p>
                Status płatności
            </p>
            <Select mode="single"
                    placeholder="Wybierz rodzaj płatności"
                    value={state.statusPayment}
                    onChange={(e) => dispatch({type: UPDATE_PAYMENT_STATUS, payload: e})}
                    onClear={() => dispatch({type: UPDATE_PAYMENT_STATUS, payload: null})}
                    allowClear={true}
            >
                <Select.Option key={1} value={1}>
                    <PaymentStatusLabel paid={1}/>
                </Select.Option>
                <Select.Option key={2} value={0}>
                    <PaymentStatusLabel paid={0}/>
                </Select.Option>
            </Select>
        </FormItem>
        <FormItem>
            <p>Sklep</p>
            <OrderProductOwnerSelect productOwners={productOwners}/>
        </FormItem>
        <div style={{display: 'flex', gap: '10px', alignItems: 'flex-end', justifyContent: 'flex-end'}}>
            <CustomButton title={'Zastosuj'} onClick={() => handleSubmit()}/>
            <Button style={{borderRadius: '10px'}} onClick={() => handleClear()}>Wyczyść</Button>
        </div>
    </Content>
}

export default FiltersForm;
import Title from "../../components/Title";
import {useParams} from "react-router-dom";
import {useEffect} from "react";
import useGetMarketingBoxInfo from "../../hooks/marketingBox/useGetMarketingBoxInfo";
import FormIndicator from "../../components/Forms/FormIndicator";
import MarketingBoxForm from "../../components/Forms/MarketingBox/MarketingBoxForm";
import useUpdateBannerMarketingBox from "../../hooks/marketingBox/useUpdateBannerMarketingBox";
import useUpdateMarketingBoxInfo from "../../hooks/marketingBox/useUpdateMarketingBoxInfo";

const UpdateMarketingBox = () => {

    const {data, isLoading, getMarketingBoxInfoByGroupName} = useGetMarketingBoxInfo();
    const {updateBanner} = useUpdateBannerMarketingBox();
    const {update} = useUpdateMarketingBoxInfo();
    const params = useParams();

    useEffect(() => {
        getMarketingBoxInfoByGroupName(params.groupName)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleOnFinish = (data) => {
        update({...data, groupName: params.groupName}).then(() => getMarketingBoxInfoByGroupName(params.groupName))

    }

    const handleUpdateBanner = (data, id) => {
        updateBanner(data, id).then(() => getMarketingBoxInfoByGroupName(params.groupName));
    }

    return <>
        {isLoading ? <FormIndicator numberOfElements={3}/> :
            <div>
                <Title title="Edytuj"/>
                <MarketingBoxForm data={data} updateInfo={handleOnFinish} handleUpdateBanner={handleUpdateBanner}
                                  getMarketingBoxInfoByGroupName={() => getMarketingBoxInfoByGroupName(params.groupName)}/>
            </div>
        }
    </>;
}

export default UpdateMarketingBox;
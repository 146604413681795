import {useState} from "react";
import axiosInstance from "../../lib/axios";
import useAlert from "../useAlert";

const useAssignTagToProduct = () => {

    const [isLoading, setIsLoading] = useState(true);
    const {successMessage, errorMessage} = useAlert();

    const update = async (
        tagsIdsArr: Array<string>,
        productId: string
    ): Promise<any> => {
        const parseToString: string = tagsIdsArr.join(",");
        const obj = { tagsIds: parseToString };

        try {
            setIsLoading(true);
            await axiosInstance.put(
                `/admin/product/tags/${productId}`,
                obj
            );

            setIsLoading(false);
            successMessage('Zapisano');

        } catch (err) {
            errorMessage('Błąd zapisu, spróbuj ponownie');
            setIsLoading(false);
            throw err;
        }
    };

    return { update, isLoading };
}

export default useAssignTagToProduct;

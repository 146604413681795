import {Tag} from "antd";

const ProductCustomRow = ({filters, tags, brands, categories, flavours}) => {

    const getPriceFrom = () => {
        if (filters.priceFrom) {
            return <div>Cena od: <Tag>{filters.priceFrom}</Tag></div>;
        }
        return '';
    }

    const getPriceTo = () => {
        if (filters.priceTo) {
            return <>Cena do: <Tag>{filters.priceTo}</Tag></>;
        }
        return '';
    }

    const getWeightFrom = () => {
        if (filters.weightFrom) {
            return <>Waga do: <Tag>{filters.weightFrom}</Tag></>;
        }
        return '';
    }

    const getWeightTo = () => {
        if (filters.weightTo) {
            return <>Waga do: <Tag>{filters.weightTo}</Tag></>;
        }
        return '';
    }

    const getBrands = () => {
        let arr = filters.brandIds.map((it) => {
           return brands.map((brand) => {
                if (it === brand.id) {
                    return brand.name;
                }
            })
        });

        const results = arr.filter(element => {
            return element !== undefined;
        });
        return <div>Producent: {results.map((it) => <Tag key={it}>{it}</Tag>)}</div>;
    }

    const getCategories = () => {
        let arr = filters.categoryIds.map((it) => {
            return categories.map((cat) => {
                if (it === cat.id) {
                    return cat.name;
                }
            })
        });

        const results = arr.filter(element => {
            return element !== undefined;
        });
        return <div>Kategorie: {results.map((it) => <Tag key={it}>{it}</Tag>)}</div>;
    }

    const getTags = () => {
        let arr = filters.tagIds.map((it) => {
            return tags.map((tag) => {
                if (it === tag.id) {
                    return tag.name;
                }
            })
        });

        const results = arr.filter(element => {
            return element !== undefined;
        });
        return <div>Tagi: {results.map((it) => <Tag>{it}</Tag>)}</div>;
    }

    const getFlavours = () => {
        let arr = filters.flavourIds.map((it) => {
            return flavours.map((flavour) => {
                if (it === flavour.id) {
                    return flavour.name;
                }
            })
        });

        const results = arr.filter(element => {
            return element !== undefined;
        });
        return <div>Smak: {results.map((it) => <Tag>{it}</Tag>)}</div>;
    }

    const getName = () => {
        if (filters.name) {
            return <div>Nazwa: <Tag>{filters.name}</Tag></div>;
        }
        return '';
    }


    return <div style={{display: "flex", gap: '10px'}}>
        <div>
            {
                getPriceFrom()
            }
        </div>
        <div>
            {
                getPriceTo()
            }
        </div>
        <div>
            {
                getWeightFrom()
            }
        </div>
        <div>
            {
                getWeightTo()
            }
        </div>
        <div>
            {
                filters.brandIds ? getBrands() : null
            }
        </div>
        <div>
            {
                filters.categoryIds ? getCategories() : null
            }
        </div>
        <div>
            {
                filters.tagIds ? getTags() : null
            }
        </div>
        <div>
            {
                filters.flavourIds ? getFlavours() : null
            }
        </div>
        <div>
            {
               getName()
            }
        </div>
    </div>;

}

export default ProductCustomRow;
import { useState } from "react";
import axiosInstance from "../../lib/axios";
import useAlert from "../useAlert";

const useUpdateProductCategories = () => {
  const [isLoading, setIsLoading] = useState(false);
  const {successMessage, errorMessage} = useAlert();

  const update = async (
    catIdsArr: Array<string>,
    productId: string
  ): Promise<any> => {
    const parseToString: string = catIdsArr.join(",");
    const obj = { categoryIds: parseToString };

    try {
      setIsLoading(true);
   await axiosInstance.put(
        `/admin/product/categories/${productId}`,
        obj
      );

      setIsLoading(false);
      successMessage("Kategoria została zapisana. Zmiany mogą pojawić się po kliku minutach.");
    } catch (err) {
        errorMessage('Błąd zapisu spróbuj ponownie. ')
      setIsLoading(false);
      throw err;
    }
  };

  return { update, isLoading };
};

export default useUpdateProductCategories;

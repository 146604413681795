import {Form, Input, Modal, Select, Switch, Typography} from "antd";
import React, {useEffect, useState} from "react";
import {themes} from "../../../layout/styles/ColorStyles";
import {CheckOutlined, CloseOutlined} from "@ant-design/icons";
import useGetAllVatRates from "../../../hooks/vatRates/useGetAllVatRates";
import useGetAllBrands from "../../../hooks/brands/useGetAllBrands";
import useCalculatePrices from "../../../hooks/useCalculatePrices";


const EditProductVariantInfo = ({handleOk, setOpenModal, openModal, item}) => {

    const {data: vatRates, isLoading: isLoadingVatRate} = useGetAllVatRates();
    const {data: brands, isLoading: isLoadingBrands} = useGetAllBrands();
    const {calculateBruttoPrice, calculateNettoPrice} = useCalculatePrices();
    const [priceNet, setPriceNet] = useState();
    const [priceBrutto, setPriceBrutto] = useState();
    const [vatRate, setVatRate] = useState(23);
    const [vatRateId, setVatRateId] = useState('vat_a');
    const [hasStock, setHasStock] = useState(true);
    const [isActive, setIsActive] = useState(true);

    useEffect(() => {
        setIsActive(item ? item.active : true);
        setPriceBrutto(item.price[0].priceGross);
        setPriceNet(item.price[0].priceNet);
        setVatRateId(item.price[0].vatRate.id);
        setVatRate(item.price[0].vatRate.value);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [form] = Form.useForm();

    if ( isLoadingVatRate || isLoadingBrands) return null;


    const handleSubmit = (values) => {

        let priceVariants = [];
        priceVariants.push({
            priceNet: priceNet,
            priceGross: priceBrutto,
            vatRateId: vatRateId,
            currencyCode: 'PLN',
            isSale: false
        });

        const parseVariants = {
            ean: values.ean,
            name: values.name,
            description: values.description,
            brandId: values.brand,
            variantValue: values.variantValue,
            hasStock: values.hasStock,
            stock: values.stock ? parseInt(values.stock) : 0,
            priceVariants: priceVariants,
        };

        handleOk(parseVariants);
    }

    const onChangePriceBrutto = (values) => {
        setPriceBrutto(parseFloat(values));
        setPriceNet(calculateNettoPrice(values, vatRate));
    }

    const onChangePriceNet = (values) => {
        setPriceNet(parseFloat(values))
        setPriceBrutto(calculateBruttoPrice(values, vatRate));
    }

    const onTaxChange = (values, obj) => {
        setPriceBrutto(calculateBruttoPrice(priceNet, values));
        setVatRate(values);
        setVatRateId(obj.key);
    }

    return <Modal
        width={1000}
        title={`Edytuj informacje o produkcie ${item.name}`}
        open={openModal}
        onOk={() => {
            form
                .validateFields()
                .then((values) => {
                    handleSubmit(values);
                })
                .catch((info) => {
                    console.log("Validate Failed:", info);
                });
        }}
        onCancel={() => setOpenModal(false)}
        okText={"Zapisz"}
        cancelText={"Anuluj"}
    >
        <Form
            layout="vertical"
            form={form}
            name="form_in_modal"
            initialValues={{
                name: item ? item.name : "",
                ean: item ? item.ean : "",
                isActive: item ? item.active : true,
                variantValue: item.productGroup.variantValue ? item.productGroup.variantValue.variantValue : '',
                stock: item ? item.stock : 0,
                brand: (item && item.brand) ? item.brand.id : '',
                vatRate: item ? vatRate : '',
                description: item ? item.description : '',
                hasStock: item ? item.hasStock : true,
            }}
        >
            <Form.Item
                label="Ean"
                name="ean"
            >
                <Input placeholder="Wpisz EAN"/>
            </Form.Item>
            <Form.Item
                label="Aktywność wariantu"
                name="isActive"
                valuePropName="checked"
                required={true}
            >
                <Switch
                    style={{
                        backgroundColor: isActive
                            ? themes.activeMenuItemColor
                            : themes.switchInActive,
                    }}
                    checkedChildren={<CheckOutlined/>}
                    unCheckedChildren={<CloseOutlined/>}
                    onChange={(value) => setIsActive(value)}
                />
            </Form.Item>
            <Form.Item
                label="Nazwa"
                name="name"
                rules={[
                    {required: true, message: "Wpisz nazwę, pole jest wymagane!"},
                ]}
            >
                <Input placeholder="Wpisz nazwę"/>
            </Form.Item>
            <Form.Item
                label="Opis"
                name="description"
            >
                <Input placeholder="Wpisz opis"/>
            </Form.Item>
            <Form.Item
                label="Wartość Wariantu"
                name="variantValue"
                rules={[
                    {required: true, message: "Wypełnij dane, pole jest wymagane!"},
                ]}
            >
                <Input placeholder="Wpisz wartość wariantu"/>
            </Form.Item>
            <Form.Item
                name="brand"
                label="Wybierz marke"
                rules={[
                    {
                        required: true,
                        message: "Wybierz marke",
                    },
                ]}
            >
                <Select placeholder={"Wybierz"}
                        allowClear
                        showSearch
                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                >
                    {brands.map((it) => (<Select.Option key={it.id} value={it.id}>
                        {it.name}
                    </Select.Option>))
                    }

                </Select>
            </Form.Item>
            <Form.Item
                initialValue={vatRate}
                name="vatRateId"
                label="Wybierz vat"
                rules={[
                    {
                        required: true,
                        message: "Wybierz vat",
                    },
                ]}
            >
                <Select placeholder={"Wybierz"}
                        allowClear
                        onChange={(e, obj) => onTaxChange(e, obj)}
                >
                    {vatRates.map((it) => (<Select.Option key={it.id} value={it.value}>
                        {it.name} [{it.value} %]
                    </Select.Option>))
                    }

                </Select>
            </Form.Item>
            <div style={{display: 'flex', gap: '5px', marginBottom: '15px'}}>
                <div style={{width: '50%'}}>
                    <Typography.Text>Cena netto</Typography.Text>
                    <Input type={'number'}
                           min={0}
                           step="0.01"
                           value={priceNet}
                           required={true}
                           onChange={(e) => onChangePriceNet(e.target.value)}/>
                </div>
                <div style={{width: '50%'}}>
                    <Typography.Text>Cena brutto</Typography.Text>
                    <Input min={0}
                           step="0.01"
                           type={'number'}
                           required={true}
                           value={priceBrutto}
                           onChange={(e) => onChangePriceBrutto(e.target.value)}/>
                </div>
            </div>
            <Form.Item
                label="Czy posiada stan magazynowy?"
                name="hasStock"
                valuePropName="checked"
                initialValue={hasStock}
            >
                <Switch
                    checkedChildren={<CheckOutlined/>}
                    unCheckedChildren={<CloseOutlined/>}
                    style={{
                        backgroundColor: hasStock
                            ? themes.activeMenuItemColor
                            : themes.switchInActive,
                    }}
                    onChange={(value) => setHasStock(value)}
                />
            </Form.Item>
            {
                hasStock && <Form.Item
                    label="Stan magazynowy"
                    name="stock"
                    rules={[
                        {
                            required: hasStock,
                            message: 'Podaj wartość stanów!',
                        },
                    ]}
                >
                    <Input type={'number'}/>
                </Form.Item>
            }
        </Form>
    </Modal>;
}

export default EditProductVariantInfo;
import useAlert from "../useAlert";
import axiosInstance from "../../lib/axios";

interface UpdateActivityMarketingBoxProps {
    groupName: string
    isActive: boolean
}

const useUpdateActivityMarketingBox = () => {

    const {successMessage, errorMessage} = useAlert();

    const update = async (obj: UpdateActivityMarketingBoxProps): Promise<any> => {
        try {
            await axiosInstance.put("/admin/marketing-box/activity/" + obj.groupName, obj);
            successMessage("Aktywność została zmieniona");
        } catch (err) {
            errorMessage("Błąd podczas zmiany aktywności");
            throw err;
        }
    };

    return { update };
}

export default useUpdateActivityMarketingBox;
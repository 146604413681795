import { useRef, useEffect } from "react";

import { TooltipWrapper } from "../../layout/styles/TableStyles";

const TooltipActions = ({ showTooltip, setShowTooltip, children }) => {
  const tooltipRef = useRef();

  const handleClickOutside = (event) => {
    if (!tooltipRef.current.contains(event.target)) {
      setShowTooltip(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div ref={tooltipRef}>
      <TooltipWrapper showTooltip={showTooltip}>{children}</TooltipWrapper>
    </div>
  );
};

export default TooltipActions;

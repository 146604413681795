import React, { useState } from "react";

const AuthContext = React.createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [isSa, setIsSa] = useState(false);

  return (
    <AuthContext.Provider value={{ user, setUser, isSa, setIsSa }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;

import React, {useState, useEffect} from "react";
import Title from "../../components/Title";
import styled from "styled-components";
import Paper from "../../layout/Paper";
import Podstawowe from "../../components/Forms/Products/Podstawowe";
import CreateProductMenu from "../../components/Forms/Products/CreateProductMenu";
import Brand from "../../components/Forms/Products/Brand";
import Media from "../../components/Forms/Products/Media";
import {useParams} from "react-router-dom";
import useGetProductFullInformation from "../../hooks/products/useGetProductFullInformation";
import useUpdateProductInfo from "../../hooks/products/useUpdateProductInfo";
import useGetAllGrammage from "../../hooks/grammage/useGetAllGrammage";
import FormIndicator from "../../components/Forms/FormIndicator";
import CategoryTreeAssigment from "../../components/Tree/Product/CategoryTreeAssigment";
import useGetAllCategories from "../../hooks/category/useGetAllCategories";
import UpdateDetailProduct from "../../components/Forms/Products/UpdateDetailProduct";
import CrossSellingTab from "../../components/Menu/ProductMenuTabs/CrossSellingTab";
import {Image, Tooltip} from "antd";
import ProductCompositionTab from "../../components/Forms/Products/ProductCOmposition/ProductCompositionTab";
import {CopyOutlined} from "@ant-design/icons";
import useGetAllBrands from "../../hooks/brands/useGetAllBrands";
import UpdateProductVatRate from "../../components/Forms/Products/UpdateProductVatRate";

const Wrapper = styled.div`
  position: relative;
`;

const PaperContent = styled.div`
  width: 100%;
  height: auto;
  display: grid;
  grid-template-columns: 200px auto;
  gap: 15px;
`;

const UpdateProduct = () => {
    const [selectedMenuIdx, setSelectedMenuIdx] = useState(0);
    const params = useParams();
    const {isLoading, getProductById, data} =
        useGetProductFullInformation();
    const {data: cat, isLoading: isCatLoad} = useGetAllCategories();

    const {isLoading: isLoadingGrammage, data: grammages} = useGetAllGrammage();
    const { data:brands , isLoading: isLoadingBrands } = useGetAllBrands();

    const {update} = useUpdateProductInfo();

    useEffect(() => {
        getProductById(params.id, true);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const reload = () => {
        getProductById(params.id, false);
    };

    const updateName = (value) => {
        update(value, params.id).then(() => reload());
    };

    function copy() {
        return window.navigator.clipboard.writeText(data.name);

    }

    const title = () => {
        return (
            <div>
                {
                    data.photos[0] ?
                        <div style={{display: 'flex', flex: 1, alignItems: 'center', marginBottom: '5px', gap: '10px'}}>
                            <Image src={data.photos[0].fullPath} width={50} height={50}/>{data.name}
                            <Tooltip placement="top" title={'Skopiuj do schowka'}>
                                <CopyOutlined onClick={() => copy()}/>
                            </Tooltip></div> : data.name
                }
            </div>
        );
    }

    return (
        <Wrapper>
            {isLoading ? '' : <Title title={title()}/>}
            <Paper>
                {
                    isLoadingGrammage || isLoading || isCatLoad || isLoadingBrands ? <FormIndicator numberOfElements={3}/> : (
                        <PaperContent>
                            <CreateProductMenu
                                data={data}
                                selected={selectedMenuIdx}
                                setSelected={setSelectedMenuIdx}
                                productId={params.id}
                            />
                            <div>
                                {selectedMenuIdx === 0 ? (
                                    <Podstawowe save={updateName} data={data} grammage={grammages} reload={reload}/>
                                ) : null}
                                {selectedMenuIdx === 1 ? (
                                    <CategoryTreeAssigment selectedCategories={data.categories} categories={cat} reload={reload}/>
                                ) : null}
                                {selectedMenuIdx === 2 ? (
                                    <Brand
                                        brands={brands}
                                        productId={params.id}
                                        selectedApi={data.brand ? data.brand : []}
                                        reload={reload}
                                    />
                                ) : null}
                                {selectedMenuIdx === 3 ? (
                                    <Media productId={params.id} data={data.photos ? data.photos : []} reload={reload} product={data}/>
                                ) : null}
                                {selectedMenuIdx === 4 ? (
                                    <UpdateDetailProduct
                                        reload={reload}
                                        selectedTags={data.tags}
                                        selectedFlavours={data.flavours}
                                    />
                                ) : null}
                                {selectedMenuIdx === 5 ? (
                                    <ProductCompositionTab product={data} grammages={grammages} reload={reload}/>
                                ) : null}
                                {selectedMenuIdx === 6 ? (
                                    <CrossSellingTab brands={brands}/>
                                ) : null}

                                {selectedMenuIdx === 7 ? (
                                    <UpdateProductVatRate product={data} reload={reload}/>
                                ) : null}
                            </div>
                        </PaperContent>)
                }

            </Paper>
        </Wrapper>
    );
};

export default UpdateProduct;

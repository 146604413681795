import { useState } from "react";
import axiosInstance from "../../lib/axios";
import useAlert from "../useAlert";

interface UpdateProductInfo {
    name: string;
    description?: string;
    metaKeywords?: string;
    metaDescription: string;
    isActive: boolean;
}

const useUpdateProductInfo = () => {

    const [isLoading, setIsLoading] = useState(false);
    const {successMessage, errorMessage} = useAlert();

    const update = async(obj: UpdateProductInfo, productId: string) : Promise<any> => {
        try {
            setIsLoading(true);
            await axiosInstance.put(
                `/admin/product/info/${productId}`,
                obj
            );

            setIsLoading(false);
            successMessage('Dane zostały zapisane. Zmiany mogą pojawić się po kilku minutach.');

        } catch (err) {
            errorMessage('Wystąpił błąd podczas zapisywania danych');
            setIsLoading(false);
            throw err;
        }
    };

    return {update, isLoading}
}

export default useUpdateProductInfo;
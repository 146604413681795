import {Form, Input, Modal} from "antd";

const BrandForm = ({handleOK, onCancel, open, name = null, title}) => {

    const [form] = Form.useForm();

    return (
        <Modal title={title}
               open={open}
               onOk={() => {
                   form
                       .validateFields()
                       .then((values) => {
                           form.resetFields();
                           handleOK(values);
                       })
                       .catch((info) => {
                           console.log("Validate Failed:", info);
                       });
               }}
               onCancel={onCancel}
               okText={!name ? "Dodaj nową markę" : "Zapisz"}
               cancelText={"Anuluj"}>
            <Form
                layout="vertical"
                form={form}
                name="form_in_modal"
                initialValues={{
                    name: name ? name : '',
                }}
            >
                <Form.Item
                    label="Nazwa"
                    name="name"
                    rules={[
                        {required: true, message: "Wpisz nazwę, pole jest wymagane!"},
                    ]}
                >
                    <Input placeholder="Wpisz markę "/>
                </Form.Item>
            </Form>
        </Modal>
    );
}

export default BrandForm;
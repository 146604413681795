import {useState} from "react";
import axiosInstance from "../../lib/axios";

const useGetStaticPageById = () => {

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [data, setData] = useState<{} | null>(null);

    const getStaticPageById = async (id: string): Promise<any> => {

            try {
                setIsLoading(true);
                const res = await axiosInstance.get(`/admin/static-page/${id}`);

                if (res.status === 200) {
                    setData(res.data);
                    setIsLoading(false);
                }

                return res.data.content;
            } catch (err) {
                throw err;
            }
    }

    return {getStaticPageById, data, isLoading}
}

export default useGetStaticPageById;
import useAlert from "../useAlert";
import axiosInstance from "../../lib/axios";

type CreateProductCustomListProps = {
    name: string;
    customListSortBy: string;
}

const useCreateProductCustomList = () => {
    const {successMessage, errorMessage} = useAlert();

    const create = async (obj: CreateProductCustomListProps): Promise<any> => {
        try {
            await axiosInstance.post("/admin/product_custom_list/", obj);
            successMessage("Zapisano");
        } catch (err) {
            errorMessage("Błąd zapisu, spróbuj ponownie");
            throw err;
        }
    };

    return {create};
}

export default useCreateProductCustomList;
import {useState} from "react";
import useAlert from "../useAlert";
import axiosInstance from "../../lib/axios";

type UpdateIngredientProps = {
    id: string,
    name: string;
    grammageId?: string;
}

const useUpdateIngredientName = () => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const {successMessage, errorMessage} = useAlert();


    const update = async (obj: UpdateIngredientProps): Promise<any> => {
        console.log(obj);
        try {
            setIsLoading(true);
            await axiosInstance.put("/admin/ingredient/" + obj.id, obj);
            setIsLoading(false);
            successMessage("Zapisano");
        } catch (err) {
            setIsLoading(false);
            errorMessage("Błąd zapisu, spróbuj ponownie");
            throw err;
        }
    };

    return {update, isLoading};
}

export default useUpdateIngredientName;
import {Switch, Table} from "antd";
import React from "react";
import {CheckOutlined, CloseOutlined, DeleteOutlined} from "@ant-design/icons";
import ProductCustomRow from "./ProductCustomItemRow/ProductCustomRow";
import {themes} from "../../../layout/styles/ColorStyles";

const ProductCustomListItemTable = ({
                                        data,
                                        brands,
                                        categories,
                                        flavours,
                                        tags,
                                        handleDelete,
                                        handleActive
                                    }) => {

    const columns = [
        {
            title: 'Aktywnosć',
            width: '10%',
            dataIndex: '',
            render: row => <Switch
                checkedChildren={<CheckOutlined/>}
                unCheckedChildren={<CloseOutlined/>}
                style={{
                    backgroundColor: row.isActive
                        ? themes.activeMenuItemColor
                        : themes.switchInActive,
                }}
                onClick={(e) => handleActive(row, e)}
                checked={row.isActive}
            />
        },
        {
            title: 'Filtry',
            width: '80%',
            dataIndex: '',
            render: row => <ProductCustomRow
                filters={row.filters}
                brands={brands}
                categories={categories}
                flavours={flavours}
                tags={tags}
            />

        },
        {
            title: 'Akcja',
            width: '10%',
            dataIndex: '',
            render: row => <DeleteOutlined onClick={() => handleDelete(row)}/>
        },
    ];


    return <Table pagination={false} dataSource={data.map((it) => {
        return {...it, key: it.id};
    })} columns={columns}/>;
}

export default ProductCustomListItemTable;
import axiosInstance from "../../lib/axios";

interface UpdatePCIActivityProps {
    productCustomItemId: string
    isActive: boolean
}

const useUpdateProductCustomItemActivity = () => {

    const update = async (obj: UpdatePCIActivityProps): Promise<any> => {
        try {
            await axiosInstance.put("/admin/product_custom_list/product_custom_item/activity/" + obj.productCustomItemId, obj);
        } catch (err) {
            throw err;
        }
    };

    return { update };
}

export default useUpdateProductCustomItemActivity;
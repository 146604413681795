import styled from "styled-components";
import {Button, Checkbox, Typography} from "antd";
import React, {useState} from "react";
import CreateProductOwnerHeadquarterAddress
    from "../../Modals/ProductOwner/ProductOwnerAddress/CreateProductOwnerHeadquarterAddress";
import UpdateProductOwnerHeadquarterAddress
    from "../../Modals/ProductOwner/ProductOwnerAddress/UpdateProductOwnerHeadquarterAddress";
import CreateProductOwnerReturnAddress
    from "../../Modals/ProductOwner/ProductOwnerAddress/CreateProductOwnerReturnAddress";
import UpdateProductOwnerReturnAddress
    from "../../Modals/ProductOwner/ProductOwnerAddress/UpdateProductOwnerReturnAddress";
import CreateProductOwnerCommunicationModal
    from "../../Modals/ProductOwner/ProductOwnerCommunication/CreateProductOwnerCommunicationModal";
import UpdateProductOwnerCommunicationModal
    from "../../Modals/ProductOwner/ProductOwnerCommunication/UpdateProductOwnerCommunicationModal";
import {DownloadOutlined, EditOutlined, FileAddOutlined} from "@ant-design/icons";
import {themes} from "../../../layout/styles/ColorStyles";

const Wrapper = styled.div`
  background-color: #fff;
  padding: 20px;
`;

const {Text, Title} = Typography;

const MainInformationTab = ({data, reload}) => {

    const [openModalForNewHeadquarterAddress, setOpenModalForNewHeadquarterAddress] = useState(false);
    const [openModalForUpdateHeadquarterAddress, setOpenModalForUpdateHeadquarterAddress] = useState(false);
    const [openModalForUpdateReturnAddress, setOpenModalForUpdateReturnAddress] = useState(false);
    const [openModalForNewReturnAddress, setOpenModalForNewReturnAddress] = useState(false);
    const [openModalForNewCommunication, setOpenModalForNewCommunication] = useState(false);
    const [openModalForUpdateCommunication, setOpenModalForUpdateCommunication] = useState(false);

    return <Wrapper>
        <Title level={3}>Regulamin</Title>
        <Text>Regulamin współpracy z Appet.pl</Text>

        <div style={{margin: "10px"}}>
            <Checkbox
                disabled={true}
                style={{marginRight: "10px"}}
                checked={true}
            />
            <Text type="secondary">
                Akcetuję <a href={"https://www.appet.pl/regulamin"}>regulamin z Appet.pl</a>{" "}
            </Text>
        </div>
        <div>
            {
                data.regulation ? <Button icon={<DownloadOutlined />} size={'middle'}><a  rel="noreferrer" target="_blank" href={data.regulation.fullUrl}>Pobierz regulamin</a></Button> : 'Brak załączonego regulaminu'
            }
        </div>
        <div style={{display: 'flex', gap: '10px', alignItems: 'center'}}>
            <Title level={3}>Dane Adresowe</Title> {!data.headquarterView ?
            <FileAddOutlined onClick={() => setOpenModalForNewHeadquarterAddress(true)}
                             style={{fontSize: '20px', color: themes.activeMenuItemColor, paddingBottom: '10px'}}/> :
            <EditOutlined onClick={() => setOpenModalForUpdateHeadquarterAddress(true)}
                          style={{fontSize: '20px', color: themes.activeMenuItemColor, paddingBottom: '10px'}}/>}
        </div>
        <div
            style={{display: "flex", flexDirection: "column", gap: "10px"}}
        >
            <Text>Nazwa Sklepu</Text>
            <Text strong>{data.name}</Text>
            <Text>Ulica i numer</Text>
            <Text strong>
                {data.headquarterView
                    ? data.headquarterView.street
                    : " Brak danych"} {" "}
                {data.headquarterView
                    ? data.headquarterView.localNumber
                    : " Brak danych"}
            </Text>
            <Text>Miasto</Text>
            <Text strong>
                {data.headquarterView
                    ? data.headquarterView.city
                    : " Brak danych"}
            </Text>
            <Text>Kod pocztowy</Text>
            <Text strong>
                {data.headquarterView
                    ? data.headquarterView.postCode
                    : " Brak danych"}
            </Text>
        </div>
        <div style={{display: 'flex', flex: 1, gap: '10px', alignItems: 'center'}}>
            <Title level={3}>Adres do wysyłki zwrotów</Title> {!data.returnAddressView ?
            <FileAddOutlined onClick={() => setOpenModalForNewReturnAddress(true)}
                             style={{fontSize: '20px', color: themes.activeMenuItemColor, paddingBottom: '10px'}}/> :
            <EditOutlined onClick={() => setOpenModalForUpdateReturnAddress(true)}
                          style={{fontSize: '20px', color: themes.activeMenuItemColor, paddingBottom: '10px'}}/>}
        </div>
        <div
            style={{display: "flex", flexDirection: "column", gap: "10px"}}
        >
            <Text>Nazwa Sklepu</Text>
            <Text strong>{data.name} </Text>
            <Text>Ulica i numer</Text>
            <Text strong>
                {data.returnAddressView
                    ? data.returnAddressView.street + " " + data.returnAddressView.localNumber
                    : " Brak danych"}
            </Text>
            <Text>Miasto</Text>
            <Text strong>
                {data.returnAddressView
                    ? data.returnAddressView.city
                    : " Brak danych"}
            </Text>
            <Text>Kod pocztowy</Text>
            <Text strong>
                {data.returnAddressView
                    ? data.returnAddressView.postCode
                    : " Brak danych"}
            </Text>
        </div>
        <div style={{display: 'flex', flex: 1, gap: '10px', alignItems: 'center'}}>
            <Title level={3}>Komunikacja</Title> {!data.communicationView ?
            <FileAddOutlined onClick={() => setOpenModalForNewCommunication(true)}
                             style={{fontSize: '20px', color: themes.activeMenuItemColor, paddingBottom: '10px'}}/> :
            <EditOutlined onClick={() => setOpenModalForUpdateCommunication(true)}
                          style={{fontSize: '20px', color: themes.activeMenuItemColor, paddingBottom: '10px'}}/>}
        </div>
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                width: "50%",
                gap: "10px",
            }}
        >
            <Text>Telefon</Text>
            <Text strong>
                {data.communicationView
                    ? data.communicationView.number
                    : " Brak danych"}
            </Text>
            <Text>Email</Text>
            <Text strong>
                {data.communicationView
                    ? data.communicationView.email
                    : " Brak danych"}
            </Text>
            <Text>Numer konta bankowego</Text>
            <Text strong>
                {data.communicationView
                    ? data.communicationView.accountBankNumber
                    : " Brak danych"}
            </Text>
        </div>
        <CreateProductOwnerHeadquarterAddress
            openModal={openModalForNewHeadquarterAddress} item={data} reload={reload}
            handleClose={() => setOpenModalForNewHeadquarterAddress(false)}
        />
        <UpdateProductOwnerHeadquarterAddress
            reload={reload}
            openModal={openModalForUpdateHeadquarterAddress}
            handleClose={() => setOpenModalForUpdateHeadquarterAddress(false)}
            item={data}
        />
        <CreateProductOwnerReturnAddress openModal={openModalForNewReturnAddress}
                                         item={data}
                                         reload={reload}
                                         handleClose={() => setOpenModalForNewReturnAddress(false)}/>
        <UpdateProductOwnerReturnAddress openModal={openModalForUpdateReturnAddress}
                                         handleClose={() => setOpenModalForUpdateReturnAddress(false)}
                                         item={data}
                                         reload={reload}/>

        <CreateProductOwnerCommunicationModal openModal={openModalForNewCommunication}
                                              handleClose={() => setOpenModalForNewCommunication(false)}
                                              item={data}
                                              reload={reload}
        />
        <UpdateProductOwnerCommunicationModal openModal={openModalForUpdateCommunication}
                                              handleClose={() => setOpenModalForUpdateCommunication(false)}
                                              item={data} reload={reload}/>
    </Wrapper>;

}

export default MainInformationTab;
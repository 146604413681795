import useAlert from "../useAlert";
import axiosInstance from "../../lib/axios";

type UpdateIsPublishedBlog = {
    id: string,
    isPublished: string;
}

const useUpdateIsPublishedBlogPost = () => {

    const {successMessage, errorMessage} = useAlert();

    const update = async (obj: UpdateIsPublishedBlog): Promise<any> => {
        try {
            await axiosInstance.put("/admin/blog/post/is-published/" + obj.id, obj);
            successMessage("Zapisano");
        } catch (err) {
            errorMessage("Błąd zapisu, spróbuj ponownie");
            throw err;
        }
    };

    return {update};
}

export default useUpdateIsPublishedBlogPost;
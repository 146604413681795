import {useState} from "react";
import axiosInstance from "../../lib/axios";


const useGetDiscountById = () => {

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [data, setData] = useState<{} | null>(null);

    const getDiscountById = async (id: string): Promise<any> => {
        try {
            const res = await axiosInstance.get(`/admin/discount/${id}`);
            if (res.status === 200) {
                setData(res.data ? res.data : []);
            }
            setIsLoading(false);
        } catch (err) {
            console.log("err");
            setIsLoading(false);
        }
    }

    return {isLoading, data, getDiscountById};
}

export default useGetDiscountById;
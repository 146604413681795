import React, {useContext} from "react";
import ProductVariantsFiltersContext from "../../../context/ProductVariantsFiltersContext";
import {Select} from "antd";
import {UPDATE_ORDER_BY} from "../../../reducers/ProductVariantsFiltersReducer";

const OrderBySelect = () => {
    const {dispatch, state} = useContext(ProductVariantsFiltersContext);
    return  <Select
        placeholder="Wybierz rodzaj sortowania?"
        mode="single"
        onChange={(e) => dispatch({type: UPDATE_ORDER_BY, payload: e})}
        defaultValue={state.orderBy}
        allowClear={true}
    >
        <Select.Option value={'stockDesc'}>Najmniej stanów</Select.Option>
        <Select.Option value={'stockAsc'}>Najwięcej stanów</Select.Option>
        <Select.Option value={'priceDesc'}>Cena od najwyższej</Select.Option>
        <Select.Option value={'priceAsc'}>Cena od najniższej</Select.Option>
        <Select.Option value={'newset'}>Najnowsze</Select.Option>
    </Select>

}

export default OrderBySelect;
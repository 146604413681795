import axiosInstance from "../../lib/axios";
import {useState} from "react";
import useAlert from "../useAlert";

const useUpdateNameBrand = () => {
    const [isLoading, setIsLoading] = useState(false);
    const {successMessage, errorMessage} = useAlert();

    const update = async (id: string, name: string): Promise<any> => {
        try {
            setIsLoading(true);
            const obj = {
                name: name,
            };
            await axiosInstance.put("/admin/tag/" + id, obj);
            setIsLoading(false);
            successMessage('Nazwa Zmieniona')
        } catch (err) {
            setIsLoading(false);
            errorMessage('Błąd zapisu, spróbuj ponownie');
            throw err;
        }
    };

    return { update, isLoading };
}

export default useUpdateNameBrand;
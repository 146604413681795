import {Table, Tag} from "antd";
import React from "react";
import UpdateBreed from "../../Modals/Breed/UpdateBreed";
import AssignBreedToTag from "../../Modals/Breed/AssignBreedToTag";

const TableBreed = ({data, reload, tags}) => {

    const columns = [
        {
            title: 'Nazwa',
            key: 'name',
            dataIndex: 'name',
            width: '10%',
        },
        {
            title: 'Typ',
            key: 'type',
            dataIndex: 'type',
            width: '5%',
        },
        {
            title: '',
            key: 'action',
            width: '15%',
            render: row => <div style={{display: 'flex', gap: '10px'}}>
                <UpdateBreed
                    reload={reload}
                    item={row}
                />
                <AssignBreedToTag reload={reload} tags={tags} item={row}/>
            </div>
        },
        {
            title: 'Tagi dla rasy',
            key: 'type',
            dataIndex: 'tags',
            width: '17,5%',
            render: row => row.other.map((it) => <Tag>{it.name}</Tag>)
        },
        {
            title: 'Tagi dla rasy w wieku młodszym',
            key: 'type',
            dataIndex: 'tags',
            width: '17,5%',
            render: row => row.puppy.map((it) => <Tag>{it.name}</Tag>)
        },
        {
            title: 'Tagi dla rasy w wieku dorosłym',
            key: 'type',
            dataIndex: 'tags',
            width: '17,5%',
            render: row => row.mature.map((it) => <Tag>{it.name}</Tag>)
        },
        {
            title: 'Tagi dla rasy w wieku starszym',
            key: 'type',
            dataIndex: 'tags',
            width: '17,5%',
            render: row => row.old.map((it) => <Tag>{it.name}</Tag>)
        }
    ];


    return <Table columns={columns} pagination={false} dataSource={data.map((it, index) => {
        return {
            ...it, key: index
        }
    })}/>

}

export default TableBreed;
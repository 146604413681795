import useCreateBrand from "../../../hooks/brands/useCreateBrand";
import BrandForm from "../../Forms/Brands/BrandForm";
import React from "react";

const CreateBrandModal = ({ reload, setOpenModal, open }) => {

  const { create } = useCreateBrand();

  const handleSave = (obj) => {
    create(obj)
      .then(() => {
        setOpenModal(false);
        reload();
      });
  };

  return (
      <BrandForm open={open} onCancel={() => setOpenModal(false)}
                 handleOK={handleSave}
                 title={'Dodaj markę'}/>
  );
};

export default CreateBrandModal;

import { useState } from "react";
import axiosInstance from "../../lib/axios";
import useAlert from "../useAlert";

const useCacheClear = () => {

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const {successMessage} = useAlert();

    const deleteCache = async () : Promise<any> => {
        try {
            setIsLoading(true);
            await axiosInstance.delete('/admin/main/clear-cache');
            setIsLoading(false);
            successMessage("Cache wyczyszczone");
        } catch (err) {
            setIsLoading(false);
            throw err;
        }
    }

    return {isLoading, deleteCache};
}

export default useCacheClear;
import styled from "styled-components";
import useGetAllProductOwners from "../../hooks/productOwners/useGetAllProductOwners";
import Title from "../../components/Title";
import FormIndicator from "../../components/Forms/FormIndicator";
import ProductOwnerTable from "../../components/Tables/ProductOwners/ProductOwnerTable";
import CreateProductOwnerModal from "../../components/Modals/ProductOwner/CreateProductOwnerModal";
import CustomButton from "../../components/Forms/Buttons/CustomButton";
import {FaPlus} from "react-icons/fa";
import {useState} from "react";

const Wrapper = styled.div`
  position: relative;
`;


const ProductOwners = () => {
    const {data, isLoading, reload }= useGetAllProductOwners();
    const [openModal, setOpenModal] = useState(false);

    return (
      <>
          <Wrapper>
              <Title title="Sklepy"/>
              <CustomButton
                  title="Utwórz"
                  icon={<FaPlus />}
                  onClick={() => setOpenModal(true)}
              />
              {(isLoading) ? <FormIndicator numberOfElements={1} /> : <ProductOwnerTable data={data} reload={() => reload()}/>}
              <CreateProductOwnerModal
                  open={openModal}
                  setOpenModal={setOpenModal}
                  reload={reload}
              />
          </Wrapper>
      </>
    );
}

export default ProductOwners;
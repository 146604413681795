import React, { ReactNode, useState } from "react";

interface Props {
  children: ReactNode | ReactNode[];
}

export interface ICategory {
  opened: Array<number>;
}

export interface ICategoryProviderProps {
  categories?: ICategory;
  setCategories: (state: ICategory) => void;
}

const initialState: ICategory = {
  opened: [],
};

export const CategoryContext =
  React.createContext<ICategoryProviderProps | null>(null);

export const CategoryProvider = ({ children }: Props): JSX.Element => {
  const [categories, setCategories] = useState<ICategory>(initialState);

  const providerValue: ICategoryProviderProps = {
    categories,
    setCategories,
  };

  return (
    <CategoryContext.Provider value={providerValue}>
      {children}
    </CategoryContext.Provider>
  );
};

export default CategoryContext;

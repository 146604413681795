import useAlert from "../useAlert";
import axiosInstance from "../../lib/axios";

interface UpdateProductOwnerHeadquarterAddressProps {
    id: string;
    street: string;
    city: string;
    postCode: string;
    localNumber: string;
}
const useUpdateProductOwnerHeadquarterAddress = () => {
    const { successMessage, errorMessage } = useAlert();

    const update = async (
        obj: UpdateProductOwnerHeadquarterAddressProps
    ): Promise<any> => {
        try {
            await axiosInstance.put("/admin/product_owner/headquarter/" + obj.id, obj);
            successMessage("Dane zmienione");
        } catch (err) {
            errorMessage("Błąd zapisu. Spróbuj ponownie.");
            throw err;
        }
    };

    return { update };
}

export default useUpdateProductOwnerHeadquarterAddress;
import {Button, Image, Table, Typography} from "antd";
import styled from "styled-components";
import PaymentStatusLabel from "../../Labels/PaymentStatusLabel";
import useMakeBillForOrder from "../../../hooks/orders/useMakeBillForOrder";

const ContentPriceDetail = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
`;


const OrderSettlementTable = ({orderSettlement, orderId, reload}) => {

    const {makeBillForOrder} = useMakeBillForOrder();

    const columns = [
        {
            title: "Wariant",
            dataIndex: "productVariantView",
            key: "productVariant",
            render: (row) => (
                <div style={{display: "flex", gap: "5px"}}>
                    {
                        row.photo ? <Image width={130} height={130} preview={true} src={row.photo.fullPath}/> : 'Brak '
                    }
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-around",
                        }}
                    >
                        <div>
                            <div style={{fontWeight: "600"}}>{row.name}</div>
                        </div>
                    </div>
                </div>
            ),
        },
        {
            title: "Ilość",
            dataIndex: "",
            key: "",
            render: row => row.quantity + ' x ' + row.productVariantView.productGroup.variantValue.formattedValueWithUnit
        },
        {
            title: "Cena netto",
            dataIndex: "totalPriceNet",
            key: "",
            render: row => row + ' zł'
        },
        {
            title: "Prowizja dla Appet",
            dataIndex: "",
            key: "",
            render: row => <div>
                <Typography>{row.totalCommissionNet} zł</Typography><Typography>({row.commissionPercent} %)</Typography>
            </div>
        },
    ];


    const handleMakeBill = () => {
        makeBillForOrder(orderId).then(reload);
    }

    return <><Table pagination={false} columns={columns}
                    dataSource={orderSettlement.orderSettlement.orderSettlementItems.map((it, index) => {
                        return {
                            ...it,
                            key: index
                        }
                    })}/>
        <div
            style={{
                backgroundColor: "white",
                height: "136px",
                display: "flex",
            }}
        >
            <div
                style={{
                    display: "flex",
                    width: "60%",
                    alignItems: "center",
                    paddingLeft: "20px",
                }}
            >
                {/*Aktywowane rabaty: <br />*/}
                {/*Nazwa rabatu -5%*/}
            </div>
            <div
                style={{
                    display: "flex",
                    width: "40%",
                    flexDirection: "column",
                }}
            >
                <ContentPriceDetail>
                    <div>Wartość zamówienia brutto:</div>
                    <div>{orderSettlement.orderSettlement.orderPriceGross} zł</div>
                </ContentPriceDetail>
                <ContentPriceDetail>
                    <div>Wartość zamówienia netto:</div>
                    <div>{orderSettlement.orderSettlement.orderPriceNet} zł</div>
                </ContentPriceDetail>
                <ContentPriceDetail>
                    <div>Prowizja dla Appet:</div>
                    <div>{orderSettlement.orderSettlement.totalCommission} zł</div>
                </ContentPriceDetail>
            </div>
        </div>
        <OrderDetailComponent>
            <OrderMainInfoComponent>
                {orderSettlement.orderSettlementBill ? <><OrderDetailRow>
                        <OrderDetailRowLeft>Rachunek rozliczony</OrderDetailRowLeft>
                        <OrderDetailRowRight>
                            <PaymentStatusLabel paid={orderSettlement.orderSettlementBill.billPaid}/>
                        </OrderDetailRowRight>
                    </OrderDetailRow>
                        <OrderDetailRow>
                            <OrderDetailRowLeft>Data rozliczenia</OrderDetailRowLeft>
                            <OrderDetailRowRight>
                                {orderSettlement.orderSettlementBill.orderDate}
                            </OrderDetailRowRight>
                        </OrderDetailRow>
                        <OrderDetailRow>
                            <OrderDetailRowLeft>Numer zamowienia</OrderDetailRowLeft>
                            <OrderDetailRowRight>
                                {orderSettlement.orderSettlementBill.orderNumber}
                            </OrderDetailRowRight>
                        </OrderDetailRow>
                        <OrderDetailRow>
                            <OrderDetailRowLeft>Kwota zamówienia</OrderDetailRowLeft>
                            <OrderDetailRowRight>
                                {orderSettlement.orderSettlementBill.orderPrice}
                            </OrderDetailRowRight>
                        </OrderDetailRow>
                        <OrderDetailRow>
                            <OrderDetailRowLeft>Prowizja</OrderDetailRowLeft>
                            <OrderDetailRowRight>
                                {orderSettlement.orderSettlementBill.orderCommission}
                            </OrderDetailRowRight>
                        </OrderDetailRow>
                    </>
                    :  <OrderDetailRow>
                        <OrderDetailRowLeft>Brak informacji o rachunku</OrderDetailRowLeft>
                    <OrderDetailRowRight>
                        <Button type='primary' onClick={() => handleMakeBill()}>Podziel rachunek</Button>
                    </OrderDetailRowRight></OrderDetailRow>}
            </OrderMainInfoComponent>
        </OrderDetailComponent>
    </>
}

export default OrderSettlementTable;

const OrderDetailComponent = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
`;

const OrderDetailRow = styled.div`
  display: flex;
  flex-direction: row;
  height: 56px;
  border-top: 1px solid #f0f0f0;
`;

const OrderDetailRowLeft = styled.div`
  width: 25%;
  background-color: #fafafa;
  height: 100%;
  display: flex;
  align-items: center;
  padding-left: 25px;
  border-right: 1px solid #f0f0f0;
`;

const OrderDetailRowRight = styled.div`
  width: 75%;
  height: 100%;
  display: flex;
  align-items: center;
  padding-left: 25px;
`;

const OrderMainInfoComponent = styled.div`
  flex: 2;
  background-color: white;
`;
import {useState} from "react";
import useAlert from "../useAlert";
import axiosInstance from "../../lib/axios";

const useClearCacheProduct = () => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const {successMessage} = useAlert();

    const deleteCache = async (id: string) : Promise<any> => {
        try {
            setIsLoading(true);
            await axiosInstance.delete('/admin/product/clear-cache/' + id);
            setIsLoading(false);
            successMessage("Cache wyczyszczone");
        } catch (err) {
            setIsLoading(false);
            throw err;
        }
    }

    return {isLoading, deleteCache};
}

export default useClearCacheProduct;
import {useState} from "react";
import axiosInstance from "../../lib/axios";

const useUpdateProductOwnerInfo = () => {
    const [isLoading, setIsLoading] = useState(false);

    const update = async (id: string, name: string, ): Promise<any> => {
        try {
            setIsLoading(true);
            const obj = {
                name: name,
            };
            const { data } = await axiosInstance.put("/admin/product_owner/info/" + id, obj);

            if (data.id) {
                setIsLoading(false);
            }
        } catch (err) {
            setIsLoading(false);
            throw err;
        }
    };

    return { update, isLoading };
}

export default useUpdateProductOwnerInfo;

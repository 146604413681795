export const CHANGE_PAGE = "change_page";
export const CHANGE_LIMIT = "change_limit";
export const CHANGE_PAGE_AND_LIMIT = "change_page_and_limit";
export const UPDATE_TEXT_SEARCH = "update_text_search";
export const UPDATE_STATUS_REVIEW = "update_status_review";
export const UPDATE_DATE_FROM = 'update_date_from';
export const UPDATE_DATE_TO = 'update_date_to';
export const UPDATE_RATE_FROM = 'update_rate_from';
export const UPDATE_RATE_TO = 'update_rate_to';
export const CLEAR_ALL = 'clear_all';
export const UPDATE_SORT_BY = 'update_sort_by';

export const limit = "limit";
export const page = "page";
export const textSearch = "textSearch";
export const statusReview = "statusReview";
export const createdAtFrom = 'createdAtFrom';
export const createdAtTo = 'createdAtTo';
export const rateFrom = 'rateFrom';
export const rateTo = 'rateTo';
export const sortBy = 'sortBy';

export const initialState = {
    [limit]: 20,
    [page]: 1,
    [textSearch]: '',
    [statusReview]: '',
    [createdAtFrom]: '',
    [createdAtTo]: '',
    [rateFrom]: 1,
    [rateTo]: 5,
    [sortBy]: 'createdAt'
}

type ACTIONTYPE =
    | { type: typeof CHANGE_PAGE; payload: number }
    | { type: typeof CHANGE_LIMIT; payload: number }
    | { type: typeof CHANGE_PAGE_AND_LIMIT; payload: { [page]: number; [limit]: number } }
    | { type: typeof UPDATE_TEXT_SEARCH; payload: string }
    | { type: typeof UPDATE_STATUS_REVIEW; payload: string }
    | { type: typeof UPDATE_DATE_FROM; payload: string }
    | { type: typeof UPDATE_DATE_TO; payload: string }
    | { type: typeof UPDATE_RATE_FROM; payload: number }
    | { type: typeof UPDATE_RATE_TO; payload: number }
    | { type: typeof CLEAR_ALL; payload: null}
    | { type: typeof UPDATE_SORT_BY; payload: string }
    ;


export const ReviewProductFilterReducer = (
    state: typeof initialState,
    action: ACTIONTYPE
) => {

    switch (action.type) {
        case CHANGE_PAGE:
            return {
                ...state,
                [page]: action.payload,
            };
        case CHANGE_LIMIT:
            return {
                ...state,
                [limit]: action.payload,
            };
        case CHANGE_PAGE_AND_LIMIT:
            return {
                ...state,
                [page]: action.payload[page],
                [limit]: action.payload[limit],
            };
        case UPDATE_TEXT_SEARCH:
            return {
                ...state,
                [textSearch]: action.payload,
            };
        case UPDATE_DATE_FROM:
            return {
                ...state,
                [createdAtFrom]: action.payload,
            };
        case UPDATE_DATE_TO:
            return {
                ...state,
                [createdAtTo]: action.payload,
            };
        case UPDATE_RATE_FROM:
            return {
                ...state,
                [rateFrom]: action.payload,
            }
        case UPDATE_RATE_TO:
            return {
                ...state,
                [rateTo]: action.payload,
            }
        case UPDATE_STATUS_REVIEW:
            return {
                ...state,
                [statusReview]: action.payload,
            }
        case CLEAR_ALL:
            return initialState;
        case UPDATE_SORT_BY:
            return {
                ...state,
                [sortBy]: action.payload,
            }
        default:
            return state;
    }
}
import {useState} from "react";
import axiosInstance from "../../../lib/axios";

const useGetPrzelewy24Configuration = () => {


    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [data, setData] = useState<{} | null>(null);

    const getPrzelewyConfiguration = async (id: string): Promise<any> => {
        try {
            setIsLoading(true);
            const res = await axiosInstance.get(`/admin/product_owner/p24/`+id);
            if (res.status === 200) {
                setData(res.data ? res.data : []);
                setIsLoading(false);
            }
        } catch (err) {
            console.log("err");
            setIsLoading(false);
        }
    };

    const reload = (id: string) => {
        getPrzelewyConfiguration(id);
    };

    return { isLoading, getPrzelewyConfiguration, reload, data};
}

export default useGetPrzelewy24Configuration;
import useCreateIngredient from "../../../hooks/ingredient/useCreateIngredient";
import IngredientForm from "../../Forms/Ingredient/IngredientForm";

const CreateIngredient = ({grammages, reload, handleClose, openModal}) => {

    const {create} = useCreateIngredient();

    const handleOk = (values) => {
        create(values).then(reload);
        handleClose();
    };

    return <IngredientForm
        grammages={grammages}
        open={openModal}
        onCancel={handleClose}
        handleOK={(values) => handleOk(values)}
    />
}

export default CreateIngredient;
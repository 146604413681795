import ImageUploading from "react-images-uploading";
import ButtonLink from "../Buttons/ButtonLink";

const LogoUploader = ({item, update}) => {

    const onChange = (imageList, addUpdateIndex) => {
        const fotoItem = imageList[addUpdateIndex];

        const data = new FormData();
        data.append("name", fotoItem.file.name);
        data.append("file", fotoItem.file);

        update(data, item.id);
    };
    return (
        <ImageUploading
            mode='single'
            value={item.logo}
            onChange={onChange}
            dataURLKey="data_url"
        >
            {({onImageUpdate, dragProps}) => (
                // write your building UI
                <div className="upload__image-wrapper">
                    <div onClick={() => onImageUpdate(item)} {...dragProps}>
                        <ButtonLink text={'Aktualizuj Logo'}/>
                    </div>
                </div>
            )}
        </ImageUploading>
    );
};

export default LogoUploader;

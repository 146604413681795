import styled from "styled-components";
import {Button, Image} from "antd";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 5px;
`;

const MenuItemWrapper = styled.div`
  display: flex;
  width: 100%;
  font-size: 16px;
  font-weight: 500;
  box-sizing: border-box;
  color: #364a63;
  gap: 10px;
`;

const ProductVariantSizeMenu = ({selected, getDebugInfo, sizeMenu}) => {
    return (
        <Wrapper>
            {sizeMenu.map((it) => (
                <MenuItemWrapper
                    key={it.id}
                    isSelected={it.code === selected}
                    onClick={() => getDebugInfo(it)}
                >
                    <Button style={{height: '40px'}}><Image height={30} src={it.owner.logo}/>{it.name}</Button>
                </MenuItemWrapper>
            ))}
        </Wrapper>
    );
};

export default ProductVariantSizeMenu;
import {Modal} from "antd";
import ButtonLink from "../../Buttons/ButtonLink";
import {useEffect, useState} from "react";
import MultiSelect from "../../Selects/MultiSelect";
import useAssignIngredientsToIngredientGroup
    from "../../../hooks/ingredientGroup/useAssignIngredientsToIngredientGroup";

const AssignIngredientToIngredientGroup = ({reload, ingredients, item}) => {

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [selectedIngredient, setSelectedIngredient] = useState(null);
    const [selectedIds, setSelectedIds] = useState([]);

    const {update} = useAssignIngredientsToIngredientGroup();

    useEffect(() => {
        let tmp = item.ingredients.map((it) => it.name);
        setSelectedIngredient(tmp);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleOk = () => {
        update({ingredientsIds: selectedIds, id: item.id}).then(reload);
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    return <>
        <ButtonLink onClick={showModal} text={'Przypisz składnik'}/>
        <Modal title="Przypisywanie składników" open={isModalVisible} onOk={handleOk} onCancel={handleCancel} mask={false}
               width={'80%'} bodyStyle={{height: '90%'}}
        >
            <MultiSelect
                values={ingredients}
                selected={selectedIngredient}
                setSelected={setSelectedIngredient}
                setSelectedIds={setSelectedIds}
                handleSave={handleOk}
            />
        </Modal>
    </>
}

export default AssignIngredientToIngredientGroup;
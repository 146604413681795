import {useEffect, useState} from "react";
import axiosInstance from "../../lib/axios";

const useGetAllBreeds = () => {

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [data, setData] = useState<{} | null>(null);

    useEffect(() => {
        getBreeds()
    }, []);

    const getBreeds = async (): Promise<any> => {

        try {
            const res = await axiosInstance.get(`/admin/breed/`);

            if (res.status === 200) {
                setData(res.data ? res.data : []);
                setIsLoading(false);
            }
        } catch (err) {
            setIsLoading(false);
        }
    };

    const reload = () => {
        getBreeds();
    };

    return { isLoading, data, reload, getBreeds };
}

export default useGetAllBreeds;
import {useState} from "react";
import axiosInstance from "../../../lib/axios";
import useAlert from "../../useAlert";

type CreateProductConnection = {
    p1: string,
    p2Ids: any,
}

const useCreateProductConnection = () => {

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const {successMessage, errorMessage } = useAlert();

    const create = async (obj: CreateProductConnection): Promise<any> => {
        try {
            setIsLoading(true);
            await axiosInstance.post("/admin/related/", obj);

            successMessage('Zapisano relacje')
            setIsLoading(false);
        } catch (err) {
            setIsLoading(false);
            errorMessage('Błąd podczas zapisu relacji')
            throw err;
        }
    };

    return {create, isLoading};

}

export default useCreateProductConnection;
import {useState} from "react";
import axiosInstance from "../../lib/axios";

const useRegenerateP24Payment = () => {

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [data, setData] = useState<{} | null>(null);

    const regenerateP24Payment = async (id: string): Promise<any> => {
        try {
            setIsLoading(true);
            const res = await axiosInstance.post(`/admin/order/regenerate-payment/${id}`);

            if (res.status === 200) {
                setData(res.data);
            }
                setIsLoading(false);
        } catch (err) {
            setIsLoading(false);
            throw err;
        }
    };

    const reload = (id: string) => {
        regenerateP24Payment(id);
    }

    return {isLoading, regenerateP24Payment, data, reload};
}

export default useRegenerateP24Payment;
import {FaTrash} from "react-icons/fa";
import PositionButton from "../../Buttons/PositionButton";
import React from "react";
import styled from "styled-components";
const RightActions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
`;

const FotoWrapper = styled.div`
  width: 100%;
  background-color: #f8f8f8;
  border-radius: 5px;
  padding: 10px;
  display: grid;
  grid-template-columns: 140px auto 100px;
  flex-direction: row;
  margin-bottom: 10px;
`;

const CenterActions = styled.div`
  display: flex;
  align-items: center;
`;
const ButtonRemove = styled.div`
  background-color: #e32d2d;
  color: #ffffff;
  height: 30px;
  padding: 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  gap: 10px;
  transition: 0.2s ease-in;

  :hover {
    background-color: #f54747;
    cursor: pointer;
  }
`;
const ProductMediaList = ({handleRemove, handleChangePosition, data = []}) => {

    return <div>{
        data.map((image, index) => (
            <FotoWrapper key={index}>
                <img src={image["fullPath"]} alt="" width="130" height="80"/>
                <CenterActions>
                    <ButtonRemove onClick={() => handleRemove(index)}>
                        <FaTrash/>
                        <div>Usuń</div>
                    </ButtonRemove>
                </CenterActions>

                <RightActions>
                    <div>
                        <PositionButton
                            direction="up"
                            // active={image.position !== 0 && data.length > 1}
                            active={index !== 0}
                            onClick={() =>
                                handleChangePosition(image.photoId, index - 1)
                            }
                        />
                    </div>
                    <div>
                        <PositionButton
                            direction="down"
                            // active={data.length - 1 !== image.position && data.length > 1}
                            active={data.length - 1 !== index}
                            onClick={() =>
                                handleChangePosition(image.photoId, index + 1)
                            }
                        />
                    </div>
                </RightActions>
            </FotoWrapper>
        ))
    }</div>;
}

export default ProductMediaList;
import styled from "styled-components";
import {useState} from "react";
import useUpdateVatRate from "../../../hooks/products/useUpdateVatRate";
import FormTitle from "../FormTitle";
import CustomButton from "../Buttons/CustomButton";
import {FaSave} from "react-icons/fa";
import VatRateSelect from "../../Selects/VatRateSelect";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 100px;
`;

const UpdateProductVatRate = ({product, reload}) => {

    const [selectedVatRate, setSelectedVatRate]=useState(null);
    const {update} = useUpdateVatRate();

    const onChange = (value) => {
        setSelectedVatRate(value);
    };


    const handleUpdate = ()=>{
        if (!selectedVatRate) {
            return;
        }

        update({productId: product.id, vatRate: selectedVatRate}).then(reload);

    }

    return (
        <div>
            <FormTitle title={"Vat"} />
            <Wrapper>
               <VatRateSelect value={product.vatRate.value} handleChange={onChange}/>
                <div>
                    <CustomButton title="Zapisz" icon={<FaSave />} onClick={handleUpdate} />
                </div>
            </Wrapper>
        </div>
    );
}

export default UpdateProductVatRate;
import styled from "styled-components";
import {themes} from "./ColorStyles";

export const WrapperTable = styled.table`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 10px;
  font-size: 14px;
  font-family: "DM Sans", sans-serif, "Helvetica Neue", Arial, "Noto Sans",
    sans-serif;
  color: #4d5c78;
`;

export const WrapperThead = styled.thead``;

export const WrapperTBody = styled.tbody`
  ::before {
    content: "@";
    display: block;
    line-height: 0px;
    text-indent: -99999px;
  }
`;

export const WrapperTr = styled.tr`
  background-color: #ffff;
  height: 100px;
  > * {
    &:first-child {
      padding-left: 10px;
      border-radius: 5px 0px 0px 5px;
    }

    &:last-child {
      width: 80px;
      text-align: center;
      border-radius: 0px 5px 5px 0px;
    }
  }

  :hover {
    -webkit-box-shadow: 0px 0px 10px 7px rgba(0, 0, 0, 0.16);
    box-shadow: 0px 0px 10px 7px rgba(0, 0, 0, 0.16);
  }
`;

export const WrapperTrHead = styled(WrapperTr)`
  font-weight: bold;
  width: 100%;
  height: 60px;
`;

// actions tooltip

export const CustomIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
`;

export const CustomIcon = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #efeff5;
  font-size: 20px;
  transition: 0.3s ease-in;

  :hover {
    background-color: #d3d3d3;
    cursor: pointer;
  }
`;

export const TooltipWrapper = styled.div`
  position: absolute;
  top: 60px;
  right: 20px;
  width: auto;
  min-width: 150px;
  height: auto;
  background-color: #ffffff;
  border: 1px solid #e5e9f2;
  border-radius: 10px;
  z-index: 1;
  visibility: ${(props) => (props.showTooltip ? "visible" : "hidden")};
`;

export const ItemTooltipWrapper = styled.div`
  position: relative;
  display: flex;
  padding: 10px;
  gap: 15px;
  color: #526484;

  :hover {
    color: ${themes.activeMenuItemColor};
    cursor: pointer;
  }
`;

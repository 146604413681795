import FormTitle from "../FormTitle";
import FormIndicator from "../FormIndicator";
import MultiSelect from "../../Selects/MultiSelect";
import React, {useEffect, useState} from "react";
import useGetAllFlavours from "../../../hooks/flavours/useGetAllFlavours";
import useAssignFlavourToProduct from "../../../hooks/products/useAssignFlavourToProduct";
import CustomButton from "../Buttons/CustomButton";

const FlavoursSelect = ({productId, selectedApi, reload}) => {

    const {data, isLoading} = useGetAllFlavours();
    const {update} = useAssignFlavourToProduct();
    const [selectedFlavours, setSelectedFlavours] = useState([]);
    const [selectedIds, setSelectedIds] = useState([]);

    useEffect(() => {
        setSelectedFlavours(selectedApi.map((it) => it.name));
        setSelectedIds(selectedApi.map((it) => it.id))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleSave = () => {
        update({flavourIds: selectedIds, productId: productId}).then(() => reload());
    }

    return <>
        <FormTitle title={"Przypisywanie smaków"}/>
        {
            (isLoading) ? <FormIndicator numberOfElements={3}/> :

                <div style={{gap: '10px'}}>
                    <MultiSelect
                        values={data}
                        selected={selectedFlavours}
                        setSelected={setSelectedFlavours}
                        setSelectedIds={setSelectedIds}
                        handleSave={handleSave}
                    />
                    <CustomButton title={'Zapisz'} onClick={handleSave}/>
                </div>

        }
    </>;
}

export default FlavoursSelect;
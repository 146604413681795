import {useState} from "react";
import useAlert from "../useAlert";
import axiosInstance from "../../lib/axios";

const useUpdatePasswordUser = () => {

    const [isLoading, setIsLoading] = useState(false);
    const {successMessage, errorMessage} = useAlert();

    const update = async (id: string, password: string): Promise<any> => {
        try {
            setIsLoading(true);
            const obj = {
                password: password,
            };
            await axiosInstance.put("/admin/users/password/" + id, obj);
            setIsLoading(false);
            successMessage('Zapisano')
        } catch (err) {
            setIsLoading(false);
            errorMessage('Błąd zapisu, spróbuj ponownie');
            throw err;
        }
    };

    return { update, isLoading };
}

export default useUpdatePasswordUser;
import {useEffect, useState} from "react";
import axiosInstance from "../../lib/axios";

const useGetAllFlavours = () => {

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [data, setData] = useState<{} | null>(null);

    useEffect(() => {
        getFlavours()
    }, []);

    const getFlavours = async (): Promise<any> => {
        try {
            const res = await axiosInstance.get(`/admin/flavour/`);

            if (res.status === 200) {
                setData(res.data ? res.data : []);
                setIsLoading(false);
            }
        } catch (err) {
            setIsLoading(false);
        }
    };

    const reload = () => {
        getFlavours();
    }

    return {isLoading, data, reload};
}

export default useGetAllFlavours;
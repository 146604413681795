import { useState } from "react";
import axiosInstance from "../../../lib/axios";
import useAlert from "../../useAlert";

type CreateCodeByNameProps = {
  id: string;
  name: string;
  maxUse: number;
};

const useCreateNewCodeByName = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const {successMessage, errorMessage} = useAlert();


  const create = async (
    obj: CreateCodeByNameProps,
  ): Promise<any> => {
    try {
      await axiosInstance.post(
        "/admin/discount-code/",
        obj
      );

      successMessage("Zapisano");
    } catch (err) {
      setIsLoading(false);
      errorMessage("Błąd zapisu, spróbuj ponownie");
      throw err;
    }
  };

  return { create, isLoading };
};

export default useCreateNewCodeByName;

import useAlert from "../useAlert";
import axiosInstance from "../../lib/axios";

interface CreateBlogPostProps {
    title: string;
    blogCategoriesIds: Array<string>,
}

const useCreateBlogPost = () => {
    const {successMessage, errorMessage} = useAlert();

    const create = async (
        obj: CreateBlogPostProps
    ): Promise<any> => {

        try {
            await axiosInstance.post(
                `/admin/blog/post/`,
                obj
            );

            successMessage("Post został dodany");

        } catch (err) {
            errorMessage("Wystąpił błąd, spróbuj ponownie");
            throw err;
        }
    };

    return {create};
}

export default useCreateBlogPost;
import React from "react";
import {Input} from "antd";
import styled from "styled-components";

const { TextArea } = Input;

const LabelWrapper = styled.div`
  margin-bottom: 10px;
`;

const Label = styled.label`
  color: #3B4759;
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
`;

const ItemWrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
`;

const DescriptionWrapper = ({label, description}) => {

    return <ItemWrapper>
        <LabelWrapper>
            <Label htmlFor={label}>{label}</Label>
        </LabelWrapper>
        <TextArea style={{backgroundColor:'#EFEFEF'}} rows={5} label='Opis wariantu' value={description} />
    </ItemWrapper>
}

export default DescriptionWrapper;
import useGetAllCategories from "../../hooks/category/useGetAllCategories";
import Title from "../../components/Title";
import CategoryTable from "../../components/Tables/Categories/CategoryTable";
import FormIndicator from "../../components/Forms/FormIndicator";

const CategoryList = () => {

    const { data, isLoading, reload } = useGetAllCategories();


    return <>
        <Title title="Lista dostępnych kategorii"/>

        {isLoading ? <FormIndicator numberOfElements={3}/> : <CategoryTable data={data} reload={reload}/>}
    </>
}

export default CategoryList;
import {useState} from "react";
import useAlert from "../useAlert";
import axiosInstance from "../../lib/axios";

interface DeleteIngredientForProduct {
    ingredientId: string,
    productId: string
}

const useDeleteIngredientFromProduct = () => {

    const [isLoading, setIsLoading] = useState(false);
    const {successMessage, errorMessage} = useAlert();

    const deleteRel = async (
        obj: DeleteIngredientForProduct
    ): Promise<any> => {

        try {
            setIsLoading(true);
            await axiosInstance.delete(
                `/admin/product/ingredient/${obj.productId}`,
                {data: obj}
            );

            successMessage("Smak został usunięty");
            setIsLoading(false);

        } catch (err) {
            setIsLoading(false);
            errorMessage("Wystąpił błąd");
            throw err;
        }
    };

    return {deleteRel, isLoading};
}

export default useDeleteIngredientFromProduct;
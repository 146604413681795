import React, {useContext} from "react";
import ProductsFiltersContext from "../../../../context/ProductsFiltersContext";
import {Select} from "antd";
import { UPDATE_PRODUCT_STATUS} from "../../../../reducers/ProductsFiltersReducers";

const ProductStatusSelect = () => {

    const {dispatch, state} = useContext(ProductsFiltersContext);

    return <Select mode="single" placeholder="Wybierz stan" allowClear={true} defaultValue={state.productStatus}
                   onChange={(e) => dispatch({type: UPDATE_PRODUCT_STATUS, payload: e})}>
        <Select.Option key={0}>
            Wszystkie
        </Select.Option>
        <Select.Option key={1} value={'moderate'}>
            Do moderacji
        </Select.Option>
        <Select.Option key={2} value={'preready'}>
            PreCompleted
        </Select.Option>
        <Select.Option key={3} value={'accepted'}>
            Gotowy
        </Select.Option>
        <Select.Option key={4} value={'waiting'}>
            Oczekujący
        </Select.Option>
        <Select.Option key={5} value={'deleted'}>
            Do usunięcia
        </Select.Option>
    </Select>
}

export default ProductStatusSelect;
import { useState } from "react";
import axiosInstance from "../../lib/axios";
import useAlert from "../useAlert";

const useUpdatePositionCategory = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { errorMessage } = useAlert();

  const updatePosition = async (id: string, position: number): Promise<any> => {
    try {
      setIsLoading(true);
      const obj = { position: position };
      await axiosInstance.put("/admin/category/position/" + id, obj);

      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      errorMessage("Błąd zmiany pozycji kategorii");
      //   throw err;
    }
  };

  return { isLoading, updatePosition };
};

export default useUpdatePositionCategory;

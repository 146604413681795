import useGetAllIngredients from "../../hooks/ingredient/useGetAllIngredients";
import {useState} from "react";
import styled from "styled-components";
import Title from "../../components/Title";
import CustomButton from "../../components/Forms/Buttons/CustomButton";
import {FaPlus} from "react-icons/fa";
import FormIndicator from "../../components/Forms/FormIndicator";
import useGetAllGrammage from "../../hooks/grammage/useGetAllGrammage";
import CreateIngredient from "../../components/Modals/Ingredient/CreateIngredient";
import {Table} from "antd";
import UpdateIngredient from "../../components/Modals/Ingredient/UpdateIngredient";

const Wrapper = styled.div`
  position: relative;
`;

const Ingredient = () => {

    const [openModal, setOpenModal] = useState(false);
    const {data, isLoading, reload} = useGetAllIngredients();
    const {data: grammages} = useGetAllGrammage();

    const columns = [{
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
    },
        {
            title: 'Gramatura',
            dataIndex: '',
            key: 'grammage',
            // defaultSortOrder: 'descend',
            // sorter: (a, b) => a.grammage.name - b.grammage.name,
            render: (row) => (<div>{row.grammage ? row.grammage.name : '-'}</div>),
        },
        {
            title: 'Action',
            key: 'action',
            dataIndex: '',

            render: (row) => (
                <div style={{flexDirection: 'row', display: 'flex', gap: '10px'}}>
                    <UpdateIngredient reload={reload} item={row} grammages={grammages}/>
                </div>
            ),
        }
    ];

    return <Wrapper>
        <Title title="Składniki (alergeny)"/>

        {isLoading ? <FormIndicator numberOfElements={1}/> :
            <>
                <CustomButton title='Utwórz' icon={<FaPlus/>} onClick={() => setOpenModal(true)}/>
                <Table
                    dataSource={data.map((it) => {
                        return {
                            ...it,

                            key: it.id,
                        }
                    })} columns={columns} pagination={false}/>
                <CreateIngredient
                    handleClose={() => setOpenModal(false)}
                    reload={reload}
                    openModal={openModal}
                    grammages={grammages}
                />
            </>
        }
    </Wrapper>
}

export default Ingredient;
import {useEffect, useState} from "react";
import useAssignFlavourToIngredientGroup from "../../../hooks/ingredientGroup/useAssignFlavourToIngredientGroup";
import ButtonLink from "../../Buttons/ButtonLink";
import {Modal} from "antd";
import MultiSelect from "../../Selects/MultiSelect";

const AssignFlavourToIngredientGroup = ({reload, flavours, item}) => {

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [selectedFlavour, setSelectedFlavour] = useState(null);
    const [selectedIds, setSelectedIds] = useState([]);

    const {update} = useAssignFlavourToIngredientGroup();

    useEffect(() => {
        let tmp = item.flavours.map((it) => it.name);
        setSelectedFlavour(tmp);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleOk = () => {
        update({flavoursIds: selectedIds, id: item.id}).then(reload);
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    return <>
        <ButtonLink onClick={showModal} text={'Przypisz smak'}/>
        <Modal title="Przypisywanie smaków" open={isModalVisible} onOk={handleOk} onCancel={handleCancel} mask={false}
               width={1000} bodyStyle={{height: '500px'}}>
            <MultiSelect
                values={flavours}
                selected={selectedFlavour}
                setSelected={setSelectedFlavour}
                setSelectedIds={setSelectedIds}
                handleSave={handleOk}
            />
        </Modal>
    </>
}

export default AssignFlavourToIngredientGroup;
import useUpdateNameBrand from "../../../hooks/brands/useUpdateNameBrand";
import ButtonLink from "../../Buttons/ButtonLink";
import React, {useState} from "react";
import BrandForm from "../../Forms/Brands/BrandForm";

const UpdateBrandModal = ({reload, item}) => {

    const [updateOpenModal, setUpdateOpenModal] = useState(false);
    const {update} = useUpdateNameBrand();

    const handleSave = (obj) => {
        update(item.id, obj.name)
            .then(() => {
                setUpdateOpenModal(false);
                reload();
            })
            .catch((err) => {
                console.log(err);
            });
    };

    return (
        <>
            <ButtonLink
                text={'Zmień nazwę'}
                onClick={() => setUpdateOpenModal(true)}
            />
            <BrandForm name={item.name} open={updateOpenModal} onCancel={() => setUpdateOpenModal(false)}
                       handleOK={handleSave}
                       title={'Edytuj nazwe'}/>
        </>
    );
};

export default UpdateBrandModal;

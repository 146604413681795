import {Button, Form, Input, Tabs, Typography} from "antd";
import Editor from "../../HtmlEditor/Editor";
import {useEffect, useState} from "react";

const {TextArea} = Input;

const CategoryDescriptionForm = ({data, handleSave}) => {

    const [descriptionAbove, setDescriptionAbove] = useState();
    const [descriptionBelow, setDescriptionBelow] = useState();

    useEffect(() => {
        setDescriptionAbove(data ? data.descriptionAboveListing : null);
        setDescriptionBelow(data ? data.descriptionBelowListing : null);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const onFinish = (values) => {
        handleSave({
            ...values,
            descriptionAboveListing: descriptionAbove,
            descriptionBelowListing: descriptionBelow
        });
    };

    return <Form
        layout='vertical'
        name="basic"
        initialValues={{
            metaTitle: data ? data.metaTitle : null,
            metaDescription: data ? data.metaDescription : null,
        }}
        onFinish={onFinish}
    >

        <Form.Item
            label="Meta Title"
            name="metaTitle"
        >
            <Input/>
        </Form.Item>

        <Form.Item
            label="Meta Description"
            name="metaDescription"
        >
            <Input/>
        </Form.Item>
        <div>
            <Typography.Text>Opis kategori nad listingiem</Typography.Text>
            <Tabs defaultActiveKey="1">
                <Tabs.TabPane tab="Edytor HTML" key="1">
                    <Editor value={descriptionAbove} setValue={setDescriptionAbove}/>
                </Tabs.TabPane>
                <Tabs.TabPane tab="Tekst" key="2">
                    <TextArea showCount
                              maxLength={10000000}
                              style={{height: '100%'}}
                              onChange={(e) => setDescriptionAbove(e.target.value)}
                              defaultValue={descriptionAbove}/>
                </Tabs.TabPane>
            </Tabs>
        </div>
        <div style={{marginTop: '20px'}}>
            <Typography.Text>Opis kategori pod listingiem</Typography.Text>
            <Editor value={descriptionBelow} setValue={setDescriptionBelow}/>
        </div>
        <Form.Item>
            <Button type="primary" htmlType="submit">
                Zapisz
            </Button>
        </Form.Item>
    </Form>
}

export default CategoryDescriptionForm;
import { useState } from "react";
import axiosInstance from "../../lib/axios";

const useGetProductVariantDebugInfo = () => {

    const [isLoadingDebugs, setIsLoadingDebugsIsLoading] = useState<boolean>(false);
    const [debugs, setDebugs] = useState<{} | null>([]);

    const getProductVariantDebugByPvId = async (id: string): Promise<any> => {
        try {
            setIsLoadingDebugsIsLoading(true);
            const res = await axiosInstance.get(`/admin/product/variant/debug/${id}`);

            if (res.status === 200) {
                setDebugs(res.data ? res.data : []);
                setIsLoadingDebugsIsLoading(false);
            }
        } catch (err) {
            throw err;
        }
    };

    const reload = (id: string) => {
        getProductVariantDebugByPvId(id);
    };

    return { isLoadingDebugs, getProductVariantDebugByPvId, debugs, reload };
}

export default useGetProductVariantDebugInfo;
import useGetAllGrammage from "../../../hooks/grammage/useGetAllGrammage";
import React, {useContext, useEffect, useState} from "react";
import FormIndicator from "../../Forms/FormIndicator";
import useCreateProductBasedOnProductVariant from "../../../hooks/products/useCreateProductBasedOnProductVariant";
import ProductBasedOnProductVariantEditorContext from "../../../context/ProductBasedOnProductVariantEditorContext";
import useAssignProductVariantToProduct from "../../../hooks/productVariants/useAssignProductVariantToProduct";
import {CLEAR_ALL} from "../../../reducers/ProductBasedOnProductVariantEditorReducers";
import {Image, Input, List, Modal, Select, Typography} from "antd";
import SearchSelect from "../../ProductVariantComponents/SearchSelect";
import useGetProductBySearchQuery from "../../../hooks/products/useGetProductBySearchQuery";
import MultiSelect from "../../Selects/MultiSelect";
import VatRateSelect from "../../Selects/VatRateSelect";

const {Option} = Select;
const {Title} = Typography;


const CreateProductBasedOnProductVariantModal = ({
                                                     brands,
                                                     open,
                                                     setOpen,
                                                     reload,
                                                     products = [],
                                                     setSelectedProducts = null
                                                 }) => {

    const {isLoading: isLoadingGrammage, data: grammages} = useGetAllGrammage();
    const {create} = useCreateProductBasedOnProductVariant();
    const {dispatch} = useContext(ProductBasedOnProductVariantEditorContext);
    const {assignProductToProductVariant} = useAssignProductVariantToProduct();
    const [grammage, setGrammage] = useState('gram');
    const [productInput, setProductInput] = useState(null);
    const [selectedBrands, setSelectedBrands] = useState([]);
    const [selectedIds, setSelectedIds] = useState([]);
    const [vatRate, setVatRate] = useState();


    const {getProductsBySearchQuery, isLoading: isLoadingProductSearch} = useGetProductBySearchQuery();
    const [query, setQuery] = useState('');
    const [options, setOptions] = useState([]);
    const [id, setId] = useState('');

    const handleSave = (obj) => {

        let ids = products.map(((it) => it.id));

        if (obj.id && obj.query) {
            assignProductToProductVariant({productId: obj.id, ids: ids, vatRate: vatRate}).finally(reload);
            window.open("https://admin.appet.pl/product/update/" + obj.id, '_blank');
        } else {
            let req = {name: obj.productInput, ids: ids, grammage: obj.grammage, vatRate: vatRate};
            create(req).then((res) => window.open("https://admin.appet.pl/product/update/" + res.id, '_blank')).finally(reload);

        }

        setSelectedProducts([]);
        dispatch({type: CLEAR_ALL, payload: null});
        setOpen(false);

    }

    const onChange = (val) => {
        setId(val.id);
        setQuery(val.name);
    }

    useEffect(() => {
        if (query.length > 0) {
            getProductsBySearchQuery({search: query, brandsIds: selectedIds}).then((test) => test ? setOptions(test) : setOptions([]));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [query]);

    const handleChangeVatRate = (value) => {
        setVatRate(value);
    }

    return (
        <Modal
            height="100%"
            width={1000}
            open={open}
            title="Utwórz produkt bazujący na wariantach"
            okText="Utwórz"
            cancelText="Anuluj"
            onCancel={() => setOpen(false)}
            closable={true}
            maskClosable={false}
            onOk={() => handleSave({id: id, query: query, grammage: grammage, productInput: productInput})}
        >
            {isLoadingGrammage ? <FormIndicator numberOfElements={1}/> :
                <>
                    <Title level={5}>Wyszukaj marki dla których mają być wyszukiwane produkty</Title>
                    <MultiSelect
                    values={brands}
                    selected={selectedBrands}
                    setSelected={setSelectedBrands}
                    setSelectedIds={setSelectedIds}

                    />
                    <Title level={5}>Wyszukaj produkt</Title>
                    <SearchSelect
                        name='Wpisz nazwę produktu'
                        onChange={val => onChange(val)}
                        query={query}
                        setQuery={setQuery}
                        options={options}
                        setOptions={setOptions}
                        isLoading={isLoadingProductSearch}
                    />
                    <Title level={5}>Lub utwórz nowy</Title>
                    <Input onChange={(e) => setProductInput(e.target.value)}/>
                    <Title level={5}>Gramatura</Title>
                    <Select

                        style={{
                            width: '100%',
                        }}
                        name="grammage"
                        defaultValue={grammages[0].id}
                        onChange={(value) => setGrammage(value)}>
                        {
                            grammages.map((it) => {
                                return <Option key={it.id} value={it.id}>{it.name},
                                    Skrót: {it.shortcut}</Option>
                            })
                        }
                    </Select>
                    <Title level={5}>Vat</Title>
                    <VatRateSelect value={vatRate} handleChange={handleChangeVatRate}/>
                    <List>
                        {products.map((it) => {
                            return <List.Item key={it.id}>
                                <Image width={50} height={50}
                                       src={it.photo[0] ? it.photo[0].fullPath : process.env.REACT_APP_PLACEHOLDER}/>
                                {it.name}</List.Item>
                        })}
                    </List>
                </>
            }
        </Modal>
    );

}

export default CreateProductBasedOnProductVariantModal;
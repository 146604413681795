import {Form, Input, Modal} from "antd";

const BrandGroupForm = ({handleOK, onCancel, open, name = null}) => {

    const [form] = Form.useForm();

    return <Modal title={'Grupa marek'}
                  open={open}
                  onOk={() => {
                      form
                          .validateFields()
                          .then((values) => {
                              form.resetFields();
                              handleOK(values);
                          })
                          .catch((info) => {
                              console.log("Validate Failed:", info);
                          });
                  }}
                  onCancel={onCancel}
                  okText={!name ? "Dodaj nową grupę" : "Zapisz"}
                  cancelText={"Anuluj"}>
        <Form
            layout="vertical"
            form={form}
            name="form_in_modal"
            initialValues={{
                name: name ? name : '',
            }}
        >
            <Form.Item
                label="Nazwa"
                name="name"
                rules={[
                    {required: true, message: "Wpisz nazwę, pole jest wymagane!"},
                ]}
            >
                <Input placeholder="Wpisz nazwę grupy"/>
            </Form.Item>
        </Form>
    </Modal>
}

export default BrandGroupForm;
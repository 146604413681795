import axiosInstance from "../../lib/axios";

const useUpdateBannerMarketingBox = () => {
    const updateBanner = async (data: FormData, id: string): Promise<any> => {
        try {
            await axiosInstance.post(
                `/admin/marketing-box/banner/${id}`,
                data,
                // config
            );

        } catch (err) {
            throw err;
        }
    };

    return { updateBanner };
}

export default useUpdateBannerMarketingBox;
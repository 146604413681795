import {TreeSelect} from "antd";
import {useEffect, useState} from "react";

const { SHOW_PARENT } = TreeSelect;
function list_to_tree(list) {

    var map = {},
        node,
        roots = [],
        i;

    for (i = 0; i < list.length; i += 1) {
        map[list[i].id] = i; // initialize the map
        list[i].children = []; // initialize the children
    }

    for (i = 0; i < list.length; i += 1) {
        node = list[i];
        if (node.parentId !== null) {
            // if you have dangling branches check that map[node.parentId] exists
            list[map[node.parentId]].children.push(node);
        } else {
            roots.push(node);
        }
    }

    return roots;
}

const CategorySelect = ({categories, selectedCategories = [], setCategories = null}) => {

    const [state, setState] = useState({
        value: [],
    });

    const [treeCategories, setTreeCategories] = useState([]);

    useEffect(() => {
        let tmpArr = [];

        selectedCategories.map((it) => {
            categories.map((cat) => {

                if (cat.id === it) {
                    tmpArr.push(cat.hash);
                }
            });
        });

        setTreeCategories(list_to_tree(categories));
        setState({
            value: tmpArr,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onChange = (value) => {
        let tmpArr = [];
        let result = categories.filter((o1) => value.some((o2) => o1.value === o2));
        result.map((it) => tmpArr.push(it.id));

        setCategories(tmpArr);
        setState({ value });
    };

    const tProps = {
        treeData: treeCategories,
        value: state.value,
        onChange: (e) => onChange(e),
        treeCheckable: true,
        showCheckedStrategy: SHOW_PARENT,
        placeholder: "Wybierz kategorię",
        style: {
            width: "100%",
        },
    };
    return <TreeSelect {...tProps} />

}

export default CategorySelect;
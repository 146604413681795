import {Tabs} from "antd";
import DiscountGroupCodesTable from "../../Tables/DiscountCodes/DiscountGroupCodesTable";
import {useEffect, useState} from "react";
import DiscountOnceCodeTable from "../../Tables/DiscountCodes/DiscountOnceCodeTable";

const DiscountCodesTabs = ({data}) => {

    const [onceUsedCode, setUsedCode] = useState([]);
    const [multipleUseCodes, setMultipleUseCodes] = useState([]);

    useEffect(() => {
        let multipleUse = data.map((it) => {
            if (it.maxUse > 1) {
                return it;
            }
        });

        let onceUse = data.map((it) => {
            if (it.maxUse === 1) {
                return it;
            }
        });

        setUsedCode(onceUse.filter((function (element) {
            return element !== undefined;
        })))

        setMultipleUseCodes(multipleUse.filter((function (element) {
            return element !== undefined;
        })))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const items = [
        {label: 'Kody grupowe', key: 'item-1', children: <DiscountGroupCodesTable data={multipleUseCodes}/>}, // remember to pass the key prop
        {label: 'Kody jednorazowego użytku', key: 'item-2', children: <DiscountOnceCodeTable data={onceUsedCode}/>},
    ];
    return <Tabs style={{backgroundColor: 'white', padding: '10px'}} items={items}/>;

}

export default DiscountCodesTabs;
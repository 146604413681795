import styled from "styled-components";
import {Input, Select} from "antd";
import useUpdateProductCustomListName from "../../hooks/productCustomList/useUpdateProductCustomListName";
import useUpdateProductCustomListButtonLink from "../../hooks/productCustomList/useUpdateProductCustomListButtonLink";
import useUpdateProductCustomListButtonText from "../../hooks/productCustomList/useUpdateProductCustomListButtonText";
import useUpdateProductCustomListSortBy from "../../hooks/productCustomList/useUpdateProductCustomListSortBy";

const TextStyle = styled.div`
  width: 186px;
  height: 58px;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 57px;
  /* identical to box height, or 157% */
  text-align: right;
`;

const {Option} = Select;

const ProductCustomListInfo = ({data}) => {

    const {update: updateName} = useUpdateProductCustomListName();
    const {update: updateButtonLink} = useUpdateProductCustomListButtonLink();
    const {update: updateButtonText} = useUpdateProductCustomListButtonText();
    const {update: updateSortBy} = useUpdateProductCustomListSortBy();

    const onChangeName = (value) => {
        if (value.target.value.length > 0) updateName({id: data.id, name: value.target.value});
    }

    const onChange = (value) => {
        updateSortBy({id: data.id, sortBy: value});
    }

    const onButtonTextChange = (value) => {
        updateButtonText({id: data.id, buttonText: value.target.value});
    }

    const onButtonLinkChange = (value) => {
        updateButtonLink({id: data.id, buttonLink: value.target.value});
    }

    return <div style={{display: 'flex', padding: '20px', justifyContent: 'center'}}>
        <div style={{display: 'flex', flexDirection: 'column', width: '100%'}}>
            <div style={{display: 'flex', alignItems: 'center', gap: '10px', marginRight: '10px', width: '100%'}}>
                <TextStyle>Nazwa listy:</TextStyle>
                <Input placeholder={data.name} onBlur={onChangeName}/>
            </div>
            <div style={{display: 'flex', alignItems: 'center', gap: '10px', marginRight: '10px', width: '100%'}}>
                <TextStyle>Sortowanie:</TextStyle>
                <Select
                    style={{width: '100%'}}
                    placeholder="Wybierz rodzaj sortowanie listy"
                    onChange={onChange}
                    allowClear
                >
                    <Option value="price_asc">Cena rosnąco</Option>
                    <Option value="price_desc">Cena malejąco</Option>
                    <Option value="newest">Najnowsze</Option>
                    <Option value="popularity">Popularne</Option>
                    <Option value="highest_rate">Najlepsze oceny</Option>
                </Select>
            </div>
        </div>
        <div style={{display: 'flex', flexDirection: 'column', width: '100%'}}>
            <div style={{display: 'flex', alignItems: 'center', gap: '10px', marginRight: '10px', width: '100%'}}>
                <TextStyle>Button Link:</TextStyle>
                <Input placeholder={data.buttonLink} onBlur={onButtonLinkChange}/>
            </div>
            <div style={{display: 'flex', alignItems: 'center', gap: '10px', marginRight: '10px', width: '100%'}}>
                <TextStyle>Button Text:</TextStyle>
                <Input placeholder={data.buttonText} onBlur={onButtonTextChange}/>
            </div>
        </div>

        <div style={{display: 'flex', alignItems: 'center', gap: '10px', marginRight: '10px', width: '100%'}}>
            <TextStyle>Shortcode:</TextStyle>
            <Input disabled placeholder={data.sid}/>
        </div>

    </div>;
}

export default ProductCustomListInfo;


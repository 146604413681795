import {useState} from "react";
import useAlert from "../useAlert";
import axiosInstance from "../../lib/axios";

type CreateFlavourProps = {
    name: string;
}


const useCreateFlavour = () => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const {successMessage, errorMessage} = useAlert();


    const create = async (obj: CreateFlavourProps): Promise<any> => {
        try {
            setIsLoading(true);
            const { data } = await axiosInstance.post("/admin/flavour/", obj);

            if (data.id) {
                setIsLoading(false);
            }

            successMessage("Zapisano");
        } catch (err) {
            setIsLoading(false);
            errorMessage("Błąd zapisu, spróbuj ponownie");
            throw err;
        }
    };

    return { create, isLoading };
}

export default useCreateFlavour;
import {useState} from "react";
import axiosInstance from "../../lib/axios";
import useAlert from "../useAlert";

type AssignProductVariantToProduct = {
    productId: string;
    ids: [];
    vatRate: number;
};


const useAssignProductVariantToProduct = () => {

    const [isLoading, setIsLoading] = useState(false);
    const { errorMessage, successMessage } = useAlert();

    const assignProductToProductVariant = async (
        obj: AssignProductVariantToProduct
    ): Promise<any> => {
        try {
            setIsLoading(true);
            await axiosInstance.post(
                `/admin/product/variant/assign/${obj.productId}`,
                obj
            );
            setIsLoading(false);
            successMessage('Product Variant został przypisany');

        } catch (err) {
            setIsLoading(false);
            errorMessage('Błąd zapisu, spróbuj ponownie');
            throw err;
        }
    };

    return {assignProductToProductVariant, isLoading};
}

export default useAssignProductVariantToProduct;
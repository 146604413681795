import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: auto;
  background-color: #ffffff;
  border-radius: 5px;
  border: 1px solid #e5e9f2;
  padding: 15px;
  margin-top: 10px;
  margin-bottom: 10px;
`;

const Paper = ({ children }) => {
  return <Wrapper>{children}</Wrapper>;
};

export default Paper;

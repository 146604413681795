import {Button, Form, Input, Select} from "antd";

const UpdateProductOwnerSettlementCommissionForm = ({handleOk, data}) => {

    const [form] = Form.useForm();

    return <Form
        labelCol={{
            span: 4,
        }}
        wrapperCol={{
            span: 16,
        }}
        form={form}
        onFinish={handleOk}
        initialValues={{
            foodCommission: data.foodCommission ? data.foodCommission : '',
            otherCommission: data.otherCommission ? data.otherCommission : '',
            settlementCommission: data.settlementType ? data.settlementType : '',
        }}
    >
        <Form.Item
            label="Prowizja na karmę"
            name="foodCommission"
            rules={[
                {
                    required: true,
                    message: 'Wypełnij pole!',
                },
            ]}
        >
            <Input />
        </Form.Item>
        <Form.Item
            label="Prowizja na akcesoria"
            name="otherCommission"
            rules={[
                {
                    required: true,
                    message: 'Wypełnij pole!',
                },
            ]}
        >
            <Input />
        </Form.Item>
        <Form.Item
            label="Wybierz rodzaj rozliczenia"
            name="settlementCommission"
            rules={[
                {
                    required: true,
                    message: 'Wypełnij pole!',
                },
            ]}
        >
            <Select disabled={data.settlementType}>
                <Select.Option value="fv">Faktura</Select.Option>
                <Select.Option value="compensation">Faktura Kompensacyjna</Select.Option>
            </Select>
        </Form.Item>
        <Form.Item
            wrapperCol={{
                offset: 8,
                span: 16,
            }}
        >
            <Button type="primary" htmlType="submit">
                Zapisz
            </Button>
        </Form.Item>
    </Form>
}

export default UpdateProductOwnerSettlementCommissionForm;
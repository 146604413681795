import {useState} from "react";
import useAlert from "../useAlert";
import axiosInstance from "../../lib/axios";

type CreateUserProps = {
    email: string;
    password: string;
    name: string,
    lastName: string;
    number: string;
    productOwnerId: string;
};


const useCreateNewUserPartner = () => {
    const [isLoading, setIsLoading] = useState(false);
    const { errorMessage} = useAlert();

    const create = async (obj: CreateUserProps): Promise<any> => {
        try {
            setIsLoading(true);
            await axiosInstance.post("/admin/users/partner", obj);

        } catch (err) {
            setIsLoading(false);
            errorMessage("Błąd zapisu, spróbuj ponownie");
            throw err;
        }
    };

    return {isLoading, create};
}

export default useCreateNewUserPartner;
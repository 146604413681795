import styled from "styled-components";
import {FaShapes, FaHammer} from "react-icons/fa";
import {NavLink} from "react-router-dom";
import {Button, Radio} from "antd";
import React, {useContext} from "react";
import {themes} from "../../../layout/styles/ColorStyles";
import useUpdateProductStatus from "../../../hooks/products/useUpdateProductStatus";
import AuthContext from "../../../context/AuthContext";
import useClearCacheProduct from "../../../hooks/products/useClearCacheProduct";

const MenuItems = ["Podstawowe", "Kategorie", "Marka", "Media", "Szczegóły", "Skład", "Cross-Selling", "Vat"];

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const WrapperMenu = styled.div`
  position: relative;
  height: fit-content;
  display: grid;
  border-radius: 5px;
  border: 1px solid #e5e9f2;
`;

const MenuItemWrapper = styled.div`
  font-size: 16px;
  font-weight: 500;
  padding: 10px;
  background-color: ${(props) => (props.isSelected ? "#e5e9f2" : "#ffffff")};
  box-sizing: border-box;
  color: #364a63;
  border-bottom: 1px solid #e5e9f2;

  :hover {
    background: rgba(235, 238, 242, 1);
    cursor: pointer;
  }
`;

const VariantWrapper = styled(MenuItemWrapper)`
  margin-top: 20px;
  background-color: #f7f9fc;
  border: 1px solid #e5e9f2;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  gap: 20px;
  padding: 5px 5px 5px 15px;
  justify-content: center;
  align-items: center;
`;

const CreateProductMenu = ({selected, setSelected, productId, data}) => {
    const [value, setValue] = React.useState(data.status);
    const {update} = useUpdateProductStatus();
    const {isSa} = useContext(AuthContext);
    const {deleteCache} = useClearCacheProduct();

    const handleChange = (e) => {
        setValue(e.target.value);
        update({status: e.target.value, productIds: [productId]});
    };

    const handleClearCache = () => {
        deleteCache(productId)
    }

    return (
        <Wrapper>
            <div style={{marginBottom: '10px'}}>
                <Radio.Group style={{display: 'flex', gap: '5px', flexDirection: 'column'}} defaultValue={data.status}
                             buttonStyle="solid" onChange={(e) => handleChange(e)}>
                    <Radio.Button style={{
                        backgroundColor: value === 'accepted' ? themes.status.send : 'white',
                        border: '1px solid grey'
                    }} value="accepted">Gotowe</Radio.Button>
                    <Radio.Button style={{
                        backgroundColor: value === 'preready' ? themes.status.preready : 'white',
                        border: '1px solid grey',
                        borderLeft: '1px solid grey'
                    }} value="preready">PreReady</Radio.Button>
                    <Radio.Button style={{
                        backgroundColor: value === 'moderate' ? themes.status.returned : 'white',
                        border: '1px solid grey',
                        borderLeft: '1px solid grey'
                    }} value="moderate">Moderacja</Radio.Button>
                    <Radio.Button style={{
                        backgroundColor: value === 'waiting' ? themes.status.submitted : 'white',
                        border: '1px solid grey'
                    }} value="waiting">Oczekujący</Radio.Button>
                    <Radio.Button style={{
                        backgroundColor: value === 'deleted' ? themes.status.canceled : 'white',
                        border: '1px solid grey'
                    }} value="deleted">Do usunięcia</Radio.Button>
                </Radio.Group>
            </div>
            <WrapperMenu>
                {MenuItems.map((it, index) => (
                    <MenuItemWrapper
                        key={index}
                        isSelected={index === selected}
                        onClick={() => setSelected(index)}
                    >
                        {it}
                    </MenuItemWrapper>
                ))}
            </WrapperMenu>
            <NavLink
                to={`/product/variants/${productId}`}
                style={{textDecoration: "none"}}
            >
                <VariantWrapper isSelected={false}>
                    <FaShapes style={{color: "#526484", fontSize: "30px"}}/>
                    Zarządzaj wariantami
                </VariantWrapper>
            </NavLink>
            <NavLink
                to={`/product/variant/debug/${productId}`}
                style={{textDecoration: "none"}}
            >
                <VariantWrapper isSelected={false}>
                    <FaHammer style={{color: "#526484", fontSize: "30px"}}/>
                    Wariant Debug
                </VariantWrapper>
            </NavLink>

            {
                isSa ? <NavLink
                    to={`/product/algorytm-logs/${productId}`}
                    style={{textDecoration: "none"}}
                >
                    <VariantWrapper isSelected={false}>
                        <FaHammer style={{color: "#526484", fontSize: "30px"}}/>
                        Logi argorytmu
                    </VariantWrapper>
                </NavLink> : null
            }
            <Button
                onClick={() => handleClearCache()}
                type='primary'
                style={{marginTop: '10px', backgroundColor: '#df0000', borderColor: '#df0000'}}>
                Wyczyść cache produktu
            </Button>
        </Wrapper>
    );
};

export default CreateProductMenu;

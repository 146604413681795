import React, {useState} from "react";
import useUpdateUserInfo from "../../../hooks/users/useUpdateUserInfo";
import ButtonLink from "../../Buttons/ButtonLink";
import UserFormAnt from "../../Forms/Users/UserFormAnt";

const EditUser = ({reload, item}) => {

    const [updateOpenModal, setUpdateOpenModal] = useState(false);
    const {update} = useUpdateUserInfo();

    const handleSave = (obj) => {
        update(item.id, obj)
            .then(() => {
                setUpdateOpenModal(false);
                reload();
            })
            .catch((err) => {
                console.log(err);
            });
    };

    return (
        <>
            <ButtonLink
                text={'Edytuj użytkownika'}
                onClick={() => setUpdateOpenModal(true)}
            />
            <UserFormAnt user={item}
                         open={updateOpenModal}
                         onCancel={() => setUpdateOpenModal(false)}
                         handleOK={handleSave}
                         title={'Edytuj użytkownika'}/>
        </>
    );
}

export default EditUser;
import axiosInstance from "../../lib/axios";

interface UpdateDeliveryPOIsFreeDelivery {
    deliveryProductOwnerId: string,
    isFreeFromPrice: boolean
}

const useUpdateDeliveryProductOwnerIsFreeDelivery = () => {

    const update = async (obj: UpdateDeliveryPOIsFreeDelivery): Promise<any> => {
        try {
            await axiosInstance.put("/admin/delivery/productOwner/is_free_delivery/" + obj.deliveryProductOwnerId, obj);

        } catch (err) {
            throw err;
        }
    };

    return { update };
}

export default useUpdateDeliveryProductOwnerIsFreeDelivery;
import {Form, Input, Select} from "antd";

const {Option} = Select;
const ProductCustomListForm = ({form}) => {

    const onChange = (values) => {
        form.setFieldsValue({values});
    }

    return  <Form
        layout="vertical"
        form={form}
        name="form_in_modal"

    >
        <Form.Item
            label="Wpisz nazwę listy produktów"
            name="name"
            rules={[
                {required: true, message: "Wpisz nazwę, pole jest wymagane!"},
            ]}
        >
            <Input placeholder="Wpisz nazwę."/>
        </Form.Item>
        <Form.Item
            label="Wybierz rodzaj sortowania"
            name="customListSortBy"
            rules={[
                {required: true, message: "Wybierz rodzaj sortowania listy!"},
            ]}
        >
            <Select
                placeholder="Wybierz rodzaj sortowanie listy"
                onChange={onChange}
                allowClear
            >
                <Option value="price_asc">Cena rosnąco</Option>
                <Option value="price_desc">Cena malejąco</Option>
                <Option value="newest">Najnowsze</Option>
                <Option value="popularity">Popularne</Option>
                <Option value="high_rate">Najlepsze oceny</Option>
            </Select>
        </Form.Item>
        <Form.Item
            label="Tekst wyświetlany na przycisku"
            name="buttonText"
        >
            <Input placeholder="Wpisz tekst."/>
        </Form.Item>
        <Form.Item
            label="Wpisz link przypisany do przycisku"
            name="buttonLink"
        >
            <Input placeholder="Wpisz nazwę."/>
        </Form.Item>

    </Form>
}

export default ProductCustomListForm;
import useGetProductsCollections from "../../hooks/products/useGetProductsCollections";
import Title from "../../components/Title";
import FormIndicator from "../../components/Forms/FormIndicator";
import FilterProduct from "../../components/Forms/Products/ProductFilters/FilterProduct";
import PaginationList from "../../components/PaginationList";
import ProductsFiltersContext from "../../context/ProductsFiltersContext";
import TableProductsTest from "../../components/Tables/TableProductsTest";
import {useContext, useEffect, useState} from "react";
import {CHANGE_PAGE_AND_LIMIT} from "../../reducers/ProductsFiltersReducers";
import styled from "styled-components";
import useGetBrandAndCategoryDirectory from "../../hooks/products/useGetBrandAndCategoryDirectory";
import CreateProductModal from "../../components/Modals/Products/CreateProductModal";
import CustomButton from "../../components/Forms/Buttons/CustomButton";

const Wrapper = styled.div`
  position: relative;
`;

const ProductsTest = () => {

    const {data, isLoading, reload} = useGetProductsCollections();
    const {state} = useContext(ProductsFiltersContext);
    const [counter, setCounter] = useState(0);
    const {
        getDirectory, data: directory, isLoading: isLoadingDirectory, categories
    } = useGetBrandAndCategoryDirectory();

    const [open, setOpen] = useState(false);

    const test = () => {
        reload(state);
    }

    useEffect(() => {
        getDirectory();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        test();
        window.scrollTo(0, 0);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.page, state.limit]);

    return <Wrapper>
        <Title title="Produkty"/><CustomButton title={'Utwórz produkt'} onClick={() => setOpen(true)}/>
        {(isLoadingDirectory) ? <FormIndicator numberOfElements={1}/> :
            <FilterProduct brands={directory.brand} productOwners={directory.productOwners} categories={categories}
                           reload={reload}/>}
        {isLoading ? (<FormIndicator numberOfElements={2}/>) : (<>
                <PaginationList pagination={data.pagination} FiltersContext={ProductsFiltersContext}
                                type={CHANGE_PAGE_AND_LIMIT} counter={counter} setCounter={setCounter}/>
                <TableProductsTest data={data ? data.results && data.results : []} reload={test}/>
                <PaginationList pagination={data.pagination} FiltersContext={ProductsFiltersContext}
                                type={CHANGE_PAGE_AND_LIMIT} counter={counter} setCounter={setCounter}/>
            </>)}
        <CreateProductModal open={open} setOpenModal={setOpen}/>
    </Wrapper>
}

export default ProductsTest;
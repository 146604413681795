import React, {useReducer} from "react";
import {initialState, ReviewProductFilterReducer} from "../reducers/ReviewProductFiltersReducer";

const ReviewProductFiltersContext = React.createContext();

export const ReviewProductFiltersProvider = ({children}) => {

    const [state, dispatch] = useReducer(ReviewProductFilterReducer, initialState);

    return (
        <ReviewProductFiltersContext.Provider value={{state, dispatch}}>
            {children}
        </ReviewProductFiltersContext.Provider>
    );
}

export default ReviewProductFiltersContext;
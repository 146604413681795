import ProductOwnerAddress from "../../../Forms/ProductOwner/ProductOwnerAddress";
import useUpdateProductOwnerReturnAddress from "../../../../hooks/productOwners/useUpdateProductOwnerReturnAddress";

const UpdateProductOwnerReturnAddress = ({openModal, handleClose, item, reload}) => {

    const {update} = useUpdateProductOwnerReturnAddress();
    const handleOk = (values) => {
        update({...values, id: item.id}).then(() => reload());
        handleClose();
    };

    return (
        <ProductOwnerAddress
            title={'Edytuj adres do zwrotów'}
            handleOk={handleOk}
            onCancel={handleClose}
            open={openModal}
            headquarter={item.returnAddressView}
        />
    );
}

export default UpdateProductOwnerReturnAddress;
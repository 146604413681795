import { useState } from "react";
import axiosInstance from "../../lib/axios";
import useAlert from "../useAlert";

const useUpdateLogoBrand = () => {
  const [isLoading, setisLoading] = useState<boolean>(false);
    const {successMessage, errorMessage} = useAlert();


    const updateLogo = async (data: FormData, id: string): Promise<any> => {
        try {
            setisLoading(true);
            const config = {
                headers: { "content-type": "multipart/form-data" },
            };
            await axiosInstance.post(
                `/admin/brand/photo/${id}`,
                data,
                config
            );

            setisLoading(false);
            successMessage('Zapisano.')

        } catch (err) {
            setisLoading(false);
            errorMessage('Błąd zapisu, spróbuj ponownie');
            throw err;
        }


    };

    return { updateLogo, isLoading };
};

export default useUpdateLogoBrand;

import {Button, Form, Input, Select} from "antd";

const ProductCompositionForm = ({data, handleSave}) => {

    const onFinish = (values) => {
        handleSave(values);
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };


    return (<Form
            name="basic"
            labelCol={{span: 7}}
            wrapperCol={{span: 10}}
            initialValues={{
                proteins: data ? data.proteins : null,
                fat: data ? data.fat : null,
                raw_fiber: data ? data.rawFiber : null,
                crude_ash: data ? data.crudeAsh : null,
                moisture: data ? data.moisture : null,
                pet_type: data ? data.petType : null,
                compositionDescription: data ? data.compositionDescription : '',
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
        >
            <Form.Item
                label="Białko"
                name="proteins"
                rules={[
                    {
                        required: true,
                        message: 'Podaj ilość białka!',
                    },
                ]}
            >
                <Input type={'number'} min={0} max={100} step={0.01} addonAfter={'%'}/>
            </Form.Item>
            <Form.Item
                label="Tłuszcze"
                name="fat"
                rules={[
                    {
                        required: true,
                        message: 'Podaj ilość tłuszczy!',
                    },
                ]}
            >
                <Input type={'number'} min={0} max={100} step={0.01} addonAfter={'%'}/>
            </Form.Item>
            <Form.Item
                label="Popiół surowy"
                name="crude_ash"
                rules={[
                    {
                        required: true,
                        message: 'Podaj wartość popiołu surowego!',
                    },
                ]}
            >
                <Input type={'number'} min={0} max={100} step={0.01} addonAfter={'%'}/>
            </Form.Item>
            <Form.Item
                label="Włókno surowe"
                name="raw_fiber"
                rules={[
                    {
                        required: true,
                        message: 'Podaj ilość włókna surowego!',
                    },
                ]}
            >
                <Input type={'number'} min={0} max={100} step={0.01} addonAfter={'%'}/>
            </Form.Item>
            <Form.Item
                label="Zawartość wilgotności"
                name="moisture"
                rules={[
                    {
                        required: true,
                        message: 'Podaj zawartość wilgotności!',
                    },
                ]}
            >
                <Input type={'number'} min={0} max={100} step={0.01} addonAfter={'%'}/>
            </Form.Item>
            <Form.Item
                label="Karma dla:"
                name="pet_type"
                rules={[
                    {
                        required: true,
                        message: 'Wybierz typ zwierzaka dla którego jest karma!',
                    },
                ]}
            >
                <Select>
                    <Select.Option value="CAT">Kot</Select.Option>
                    <Select.Option value="DOG">Pies</Select.Option>
                </Select>
            </Form.Item>
            <Form.Item
                label="Opis składu"
                name="compositionDescription"
            >
                <Input.TextArea/>
            </Form.Item>
            <Form.Item
            >
                <Button type="primary" htmlType="submit">
                    Zapisz
                </Button>
            </Form.Item>
        </Form>
    )
}

export default ProductCompositionForm;
import {DatePicker, Form, Input, Modal} from "antd";

const OrderSettlementInvoiceForm = ({handleOK, onCancel, open}) => {

    const [form] = Form.useForm();

    return (
        <Modal title={'Dodaj rozliczenie'}
               open={open}
               onOk={() => {
                   form
                       .validateFields()
                       .then((values) => {
                           form.resetFields();
                           handleOK(values);
                       })
                       .catch((info) => {
                           console.log("Validate Failed:", info);
                       });
               }}
               onCancel={onCancel}
               okText={"Zapisz"}
               cancelText={"Anuluj"}>
            <Form
                layout="vertical"
                form={form}
                name="form_in_modal"
            >
                <Form.Item
                    label="Numer faktury"
                    name="invoiceNumber"
                    rules={[
                        {required: true, message: "Wpisz numer faktury, pole jest wymagane!"},
                    ]}
                >
                    <Input placeholder="Wpisz numer faktury "/>
                </Form.Item>
                <Form.Item name="dateFrom" label="Data rozliczenia od" rules={[
                    {required: true, message: "Pole jest wymagane!"},
                ]} >
                    <DatePicker style={{width: '100%'}} placeholder='Data rozliczenia od'/>
                </Form.Item>
                <Form.Item name="dateTo" label="Data rozliczenia do" rules={[
                    {required: true, message: "Pole jest wymagane!"},
                ]}>
                    <DatePicker style={{width: '100%'}} placeholder='Data rozliczenia do'/>
                </Form.Item>
                <Form.Item name="payTo" label="Płatność do"  rules={[
                    {required: true, message: "Pole jest wymagane!"},
                ]} >
                    <DatePicker style={{width: '100%'}} placeholder='Płatność do'/>
                </Form.Item>
            </Form>
        </Modal>
    );
}

export default OrderSettlementInvoiceForm;

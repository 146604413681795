import axiosInstance from "../../lib/axios";

const useRemoveProductCustomItem = () => {

    const removeProductCustomItem = async (id: string): Promise<any> => {
        try {
            await axiosInstance.delete(`/admin/product_custom_list/` + id);
        } catch (err) {
            console.log(err);
        }
    }

    return {removeProductCustomItem};
}

export default useRemoveProductCustomItem;
import useCreateBrandGroup from "../../../hooks/brandGroup/useCreateBrandGroup";
import BrandGroupForm from "../../Forms/BrandGroup/BrandGroupForm";

const CreateBrandGroup = ({reload, handleClose, openModal}) => {

    const {create} = useCreateBrandGroup();

    const handleOk = (values) => {
    create(values).then(reload);
        handleClose();
    }

    return <BrandGroupForm open={openModal} onCancel={handleClose} handleOK={(value) => handleOk(value)}/>;
}

export default CreateBrandGroup;
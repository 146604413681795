import { useState, useEffect } from "react";
import FormTitle from "../FormTitle";
import { Select } from "antd";
import styled from "styled-components";
import { FaSave } from "react-icons/fa";
import useAssignBrandToProduct from "../../../hooks/brands/useAssignBrandToProduct";
import CustomButton from "../Buttons/CustomButton";

const { Option } = Select;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 100px;
`;

const Brand = ({ productId, selectedApi, reload, brands }) => {
  const [selectedBrandId, setSelectedBrandId] = useState(null);
  const { update } = useAssignBrandToProduct();
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    setSelectedBrandId(selectedApi ? selectedApi.id : null);
    setIsReady(true);
  }, [selectedApi]);

  const onChange = (brandId) => {
    setSelectedBrandId(brandId);
  };


  const handleUpdate = () => {
    if (!selectedBrandId) {
      return;
    }

    update(productId, selectedBrandId).then(() => reload());
  };

  if (!isReady) return null;

  return (
      <div>
        <FormTitle title={"Marka"} />
        <Wrapper>
          <Select
              showSearch
              value={selectedBrandId}
              style={{ width: 200 }}
              placeholder="Wybierz lub szukaj"
              optionFilterProp="children"
              onChange={onChange}
              filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
          >
            {brands.map((it, index) => {
              return (
                  <Option key={index} value={it.id}>
                    {it.name}
                  </Option>
              );
            })}
          </Select>
          <div>
            <CustomButton title="Zapisz" icon={<FaSave />} onClick={handleUpdate} />
          </div>
        </Wrapper>
      </div>
  );
};

export default Brand;

import { useState } from "react";
import axiosInstance from "../../lib/axios";
import useAlert from "../useAlert";

const useUpdateNameCategory = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { errorMessage } = useAlert();

  const updateName = async (id: string, name: string): Promise<any> => {
    try {
      setIsLoading(true);
      const obj = { name: name };
      await axiosInstance.put("/admin/category/name/" + id, obj);

      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      errorMessage("Błąd zmiany nazwy kategorii");
      //   throw err;
    }
  };

  return { isLoading, updateName };
};

export default useUpdateNameCategory;

const tradeList = [
    {
        value: 'agd',
        label: "AGD",
    },
    {
        value: 'agdrtv',
        label: "AGD i RTV",
    },
    {
        value: 'alkoh',
        label: "Alkohole",
    },
    {
        value: 'apteki',
        label: "Apteki",
    },
    {
        value: 'artlab',
        label: "Artykuły laboratoryjne",
    },
    {
        value: 'artmed',
        label: "Artykuły medyczne",
    },
    {
        value: 'artspoz',
        label: "Artykuły spożywcze",
    },
    {
        value: 'aukcje',
        label: "Aukcje",
    },
    {
        value: 'behape',
        label: "BHP",
    },
    {
        value: 'blizna',
        label: "Bielizna",
    },
    {
        value: 'bilety',
        label: "Bilety",
    },
    {
        value: 'buki',
        label: "Bukmacher",
    },
    {
        value: 'biz',
        label: "Biżuteria i zegarki",
    },
    {
        value: 'budow',
        label: "Budownictwo",
    },
    {
        value: 'chemia',
        label: "Chemia",
    },
    {
        value: 'czaspis',
        label: "Czasopisma",
    },
    {
        value: 'dekor',
        label: "Dekoracje",
    },
    {
        value: 'dewoc',
        label: "Dewocjonalia",
    },
    {
        value: 'domiogr',
        label: "Dom i ogród",
    },
    {
        value: 'dziecko',
        label: "Dziecko",
    },
    {
        value: 'elektronika',
        label: "Elektronika",
    },
    {
        value: 'epapier',
        label: "E-papierosy",
    },
    {
        value: 'ezoter',
        label: "Ezoteryka",
    },
    {
        value: 'filatel',
        label: "Filatelistyka",
    },
    {
        value: 'finanse',
        label: "Finanse",
    },
    {
        value: 'fotogr',
        label: "Fotografia",
    },
    {
        value: 'fundacja',
        label: "Fundacja",
    },
    {
        value: 'galant',
        label: "Galanteria",
    },
    {
        value: 'gadzet',
        label: "Gadżety",
    },
    {
        value: 'gry',
        label: "Gry",
    },
    {
        value: 'komphost',
        label: "Hosting",
    },
    {
        value: 'hotel',
        label: "Hotelarstwo",
    },
    {
        value: 'instyt',
        label: "Instytucje",
    },
    {
        value: 'komputery',
        label: "Komputery",
    },
    {
        value: 'ksiazki',
        label: "Książki",
    },
    {
        value: 'kosmetyki',
        label: "Kosmetyki",
    },
    {
        value: 'ksieg',
        label: "Księgarnia",
    },
    {
        value: 'kip',
        label: "Kwiaty i prezenty",
    },
    {
        value: 'mwf',
        label: "Masowi Wystawcy Faktur",
    },
    {
        value: 'maszyny',
        label: "Maszyny",
    },
    {
        value: 'matbiur',
        label: "Materiały biurowe",
    },
    {
        value: 'matfol',
        label: "Materiały foliowe",
    },
    {
        value: 'matpap',
        label: "Materiały papierowe",
    },
    {
        value: 'militaria',
        label: "Militaria",
    },
    {
        value: 'motoryz',
        label: "Motoryzacja",
    },
    {
        value: 'mim',
        label: "Multimedia i muzyka",
    },
    {
        value: 'nagrob',
        label: "Nagrobki",
    },
    {
        value: 'narzedzia',
        label: "Narzędzia",
    },
    {
        value: 'nis',
        label: "Nauka i szkolnictwo",
    },
    {
        value: 'numiz',
        label: "Numizmatyka",
    },
    {
        value: 'obuwie',
        label: "Obuwie",
    },
    {
        value: 'odziez',
        label: "Odzież",
    },
    {
        value: 'ogl',
        label: "Ogłoszenia",
    }, {
        value: 'ogrod',
        label: "Ogród",
    },
    {
        value: 'oprogra',
        label: "Oprogramowanie",
    },
    {
        value: 'oswietl',
        label: "Oświetlenie",
    },
    {
        value: 'pasman',
        label: "Pasmanteria",
    },
    {
        value: 'podroze',
        label: "Podróże",
    },
    {
        value: 'randki',
        label: "Portal randkowy",
    },
    {
        value: 'portfel',
        label: "Portfel elektroniczny",
    },
    {
        value: 'prasa',
        label: "Prasa",
    },
    {
        value: 'prawo',
        label: "Prawo",
    },
    {
        value: 'kurier',
        label: "Przesyłki kurierskie",
    },
    {
        value: 'reklama',
        label: "Reklama",
    },
    {
        value: 'rekodz',
        label: "Rękodzieło",
    },
    {
        value: 'rodzice',
        label: "Rodzice",
    },
    {
        value: 'rtv',
        label: "RTV",
    },
    {
        value: 'serint',
        label: "Serwis internetowy",
    },
    {
        value: 'sklmuz',
        label: "Sklep muzyczny",
    },
    {
        value: 'siw',
        label: "Sport i wypoczynek",
    },
    {
        value: 'suplem',
        label: "Suplementy diety",
    },
    {
        value: 'szklo',
        label: "Szkło",
    },
    {
        value: 'szkol',
        label: "Szkolenia",
    },
    {
        value: 'sztuka',
        label: "Sztuka",
    },
    {
        value: 'slubne',
        label: "Ślubne",
    },
    {
        value: 'tif',
        label: "Teatr i film",
    },
    {
        value: 'telek',
        label: "Telekomunikacja",
    },
    {
        value: 'tkan',
        label: "Tkaniny",
    },
    {
        value: 'twstrwww',
        label: "Tworzenie stron WWW",
    },
    {
        value: 'ubezp',
        label: "Ubezpieczenia",
    },
    {
        value: 'uslugi',
        label: "Usługi",
    },
    {
        value: 'wielob',
        label: "Wielobranżowość",
    },
    {
        value: 'wpc',
        label: "WP -conceptshop.pl",
    },
    {
        value: 'wypmiesz',
        label: "Wyposażenie mieszkania",
    },
    {
        value: 'wypmiesz',
        label: "Wyposażenie mieszkania",
    },
    {
        value: 'wypsklep',
        label: "Wyposażenie sklepów",
    },
    {
        value: 'wyrtyt',
        label: "Wyroby tytoniowe",
    },

    {
        value: 'wio',
        label: "Wzrok i okulary",
    },

    {
        value: 'vod',
        label: "Vod",
    },

    {
        value: 'zabawki',
        label: "Zabawki",
    },
    {
        value: 'zik',
        label: "Zdrowie i kosmetyki",
    },
    {
        value: 'zwierz',
        label: "Zwierzęta",
    },
];

export default tradeList;

import axiosInstance from "../../../lib/axios";
import useAlert from "../../useAlert";

type GenerateCodesProps = {
  quantity: number;
  length: number;
  prefix: string;
  maxUse: number;
  isActive: boolean;
  discountId: string
};

const useGenerateNewCodes = () => {

  const {successMessage, errorMessage} = useAlert();

  const generate = async (
    obj: GenerateCodesProps,
  ): Promise<any> => {
    try {
     await axiosInstance.post(
        "/admin/discount-code/generate",
        obj
      );


      successMessage("Wygenerowano nowe kody");
    } catch (err) {

      errorMessage("Błąd zapisu, spróbuj ponownie");
      throw err;
    }
  };

  return { generate };
};

export default useGenerateNewCodes;

import styled from "styled-components";
import Title from "../../components/Title";
import {FaPlus} from "react-icons/fa";
import CustomButton from "../../components/Forms/Buttons/CustomButton";
import {useState} from "react";
import useGetAllDeliveries from "../../hooks/delivery/useGetAllDeliveries";
import TableDelivery from "../../components/Tables/TableDelivery";
import FormIndicator from "../../components/Forms/FormIndicator";
import CreateDelivery from "../../components/Modals/Delivery/CreateDelivery";

const Wrapper = styled.div`
  position: relative;
`;

const Delivery = () => {
    const [openModal, setOpenModal] = useState(false);
    const {data, isLoading, getDeliveries} = useGetAllDeliveries();

    return (
        <>
            <Wrapper>
                <Title title="Dostawy"/>
                <CustomButton
                    title="Utwórz"
                    icon={<FaPlus/>}
                    onClick={() => setOpenModal(true)}
                />
                {isLoading ? <FormIndicator numberOfElements={3}/> : <TableDelivery data={data} reload={getDeliveries}/>}
            </Wrapper>
            <CreateDelivery open={openModal}
                            setOpenModal={setOpenModal}
                            reload={getDeliveries}/>
        </>
    );
};

export default Delivery;

export const CHANGE_PAGE = "change_page";
export const CHANGE_LIMIT = "change_limit";
export const CHANGE_PAGE_AND_LIMIT = "change_page_and_limit";

export const CLEAR_ALL = 'clear_all';

export const UPDATE_PRICE_FROM = 'update_price_from';
export const UPDATE_PRICE_TO = 'update_price_to';
export const UPDATE_DATE_FROM = 'created_at_from';
export const UPDATE_DATE_TO = 'created_at_to';
export const UPDATE_QUERY_EMAIL = 'update_query_email';
export const UPDATE_CITY = 'update_city';
export const UPDATE_POST_CODE = 'update_post_code';
export const UPDATE_ORDER_STATUS = 'update_order_status';
export const UPDATE_PAYMENT_STATUS = 'update_payment_status';
export const UPDATE_DELIVERY_ID = 'update_delivery_id';
export const UPDATE_ORDER_NUMBER = 'update_order_number';
export const UPDATE_PRODUCT_OWNER_ID = 'update_product_owner_id';
export const UPDATE_ORDER_BY = 'update_order_by';
export const UPDATE_CLIENT_INFO = 'update_client_info';

export const priceFrom = "priceFrom";
export const priceTo = "priceTo";
export const limit = "limit";
export const page = "page";
export const queryEmail = 'queryEmail';
export const city = 'city';
export const postCode = 'postCode';
export const discountFrom = 'discountFrom';
export const discountTo = 'discountTo';
export const createdAtTo = 'createdAtTo';
export const createdAtFrom = 'createdAtFrom';
export const status = 'status';
export const orderNumber = 'orderNumber';
export const statusPayment = "statusPayment";
export const deliveryId = "deliveryId";
export const productOwnerId = "productOwnerId";
export const orderBy = "orderBy";
export const clientInfo = 'clientInfo';

export const initialState = {
    [limit]: 20,
    [page]: 1,
    [queryEmail]: '',
    [city]: '',
    [postCode]: '',
    [priceFrom]: '',
    [priceTo]: '',
    [discountFrom]: '',
    [discountTo]: '',
    [createdAtFrom]: '',
    [createdAtTo]: '',
    [status]: '',
    [orderNumber]: '',
    [statusPayment]: null,
    [deliveryId]: "",
    [productOwnerId]: "",
    [orderBy]: "created_at_desc",
    [clientInfo]: ''
};


type ACTIONTYPE =
    | { type: typeof CHANGE_PAGE; payload: number }
    | { type: typeof CHANGE_LIMIT; payload: number }
    | { type: typeof CHANGE_PAGE_AND_LIMIT; payload: { [page]: number; [limit]: number } }
    | { type: typeof CLEAR_ALL; payload: null }
    | { type: typeof UPDATE_PRICE_FROM; payload: string }
    | { type: typeof UPDATE_PRICE_TO; payload: string }
    | { type: typeof UPDATE_DATE_FROM; payload: string }
    | { type: typeof UPDATE_DATE_TO; payload: string }
    | { type: typeof UPDATE_QUERY_EMAIL; payload: string }
    | { type: typeof UPDATE_CITY; payload: string }
    | { type: typeof UPDATE_POST_CODE; payload: string }
    | { type: typeof UPDATE_ORDER_STATUS; payload: string }
    | { type: typeof UPDATE_ORDER_NUMBER; payload: string }
    | { type: typeof UPDATE_PAYMENT_STATUS; payload: string }
    | { type: typeof UPDATE_DELIVERY_ID; payload: string }
    | { type: typeof UPDATE_PRODUCT_OWNER_ID; payload: string }
    | { type: typeof UPDATE_ORDER_BY; payload: string }
    | { type: typeof UPDATE_CLIENT_INFO; payload: string };


export const OrderFilterReducers = (state: typeof initialState, action: ACTIONTYPE) => {

    switch (action.type) {
        case CHANGE_PAGE:
            return {
                ...state,
                [page]: action.payload,
            };
        case CHANGE_LIMIT:
            return {
                ...state,
                [limit]: action.payload,
            };
        case CHANGE_PAGE_AND_LIMIT:
            return {
                ...state,
                [page]: action.payload[page],
                [limit]: action.payload[limit],
            };
        case CLEAR_ALL:
            return initialState;

        case UPDATE_PRICE_FROM:
            return {
                ...state,
                [priceFrom]: action.payload,
            }
        case UPDATE_PRICE_TO:
            return {
                ...state,
                [priceTo]: action.payload,
            }
        case UPDATE_DATE_FROM:
            return {
                ...state,
                [createdAtFrom]: action.payload,
            }
        case UPDATE_DATE_TO:
            return {
                ...state,
                [createdAtTo]: action.payload,
            }
        case UPDATE_QUERY_EMAIL:
            return {
                ...state,
                [queryEmail]: action.payload,
            }
        case UPDATE_CITY:
            return {
                ...state,
                [city]: action.payload,
            }
        case UPDATE_POST_CODE:
            return {
                ...state,
                [postCode]: action.payload,
            }
        case UPDATE_ORDER_STATUS:
            return {
                ...state,
                [status]: action.payload,
            }
        case UPDATE_ORDER_NUMBER:
            return {
                ...state,
                [orderNumber]: action.payload,
            }
        case UPDATE_PAYMENT_STATUS:
            return {
                ...state,
                [statusPayment]: action.payload
            }

        case UPDATE_DELIVERY_ID:
            return {
                ...state,
                [deliveryId]: action.payload
            }
        case UPDATE_PRODUCT_OWNER_ID:
            return {
                ...state,
                [productOwnerId]: action.payload
            }

        case UPDATE_ORDER_BY:
            return {
                ...state,
                [orderBy]: action.payload
            }
        case UPDATE_CLIENT_INFO:
            return {
                ...state,
                [clientInfo]: action.payload
            }
        default:
            return state;
    }
}
import useAlert from "../useAlert";
import axiosInstance from "../../lib/axios";

const useUpdateCategoryActivity = () => {


    const {successMessage, errorMessage} = useAlert();

    const update = async (id: string, isActive: boolean): Promise<any> => {
        try {
            const obj = {
                isActive: isActive,
            };
            const { data } = await axiosInstance.put("/admin/category/activity/" + id, obj);

            if (data.id) {
                successMessage('Zapisano.')
            }
        } catch (err) {

            errorMessage('Błąd zapisu, spróbuj ponownie');
            throw err;
        }
    };

    return { update };
}

export default useUpdateCategoryActivity;
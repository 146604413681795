import React from "react";
import "react-quill/dist/quill.snow.css";
import ReactQuill from "react-quill";

const Editor = ({ value, setValue }) => {

    const modules = {
        toolbar: {
            container: [
                [{ header: [1, 2, 3, 4, 5, 6, false] }],
                ["bold", "italic", "underline", "strike", "blockquote"],
                [{ size: ["small", false, "large", "huge"] }, { color: [] }],
                [
                    { list: "ordered" },
                    { list: "bullet" },
                    { indent: "-1" },
                    { indent: "+1" },
                    { align: [] }
                ],
                ["link",
                    // "image",
                    "video",'width', 'height'
                ],
                ["clean"]
            ],
        },
        clipboard: { matchVisual: false }
    };

    const handleChange = (text) => {
        setValue(text)
    }

    return (
        <ReactQuill
            formats={formats}
            modules={modules}
            theme="snow"
            value={value}
            onChange={handleChange}
        />
    );
};

export default Editor;

export const formats = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "video",
    'width', 'height'
];

export const modules = {
    toolbar: [
        [{ header: '1' }, { header: '2' }, { header: '3' }, { font: [] }],
        [{ size: [] }],
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        [
            { list: 'ordered' },
            { list: 'bullet' },
            { indent: '-1' },
            { indent: '+1' }
        ],
        ['link'],
        ['clean']
    ],
    clipboard: {
        // toggle to add extra line breaks when pasting HTML:
        matchVisual: false
    },
    // imageResize: {
    //     parchment: Quill.import('parchment'),
    //     modules: ['Resize', 'DisplaySize']
    // }
};
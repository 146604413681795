import {useParams} from "react-router-dom";
import FormIndicator from "../../components/Forms/FormIndicator";
import {ArrowLeftOutlined, CheckOutlined, CloseOutlined} from "@ant-design/icons";
import {Button, Input, Switch, Typography} from "antd";
import styled from "styled-components";
import useUpdateStaticPageContent from "../../hooks/staticPage/useUpdateStaticPageContent";
import useGetStaticPageById from "../../hooks/staticPage/useGetStaticPageById";
import React, {useEffect} from "react";
import {themes} from "../../layout/styles/ColorStyles";
import Paper from "../../layout/Paper";
import Editor from "../../components/HtmlEditor/Editor";
import useUpdateStaticPageActivity from "../../hooks/staticPage/useUpdateStaticPageActivity";

const Title = styled.div`
  /* position: absolute; */
  display: flex;
  color: #364a63;
  margin-bottom: 15px;
  border-bottom: 1.5px solid #e5e9f2;`;


const UpdateStaticPage = () => {

    const {update: updateContent} = useUpdateStaticPageContent();
    const {update: updateActivity} = useUpdateStaticPageActivity();
    const {getStaticPageById, data, isLoading} = useGetStaticPageById();
    const [content, setContent] = React.useState('');

    const params = useParams();

    useEffect(() => {
        getStaticPageById(params.id).then((res) => {
            setContent(res)
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const updateIsActive = (e) => {
        updateActivity({id: params.id, isActive: e})
    }

    const handleSave = () => {
        updateContent({id: params.id, content});
    }

    return <>
        {isLoading ? <FormIndicator numberOfElements={1}/> :
            <>
                <Title>
                    <div onClick={() => window.history.go(-1)}>
                        <ArrowLeftOutlined/> <Typography.Text strong>Wróc</Typography.Text>
                    </div>
                </Title>
                <div style={{display: 'flex', flex: 1, width: '100%', gap: '10px', alignItems: 'center'}}>
                    <div style={{
                        width: '20%',
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        gap: '10px'
                    }}>
                        <Typography>Aktywność:</Typography> <Switch
                        checkedChildren={<CheckOutlined/>}
                        unCheckedChildren={<CloseOutlined/>}
                        style={{
                            backgroundColor: data.isActive
                                ? themes.activeMenuItemColor
                                : themes.switchInActive,
                        }}
                        onClick={(e) => updateIsActive(e)}
                        checked={true}
                    />
                    </div>
                    <div style={{
                        width: '40%',
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        gap: '10px'
                    }}>
                        <Typography>Nazwa:</Typography><Input disabled={true} value={data.name}/>
                    </div>
                    <div style={{
                        width: '40%',
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        gap: '10px'
                    }}>
                        <Typography>URL:</Typography> <Input disabled={true} value={data.sid}/>
                    </div>
                </div>
                <Paper>
                    <Editor value={content} setValue={setContent}/>
                    <Button style={{marginTop: "10px"}} type='primary' onClick={() => handleSave()}>Zapisz</Button>
                </Paper>
            </>}
    </>;
}
export default UpdateStaticPage;
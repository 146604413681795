import styled from "styled-components";
import useGetFilteredDiscounts from "../../hooks/discount/useGetFilteredDiscounts";
import FormIndicator from "../../components/Forms/FormIndicator";
import Title from "../../components/Title";
import CustomButton from "../../components/Forms/Buttons/CustomButton";
import TableDiscounts from "../../components/Tables/TableDiscounts";
import {NavLink} from "react-router-dom";

const Wrapper = styled.div`
  position: relative;
`;

const Discounts = () => {

    const {data, isLoading, reload } = useGetFilteredDiscounts();

    if (isLoading) return <FormIndicator numberOfElements={3} />;

    return (
        <>
            <Wrapper>
                <Title title="Lista promocji" />
                {isLoading ? (
                    <FormIndicator numberOfElements={3} />
                ):
                    (
                        <>
                        <NavLink
                            to={'/discount/new'}
                            style={{ textDecotration: "none !important" }}
                        >
                        <CustomButton
                            title="Utwórz"
                        />
                        </NavLink>
                            <TableDiscounts data={data} reload={reload}/>
                        </>
                    )
                }
            </Wrapper>
        </>
    );

}

export default Discounts;
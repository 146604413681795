import {useContext, useState} from "react";
import ReviewProductFiltersContext from "../../../../context/ReviewProductFiltersContext";
import {Input} from "antd";
import {UPDATE_RATE_FROM, UPDATE_RATE_TO} from "../../../../reducers/ReviewProductFiltersReducer";

const RateInput = () => {

    const {dispatch, state} = useContext(ReviewProductFiltersContext);

    const [rateFrom, setRateFrom] = useState(state.rateFrom);
    const [rateTo, setRateTo] = useState(state.rateTo);


    return <div style={{
        display: "flex",
        flexDirection: "row",
    }}>
        <Input min={0} defaultValue={rateFrom} style={{width: "100%"}} onBlur={(e) => dispatch({
            type: UPDATE_RATE_FROM,
            payload: e.target.value
        }) && setRateFrom(e.target.value)}/>
        <Input
            style={{
                width: "15%",
                borderLeft: 0,
                borderRight: 0,
                pointerEvents: "none",
            }}
            placeholder="~"
            disabled
        />
        <Input type='number' min={0} defaultValue={rateTo} style={{width: "100%"}} onBlur={(e) => dispatch({
            type: UPDATE_RATE_TO,
            payload: e.target.value
        }) && setRateTo(e.target.value)}/>
    </div>

}

export default RateInput;
import {useState} from "react";
import useAlert from "../useAlert";
import axiosInstance from "../../lib/axios";

interface CreateNumberEmailContactOfProductOwner {
    id: string;
    mobileNumber: string;
    email: string;
    bankAccountNumber: string;
}
const useCreateProductOwnerCommunication = () => {
    const [isLoading, setisLoading] = useState<boolean>(false);
    const { successMessage, errorMessage } = useAlert();

    const create = async (
        obj: CreateNumberEmailContactOfProductOwner
    ): Promise<any> => {
        console.log('test');
        try {
            setisLoading(true);
            await axiosInstance.post("/admin/product_owner/communication/" + obj.id, obj);
            successMessage("Dane zmienione");
        } catch (err) {
            setisLoading(false);
            errorMessage("Błąd zapisu. Spróbuj ponownie.");
            throw err;
        }
    };

    return { create, isLoading };
}

export default useCreateProductOwnerCommunication;
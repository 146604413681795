import useCreateNewCodeByName from "../../../../hooks/discount/codes/useCreateNewCodeByName";
import {useParams} from "react-router-dom";
import {Form, Input, InputNumber, Modal} from "antd";

const CreateGroupCodeByNameModal = ({
                                        openNewGroupCodeModal, setOpenNewGroupCodeModal, reload
                                    }) => {
    const {create} = useCreateNewCodeByName();

    const params = useParams();
    const [form] = Form.useForm();

    return (
        <Modal title={'Grupowy kod rabatowy'}
               open={openNewGroupCodeModal}
               onOk={() => {
                   form
                       .validateFields()
                       .then((values) => {
                           form.resetFields();
                           create({
                               ...values,
                               discountId: params.id,
                               isActive: true,
                           }).then(() => setOpenNewGroupCodeModal(false) & reload(params.id));
                       })
                       .catch((info) => {
                           console.log("Validate Failed:", info);
                       });
               }}
               onCancel={() => setOpenNewGroupCodeModal(false)}
               okText={"Zapisz"}
               cancelText={"Anuluj"}>
            <Form
                layout="vertical"
                form={form}
                name="form_in_modal"
            >
                <Form.Item
                    label="Treść kodu rabatowego"
                    name="code"
                    rules={[
                        {required: true, message: "Wpisz nazwę, pole jest wymagane!"},
                    ]}
                >
                    <Input placeholder="Wpisz treść kodu do wpisania w koszyku"/>
                </Form.Item>
                <Form.Item
                    label="Maksymalna ilość użyć kodu rabatowego dla wszystkich użytkowników"
                    name="maxUse"
                    rules={[
                        {required: true, message: "Wpisz ilość kodów, pole jest wymagane!"},
                    ]}
                >
                    <InputNumber style={{width: '100%'}} type='number' placeholder="Wpisz ile razy łącznie może być użyty ten kod rabatowy"/>
                </Form.Item>
            </Form>
        </Modal>
    );

}

export default CreateGroupCodeByNameModal;
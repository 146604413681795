import useAlert from "../useAlert";
import axiosInstance from "../../lib/axios";

type UpdateBlogPostTitleProps = {
    id: string,
    name: string;
}


const useUpdateBlogPostTitle = () => {
    const {successMessage, errorMessage} = useAlert();


    const update = async (obj: UpdateBlogPostTitleProps): Promise<any> => {
        try {
            await axiosInstance.put("/admin/blog/post/title/" + obj.id, obj);
            successMessage("Zapisano");
        } catch (err) {
            errorMessage("Błąd zapisu, spróbuj ponownie");
            throw err;
        }
    };

    return {update};
}


export default useUpdateBlogPostTitle;
import {createdAtFrom, createdAtTo} from "../../reducers/ProductsFiltersReducers";
import axiosInstance from "../../lib/axios";
import useAlert from "../useAlert";
import {useState} from "react";

const useGenerateOrdersToFile = () => {

    const {errorMessage} = useAlert();
    const [isLoadingFile, setIsLoadingFile] = useState(false);

    const getFilesOrders = async (state: any): Promise<any> => {

        setIsLoadingFile(true);
        let modifiedState = {
            ...state,
            limit: 10000,
            createdAtFrom: state[createdAtFrom] ? state[createdAtFrom] : null,
            createdAtTo: state[createdAtTo] ? state[createdAtTo] : null
        }
        try {
            const res = await axiosInstance.get(`/admin/order/generate_csv`, {params: modifiedState}).then();

            if (res.status === 200) {
                let blobFile = new Blob([res.data])

                const file = new File([blobFile], 'orders', {
                    type: "arraybuffer",
                });
                const link = document.createElement("a");
                link.href = window.URL.createObjectURL(file);
                link.download = 'orders.csv';
                link.click();

                setIsLoadingFile(false);
            }
        } catch (err) {
            setIsLoadingFile(false);
            errorMessage('Coś poszło nie tak')
        }
    };

    return {getFilesOrders, isLoadingFile};
}

export default useGenerateOrdersToFile;
import {useState} from "react";
import axiosInstance from "../../lib/axios";
import {updatedAtFrom, updatedAtTo} from "../../reducers/CartFilterReducers";

const useGetPaginatedCarts = () => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [data, setData] = useState<{} | null>(null);

    const getAbandonedCarts = async (state: any): Promise<any> => {
        let modifiedState = {
            ...state,
            updatedAtFrom: state[updatedAtFrom] ? state[updatedAtFrom] : null,
            updatedAtTo: state[updatedAtTo] ? state[updatedAtTo] : null
        }
        try {
            const res = await axiosInstance.get(`/admin/cart/paginated`, {params: modifiedState});

            if (res.status === 200) {
                setData(res.data ? res.data : []);
                setIsLoading(false);
            }
        } catch (err) {
            setIsLoading(false);
        }
    };

    const reload = (filters: any) => {
        setIsLoading(true);
        getAbandonedCarts(filters);
    }

    return {isLoading, data, reload, getAbandonedCarts};
}

export default useGetPaginatedCarts;
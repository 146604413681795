import React, {useContext, useRef} from "react";
import {Button, Form} from "antd";
import styled from "styled-components";
import {
    CLEAR_ALL,
    UPDATE_QUERY_EMAIL,
    UPDATE_QUERY_LASTNAME,
    UPDATE_QUERY_NAME, UPDATE_QUERY_PHONENUMBER
} from "../../../reducers/CartFilterReducers";
import InputSearch from "../InputSearch";
import CartFilterContext from "../../../context/CartFilterContext";
import CartStatusSelect from "./CartStatusSelect";
import DatePickerFrom from "./DatePickerFrom";
import {UPDATE_DATE_FROM, UPDATE_DATE_TO} from "../../../reducers/CartFilterReducers";
import DatePickerTo from "../Products/ProductFilters/DatePickerTo";

const Content = styled(Form)`
  display: grid;
  width: 100%;
  height: 100%;
  column-gap: 10px;
  grid-template-columns: 25% 24% 25% 25%;
`;

const FormFilterCart = ({reload}) => {

    const {dispatch, state} = useContext(CartFilterContext);
    const [form] = Form.useForm();
    const ref = useRef();

    function handleKeyUp(event) {
        // Enter
        if (event.keyCode === 13) {
            ref.current.submit();
        }
    }

    const onFinish = () => {
        reload(state);
    }

    const handleOnReset = () => {
        form.resetFields();
        dispatch({type: CLEAR_ALL});
    }

    return <Content
        ref={ref} onKeyUp={handleKeyUp} tabIndex={0}
        onFinish={onFinish}
        form={form}
        labelCol={{span: 24}}
    >
        <Form.Item label={'Email'} name={'email'}>
            <InputSearch context={CartFilterContext} type={UPDATE_QUERY_EMAIL} test={state.email}/>
        </Form.Item>

        <Form.Item label={'Imie'} name={'name'}>
            <InputSearch context={CartFilterContext} type={UPDATE_QUERY_NAME} test={state.name}/>
        </Form.Item>
        <Form.Item label={'Nazwisko'} name={'lastName'}>
            <InputSearch context={CartFilterContext} type={UPDATE_QUERY_LASTNAME} test={state.lastName}/>
        </Form.Item>
        <Form.Item label={'Numer telefony'} name={'phoneNumber'}>
            <InputSearch context={CartFilterContext} type={UPDATE_QUERY_PHONENUMBER} test={state.phoneNumber}/>
        </Form.Item>
        <Form.Item label={'Ponowny kontakt'}>
            <CartStatusSelect/>
        </Form.Item>
        <Form.Item label="Data utworzenia od" name="dateFrom">
            <DatePickerFrom type={UPDATE_DATE_FROM} context={CartFilterContext}/>
        </Form.Item>
        <Form.Item label="Data utworzenia do" name="dateTo">
            <DatePickerTo type={UPDATE_DATE_TO} context={CartFilterContext}/>
        </Form.Item>
        <Form.Item>
            <Button
                style={{marginRight: "10px", marginTop: '40px'}}
                type="primary"
                htmlType="submit"
            >
                Zastosuj
            </Button>
            <Button htmlType="button" onClick={() => handleOnReset()}>
                Wyczyść filtry
            </Button>
        </Form.Item>
    </Content>;
}

export default FormFilterCart;
import {Table} from "antd";
import PaymentStatusLabel from "../../Labels/PaymentStatusLabel";
import useUploadInvoiceFile from "../../../hooks/productOwners/OrderSettlementInvoice/useUploadInvoiceFile";
import OrderSettlementBillModal from "../../Modals/ProductOwner/OrderSettlementInvoice/OrderSettlementBillModal";

const OrderSettlementInvoiceTable = ({data}) => {

    const {update} = useUploadInvoiceFile();

    const onUpload = (event, id) => {

        let file = event.target.files[0];
        const formData = new FormData();

        formData.append(
            "file",
            file,
            file.name
        );

        update(formData, id);
    }

    const columns = [
        {
            title: 'Data (od - do)',
            dataIndex: '',
            key: '',
            width: '20%',
            render: (record) => <div>{record.dateFrom} - {record.dateTo}</div>,
            sorter: (a, b) => a.dateFrom.length - b.dateFrom.length,
        },
        {
            title: 'Płatność',
            dataIndex: '',
            key: 'isPaid',
            render: (record) => <PaymentStatusLabel textTrue={'Opłacone'} textFalse={'Nieopłacone'} paid={record}/>,
            sorter: (a, b) => a.record.length - b.record.length,
        },
        {
            title: 'Ilość transakcji',
            dataIndex: 'totalTransactionQty',
            key: 'transactionCount',
            // sorter: (a, b) => {
            //     console.log(a, b)
            // },
        },
        {
            title: 'Kwota',
            dataIndex: 'totalPriceGross',
            key: 'totalPriceGross',
            render: (record) => <div>{record} zł</div>
        },
        {
            title: '',
            dataIndex: '',
            key: 'showTransaction',
            render: (record) => <OrderSettlementBillModal id={record.id}
                                                          title={record.dateFrom + ' - ' + record.dateTo}/>
        },
        {
            title: 'Faktura',
            dataIndex: '',
            key: 'showInvoice',
            render: (record) => record.filePath ? <a href={record.filePath} target='_blank' rel='noopener noreferrer'>Pobierz fakturę</a> :
                <div>Brak faktury</div>
        },
        {
            title: 'Dodaj fakturę',
            dataIndex: '',
            key: 'addInvoice',
            render: (row) => <input type='file' onChange={(e) => onUpload(e, row.id)}/>
        }
    ];

    return <>
        <Table dataSource={data.map((it) => {
            return {...it, key: it.id}
        })} columns={columns}/></>;
}

export default OrderSettlementInvoiceTable;
import {List, Modal} from "antd";
import useRemoveProduct from "../../../hooks/products/useRemoveProduct";

const DeleteProducts = ({openModal, setOpenModal, selectedProducts}) => {

    const {removeProduct} = useRemoveProduct();

    const handleCancel = () => {
        setOpenModal(false);
    };

    const handleOk = () => {
        let tmp = selectedProducts.map((it) => it.id);
        removeProduct(tmp);
    }

    return <Modal
        title='Usuwanie produktów'
        open={openModal}
        key='modal'
        onCancel={handleCancel}
        onOk={handleOk}
    >
        <List>
            {selectedProducts.map((item, index) => <List.Item key={index}>{index + 1}. {item.name}</List.Item>)}
        </List>
    </Modal>
}

export default DeleteProducts;
import {Button, Form, Input, Select, Typography} from "antd";
import React, {useState} from "react";
import tradeList from "../../../const/tradeList";

const {Text} = Typography;
const {TextArea} = Input;

const Przlewy24RegisterForm = ({handleSave, data, isLocked = false}) => {

    const [form] = Form.useForm();
    const [businessTypeValue, setBusinessTypeValue] = useState(null);

    const onFinishForm = (values) => {
        handleSave(values);
    }

    return <Form
        onFinish={onFinishForm}
        form={form}
        layout="vertical"
        initialValues={{
            address_city: data ? data.addressCity : null,
            address_street: data ? data.addressStreet : null,
            address_post_code: data ? data.addressPostCode : null,
            bank_account: data ? data.bankAccount : null,
            business_type: data ? data.businessType : null,
            contact_person_email: data ? data.contactPersonEmail : null,
            contact_person_name: data ? data.contactPersonName : null,
            contact_person_phone_number: data ? data.contactPersonPhoneNumber : null,
            correspondence_address_city: data ? data.correspondenceAddressCity : null,
            correspondence_address_street: data ? data.correspondenceAddressStreet : null,
            correspondence_address_post_code: data ? data.correspondenceAddressPostCode : null,
            email: data ? data.email : null,
            invoice_email: data ? data.invoiceEmail : null,
            krs: data ? data.krs : null,
            name: data ? data.name : null,
            nip: data ? data.nip : null,
            pesel: data ? data.pesel : null,
            phone_number: data ? data.phoneNumber : null,
            regon: data ? data.regon : null,
            services_description: data ? data.servicesDescription : null,
            shop_url: data ? data.shopUrl : null,
            technical_contact_email: data ? data.technicalContactEmail : null,
            technical_contact_name: data ? data.technicalContactName : null,
            technical_contact_phone_number: data ? data.technicalContactPhoneNumber : null,
            trade: data ? data.trade : null,
        }}
    >
        <Form.Item label={<Label mainText={'Rodzaj działalności'} text={'(business_type)'}/>}
                   name={'business_type'} rules={[
            {
                required: true,
                message: 'Wybierz działalność, pole wymagane!',
            },
        ]}>
            <Select
                disabled={isLocked}
                placeholder={'Podaj rodzaj działalności'} onChange={(e) => setBusinessTypeValue(e)}>
                <Select.Option value="2">Jednoosobowa działalność gospodarcza</Select.Option>
                <Select.Option value="3">Spółka cywilna</Select.Option>
                <Select.Option value="4">Spółka jawna</Select.Option>
                <Select.Option value="5">Spółka komandytowa</Select.Option>
                <Select.Option value="6">Spółka komandytowo-akcyjna</Select.Option>
                <Select.Option value="7">Spółka akcyjna</Select.Option>
                <Select.Option value="8">Spółka z ograniczoną odpowiedzialnością</Select.Option>
                <Select.Option value="9">Stowarzyszenie, fundacja, organizacja pożytku
                    publicznego</Select.Option>
                <Select.Option value="10">Spółdzielnia</Select.Option>
            </Select>
        </Form.Item>
        <Form.Item
            label={<Label mainText={'Nazwa'} text={'Pełna nazwa działalności.'}/>}
            name="name"
            rules={[
                {
                    required: true,
                    message: 'Wpisz nazwę, pole wymagane!',
                },
            ]}
        >
            <Input disabled={isLocked}/>
        </Form.Item>
        <Form.Item
            label={<Label mainText={'Adres e-mail'} text={'Adres email Merchanta.'}/>}
            name="email"
            rules={[
                {
                    type: 'email',
                    required: true,
                    message: 'Wpisz email, pole wymagane!',
                },
            ]}
        >
            <Input disabled={isLocked}/>
        </Form.Item>
        <Form.Item
            label={<Label mainText={'Number telefonu'} text={'Numer telefonu w formacie xxxxxxxxx'}/>}
            name="phone_number"
            rules={[
                {
                    required: true,
                    message: 'Wpisz numer telefon, pole wymagane!',
                },
            ]}
        >
            <Input disabled={isLocked} type='number'/>
        </Form.Item>
        <Form.Item
            label={<Label mainText={'Numer konta bankowego'}/>}
            name="bank_account"
            rules={[
                {
                    required: true,
                    message: 'Wpisz numer konta bankowego, pole wymagane!',
                },
            ]}
        >
            <Input disabled={isLocked}/>
        </Form.Item>
        <Form.Item
            label={<Label mainText={'Adres email do faktur'}/>}
            name="invoice_email"
            rules={[
                {
                    type: 'email',
                    required: true,
                    message: 'Wpisz email, pole wymagane!',
                },
            ]}
        >
            <Input disabled={isLocked}/>
        </Form.Item>

        <Form.Item
            label={<Label
                mainText={'Adres URL merchanta'} />}
            name="shop_url"
            rules={[
                {
                    required: true,
                    message: 'Wpisz URL, pole wymagane!',
                },
            ]}
        >
            <Input disabled={isLocked}/>
        </Form.Item>
        <Form.Item
            label={<Label
                mainText={'Opis sklepu - Parametr nie jest wymagany, o ile zostamnie przesłane pole shop_url*'}/>}
            name="services_description"
        >
            <TextArea rows={4} disabled={isLocked}/>
        </Form.Item>
        <Form.Item label={<Label mainText={'Branża merchanta'} />}
                   name={'trade'} rules={[
            {
                required: true,
                message: 'Wybierz działalność, pole wymagane!',
            },
        ]}>
            <Select disabled={isLocked}
                    placeholder={'Branża merchanta'} onChange={(e) => setBusinessTypeValue(e)}>
                {tradeList.map((item, index) => {
                    return <Select.Option key={index} value={item.value}>{item.label}</Select.Option>
                })}
            </Select>
        </Form.Item>

        <Form.Item
            label={<Label
                mainText={'Numer KRS - Wymagany tylko w przypadku, jeśli Rodzaj działalności > 3'}/>}
            name="krs"
            rules={[
                {
                    required: parseInt(businessTypeValue) > 3,
                    message: 'Wpisz numer KRS, pole wymagane!',
                },
            ]}
        >
            <Input disabled={isLocked}/>
        </Form.Item>
        <Form.Item
            label={<Label
                mainText={'Numer NIP - Wymagany tylko w przypadku, jeśli Rodzaj działalności jest inny niż “Osoba fizyczna”'}
                text={'(nip)'}/>}
            name="nip"
            rules={[
                {
                    required: true,
                    message: 'Wpisz numer NIP, pole wymagane!',
                },
            ]}
        >
            <Input disabled={isLocked}/>
        </Form.Item>
        <Form.Item
            label={<Label
                mainText={'Numer REGON - Wymagany tylko w przypadku, jeśli Rodzaj działalności jest inny niż “Osoba fizyczna”'}
                text={'(regon)'}/>}
            name="regon"
            rules={[
                {
                    required: true,
                    message: 'Wpisz numer REGON, pole wymagane!',
                },
            ]}
        >
            <Input disabled={isLocked}/>
        </Form.Item>
        {/*<Form.Item*/}
        {/*    label={<Label*/}
        {/*        mainText={'Imię i nazwisko osoby upoważnionej do zawarcia umowy'}*/}
        {/*        text={'(representatives > name)'}/>}*/}
        {/*    name="representatives[name]"*/}
        {/*    rules={[*/}
        {/*        {*/}
        {/*            required: true,*/}
        {/*            message: 'Wpisz dane osoby, pole wymagane!',*/}
        {/*        },*/}
        {/*    ]}*/}
        {/*>*/}
        {/*    <Input/>*/}
        {/*</Form.Item>*/}
        {/*<Form.Item*/}
        {/*    label={<Label*/}
        {/*        mainText={'PESEL osoby upoważnionej do zawarcia umowy'}*/}
        {/*        text={'(representatives > pesel)'}/>}*/}
        {/*    name="representatives[pesel]"*/}
        {/*    rules={[*/}
        {/*        {*/}
        {/*            required: true,*/}
        {/*            message: 'Wpisz dane osoby, pole wymagane!',*/}
        {/*        },*/}
        {/*    ]}*/}
        {/*>*/}
        {/*    <Input/>*/}
        {/*</Form.Item>*/}
        <Form.Item
            label={<Label
                mainText={'Imię i nazwisko osoby kontaktowej'}
        />}
            name="contact_person_name"
            rules={[
                {
                    required: true,
                    message: 'Wpisz dane osoby, pole wymagane!',
                },
            ]}
        >
            <Input disabled={isLocked}/>
        </Form.Item>
        <Form.Item
            label={<Label
                mainText={'Adres e-mail osoby kontaktowej'}
            />}
            name="contact_person_email"
            rules={[
                {
                    type: 'email',
                    required: true,
                    message: 'Wpisz email osoby do kontaktu, pole wymagane!',
                },
            ]}
        >
            <Input disabled={isLocked}/>
        </Form.Item>
        <Form.Item
            label={<Label
                mainText={'Numer telefonu osoby kontaktowej'}
         />}
            name="contact_person_phone_number"
            rules={[
                {
                    required: true,
                    message: 'Wpisz numer telefonu osoby do kontaktu, pole wymagane!',
                },
            ]}
        >
            <Input disabled={isLocked}/>
        </Form.Item>
        <Form.Item
            label={<Label
                mainText={'Imię i nazwisko osoby opowiedzialnej za kontakt techniczny'}
               />}
            name="technical_contact_name"
            rules={[
                {
                    required: true,
                    message: 'Wpisz imię i nazwisko, osoby odpowiedzialnej za kontakt techniczny, pole wymagane!',
                },
            ]}
        >
            <Input disabled={isLocked}/>
        </Form.Item>
        <Form.Item
            label={<Label
                mainText={'Adres e-mail do kontaktu technicznego'}
             />}
            name="technical_contact_email"
            rules={[
                {
                    type: 'email',
                    required: true,
                    message: 'Wpisz email, osoby odpowiedzialnej za kontakt techniczny, pole wymagane!',
                },
            ]}
        >
            <Input disabled={isLocked}/>
        </Form.Item>
        <Form.Item
            label={<Label
                mainText={'Numer telefonu do kontaktu technicznego'}
               />}
            name="technical_contact_phone_number"
            rules={[
                {
                    required: true,
                    message: 'Wpisz numer telefonu osoby odpowiedzialnej za kontakt techniczny, pole wymagane!',
                },
            ]}
        >
            <Input disabled={isLocked}/>
        </Form.Item>
        <Form.Item
            label={<Label
                mainText={'Adres siedziby: miasto'}
                />}
            name="address_city"
            rules={[
                {
                    required: true,
                    message: 'Wpisz miasto, pole wymagane!',
                },
            ]}
        >
            <Input disabled={isLocked}/>
        </Form.Item>
        <Form.Item
            label={<Label
                mainText={'Adres siedziby: kod pocztowy'}
                />}
            name="address_post_code"
            rules={[
                {
                    required: true,
                    message: 'Wpisz kod pocztowy, pole wymagane!',
                },
            ]}
        >
            <Input disabled={isLocked}/>
        </Form.Item>
        <Form.Item
            label={<Label
                mainText={'Adres siedziby: ulica i numer'}
                />}
            name="address_street"
            rules={[
                {
                    required: true,
                    message: 'Wpisz ulicę do korespondecji, pole wymagane!',
                },
            ]}
        >
            <Input disabled={isLocked}/>
        </Form.Item>
        <Form.Item
            label={<Label
                mainText={'Adres do korespondecji: miasto'}
                />}
            name="correspondence_address_city"
            rules={[
                {
                    required: true,
                    message: 'Wpisz miasto korespondecji, pole wymagane!',
                },
            ]}
        >
            <Input disabled={isLocked}/>
        </Form.Item>
        <Form.Item
            label={<Label
                mainText={'Adres do korespondecji: kod pocztowy'}
               />}
            name="correspondence_address_post_code"
            rules={[
                {
                    required: true,
                    message: 'Wpisz kod pocztowy korespondecji, pole wymagane!',
                },
            ]}
        >
            <Input disabled={isLocked}/>
        </Form.Item>
        <Form.Item
            label={<Label
                mainText={'Adres do korespondecji: ulica i numer'}/>}
            name="correspondence_address_street"
            rules={[
                {
                    required: true,
                    message: 'Wpisz ulicę do korespondecji, pole wymagane!',
                },
            ]}
        >
            <Input disabled={isLocked}/>
        </Form.Item>
        {
            isLocked ? null : <Button
                type="primary"
                htmlType="submit"
            >
                Zapisz
            </Button>
        }

    </Form>
}

export default Przlewy24RegisterForm;

const Label = ({mainText, text = ''}) => {

    return <div><Text>{mainText} </Text>
        <Text type="secondary">{text}</Text></div>;
}
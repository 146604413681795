import { useState } from "react";
import axiosInstance from "../../lib/axios";

const useGetFullInfoUser = () => {

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [data, setData] = useState<{} | null>(null);

    const getUserInfo = async (id: string): Promise<any> => {
        try {
            setIsLoading(true);
            const res = await axiosInstance.get(`/admin/users/info/`+id);
            if (res.status === 200) {
                setData(res.data ? res.data : []);
                setIsLoading(false);
            }
        } catch (err) {
            console.log("err");
            setIsLoading(false);
        }
    };

    const reload = (id: string) => {
        getUserInfo(id);
    };

    return { isLoading, getUserInfo, reload , data};
}

export default useGetFullInfoUser;
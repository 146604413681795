import {Select} from "antd";
import React, {useContext} from "react";
import ProductVariantsFiltersContext from "../../../context/ProductVariantsFiltersContext";
import {UPDATE_PRODUCT_HAS_GROUP} from "../../../reducers/ProductVariantsFiltersReducer";

const HasProductGroupSelect = () => {

    const {dispatch, state} = useContext(ProductVariantsFiltersContext);

    return  <Select
        placeholder="Czy produkt jest przypisany?"
        mode="single"
        onChange={(e) => dispatch({type: UPDATE_PRODUCT_HAS_GROUP, payload: e})}
        defaultValue={state.hasProductGroup}
        allowClear={true}
    >
        <Select.Option value={1}>Tak</Select.Option>
        <Select.Option value={0}>Nie</Select.Option>
    </Select>
}

export default HasProductGroupSelect;
import {useState} from "react";
import axiosInstance from "../../lib/axios";

const useGetFullInformationProductCustomList = () => {

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [data, setData] = useState<{} | null>(null);

    const getProductCustomListInfo = async (id: string): Promise<any> => {
        try {
            const res = await axiosInstance.get(`/admin/product_custom_list/show/${id}`);

            if (res.status === 200) {
                setData(res.data ? res.data : []);
                setIsLoading(false);
            }
        } catch (err) {
            throw err;
        }
    };

    const reload = (id: string) => {
        getProductCustomListInfo(id);
    }

    return {isLoading, getProductCustomListInfo, data, reload};

}

export default useGetFullInformationProductCustomList;
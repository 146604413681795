import useUpdatePasswordUser from "../../../hooks/users/useUpdatePasswordUser";
import UpdatePasswordForm from "../../Forms/Users/UpdatePasswordForm";

const ChangePasswordModal = ({user, reload, setOpenModal, openModal }) => {

    const {update} = useUpdatePasswordUser();


    const handleSave = (obj) => {
        update(user.id, obj.password)
            .then(() => {
                setOpenModal(false);
                reload();
            });
    }

    return (
        <UpdatePasswordForm open={openModal} onCancel={() => setOpenModal(false)}
                 handleOK={handleSave}
                 title={'Zmień hasło'}/>
    );
};

export default ChangePasswordModal;

import useAlert from "../useAlert";
import axiosInstance from "../../lib/axios";

const useAddProductCustomItem = () => {

    const {successMessage, errorMessage} = useAlert();

    const add = async (obj: any, id: string): Promise<any> => {
        try {
            await axiosInstance.post("/admin/product_custom_list/product_custom_item/" + id, obj);
            successMessage("Zapisano");
        } catch (err) {
            errorMessage("Błąd zapisu, spróbuj ponownie");
            throw err;
        }
    };

    return {add};
}

export default useAddProductCustomItem;
import useCreateBreed from "../../../hooks/breeds/useCreateBreed";
import React from "react";
import BreedForm from "../../Forms/Breed/BreedForm";

const CreteBreed = ({reload, setOpenModal, open}) => {
    const {create} = useCreateBreed();


    const handleSave = (obj) => {
        create(obj)
            .then(() => {
                setOpenModal(false);
                reload();
            });
    };

    return (
        <BreedForm open={open}
                   onCancel={() => setOpenModal(false)}
                   handleOK={handleSave}
                   title={'Dodaj rasę'}/>
    );

}

export default CreteBreed;
import {Table, Tag} from "antd";
import UpdateBrandGroupName from "../../Modals/BrandGroups/UpdateBrandGroupName";
import AssignBrandToBrandGroup from "../../Modals/BrandGroups/AssignBrandToBrandGroup";

const BrandGroupTable = ({dataSource, reload, brands}) => {

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Przypisane marki',
            key: 'brands',
            dataIndex: '',
            render: row => {
                return row.brands.map((it) => {
                    return <Tag key={it.id}>{it.name}</Tag>
                })
            },
            width: '70%'
        },
        {
            title: 'Action',
            key: 'action',
            dataIndex: '',
            render: (row) => (
                <div style={{flexDirection: 'row', display: 'flex', gap: '10px' }}>
                    <UpdateBrandGroupName reload={reload} item={row}/>
                    <AssignBrandToBrandGroup reload={reload} item={row} brands={brands}/>
                </div>
            ),
        }
    ];

    return <Table dataSource={dataSource.map((it) => {
        return {...it, key: it.id}
    })} columns={columns} pagination={false} />;
}

export default BrandGroupTable;
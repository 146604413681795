import {ArrowLeftOutlined} from "@ant-design/icons";
import { Image, Table, Typography} from "antd";
import useGetProductCustomListResult from "../../hooks/productCustomList/useGetProductCustomListResult";
import {useParams} from "react-router-dom";
import {useEffect} from "react";
import FormIndicator from "../../components/Forms/FormIndicator";
import styled from "styled-components";

const NameContent = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  align-items: center;
  gap: 10px;
`;


const ProductCustomListProducts = () => {

    const params = useParams();
    const {getProductCustomListResult, isLoading, data} = useGetProductCustomListResult()

    useEffect(() => {
        getProductCustomListResult(params.sid);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const columns = [
        {
            title: 'Nazwa',
            dataIndex: '',
            key: 'name',
            render: (row) => <NameContent>
                <Image
                    width={50}
                    height={50}
                    src={row.photos ? row.photos[0] : 'graphic-placeholder.png'}
                    preview={!!row.photos}
                />
                <div>{row.name}</div>
            </NameContent>
        },
        {
            title: 'Producent',
            dataIndex: '',
            key: 'name',
            render: (row) => <>{row.brand.name}</>
        },
        {
            title: 'Cena brutto od:',
            dataIndex: '',
            key: 'priceBrutto',
            render: (row) => <>{row.price.priceGross} zł</>
        },
        {
            title: '',
            dataIndex: '',
            key: 'showProduct',
            render: (row) =><a  rel="noreferrer" target="_blank" href={"https://www.dev.appet.pl/product/show/" + row.slug}>Zobacz produkt</a>
        },
    ];

    return <>
        <div onClick={() => window.history.go(-1)} style={{padding: '15px'}}>
            <ArrowLeftOutlined/> <Typography.Text strong>Wróc</Typography.Text>
        </div>

        {
            isLoading ? <FormIndicator numberOfElements={3}/> : <Table columns={columns} dataSource={data.result} pagination={false}/>
        }
    </>;
}

export default ProductCustomListProducts;
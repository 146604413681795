import React, {useReducer} from "react";
import {initialState} from "../reducers/OrderFiltersReducers";
import {OrderSettlementFilterReducer} from "../reducers/OrderSettlementFilterReducers";

const OrderSettlementFilterContext = React.createContext();

export const OrderSettlementFiltersProvider = ({children}) => {

    const [state, dispatch] = useReducer(OrderSettlementFilterReducer, initialState);

    return <OrderSettlementFilterContext.Provider value={{state,dispatch}}>
        {children}
    </OrderSettlementFilterContext.Provider>
}

export default OrderSettlementFilterContext;
import {useState} from "react";
import useUpdateIngredientName from "../../../hooks/ingredient/useUpdateIngredientName";
import ButtonLink from "../../Buttons/ButtonLink";
import IngredientForm from "../../Forms/Ingredient/IngredientForm";

const UpdateIngredient = ({item, reload, grammages}) => {

    const [updateOpenModal, setUpdateOpenModal] = useState(false);
    const {update} = useUpdateIngredientName();

    const handleOk = (values) => {
        let obj = {
            id: item.id,
            grammageId: values.grammageId,
            name: values.name
        }

        update(obj).then(() => {
            reload();
            setUpdateOpenModal(false);
        })
    }

    return <>
        <ButtonLink text={"Edycja"} onClick={() => setUpdateOpenModal(true)}/>
        <IngredientForm
            grammages={grammages}
            item={item}
            open={updateOpenModal}
            title={'Edycja nazwy'}
            handleOK={handleOk}
            onCancel={() => setUpdateOpenModal(false)}
        /></>
}

export default UpdateIngredient;
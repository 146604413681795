import useGetCartAbandonedDetails from "../../hooks/cart/useGetCartAbandonedDetails";
import {useEffect} from "react";
import {useParams} from "react-router-dom";
import FormIndicator from "../../components/Forms/FormIndicator";
import Title from "../../components/Title";
import styled from "styled-components";
import {Card, Image} from "antd";
import TableAbandonedCartItems from "../../components/Tables/Cart/TableAbandonedCartItems";

const CartDetailComponent = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
`;

const OrderMainInfoComponent = styled.div`
  flex: 2;
  background-color: white;
`;

const AbandonendCartDetail = () => {

    const params = useParams();
    const {data, isLoading, getCartById} = useGetCartAbandonedDetails();

    useEffect(() => {
        getCartById(params.id);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    return (<>
            {
                isLoading ? <FormIndicator numberOfElements={3}/> : <>

                    <Title title="Szczegóły Koszyka"/>
                    <CartDetailComponent>
                        <OrderMainInfoComponent>
                            <TableAbandonedCartItems data={data}/>
                            <Card type='inner' title='Imię i nazwisko klienta'>
                                {data[0].orderAddressView ? data[0].orderAddressView.name : 'brak danych'}
                            </Card>
                            <Card type='inner' title='Numer telefonu'>
                                {data[0].orderAddressView ? data[0].orderAddressView.mobilePhone : 'brak danych'}
                            </Card>
                            <Card type='inner' title='Email'>
                                {data[0].orderAddressView ? data[0].orderAddressView.email : 'brak danych'}
                            </Card>
                            <Card type='inner' title='Dostawa'>
                                {data[0].orderDeliveryView ?  <Image
                                    style={{width: '50px', height: '50px', objectFit: 'contain'}}
                                    preview={false}
                                    src={
                                        data[0].orderDeliveryView.deliveryProductOwner.deliveryView.logo
                                    }
                                />
                                    : 'brak danych'}
                            </Card>
                        </OrderMainInfoComponent>
                        <div style={{flex: 1}}>
                            <Card type='inner' title='Data dodania pierwszego produktu'>
                                {data[0].createdAt}
                            </Card>
                            <Card type='inner' title='Data ostatniej aktualizacji'>
                                {data.slice(-1)[0].updatedAt}
                            </Card>
                            <Card type='inner' title='Czy użytkownik zalogowany tworzył koszyk?'>
                                {data[0].userView ? 'Tak' : 'Nie' }
                            </Card>
                        </div>
                    </CartDetailComponent>
                </>
            }
        </>
    );
}

export default AbandonendCartDetail;
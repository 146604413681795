import CustomButton from "../../Forms/Buttons/CustomButton";
import FormTitle from "../../Forms/FormTitle";
import React, {useEffect, useState} from "react";
import CreateCrossSellingModal from "../../Modals/Products/CreateCrossSellingModal";
import {Button, Image, Table} from "antd";
import useGetProductFullInformation from "../../../hooks/products/useGetProductFullInformation";
import {useParams} from "react-router-dom";
import useDeleteRelation from "../../../hooks/products/productCrossSelling/useDeleteRelation";
import Indicator from "../../Indicator";

const CrossSellingTab = ({brands}) => {

    const params = useParams();

    const {isLoading, getProductById, data} =
        useGetProductFullInformation();

    const {deleteApi} = useDeleteRelation();

    useEffect(() => {
        getProductById(params.id);


        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const deleteRelation = (row) => {
        deleteApi({p1: data.id, p2: row.id}).then(() => {
            getProductById(params.id)
        });
    };

    const columns = [
        {
            title: 'Zdjęcie',
            dataIndex: 'photo',
            key: 'photo',
            render: (row) =>
                <Image
                    width={50}
                    height={50}
                    src={row ? row.fullPath : 'graphic-placeholder.png'}
                    preview={!!row}
                />
        },
        {
            title: 'Nazwa',
            dataIndex: '',
            key: 'name',
            render: (row) => <a href={'/product/update/'+row.id}>{row.name}</a>
        },
        {
            title: '',
            render: row => <Button onClick={() => deleteRelation(row)}>Usuń relacje</Button>
        },
    ];
    const [openModal, setOpenModal] = useState(false);

    return (
        <>
            {isLoading ? <Indicator/> : <><FormTitle title={"Produkty powiązane"}/>
                <CustomButton title={'Dodaj powiązany produkt'} onClick={() => setOpenModal(true)}/>
                <div style={{margin: '10px'}}>
                    <Table columns={columns} dataSource={data.related.map((it) => {
                        return {
                            ...it,
                            key: it.id
                        }
                    })
                    }
                           pagination={false}/>
                </div>
                <CreateCrossSellingModal brands={brands} openModal={openModal}
                                         setOpenModal={setOpenModal}
                                         item={data}
                                         reload={() => getProductById(data.id)}
                /></>}
        </>
    )
}

export default CrossSellingTab;
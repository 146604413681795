import { useState } from "react";
import axiosInstance from "../../lib/axios";
import useAlert from "../useAlert";

type CreateCategoryProps = {
  name: string;
  parentId: string;
};

const useCreateCategory = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { errorMessage } = useAlert();

  const create = async (obj: CreateCategoryProps): Promise<any> => {
    try {
      setIsLoading(true);
      await axiosInstance.post("/admin/category/", obj);

      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      errorMessage("Błąd dodawania kategorii. Spróbuj ponownie");
      // throw err;
    }
  };

  return { create, isLoading };
};

export default useCreateCategory;

export const CHANGE_PAGE_AND_LIMIT = "change_page_and_limit";
export const CHANGE_PAGE = "change_page";
export const CHANGE_LIMIT = "change_limit";

export const CLEAR_ALL = 'clear_all';

export const UPDATE_PRICE_FROM = 'update_price_from';
export const UPDATE_PRICE_TO = 'update_price_to';
export const UPDATE_DATE_FROM = 'created_at_from';
export const UPDATE_DATE_TO = 'created_at_to';
export const UPDATE_ORDER_NUMBER = 'update_order_number';
export const UPDATE_ORDER_STATUS = 'update_order_status';
export const UPDATE_PRODUCT_OWNER_ID = 'update_product_owner_id';
export const UPDATE_IS_BILL_PAID = 'update_is_bill_paid';
export const UPDATE_TYPE_OF_ORDER_SETTLEMENT = 'update_type_of_order_settlement';


export const priceFrom = "priceFrom";
export const priceTo = "priceTo";
export const limit = "limit";
export const page = "page";
export const discountFrom = 'discountFrom';
export const discountTo = 'discountTo';
export const createdAtTo = 'createdAtTo';
export const createdAtFrom = 'createdAtFrom';
export const orderNumber = 'orderNumber';
export const productOwnerId = "productOwnerId";
export const orderBy = "orderBy";
export const isBillPaid = 'isBillPaid';
export const typeOfOrderSettlement = 'typeOfOrderSettlement';
export const status = 'status';

export const initialState = {
    [limit]: 20,
    [page]: 1,
    [typeOfOrderSettlement]: '',
    [isBillPaid]: '',
    [priceFrom]: '',
    [priceTo]: '',
    [discountFrom]: '',
    [discountTo]: '',
    [createdAtFrom]: '',
    [createdAtTo]: '',
    [orderNumber]: '',
    [productOwnerId]: "",
    [orderBy]: "created_at_desc",
    [status]: '',
};

type ACTIONTYPE =
    | { type: typeof CHANGE_PAGE; payload: number }
    | { type: typeof CHANGE_LIMIT; payload: number }
    | { type: typeof CHANGE_PAGE_AND_LIMIT; payload: { [page]: number; [limit]: number } }
    | { type: typeof CLEAR_ALL; payload: null }
    | { type: typeof UPDATE_PRICE_FROM; payload: string }
    | { type: typeof UPDATE_PRICE_TO; payload: string }
    | { type: typeof UPDATE_DATE_FROM; payload: string }
    | { type: typeof UPDATE_DATE_TO; payload: string }
    | { type: typeof UPDATE_IS_BILL_PAID; payload: boolean }
    | { type: typeof UPDATE_TYPE_OF_ORDER_SETTLEMENT; payload: string }
    | { type: typeof UPDATE_ORDER_STATUS; payload: string }
    | { type: typeof UPDATE_ORDER_NUMBER; payload: string }
    | { type: typeof UPDATE_PRODUCT_OWNER_ID; payload: string };


export const OrderSettlementFilterReducer = (state: typeof initialState, action: ACTIONTYPE) => {

    switch (action.type) {
        case CHANGE_PAGE:
            return {
                ...state,
                [page]: action.payload,
            };
        case CHANGE_LIMIT:
            return {
                ...state,
                [limit]: action.payload,
            };
        case CHANGE_PAGE_AND_LIMIT:
            return {
                ...state,
                [page]: action.payload[page],
                [limit]: action.payload[limit],
            };
        case CLEAR_ALL:
            return initialState;

        case UPDATE_PRICE_FROM:
            return {
                ...state,
                [priceFrom]: action.payload,
            }
        case UPDATE_PRICE_TO:
            return {
                ...state,
                [priceTo]: action.payload,
            }
        case UPDATE_DATE_FROM:
            return {
                ...state,
                [createdAtFrom]: action.payload,
            }
        case UPDATE_DATE_TO:
            return {
                ...state,
                [createdAtTo]: action.payload,
            }
        case UPDATE_IS_BILL_PAID:
            return {
                ...state,
                [isBillPaid]: action.payload,
            }
        case UPDATE_ORDER_STATUS:
            return {
                ...state,
                [status]: action.payload,
            }
        case UPDATE_ORDER_NUMBER:
            return {
                ...state,
                [orderNumber]: action.payload,
            }

        case UPDATE_PRODUCT_OWNER_ID:
            return {
                ...state,
                [productOwnerId]: action.payload
            }

        case UPDATE_TYPE_OF_ORDER_SETTLEMENT:
            return {
                ...state,
                [typeOfOrderSettlement]: action.payload
            }
        default:
            return state;
    }
}
import { useState } from "react";
import axiosInstance from "../../lib/axios";

const useUpdateProductOwnerActivity = () => {
    const [isLoading, setIsLoading] = useState(false);

    const update = async (id: string, isActive: boolean): Promise<any> => {
        try {
            setIsLoading(true);
            const obj = {
                isActive: isActive,
            };
            const { data } = await axiosInstance.put("/admin/product_owner/activity/" + id, obj);

            if (data.id) {
                setIsLoading(false);
            }

        } catch (err) {
            setIsLoading(false);
            throw err;
        }
    };

    return { update, isLoading };
}

export default useUpdateProductOwnerActivity;

import {useState} from "react";
import useAlert from "../useAlert";
import axiosInstance from "../../lib/axios";

interface UpdateVatRateProps {
    vatRate: number,
    productId: string
}

const useUpdateVatRate = () => {
    const [isLoading, setIsLoading] = useState(false);
    const {successMessage, errorMessage} = useAlert();

    const update = async(obj: UpdateVatRateProps) : Promise<any> => {
        try {
            setIsLoading(true);
            await axiosInstance.put(
                `/admin/product/vat-rate/` + obj.productId,
                obj
            );

            setIsLoading(false);
            successMessage('Vat produktu został zmieniony');

        } catch (err) {
            errorMessage('Błąd podczas zmiany vatu produktu');
            setIsLoading(false);
            throw err;
        }
    };

    return {update, isLoading}
}

export default useUpdateVatRate;
import {Image, Tabs, Typography} from "antd";
import useGetProductOwnerFullInfo from "../../hooks/productOwners/useGetProductOwnerFullInfo";
import WorkersTab from "../../components/Tabs/ProductOwnerTabs/WorkersTab";
import {useEffect} from "react";
import {useParams} from "react-router-dom";
import FormIndicator from "../../components/Forms/FormIndicator";
import MainInformationTab from "../../components/Tabs/ProductOwnerTabs/MainInformationTab";
import SettlementCommissionTab from "../../components/Tabs/ProductOwnerTabs/SettlementCommissionTab";
import Przelewy24 from "../../components/Tabs/ProductOwnerTabs/Przelewy24";
import OrderSettlementTab from "../../components/Tabs/ProductOwnerTabs/OrderSettlementTab";
import DeliveryTab from "../../components/Tabs/ProductOwnerTabs/DeliveryTab";
import ConfigurationSettingTab from "../../components/Tabs/ProductOwnerTabs/ConfigurationSettingTab";
import ProductOwnerDescriptionTab from "../../components/Tabs/ProductOwnerTabs/ProductOwnerDescriptionTab";

const {TabPane} = Tabs;
const {Title} = Typography;
const ProductOwnerDetails = () => {

    const params = useParams();
    const {data, getProductOwnerInfo, isLoading, reload} = useGetProductOwnerFullInfo();

    useEffect(() => {
        getProductOwnerInfo(params.id);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (<>
        {
            isLoading ? <FormIndicator numberOfElements={3}/> : (<>
                {data.logo ? <Image src={data.logo} height={50} width={100} style={{marginBottom: '10px'}}/> : <Title level={4}>{data.name}</Title>}
                <div className="card-container">
                    <Tabs type="card">
                        <TabPane tab="Ustawienia" key="1">
                            <ConfigurationSettingTab data={data} reload={() => getProductOwnerInfo(params.id)}/>
                        </TabPane>
                        <TabPane tab="Informację ogólne" key="2">
                            <MainInformationTab data={data} reload={() => getProductOwnerInfo(params.id)}/>
                        </TabPane>
                        <TabPane tab="Dostawy" key="3">
                            <DeliveryTab/>
                        </TabPane>
                        <TabPane tab="Rozliczenia" key="4">
                            <OrderSettlementTab />
                        </TabPane>
                        <TabPane tab="Pracownicy" key="5">
                            <WorkersTab data={data.users} reload={() => reload(params.id)}/>
                        </TabPane>
                        <TabPane tab="Prowizje" key="6">
                           <SettlementCommissionTab />
                        </TabPane>
                        <TabPane tab="Przelewy 24" key="7">
                            <Przelewy24 />
                        </TabPane>
                        <TabPane tab="Opis sklepu" key="8">
                            <ProductOwnerDescriptionTab data={data} />
                        </TabPane>
                    </Tabs>
                </div>
            </>)
        }
    </>);


}

export default ProductOwnerDetails;
import useAlert from "../useAlert";
import axiosInstance from "../../lib/axios";

interface CreateStaticPageProps {
    name: string
}

const useCreateStaticPage = () => {

    const {successMessage, errorMessage} = useAlert();

    const create = async (obj: CreateStaticPageProps): Promise<any> => {
        try {
            await axiosInstance.post("/admin/static-page/", obj);
            successMessage("Strona została utworzona");
        } catch (err) {
            errorMessage("Błąd podczas zapisu, spróbuj ponownie");
            throw err;
        }
    };

    return { create };

}

export default useCreateStaticPage;
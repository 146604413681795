import {useState} from "react";
import axiosInstance from "../../lib/axios";

const useGetBlogPostById = () => {

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [data, setData] = useState<{} | null>(null);

    const getBlogPost = async (id: string, hasIndicator: boolean = true): Promise<any> => {
        try {
            setIsLoading(hasIndicator);
            const res = await axiosInstance.get(`/admin/blog/post/${id}`);

            if (res.status === 200) {
                setData(res.data);
            }
            setIsLoading(false);
            return res.data;
        } catch (err) {
            throw err;
        }
    }
    return {isLoading, getBlogPost, data, setIsLoading};
}

export default useGetBlogPostById;
import useGetSettlmenetCommissionByPOId
    from "../../../hooks/productOwners/settlementCommission/useGetSettlmenetCommissionByPOId";
import {useEffect} from "react";
import FormIndicator from "../../Forms/FormIndicator";
import {useParams} from "react-router-dom";
import useUpdateSettlementCommission
    from "../../../hooks/productOwners/settlementCommission/useUpdateSettlementCommission";
import UpdateProductOwnerSettlementCommissionForm
    from "../../Forms/ProductOwner/SettlementCommission/UpdateProductOwnerSettlementCommissionForm";

const SettlementCommissionTab = () => {

    const {data, isLoading, getProductOwnerSettlementCommission} = useGetSettlmenetCommissionByPOId();
    const {update} = useUpdateSettlementCommission();
    const params = useParams();


    useEffect(() => {
        getProductOwnerSettlementCommission(params.id);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleSave = (values) => {
        update({id: params.id, ...values});
    }


    return <>
        {isLoading ? <FormIndicator numberOfElements={3}/> : (<>
            <UpdateProductOwnerSettlementCommissionForm data={data} handleOk={handleSave}/>
        </>)}
    </>
}

export default SettlementCommissionTab;
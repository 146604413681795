import {useState} from "react";
import axiosInstance from "../../lib/axios";
import useAlert from "../useAlert";

type UpdateInfoDeliveryProps = {
    name: string;
    description: string;
}

const useUpdateInfoDelivery = () => {

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const {successMessage, errorMessage} = useAlert();


    const update = async (id: string, obj: UpdateInfoDeliveryProps): Promise<any> => {
        try {
            setIsLoading(true);
            const { data } = await axiosInstance.put("/admin/delivery/info/" + id, obj);

            if (data.id) {
                setIsLoading(false);
            }
            successMessage("Zapisano");
        } catch (err) {
            setIsLoading(false);
            errorMessage("Błąd zapisu, spróbuj ponownie");
            throw err;
        }
    };

    return { update, isLoading };
}

export default useUpdateInfoDelivery;
import {Select} from "antd";
import React, {useContext, useEffect, useState} from "react";
import OrderFiltersContext from "../../../context/OrderFiltersContext";
import {UPDATE_DELIVERY_ID} from "../../../reducers/OrderFiltersReducers";

const OrderDeliverySelect = ({deliveries}) => {

    const {dispatch, state} = useContext(OrderFiltersContext);

    const [del, setDelivery] = useState([]);

    useEffect(() => {
        let tmp = deliveries.map((it) => {
            return {
                ...it,
                label: it.name,
                value: it.id
            }
        })

        setDelivery(tmp);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <Select
        showSearch
        placeholder="Wybierz kuriera"
        allowClear={true}
        onClear={() => dispatch({type: UPDATE_DELIVERY_ID, payload: null})}
        onChange={(e, obj) => {
            if (obj) {
                dispatch({type: UPDATE_DELIVERY_ID, payload: obj.value})
            }
        }}
        filterOption={(input, option) =>
            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
        }
        optionFilterProp="children"
        value={state.deliveryId}
        options={del}
    >
    </Select>
}

export default OrderDeliverySelect;
import React, {useEffect, useState} from "react";
import './../index.css';
import routing from "../routing/routing";
import {Layout, Menu} from 'antd';
import {NavLink} from "react-router-dom";
import CustomButton from "../components/Forms/Buttons/CustomButton";
import {MAIN_APP} from "../const/url";
import UserProfile from "../components/Menu/UserProfile";

const {Header, Sider, Content} = Layout;

function getItem(label, key, icon, children) {
    return {
        key,
        icon,
        children,
        label,
    };
}

const LayoutTest = ({children}) => {
    const [collapsed, setCollapsed] = useState(false);

    const [routes, setRoutes] = useState([]);

    useEffect(() => {

        let tmp = routing.map((it, index) => {
            let ch = [];
            if (it.children) {
                ch = it.children.map((child) => {
                    return getItem(<NavLink to={child.path}>{child.name}</NavLink>, child.name)
                });
            }

            return getItem(ch.length ? it.name :
                <NavLink to={it.path}>{it.name}</NavLink>, index + 1, it.icon, it.children ? ch : null)
        })

        setRoutes(tmp);
    }, []);

    return (
        <Layout style={{
            minHeight: '100vh',
        }}>
            <Sider style={{backgroundColor: 'white'}} collapsible collapsed={collapsed}
                   onCollapse={(value) => setCollapsed(value)}>
                <div className="logo">
                    <img src='https://appet-prod-upload.s3.eu-central-1.amazonaws.com/static/logo-admin.png'
                         alt={"logo-appet"} height={75} width={190}/>
                </div>
                <Menu
                    theme="light"
                    mode="inline"
                    defaultSelectedKeys={['1']}
                    items={routes}
                />
            </Sider>
            <Layout className="site-layout">
                <Header
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        padding: 0,
                        backgroundColor: 'white',
                        gap: '10px',
                        justifyContent: 'flex-end',
                        alignItems: 'center'
                    }}
                >
                    <CustomButton title={'Główna aplikacja'} onClick={() => window.open(MAIN_APP, '_blank')}/>
                    <UserProfile/>
                </Header>
                <Content
                    style={{
                        width: '100%',
                        height: '100%',
                        padding: 24,
                        minHeight: 280,
                    }}
                >
                    <main>{children}</main>
                </Content>
            </Layout>
        </Layout>
    );

};

export default LayoutTest;

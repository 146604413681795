import {themes} from "../layout/styles/ColorStyles";

const NO_ACTION = "no_action";
const NO_ANSWER = "no_answer";
const NEW_ORDER = "new_order";
const CANCELLED_BY_USER = "cancelled_by_user";


const status = [
    {
        value: NO_ACTION,
        label: "-",
        color: themes.status.created,
    },
    {
        value: NO_ANSWER,
        label: "Brak odpowiedzi",
        color: themes.status.submitted,
    },
    {
        value: NEW_ORDER,
        label: "Nowe zamówienie",
        color: themes.status.packaged,
    },
    {
        value: CANCELLED_BY_USER,
        label: "Rezygnacja",
        color: themes.status.send,
    },

];

export default status;

import axiosInstance from "../../lib/axios";
import useAlert from "../useAlert";

interface UpdateDeliveryFee {
    productOwnerId: string;
    price: string;
    deliveryId: string;
    isFreeFromPrice: boolean;
    freeFromPrice: null;
    maxTimeSendSameDay: string;
    deliveryDurationTime: number;
    isDeliveryNextDay: boolean;
}

const useAssignDeliveryToProductOwner = () => {
    const {successMessage, errorMessage} = useAlert();

    const assign = async (obj: UpdateDeliveryFee): Promise<any> => {
        try {
            await axiosInstance.put("/admin/delivery/productOwner/assigment/" + obj.productOwnerId, obj);
            successMessage("Dodano nową metodę dostawy");
        } catch (err) {
            errorMessage("Błąd zapisu, spróbuj ponownie.");
            throw err;
        }
    };

    return {assign};
};

export default useAssignDeliveryToProductOwner;

import {CategoryProvider} from "./CategoryContext";
import {ProductsFiltersProvider} from "./ProductsFiltersContext";
import {UserFiltersProvider} from "./UserFiltersContext";
import {OrderFiltersProvider} from "./OrderFiltersContext";
import {ProductVariantFiltersProvider} from "./ProductVariantsFiltersContext";
import {ProductBasedOnProductVariantEditorProvider} from "./ProductBasedOnProductVariantEditorContext";
import React from "react";
import {ProductProvider} from "./ProductContext";
import {ReviewProductFiltersProvider} from "./ReviewProductFiltersContext";
import {CartFilterProvider} from "./CartFilterContext";
import {OrderSettlementFiltersProvider} from "./OrderSettlementFilterContext";

interface Props {
    components: Array<React.JSXElementConstructor<React.PropsWithChildren<any>>>;
    children: React.ReactNode;
}

// tutaj nalezy umieszczac providery, które mają zostać przekazane do aplikacji
const providers = [OrderFiltersProvider,
    ProductsFiltersProvider,
    CategoryProvider,
    UserFiltersProvider,
    ProductVariantFiltersProvider,
    ProductBasedOnProductVariantEditorProvider, ProductProvider, ReviewProductFiltersProvider, CartFilterProvider, OrderSettlementFiltersProvider];

const AppContextProvider = (props: Props) => {
    const {children} = props;

    return (
        <>
            {providers.reduceRight((acc, Comp) => {
                return <Comp>{acc}</Comp>;
            }, children)}
        </>
    );
};

export default AppContextProvider;

import React, {useEffect, useState} from "react";
import useGetPrzelewy24Configuration from "../../../hooks/productOwners/Przelewy24/useGetPrzelewy24Configuration";
import {useParams} from "react-router-dom";
import {Button, Typography} from "antd";
import ButtonLink from "../../Buttons/ButtonLink";
import DrawerAkm from "../../DrawerAkm";
import Przlewy24RegisterForm from "../../Forms/ProductOwner/Przlewy24RegisterForm";
import useRegisterPOPrzelewy24 from "../../../hooks/productOwners/Przelewy24/useRegisterPOPrzelewy24";
import Paper from "../../../layout/Paper";
import PaymentStatusLabel from "../../Labels/PaymentStatusLabel";


const Przelewy24 = () => {

    const [openDrawer, setOpenDrawer] = useState(false);
    const params = useParams();
    const {data, isLoading, getPrzelewyConfiguration} = useGetPrzelewy24Configuration();
    const {create} = useRegisterPOPrzelewy24();

    useEffect(() => {

        getPrzelewyConfiguration(params.id);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    if (isLoading ) return null;

    const onClose = () => {
        setOpenDrawer(false);
    };

    const handleSave = (values) => {
        create({...values, id: params.id}).then(() => getPrzelewyConfiguration(params.id));
    }

    return (
        <Paper>
            <div style={{display: 'flex', flexDirection: 'column', gap: '20px'}}>
                <Typography.Title level={3}>Konfiguracja Przelewy24</Typography.Title>
                <Typography.Text>Konto zarejestrowane:</Typography.Text>
                <PaymentStatusLabel paid={data.p24MerchantAccountRegistered} textTrue={'Tak'} textFalse={'Nie'}/>
                {
                    data.p24MerchantAccountRegistered ? <div style={{width: '200px'}}>
                            <ButtonLink onClick={() => setOpenDrawer(true)}
                                        text={'Zobacz wprowadzone dane podczas rejestracji'}/></div> :
                        <Button onClick={() => setOpenDrawer(true)} style={{width: '200px'}} type='primary'>Wpisz dane
                            sklepu</Button>
                }
                {
                    !data.p24MerchantAccountRegistered ?
                        <div style={{display:'flex', flexDirection: 'column'}}>
                            <Typography.Text>Link do rejestracji</Typography.Text><Typography.Text type='secondary'>
                            Jeżeli konto nie jest jeszcze zarejestrowane. Ale dane zostały wcześniej wprowadzone, należy zatwierdzić je ponownie w formularzu rejestracji.
                        </Typography.Text></div> : ''
                }
                {
                    (!data.p24MerchantRegistrationForm ) ? <Typography.Text strong>Uzupełnij dane sklepu, aby wyświetlić link</Typography.Text> : <Typography.Text strong>{data.p24MerchantRegistrationForm.link}</Typography.Text>
                }
                <Typography.Text>Status konta w Przelewy24: (customer_status)</Typography.Text>
                <PaymentStatusLabel paid={data.p24CustomerStatus} textTrue={data.p24CustomerStatus} textFalse='Niezarejestrowany'/>
                <Typography.Text>Token konta Przelewy24: </Typography.Text>
                {
                    data.p24MerchantToken ? <Typography.Text strong>{data.p24MerchantToken}</Typography.Text> : 'Nie przypisano'
                }
                <DrawerAkm
                    title={data.p24MerchantAccountRegistered ? "Utwórz" : "Podgląd danych"}
                    open={openDrawer}
                    onClose={onClose}
                >
                    <Przlewy24RegisterForm handleSave={handleSave} data={data.p24MerchantRegistrationForm} isLocked={data.p24MerchantAccountRegistered}/>
                </DrawerAkm>
            </div>
        </Paper>
    )
}

export default Przelewy24;
import styled from "styled-components";
import {Form, Formik} from "formik";
import * as Yup from "yup";
import CustomField from "../CutomField";
import CustomError from "../CustomError";
import CustomSubmitButton from "../Buttons/CustomSubmitButton";

const FormWrapper = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
`;

const FormContent = styled(Form)`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 20px;
`;

const ItemWrapper = styled.div`
  position: relative;
  margin-bottom: ${(props) => (props.error ? "15px" : "0px")};
`;

const CreateProductOwnerSchema = Yup.object().shape({
    name: Yup.string().required("Nazwa jest wymagana"),
});

const CreateProductOwner = ({save, name = null}) => {

    const handleSubmit = (values) => {
        save(values);
    };

    return (
        <>
            <FormWrapper>
                <Formik
                    initialValues={{
                        name: name ? name : "",
                    }}
                    onSubmit={handleSubmit}
                    validationSchema={CreateProductOwnerSchema}
                    validateOnChange={false}
                    validateOnBlur={false}
                >
                    {({ errors, touched }) => (
                        <FormContent>
                            <ItemWrapper error={errors.name}>
                                <CustomField
                                    name={"name"}
                                    placeholder={""}
                                    labelText={"Nazwa"}
                                />
                                {errors.name && touched.name ? (
                                    <CustomError error={errors.name} />
                                ) : null}
                            </ItemWrapper>
                            <div
                                style={{
                                    marginTop: "45px",
                                    display: "flex",
                                }}
                            >
                                <CustomSubmitButton title="Zapisz" />
                            </div>
                        </FormContent>
                    )}
                </Formik>
            </FormWrapper>
        </>
    );
}

export default CreateProductOwner;
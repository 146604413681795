import {useState} from "react";
import useAlert from "../../useAlert";
import axiosInstance from "../../../lib/axios";

interface UpdateProductIngredientValueProps {
    ingredientId: string;
    value: string;
    productId: string;
}

const useUpdateIngredientProductValue = () => {
    const [isLoading, setIsLoading] = useState(false);
    const {successMessage, errorMessage} = useAlert();

    const update = async(obj: UpdateProductIngredientValueProps) : Promise<any> => {
        try {
            setIsLoading(true);
            await axiosInstance.put(
                `/admin/product/ingredient_value/${obj.productId}`,
                obj
            );

            setIsLoading(false);
            successMessage('Dane zostały zapisane');

        } catch (err) {
            errorMessage('Wystąpił błąd podczas zapisywania danych');
            setIsLoading(false);
            throw err;
        }
    };

    return {update, isLoading}
}

export default useUpdateIngredientProductValue;
import {Select} from "antd";
import useUpdateReviewStatus from "../../../hooks/review/useUpdateReviewStatus";
import {ReviewStatusDot} from "../../../layout/ReviewStatusDot";

const SelectReviewStatus = ({selected, reload}) => {

    const {updateReviewStatus} = useUpdateReviewStatus();

    const handleChange = (value) => {

        updateReviewStatus([selected.id], value).then(() => {
            reload();
        });
    }

    return <Select defaultValue={selected.status} style={{width: '100%'}} onChange={handleChange}
    >
        <Select.Option
            value={'submitted'}>
            <ReviewStatusDot status={'submitted'} value={'Zaakceptowane'} />
        </Select.Option>
        <Select.Option
            value={'waiting'}>
            <ReviewStatusDot status={'waiting'} value={'Oczekujące'} />
        </Select.Option>
        <Select.Option
            value={'deleted'}>
            <ReviewStatusDot status={'deleted'} value={'Do usunięcia'} />
        </Select.Option>
    </Select>
}

export default SelectReviewStatus;
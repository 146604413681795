import styled from "styled-components";
import useGetOrderSettlementCollection from "../../hooks/orders/useGetOrderSettlementCollection";
import {useContext, useEffect} from "react";
import useGetAllProductOwners from "../../hooks/productOwners/useGetAllProductOwners";
import FilterCollapse from "../../components/Collaps/FilterCollapse";
import FormIndicator from "../../components/Forms/FormIndicator";
import OrderSettlementTable from "../../components/Tables/OrderSettlement/OrderSettlementTable";
import FiltersForm from "../../components/Forms/OrderSettlement/FiltersForm";
import OrderSettlementFilterContext from "../../context/OrderSettlementFilterContext";
import useGetOrderSettlementSummary from "../../hooks/orders/useGetOrderSettlementSummary";
import PaginationList from "../../components/PaginationList";
import {CHANGE_PAGE_AND_LIMIT} from "../../reducers/OrderSettlementFilterReducers";
import {Tooltip} from "antd";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SummaryBox = styled.div`
  width: 100%;
  height: auto;
  background-color: #ffffff;
  border-radius: 5px;
  border: 1px solid #e5e9f2;
  padding: 15px;
  margin-top: 10px;
  margin-bottom: 10px;
  display: grid;
  grid-template-columns: 12% 12% 12% 12% 12% 12%;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;
`;

export const DetailBox = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;
  padding: 5px;
  line-height: 28px;
`;

export const Title = styled.div`
  flex: 1;
`;


export const Price = styled.div`
  flex: 2;
  font-size: 25px;
`;


const OrderSettlement = () => {

    const {data, isLoading, reload} = useGetOrderSettlementCollection();
    const {data: productOwners, isLoading: isLoadingProductOwners} = useGetAllProductOwners();
    const {data: summary, isLoading: isLoadingSummary, getOrdersSettlementSummary} = useGetOrderSettlementSummary();
    const {state} = useContext(OrderSettlementFilterContext);


    useEffect(() => {
        reload(state);
        getOrdersSettlementSummary(state);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.page, state.limit]);

    return <Wrapper>
        {isLoadingSummary ? <FormIndicator numberOfElements={1}/> : <SummaryBox>
            <DetailBox>
                <Title>Wartość produktów przed rabatem brutto</Title>
                <Price> {summary.originalItemPriceGross} zł</Price>
                <Title>Wartość produktów przed rabatem netto</Title>
                <Price>{summary.originalItemPriceNet} zł</Price>
            </DetailBox>


            <DetailBox>
                <Tooltip title="Suma wartości produktów bez rabatu + dostawa - nasza prowizja">
                    <Title>Suma wartości które partner powinnien otrzymać z zamówień - brutto</Title>
                    <Price>{summary.totalPriceToShopGross}</Price>
                    <Title>Suma wartości które partner powinnien otrzymać z zamówień - netto</Title>
                    <Price>{summary.totalPriceToShopNet}</Price>
                </Tooltip>
            </DetailBox>

            <DetailBox>
                <Tooltip
                    title="Suma wartości zamówień (wartość produktów + dostawa) z uwzględnieniem rabatów jeśli były">
                    <Title>Suma wartości zamówienia które zapłacił klient brutto</Title>
                    <Price>{summary.totalPricePaidByCustomerGross}</Price>
                    <Title>Suma wartości zamówienia które zapłacił klient netto</Title>
                    <Price>{summary.totalPricePaidByCustomerNet}</Price>
                </Tooltip>
            </DetailBox>

            <DetailBox>
                <Tooltip
                    title="Suma wartości zamówień wysłanych do partnera z odjęciem naszej prowizji">
                    <Title>Suma wartości zamówień które zostały wysłane do partnera</Title>
                    <Price>{(summary.totalPriceToShopGross - summary.totalDiscount).toFixed(2)} </Price>
                    <Title>Suma wartości zamówień które powinien zapłacić klient</Title>
                    <Price> {summary.originalTotalPriceGross} </Price>
                </Tooltip>
            </DetailBox>

            <DetailBox>
                <Title>Wartość prowizji brutto</Title>
                <Price>{summary.totalCommissionGross} zł</Price>
                <Title>Wartość prowizji netto</Title>
                <Price>{summary.totalCommissionNet} zł</Price>
            </DetailBox>
            <DetailBox>
                <Title>Wartość dostaw brutto</Title>
                <Price>{summary.totalDeliveryPriceGross} zł</Price>
                <Title>Wartość dostaw netto</Title>
                <Price>{summary.totalDeliveryPriceNet} zł</Price>
            </DetailBox>

            {/*<DetailBox>*/}
            {/*    <Title>Wartość rabatów na produkty brutto</Title>*/}
            {/*    <Price>{summary.totalDiscountGrossProduct} zł</Price>*/}
            {/*    <Title>Wartość rabatów na produkty netto</Title>*/}
            {/*    <Price>{summary.totalDiscountNetProduct} zł</Price>*/}
            {/*</DetailBox>*/}

            <DetailBox>
                <Title>Wartość netto wszystkich rabatów</Title>
                <Price>{(summary.productVatValues.vat_8 + summary.productVatValues.vat_23 + summary.totalDiscountDelivery / 1.23).toFixed(2)} zł</Price>
                {/*<Title>Wartość brutto wszystkich rabatów</Title>*/}
                {/*<Price>{summary.totalDiscount} zł</Price>*/}
                <Title>Suma wartości rabatów brutto (23% VAT)</Title>
                <Price>{(summary.totalDiscountDelivery + (summary.productVatValues.vat_8 + summary.productVatValues.vat_23) * 1.23).toFixed(2)} zł</Price>
            </DetailBox>

            <DetailBox>
                <Title>Wartość rabatów na dostawy brutto </Title>
                <Price>{summary.totalDiscountDelivery} zł</Price>
                <Title>Wartość rabatów na dostawy netto </Title>
                <Price>{(summary.totalDiscountDelivery / 1.23).toFixed(2)} zł</Price>
            </DetailBox>


            <DetailBox>
                <Title>Wartość rabatów netto z Vat_8</Title>
                <Price>{(summary.productVatValues.vat_8).toFixed(2)} zł</Price>
                <Title>Wartość rabatów netto z Vat_23</Title>
                <Price>{(summary.productVatValues.vat_23).toFixed(2)} zł</Price>
            </DetailBox>

            <DetailBox>
                <Title>Ilość transakcji sprzedanych</Title>
                <Price>{summary.totalTransactionSell}</Price>
                <Title>Ilość transakcji zwróconcych</Title>
                <Price>{summary.totalTransactionRefund}</Price>
            </DetailBox>

            <DetailBox>
                <Title>Kwota zwrotów</Title>
                <Price>{summary.totalRefund} zł</Price>
            </DetailBox>

        </SummaryBox>}
        {!isLoadingProductOwners ? <FilterCollapse
            children={<FiltersForm productOwners={productOwners} reloadSummary={getOrdersSettlementSummary}
                                   reload={reload}/>}
        /> : <FormIndicator numberOfElements={1}/>}
        {isLoading ? (<FormIndicator numberOfElements={2}/>) : <>
            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                <PaginationList pagination={data.pagination} FiltersContext={OrderSettlementFilterContext}
                                type={CHANGE_PAGE_AND_LIMIT}/>
            </div>
            <OrderSettlementTable data={data ? data.results : []}/>
        </>}
    </Wrapper>;

}

export default OrderSettlement;
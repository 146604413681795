import useAlert from "../useAlert";
import axiosInstance from "../../lib/axios";

type CreateIngredientGroupProps = {
    id: string,
    name: string;
}

const useUpdateIngredientGroupName = () => {

    const {successMessage, errorMessage} = useAlert();


    const update = async (obj: CreateIngredientGroupProps): Promise<any> => {
        try {
            await axiosInstance.put("/admin/ingredient_group/" + obj.id, obj);
            successMessage("Zapisano");
        } catch (err) {
            errorMessage("Błąd zapisu, spróbuj ponownie");
            throw err;
        }
    };

    return {update};
}

export default useUpdateIngredientGroupName;
import {Form, Input, Modal} from "antd";

const CategorySlugAlt = ({handleOK, onCancel, open, item = null}) => {


    const [form] = Form.useForm();

    return (
        <Modal title={'Aktualizacja Sluga Alternatywnego'}
               open={open}
               onOk={() => {
                   form
                       .validateFields()
                       .then((values) => {
                           form.resetFields();
                           handleOK(values);
                       })
                       .catch((info) => {
                           console.log("Validate Failed:", info);
                       });
               }}
               onCancel={onCancel}
               okText={"Zapisz"}
               cancelText={"Anuluj"}>
            <Form
                layout="vertical"
                form={form}
                name="form_in_modal"
                initialValues={{
                    slugAlt: item ? item.slugAlt : '',
                }}
            >
                <Form.Item
                    label="Nazwa"
                    name="slugAlt"
                    rules={[
                        {required: true, message: "Wpisz nazwę, pole jest wymagane!"},
                    ]}
                >
                    <Input placeholder="Wpisz nazwę "/>
                </Form.Item>
            </Form>
        </Modal>
    );
}

export default CategorySlugAlt;
import React from "react";
import { themes } from "../../../layout/styles/ColorStyles";
import {Button} from "antd";

const CustomButton = ({ title, icon = null, onClick, isLoading = false }) => {
  return (
      <Button loading={isLoading} style={{
          backgroundColor: themes.activeMenuItemColor,
          color: 'black',
          display: 'flex',
          alignItems: 'center',
          gap: '10px',
          borderRadius: '10px'
      }} onClick={onClick}>
          {icon ? <div>{icon}</div> : null}
          <div>{title}</div>
      </Button>

  );
};

export default CustomButton;

import {useState} from "react";
import useAssignDeliveryToProductOwner from "../../../hooks/productOwnerDeliveries/useAssignDeliveryToProductOwner";
import moment from "moment";
import NewDeliveryAssigmentForProductOwnerForm
    from "../../Forms/ProductOwnerDeliveries/NewDeliveryAssigmentForProductOwnerForm";
import {Button} from "antd";
import {useParams} from "react-router-dom";

const AssignNewDeliveryToProductOwnerModel = ({ reload, deliveries }) => {
    const [open, setIsOpen] = useState(false);
    const { assign } = useAssignDeliveryToProductOwner();
    const params = useParams();


    const handleOk = (values) => {
        assign({
            productOwnerId: params.id,
            deliveryId: values.delivery,
            freeFromPrice: values.freeFromPrice,
            price: values.price,
            isFreeFromPrice: values.isFreeFromPrice,
            isActive: values.isActive,
            deliveryDurationTime: values.deliveryDurationTime,
            maxTimeSendSameDay: values.isDeliverySameDay
                ? moment(values.maxTimeSendSameDay).format("HH:ss")
                : null,
            isDeliveryNextDay: values.isDeliverySameDay,
        }).finally(() => {
            reload();
            setIsOpen(false);
        });
    };

    return (
        <>
            <Button
                style={{ marginBottom: "24px" }}
                type="primary"
                onClick={() => setIsOpen(!open)}
            >
                Dodaj nową metodę dostawy
            </Button>
            <NewDeliveryAssigmentForProductOwnerForm
                title={"Dodaj nową metodę dostawy"}
                deliveries={deliveries}
                handleOk={handleOk}
                onCancel={() => setIsOpen(false)}
                open={open}
            />
        </>
    );
}

export default AssignNewDeliveryToProductOwnerModel;
import {useState} from "react";
import useUpdateBrandGroupName from "../../../hooks/brandGroup/useUpdateBrandGroupName";
import ButtonLink from "../../Buttons/ButtonLink";
import BrandGroupForm from "../../Forms/BrandGroup/BrandGroupForm";

const UpdateBrandGroupName = ({item, reload}) => {
    const [updateOpenModal, setUpdateOpenModal] = useState(false);
    const {update} = useUpdateBrandGroupName();

    const handleOk = (values) => {
        let obj = {
            id: item.id,
            ...values
        }

        update(obj).then(() => {
            reload();
            setUpdateOpenModal(false);
        })
    }

    return <>
        <ButtonLink text={'Edycja'} onClick={() => setUpdateOpenModal(true)}/>
        <BrandGroupForm
            name={item.name}
            open={updateOpenModal}
            onCancel={() => setUpdateOpenModal(false)}
            handleOK={handleOk}
        />
    </>
}

export default UpdateBrandGroupName;
import useAlert from "../useAlert";
import axiosInstance from "../../lib/axios";

interface UpdateBlogPostSeoInfoProps {
    id: string
    metTitle?: string
    metaDescription?: string
}


const useUpdateBlogPostSeoInfo = () => {

    const {successMessage, errorMessage} = useAlert();

    const updateSeoInfo = async (obj: UpdateBlogPostSeoInfoProps): Promise<any> => {
        try {
            await axiosInstance.put("/admin/blog/post/blog-post-seo/" + obj.id, obj);
            successMessage("Dane zostały zaaktualizowane");
        } catch (err) {
            errorMessage("Wystąpił błąd podczas aktualizacji strony");
            throw err;
        }
    };

    return { updateSeoInfo };
}

export default useUpdateBlogPostSeoInfo;
import {useParams} from "react-router-dom";
import useGetAlgorytmLogs from "../../hooks/products/algorytm/useGetAlgorytmLogs";
import React, {useEffect} from "react";
import styled from "styled-components";
import Title from "../../components/Title";
import Paper from "../../layout/Paper";
import FormIndicator from "../../components/Forms/FormIndicator";
import {Collapse} from "antd";
import ReactJson from "react-json-view";

const Wrapper = styled.div`
  position: relative;
`;

const { Panel } = Collapse;

const AlgorytmPage = () => {

    const params = useParams();
    const {data, isLoading, getLogsAlgroytm} = useGetAlgorytmLogs();

    useEffect(() => {
        getLogsAlgroytm(params.id);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    return <Wrapper>
        <div><Title title="Logi dla produktu"/></div>
        <Paper>
            {
                isLoading ? <FormIndicator numberOfElements={1}/> : (
                    <Collapse defaultActiveKey={['0']}>
                        {
                            data.map((it, index) => (
                                <Panel header={it.result + ' ' +  it.type} key={index}>
                                        <pre style={{
                                            overflow: 'auto',
                                            whiteSpace: 'pre-wrap',
                                        }}>

                                            {
                                                <ReactJson
                                                    displayDataTypes={false}
                                                    src={it.log}
                                                    theme="flat"
                                                />
                                   }
                                        </pre>
                                </Panel>
                            ))
                        }
                    </Collapse>
                )
            }
        </Paper>
    </Wrapper>;
}

export default AlgorytmPage;
import {Form, Input, Modal, Select} from "antd";
import useKeypress from "../../../../hooks/useKeypress";

const AssignIngredientToProductForm = ({open, ingredients, handleOk, onCancel}) => {

    const [form] = Form.useForm();

    const dispatchForm = () => {
        form
            .validateFields()
            .then((values) => {
                form.resetFields();
                handleOk(values);
            })
            .catch((info) => {
                console.log("Validate Failed:", info);
            });
    }



    useKeypress('Alt', 'Enter', () => {
        if (open) {
            dispatchForm();
        }
    });


    return (
        <Modal
            visible={open}
            onOk={() => dispatchForm()}
            onCancel={onCancel}
            cancelText={"Anuluj"}
            okText={"Zapisz [Alt+Enter]"}
        >

            <Form
                layout="vertical"
                form={form}
                name="form_in_modal"
            >
                <Form.Item
                    label="Nazwa składnika"
                    name="ingredientId"
                    rules={[
                        {required: true, message: "Wpisz nazwę, pole jest wymagane!"},
                    ]}
                >
                    <Select
                        id={"select-ing"}
                        showSearch
                        // mode="single"
                        style={{width: '100%'}}
                        placeholder="Wybierz składnik"
                        optionFilterProp="children"
                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                        filterSort={(optionA, optionB) =>
                            optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                        }
                    >
                        {ingredients.map((ingredient) => (
                            <Select.Option key={ingredient.id} value={ingredient.id}>
                                {ingredient.name}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item
                    label="Wartość/100g"
                    name="value"
                >
                    <Input placeholder="Wpisz wartość składnika"/>
                </Form.Item>
            </Form>
        </Modal>
    );
}

export default AssignIngredientToProductForm;
import React, { useReducer } from "react";
import {initialState, UserFilterReducer} from "../reducers/UserFiltersReducres";

const UserFiltersContext = React.createContext();

export const UserFiltersProvider = ({children}) => {

    const [state, dispatch] = useReducer(UserFilterReducer, initialState);

    return <UserFiltersContext.Provider value={{state, dispatch}}>
        {children}
    </UserFiltersContext.Provider>
}

export default UserFiltersContext;
import React from "react";
import styled from "styled-components";
import { FaArrowUp, FaArrowDown } from "react-icons/fa";

const Wrapper = styled.div`
  width: 35px;
  height: 35px;
  background-color: #ffffff;
  opacity: ${(props) => (props.active ? 1 : 0.3)};
  box-shadow: 0 1px 3px 1px rgb(0 0 0 / 10%);
  color: #526484;
  display: flex;
  align-items: center;
  justify-content: center;

  :hover {
    cursor: pointer;
  }
`;

const PositionButton = ({ direction = "up", onClick, active }) => {
  return (
    <Wrapper
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        if (active) {
          onClick();
        }
      }}
      active={active}
    >
      <div>{direction === "up" ? <FaArrowUp /> : <FaArrowDown />}</div>
    </Wrapper>
  );
};

export default PositionButton;

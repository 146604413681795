import {Form, Input, Modal} from "antd";

const FlavourForm = ({handleOK, onCancel, open, title, item = null,}) => {
    const [form] = Form.useForm();

    return (
        <Modal
            title={title}
            open={open}
            onOk={() => {
                form
                    .validateFields()
                    .then((values) => {
                        form.resetFields();
                        handleOK(values);
                    })
                    .catch((info) => {
                        console.log("Validate Failed:", info);
                    });
            }}
            onCancel={onCancel}
            okText={!item ? "Dodaj nowy smak" : "Zapisz"}
            cancelText={"Anuluj"}>
            <Form
                layout="vertical"
                form={form}
                name="form_in_modal"
                initialValues={{
                    name: item ? item.name : '',
                }}
            >
                <Form.Item
                    label="Nazwa"
                    name="name"
                    rules={[
                        {required: true, message: "Wpisz nazwę, pole jest wymagane!"},
                    ]}
                >
                    <Input placeholder="Wpisz nazwę smaku"/>
                </Form.Item>
            </Form>
        </Modal>
    );
}

export default FlavourForm;
import {useEffect, useState} from "react";
import useGetAllIngredientGroup from "../../hooks/ingredientGroup/useGetAllIngredientGroup";
import styled from "styled-components";
import Title from "../../components/Title";
import CustomButton from "../../components/Forms/Buttons/CustomButton";
import {FaPlus} from "react-icons/fa";
import FormIndicator from "../../components/Forms/FormIndicator";
import CreateIngredientGroup from "../../components/Modals/IngredientGroup/CreateIngredientGroup";
import IngredientGroupTable from "../../components/Tables/IngredientGroup/IngredientGroupTable";
import useGetAllIngredients from "../../hooks/ingredient/useGetAllIngredients";
import CreateIngredient from "../../components/Modals/Ingredient/CreateIngredient";
import useGetAllGrammage from "../../hooks/grammage/useGetAllGrammage";
import useGetAllFlavours from "../../hooks/flavours/useGetAllFlavours";
import CreateFlavourModal from "../../components/Modals/Flavours/CreateFlavourModal";

const Wrapper = styled.div`
  position: relative;
`;

const IngredientGroup = () => {

    const [openModal, setOpenModal] = useState(false);
    const [openModalIngredient, setOpenModalIngredient] = useState(false);
    const [openModalFlavour, setOpenModalFlavour] = useState(false);
    const {data, isLoading, reload} = useGetAllIngredientGroup();
    const {data: ingredients, isLoading: isLoadingIngredients, reload: reloadIngredients} = useGetAllIngredients();
    const {data: grammages, isLoading: isLoadingGrammages, getGrammage} = useGetAllGrammage();
    const {data: flavours, isLoading: isLoadingFlavours, reload: reloadFlavours} = useGetAllFlavours();

    useEffect(() => {
        getGrammage();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (<Wrapper>
        <Title title="Grupy składników(alergenów)"/>
        <div style={{display: 'flex', flexDirection: 'row', gap: '10px'}}>
            <CustomButton title='Utwórz grupę' icon={<FaPlus/>} onClick={() => setOpenModal(true)}/>
            <CustomButton title='Utwórz składnik' icon={<FaPlus/>} onClick={() => setOpenModalIngredient(true)}/>
            <CustomButton title='Utwórz smak' icon={<FaPlus/>} onClick={() => setOpenModalFlavour(true)}/>
        </div>

        {isLoading || isLoadingIngredients || isLoadingGrammages || isLoadingFlavours ? <FormIndicator numberOfElements={3}/> : <>
            <IngredientGroupTable data={data} reload={reload} ingredients={ingredients} flavours={flavours}/>
            <CreateIngredient
                grammages={grammages}
                handleClose={() => setOpenModalIngredient(false)}
                openModal={openModalIngredient}
                reload={reloadIngredients}
            />

        </>}
        <CreateIngredientGroup
            handleClose={() => setOpenModal(false)}
            reload={reload}
            openModal={openModal}
        />

        <CreateFlavourModal
            open={openModalFlavour}
            setOpenModal={setOpenModalFlavour}
            reload={() => reloadFlavours()}
        />
    </Wrapper>);
}

export default IngredientGroup;
import {useEffect, useState} from "react";
import axiosInstance from "../../lib/axios";

const useGetAllMarketingBoxes = () => {

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [data, setData] = useState<{} | null>(null);

    useEffect(() => {
        getMarketingBoxes();
    }, []);

    const getMarketingBoxes = async (): Promise<any> => {
        try {
            const res = await axiosInstance.get(`/admin/marketing-box/`);

            if (res.status === 200) {
                setData(res.data ? res.data : []);
            }
            setIsLoading(false);
        } catch (err) {
            setIsLoading(false);
        }
    }

    return {isLoading, data, getMarketingBoxes};
}

export default useGetAllMarketingBoxes;
import {useContext} from "react";
import CartFilterContext from "../../../context/CartFilterContext";
import {Select} from "antd";
import {UPDATE_CART_STATUS} from "../../../reducers/CartFilterReducers";
import cartStatus from "../../../const/cartStatus";

const CartStatusSelect = () => {

    const {dispatch, state} = useContext(CartFilterContext);

    return <Select
        placeholder="Wybierz status"
        allowClear={true}
        defaultValue={state.status}
        onChange={(e) => dispatch({type: UPDATE_CART_STATUS, payload: e})}
        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
        showSearch
    >
        {cartStatus.map((it) => (
            <Select.Option key={it.value} value={it.value}>
                {it.label}
            </Select.Option>
        ))}
        <Select.Option key={0} value="">Wszystkie</Select.Option>
    </Select>
}

export default CartStatusSelect;
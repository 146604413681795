import styled from "styled-components";
import Title from "../../components/Title";
import {FaPlus} from "react-icons/fa";
import CustomButton from "../../components/Forms/Buttons/CustomButton";
import {useState} from "react";
import FormIndicator from "../../components/Forms/FormIndicator";
import useGetAllProductCustomList from "../../hooks/productCustomList/useGetAllProductCustomList";
import ProductCustomListTable from "../../components/Tables/ProductCustomList/ProductCustomListTable";
import CreateProductCustomList from "../../components/Modals/ProductCustomList/CreateProductCustomList";

const Wrapper = styled.div`
  position: relative;
`;


const ProductCustomList = () =>{

    const [openModal, setOpenModal] = useState(false);
    const {data, isLoading, reload }= useGetAllProductCustomList();
    return <>
        <Wrapper>
            <Title title="Listy produktowe"/>
            <CustomButton
                title="Utwórz"
                icon={<FaPlus />}
                onClick={() => setOpenModal(true)}
            />
            {isLoading ? <FormIndicator numberOfElements={1} /> : <ProductCustomListTable data={data} reload={() => reload()}/>}
        <CreateProductCustomList handleClose={() => setOpenModal(false)} openModal={openModal} reload={reload}/>
        </Wrapper>
    </>;
}

export default ProductCustomList;
import axiosInstance from "../../lib/axios";

interface UpdatePCLSortByProps {
    id: string
    sortBy: string
}
const useUpdateProductCustomListSortBy = () => {

    const update = async (obj: UpdatePCLSortByProps): Promise<any> => {
        try {
            await axiosInstance.put("/admin/product_custom_list/sort_by/" + obj.id, obj);
        } catch (err) {
            throw err;
        }
    };

    return { update };
}
export default useUpdateProductCustomListSortBy;
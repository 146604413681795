import Modal from "react-modal";
import useAlert from "../../../hooks/useAlert";
import useUpdateProductOwnerInfo from "../../../hooks/productOwners/useUpdateProductOwnerInfo";
import {customStyles, IconWrapper, Title} from "../../../layout/styles/ModalStyle";
import {FaPlus} from "react-icons/fa";
import CreateProductOwner from "../../Forms/ProductOwner/CreateProductOwner";

Modal.setAppElement("#root");

const UpdateProductOwnerModal = ({reload, item, openModal, handleClose}) => {

    const { errorMessage } = useAlert();
    const { update } = useUpdateProductOwnerInfo();

    const handleSave = (obj) => {
        update(item.id, obj.name)
            .then(() => {
                handleClose();
                reload();
            })
            .catch((err) => {
                console.log(err);
                errorMessage("Błąd aktualizacji sklepu. Spróbuj ponownie.");
            });
    }

    return (
        <>
            <Modal
                isOpen={openModal}
                onRequestClose={handleClose}
                style={customStyles}
                contentLabel="Example Modal"
            >
                <div style={{ width: "600px" }}>
                    <Title>
                        <IconWrapper>
                            <FaPlus style={{ color: "white" }} />
                        </IconWrapper>
                        Edytuj Hurtownie: {item.name}
                    </Title>
                    <CreateProductOwner save={handleSave} name={item.name} code={item.code}/>
                </div>
            </Modal>
        </>
    );

}

export default UpdateProductOwnerModal;
import {Select} from "antd";

const AbandonedCartContactSelect = ({value, handleChange}) => {


    return <Select
        mode="single"
        style={{width: '100%'}}
        value={value}
        onChange={handleChange}
        name="isPublished"
    >
        <Select.Option key={'no_action'} value={'no_action'}>
            -
        </Select.Option>
        <Select.Option key={'no_answer'} value={'no_answer'}>
            Brak odpowiedzi
        </Select.Option>
        <Select.Option key={'new_order'} value={'new_order'}>
            Złożono nowe zamówienie
        </Select.Option>
        <Select.Option key={'cancelled_by_user'} value={'cancelled_by_user'}>
            Anulowane przez użytkownika
        </Select.Option>
    </Select>
}

export default AbandonedCartContactSelect;
import {useState} from "react";
import axiosInstance from "../../lib/axios";

const useCheckOrderCanBeRefunded = () => {

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [data, setData] = useState<{} | null>(null);

    const checkRefund = async (id: any): Promise<any> => {

        try {
            const res = await axiosInstance.get(`/admin/order/check-refund/` + id);

            if (res.status === 200) {
                setData(res.data ? res.data : []);
                setIsLoading(false);
            }
        } catch (err) {
            setIsLoading(false);
        }
    };

    return {isLoading, data, checkRefund};
}

export default useCheckOrderCanBeRefunded;
import {useState} from "react";
import axiosInstance from "../../lib/axios";

const useGetPetsByUserId = () => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [data, setData] = useState<{} | null>(null);

    const getPetsUser = async (id: string): Promise<any> => {
        try {
            const res = await axiosInstance.get(`/admin/pet/user/` + id);

            if (res.status === 200) {
                setData(res.data ? res.data : []);
                setIsLoading(false);
            }
        } catch (err) {
            setIsLoading(false);
        }
    }


    return {isLoading, data, getPetsUser};
}

export default useGetPetsByUserId;
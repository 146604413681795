import ButtonLink from "../../Buttons/ButtonLink";
import {useState} from "react";
import {Modal, Typography} from "antd";
import useRemoveVariant from "../../../hooks/productVariants/useRemoveVariant";

const {Text} = Typography;

const DeleteProductVariant = ({row, reload}) => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const {removeProductVariant} = useRemoveVariant();

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };


    const handleOk = () => {
        removeProductVariant(row.id).then(reload);
    }

    return <><ButtonLink onClick={() => showModal()} text={'Usuń wariant'}/>
        <Modal title="Potwierdź zmiany" open={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
            <Text strong>
                Czy na pewno chcesz usunąć wariant?
            </Text>
        </Modal></>
}

export default DeleteProductVariant;
import { Table} from "antd";
const DiscountGroupCodesTable = ({data}) => {

    const columns = [
        // {
        //     title: "Aktywność",
        //     dataIndex: "",
        //     width: "10%",
        //     render: (row) => (
        //         <Switch
        //             style={{
        //                 backgroundColor: row.active
        //                     ? themes.activeMenuItemColor
        //                     : themes.switchInActive,
        //             }}
        //             checkedChildren={<CheckOutlined/>}
        //             unCheckedChildren={<CloseOutlined/>}
        //             checked={row.active}
        //             onChange={(checked) => handleUpdateCodeActivity(row, checked)}
        //         />
        //     ),
        // },
        {
            title: "Kod rabatowy",
            dataIndex: "code",
            width: "15%",
        },
        {
            title: "Użyto kodu",
            dataIndex: "usageCount",
            width: "15%",
        },
        {
            title: "Pozostało do użycia",
            dataIndex: "",
            width: "15%",
            render: row => row.maxUse - row.usageCount
        },
        {
            title: "Maksymalna ilość użyć kodu",
            dataIndex: "maxUse",
            width: "15%",
        },
        {
            title: "Data utworzenia",
            dataIndex: "createdAt",
            width: "15%",
        },
    ];


    return <Table pagination={false} columns={columns} dataSource={data.map((it) => {
        return {
            key: it.id, ...it
        }
    })}/>;
}

export default DiscountGroupCodesTable;
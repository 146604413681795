import styled from "styled-components";
import {useState} from "react";
import useGetAllTags from "../../hooks/tags/useGetAllTags";
import Title from "../../components/Title";
import {FaPlus} from "react-icons/fa";
import CustomButton from "../../components/Forms/Buttons/CustomButton";
import FormIndicator from "../../components/Forms/FormIndicator";
import TableTags from "../../components/Tables/TableTags";
import CreateTagModal from "../../components/Modals/Tags/CreateTagModal";

const Wrapper = styled.div`
  position: relative;
`;

const Tags = () => {
    const [openModal, setOpenModal] = useState(false);
    const {data, isLoading, reload} = useGetAllTags();

    return <>
        <Wrapper>
            <Title title="Tagi"/>
            <CustomButton
                title="Utwórz"
                icon={<FaPlus/>}
                onClick={() => setOpenModal(true)}
            />
            {isLoading ? <FormIndicator numberOfElements={1}/> : <TableTags data={data} reload={reload}/>}
        </Wrapper>
        <CreateTagModal
            openModal={openModal}
            setOpenModal={setOpenModal}
            reload={reload}/>
    </>;
}

export default Tags;
import useAlert from "../useAlert";
import axiosInstance from "../../lib/axios";

type CreateBrandGroupProps = {
    name: string;
}

const useCreateBrandGroup = () => {

    const {successMessage, errorMessage} = useAlert();

    const create = async (obj: CreateBrandGroupProps): Promise<any> => {
        try {
            await axiosInstance.post("/admin/brand/group/", obj);
            successMessage("Zapisano");
        } catch (err) {
            errorMessage("Błąd zapisu, spróbuj ponownie");
            throw err;
        }
    };

    return {create};
}

export default useCreateBrandGroup;